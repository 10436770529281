import { FC } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import notificationsStore from 'stores/notifications.store';
import chessLogo from 'assets/images/chess_logo.png';
import { ReactComponent as User } from 'assets/images/svg/user.svg';
import { ReactComponent as Bell } from 'assets/images/svg/bell.svg';
import appStyles from 'App.module.scss';
import styles from './Header.module.scss';

interface ILogoSectionProps {
  toggleAccountNav: React.MouseEventHandler<HTMLButtonElement>;
  toggleNotificationNav: React.MouseEventHandler<HTMLButtonElement>;
  unreadNotificationCount: number;
}

const LogoSection: FC<ILogoSectionProps> = ({ toggleAccountNav, toggleNotificationNav, unreadNotificationCount }) => (
  <div className={styles.header_right}>
    <div className={styles.nav__logoContainer}>
      <img className={styles.nav__logo} src={chessLogo} alt="chess logo" />
    </div>
    <div className={styles.headerButtons}>
      <button
        id="notification_button"
        onClick={toggleNotificationNav}
        type="button"
        data-toggle="collapse"
        data-target="#notificationsContent"
        aria-controls="notificationsContent"
        aria-expanded="false"
        aria-label="Toggle latest notifications"
        className={classNames(styles.header__button, styles.notification__button, appStyles.button_icon)}
        title="Notifications">
        <Bell height="24px" />
        {unreadNotificationCount !== 0 && (
          <span className={styles.notification__count} id="unreadNotificationCount">
            {notificationsStore.unreadNotificationCount}
          </span>
        )}
      </button>
      <button
        onClick={toggleAccountNav}
        className={classNames(styles.header__button, appStyles.button_icon, styles.accountNav__toggle)}
        type="button"
        data-toggle="collapse"
        data-target="#navbarAccountContent"
        aria-controls="navbarAccountContent"
        aria-expanded="false"
        aria-label="Toggle account info"
        title="Account menu">
        <User height="24px" />
      </button>
    </div>
  </div>
);

export default observer(LogoSection);
