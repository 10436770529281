import { IExceptionTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';
import moment, { Moment } from 'moment';
import api from 'api/api';
import appInsights from 'App';
import { FalconError } from 'models/generic/falconError.model';
import userStore from 'stores/user.store';
import { Routing } from 'utils/routing';

class Utils {
  environment = {
    get isProduction() {
      return process.env.REACT_APP_ENVIRONMENT_NAME === 'Production';
    },
    get isDevelopment() {
      return process.env.REACT_APP_ENVIRONMENT_NAME === 'Development';
    }
  };

  session = {
    getFromSession<T>(key: string): T | null {
      let result: T | null = null;
      const json = sessionStorage.getItem(key);

      if (json) {
        result = JSON.parse(json) as T;
      }

      return result;
    },

    saveToSession(key: string, obj: unknown) {
      if (obj !== null && obj !== undefined) {
        sessionStorage.setItem(key, JSON.stringify(obj));
      } else {
        this.removeFromSession(key);
      }
    },

    removeFromSession(key: string) {
      sessionStorage.removeItem(key);
    }
  };

  localStorage = {
    getFromLocalStorage<T>(key: string): T | null {
      let result: T | null = null;
      const json = localStorage.getItem(key);
      if (json) {
        result = JSON.parse(json) as T;
      }

      return result;
    },

    saveToLocalStorage(key: string, obj: unknown) {
      if (obj !== null && obj !== undefined) {
        localStorage.setItem(key, JSON.stringify(obj));
      } else {
        this.removeFromLocalStorage(key);
      }
    },

    removeFromLocalStorage(key: string) {
      localStorage.removeItem(key);
    }
  };

  dateTime = {
    isWeekend(date: Date | string | Moment) {
      const momentDate = moment.utc(date);

      return momentDate.isoWeekday() === 6 || momentDate.isoWeekday() === 7;
    },

    getFormattedMomentDate(m: Moment): string {
      return m ? m.format('Do MMMM YYYY') : '';
    },

    getFormattedMomentTime(m: Moment): string {
      return m ? m.format('HH:mm') : '';
    },

    getFormattedMomentDateAndTime(m: Moment): string {
      return m ? m.format('Do MMMM YYYY, HH:mm') : '';
    },

    getFormattedDate(d: string | Date) {
      let date: Date;

      if (d instanceof Date) {
        date = d;
      } else {
        date = new Date(d);
      }

      return moment(date).format('Do MMMM YYYY');
    },

    toMomentFromUtc(dateTime: Date | Moment, toLocal: boolean): Moment {
      if (dateTime) {
        // convert to moment
        if (dateTime instanceof Date) {
          dateTime = moment(dateTime);
        }

        if (dateTime) {
          dateTime = moment.utc(dateTime);

          if (dateTime && toLocal) {
            dateTime = dateTime.local();
          }
        }

        return dateTime;
      } else {
        return moment.invalid();
      }
    }
  };

  insights = {
    logErrorToAppInsights(error: FalconError) {
      const errorObject: IExceptionTelemetry = {
        exception: new Error(error.errorMessages[0]),
        severityLevel: SeverityLevel.Error
      };

      // log error code, status and messages
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const properties: any = {};
      if (error.code) {
        properties.code = error.code;
      }
      if (error.statusText) {
        properties.statusText = error.statusText;
      }
      properties.messages = error.errorMessages;

      // log current URL and user
      properties.url = document.location.href;

      if (userStore.currentUser) {
        properties.userId = userStore.currentUser.id;
        properties.userName = userStore.currentUser.fullName;
        properties.userEmail = userStore.currentUser.email;
        properties.userB2C = userStore.currentUser.b2c;
        if (userStore.currentUser.currentClient) {
          properties.userClientId = userStore.currentUser.currentClient.clientId;
        }
      }

      // log session ID
      const sessionValue = appInsights.context.getSessionId();
      if (sessionValue) {
        properties.session = sessionValue;
      }

      errorObject.properties = properties;

      appInsights.trackException(errorObject);
    }
  };

  currency = {
    formatNumberAsCurrency(value: number | null) {
      return value || value === 0 ? '£' + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '';
    }
  };

  ip = {
    async getCurrentIpAddress(): Promise<string | null> {
      const ipAddressUrl = process.env.REACT_APP_IP_ADDRESS_API ? process.env.REACT_APP_IP_ADDRESS_API : '';

      const result = await api.getFromExternal<string>(ipAddressUrl, 'Unable to check your IP address.');
      if (result instanceof FalconError) {
        return null;
      } else {
        return result;
      }
    }
  };

  routing = new Routing();
}

export default new Utils();
