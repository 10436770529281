import appStyles from 'App.module.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { IBaseProps } from 'models/generic/iBaseProps';
import { Component } from 'react';
import cookie from 'react-cookies';
import { Link, withRouter } from 'react-router-dom';
import Button from '../FormElements/Button/Button';
import styles from './CookiePopup.module.scss';

@observer
class CookiePopup extends Component<IBaseProps> {
  allowAnalyticsCookies = cookie.load('allowAnalyticsCookies');
  @observable showCookieDialog = false;

  componentDidMount() {
    this.setDialog();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidUpdate(prevProps: IBaseProps) {
    this.setDialog();
  }

  setDialog() {
    this.allowAnalyticsCookies = cookie.load('allowAnalyticsCookies');
    if (this.props.location.pathname === '/cookies') {
      this.showCookieDialog = false;
    } else {
      // If allowAnalyticsCookies is undefined or if allowAnalyticsCookies.expiry is before today show the dialog
      if (this.allowAnalyticsCookies === undefined) {
        this.showCookieDialog = true;
      }
    }
  }

  setCookiePreference(acceptCookies: boolean) {
    const expiry = new Date();
    expiry.setFullYear(new Date().getFullYear() + 1);
    if (acceptCookies) {
      cookie.save('allowAnalyticsCookies', 'true', { expires: expiry });
    } else {
      cookie.save('allowAnalyticsCookies', 'false', { expires: expiry });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any)[`ga-disable-${process.env.REACT_APP_GA_KEY}`] = true;

      this.deleteGaCookies();
    }

    this.showCookieDialog = false;
  }

  deleteGaCookies() {
    // manually remove any GA cookies
    const cookies = cookie.loadAll();
    const names = Object.keys(cookies);
    const options = {
      path: '/',
      domain: process.env.REACT_APP_GA_COOKIE_DOMAIN
    };
    names.forEach((cookieName) => {
      if (cookieName.startsWith('_ga') || cookieName.startsWith('_gat') || cookieName.startsWith('_gid')) {
        cookie.remove(cookieName, options);
      }
    });
  }

  render() {
    return (
      <div className={`${styles.cookie} ${this.showCookieDialog ? '' : styles.cookie_hidden}`} id="cookieDialog">
        <div className={`${styles.cookie__heading} ${appStyles.text_midBlue}`}>Cookies</div>
        <div className={styles.cookie__content}>
          <p className={`${styles.cookie__text} ${appStyles.text_midBlue}`}>
            We use cookies to improve your experience and collect statistics to optimise site functionality. By using the Customer Portal, you agree to our{' '}
            <Link to="/cookies" className={styles.cookie__link} title="Cookie Policy">
              Cookie Policy
            </Link>
            .
          </p>
          <p className={`${styles.cookie__text} ${appStyles.text_midBlue}`}>
            You can choose to accept or opt out of analytical cookies below or view our{' '}
            <Link to="/cookies" className={styles.cookie__link} title="Cookie Policy">
              Cookie Policy
            </Link>{' '}
            for more information.
          </p>
        </div>
        <div className={styles.cookie__section}>
          <Button
            customClass={styles.cookie__button}
            buttonStyle="outline_primary"
            id="cookieOptOut"
            size="md"
            handleClick={() => {
              this.setCookiePreference(false);
            }}>
            opt out
          </Button>
          <Button
            customClass={styles.cookie__button}
            buttonStyle="primary"
            id="cookieOptIn"
            size="md"
            handleClick={() => {
              this.setCookiePreference(true);
            }}>
            accept
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(CookiePopup);
