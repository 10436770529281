import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { IRouteIdProp } from 'routing/types';
import lineFaultsStore from 'stores/lineFaults.store';
import messagesStore from 'stores/messages.store';
import { messages } from 'utils/messages';
import AgreementDialog from 'components/Generic/AgreementDialog/AgreementDialog';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import Icon from 'components/Generic/Icon/icon';
import FaultFound from 'components/Services/LineFaults/LineCheckResults/FaultFound';
import NoFaultFound from 'components/Services/LineFaults/LineCheckResults/NoFaultFound';
import appStyles from 'App.module.scss';
import Preloader from 'components/Generic/Preloader/Preloader';
import engineerAppointmentStyles from '../EngineerAppointmentSelection/EngineerAppointment.module.scss';
import lineDataFormStyles from '../LineDataForm/LineDataForm.module.scss';
import lineFaultStyles from '../LineFaults.module.scss';
import lineCheckResultsStyles from './LineCheckResults.module.scss';

@observer
class LineCheckResults extends Component<RouteComponentProps<IRouteIdProp>> {
  @observable dialogVisible = false;

  @action toggleDialog = () => {
    this.dialogVisible = !this.dialogVisible;
  };

  @computed get engineerAppointmentDisabled() {
    return !(lineFaultsStore.lineFaultCheck?.isFault || lineFaultsStore.lineFaultCheck?.noFaultCheckListConfirmed || lineFaultsStore.pending);
  }

  @computed get hasLineAssurance() {
    return lineFaultsStore.lineFaultCheck?.hasLineAssurance ?? false;
  }

  handleBookEngineerAppointment = () => {
    if (this.hasLineAssurance) {
      return this.props.history.push(`/services/line-faults/${this.props.match.params.id}/engineer-appointment`);
    } else {
      this.toggleDialog();
    }
  };

  acceptAndProceed = async () => {
    try {
      const result = await lineFaultsStore.updateAcknowledgeAcceptance({ timeRelatedChargesAccepted: true });

      if (!result.timeRelatedChargesAccepted) {
        messagesStore.addErrorAsString('Something went wrong during accepting time-related charges. Please, try again later');
        throw new Error('Something went wrong during accepting time-related charges. Please, try again later');
      }

      return this.props.history.push(`/services/line-faults/${this.props.match.params.id}/engineer-appointment`);
    } finally {
      this.toggleDialog();
    }
  };

  handleStartAnotherTest = () => {
    const { history } = this.props;

    lineFaultsStore.cleanResults();

    return history.push('/services/line-faults');
  };

  render() {
    return (
      <div>
        {lineFaultsStore.pending ? (
          <div className={engineerAppointmentStyles.preloader__container}>
            <Preloader id="lineTestPreloader" titleClass={lineDataFormStyles.form__title} />
          </div>
        ) : (
          <>
            <div className={classNames(lineFaultStyles.header__container, appStyles.heading)}>
              <Icon icon={Icon.tickets} customClass={classNames(appStyles.noPointerEvents, appStyles.heading__icon)} size="xl" color="midBlue" iconName="cog" />
              <h1 className={classNames(appStyles.heading__text, appStyles.text_midBlue)}>
                <span>
                  Line Check Results -{' '}
                  <span id="checkedCli" className={lineCheckResultsStyles.header__identifier}>
                    {lineFaultsStore.lineFaultCheck?.cli}
                  </span>
                </span>
              </h1>
            </div>
            {lineFaultsStore.isFault ? (
              <FaultFound
                automaticallyRaised={!!lineFaultsStore.lineFaultCheck?.troubleReportReference}
                ticketId={lineFaultsStore.lineFaultCheck?.supportTicketId}
                message={lineFaultsStore.faultCheckResponseMessage}
              />
            ) : (
              <NoFaultFound hasLineAssurance={this.hasLineAssurance} />
            )}
            {!lineFaultsStore.troubleReportRaised && (
              <>
                <div className={lineCheckResultsStyles.button__wrapper}>
                  <Button
                    size="lg"
                    buttonStyle="primary"
                    id="btnRaiseTroubleTicket"
                    handleClick={this.handleBookEngineerAppointment}
                    customClass={lineCheckResultsStyles.button}
                    disabled={this.engineerAppointmentDisabled}>
                    <span>Book Engineer Appointment</span>
                    <Icon icon={Icon.chevronRight} size="lg" color="currentColor" iconName="chevron-right" />
                  </Button>
                </div>
                <AgreementDialog
                  visible={this.dialogVisible}
                  onCancel={this.toggleDialog}
                  onApply={this.acceptAndProceed}
                  message={messages.additionalChargesAgreement}
                />
              </>
            )}
          </>
        )}
        <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
          <Button id="btnPreformAnotherTest" key="btnPreformAnotherTest" buttonStyle="outline_secondary" size="lg" handleClick={this.handleStartAnotherTest}>
            <span>Perform Another Test</span>
          </Button>
        </BottomButtonContainer>
      </div>
    );
  }
}

export default LineCheckResults;
