import { Component } from 'react';
import { Prompt } from 'react-router';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Confirmation } from 'components/Generic/Confirmation/Confirmation';
import Button from 'components/Generic/FormElements/Button/Button';
import Icon from 'components/Generic/Icon/icon';
import { DirectDebit } from 'models/api/directDebit.model';
import { DirectDebitDownload } from 'models/api/directDebitDownloadLog.model';
import { FalconError } from 'models/generic/falconError.model';
import invoicesStore from 'stores/invoices.store';
import constants from 'utils/constants';
import appStyles from 'App.module.scss';

interface IStep4Props {
  currentStep: number;
  directDebit: DirectDebit;
}

@observer
export class Step4 extends Component<IStep4Props> {
  @observable letterDownloaded = false;

  ddLetter: unknown = null;
  ddDownloadForm = new DirectDebitDownload();

  confirmationMessage = `If you have any questions, don't hesitate to get in contact with Customer Service on ${constants.keyVariables.helpdeskTelephone}.`;

  clickDownload = async () => {
    // Return PDF of download letter from invoice store in new tab.
    if (this.props.directDebit && this.props.directDebit.sageAccountNumber !== null) {
      // log download initiated
      await this.logDownloadEvent('Started', 'OK');

      // get letter
      this.ddLetter = await invoicesStore.getDirectDebitLetter(this.props.directDebit.sageAccountNumber as string);

      // log download completed
      if (this.ddLetter instanceof FalconError) {
        await this.logDownloadEvent('Completed', 'Failed');
      } else {
        await this.logDownloadEvent('Completed', 'OK');
      }

      // completed dd download
      this.letterDownloaded = true;
    } else {
      throw new Error('Could not download this Direct Debit letter');
    }
  };

  async logDownloadEvent(eventName: string, eventStatus: string) {
    this.ddDownloadForm.directDebitId = this.props.directDebit.directDebitId;
    this.ddDownloadForm.eventCorrelationId = this.props.directDebit.eventCorrelationId;
    this.ddDownloadForm.eventName = eventName;
    this.ddDownloadForm.eventStatus = eventStatus;

    await invoicesStore.logDirectDebitDownload(this.ddDownloadForm);
  }

  render() {
    if (this.props.currentStep !== 4) {
      // Prop: The current step
      return null;
    }

    return (
      <div>
        <Prompt
          when={!this.letterDownloaded}
          // eslint-disable-next-line max-len
          message="You have not downloaded your Direct Debit confirmation letter.  Please cancel and do so before you proceed as it is important you keep a copy of this letter on file, or click OK to confirm you will download at a later date."
        />
        <Confirmation
          mainMessage="Your Direct Debit has been set up"
          subMessages={[this.confirmationMessage]}
          containerId="dd_success"
          icon={Icon.success}
          iconText="Complete"
        />
        <div className={appStyles.container}>
          <Button id="dd_downloadletter" title="Download Letter" customClass="indentedButton" buttonStyle="primary" size="lg" handleClick={this.clickDownload}>
            Download Confirmation of Direct Debit
          </Button>
        </div>
      </div>
    );
  }
}
