import { withRouter } from 'react-router-dom';
import { Grid } from '@progress/kendo-react-grid';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import { BaseClientGrid } from 'components/Generic/Grid/BaseClientGrid';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { ReturnsStatusColumnMenu } from 'components/Generic/Grid/ReturnsStatusColumnMenu';
import { Returns } from 'models/api/returns.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import productsStore from 'stores/products.store';
import { IMatchResult } from 'utils/routing';
import utils from 'utils/utils';
import { ReactComponent as DocumentsSVGLogo } from 'assets/images/svg/documents.svg';
import appStyles from 'App.module.scss';

interface IReturnsGridProps extends IBaseProps {
  search: string;
  setSelectedReturn: (r: Returns | null) => void;
}

@observer
class ReturnsGrid extends BaseClientGrid<Returns, IReturnsGridProps> {
  @observable selectedReturn: Returns | null = null;
  subscriptionId = '';

  constructor(props: IReturnsGridProps) {
    super(props);

    // set default sort and filter
    this.sort = [{ field: 'rma', dir: 'desc' }];
    this.filter = {
      logic: 'or',
      filters: [
        { field: 'status', operator: 'eq', value: 'Open' },
        { field: 'status', operator: 'eq', value: 'Pending' }
      ]
    };

    // monitor route changes
    this.subscriptionId = utils.routing.subscribe((location) => {
      const match = utils.routing.matchRoutes(location, ['/products/returns/:id', '/products/returns']);
      this.setUpView(match);
    });
  }

  async componentDidMount() {
    this.allData = await productsStore.getReturns();
    this.gridData = this.allData;
    this.setUpView(this.props.match);
  }

  componentDidUpdate(prevProps: IReturnsGridProps) {
    if (this.props.search !== prevProps.search) {
      this.filterData(this.props.search);
    }
  }

  componentWillUnmount() {
    utils.routing.unsubscribe(this.subscriptionId);
  }

  filterData = (searchInput: string) => {
    const keyword = searchInput.toLowerCase();

    const result = this.allData.filter((r: Returns) => Returns.getAllSearchableFieldsAsString(r).toLowerCase().indexOf(keyword) > -1);

    this.gridData = result;
  };

  setUpView(match: IMatchResult | null) {
    // get return ID from match params (query string)
    let returnsId: number | null = null;
    if (match && match.params) {
      returnsId = +match.params.id || null;
    }

    let returns: Returns | null = null;
    if (returnsId) {
      // if id present in query string then show returns breakdown
      // otherwise show all returns
      returns = this.allData.find((r) => r.rma === returnsId) || null;
    }

    this.selectedReturn = returns;
    this.props.setSelectedReturn(returns);
  }

  goToReturn = (returns: Returns) => {
    this.props.history.push('/products/returns/' + returns.rma);
  };

  back = () => {
    this.props.history.push('/products/returns');
  };

  render() {
    return (
      <>
        <div id="mainGrid">
          <Grid
            data={this.getData(this.selectedReturn)}
            filter={this.pureFilter}
            filterable={false}
            pageable={this.getPageable(this.selectedReturn)}
            skip={this.skip}
            take={this.take}
            total={this.getTotal(this.selectedReturn)}
            onPageChange={this.pageChange}
            sortable={this.getSortable(this.selectedReturn)}
            sort={this.getSort(this.selectedReturn)}
            resizable
            onFilterChange={this.filterChange}
            onSortChange={this.sortChange}>
            {ColumnHelper.getGridColumns([
              { field: 'rma', title: 'Return ID', dataType: 'text', size: 'xs', currentFilter: this.filter },
              { field: 'recordType', title: 'Type', dataType: 'text', size: 'lg', currentFilter: this.filter },
              {
                field: 'status',
                title: 'Status',
                dataType: 'text',
                size: 'lg',
                currentFilter: this.filter,
                columnMenu: this.selectedReturn ? undefined : ReturnsStatusColumnMenu
              },
              this.selectedReturn
                ? undefined
                : {
                    field: '',
                    title: 'View',
                    dataType: 'icon',
                    size: 'xs',
                    cell: (props) => (
                      <td className="k-table-td gridColumn_xs gridColumn_button">
                        <div role="button" onClick={() => this.goToReturn(props.dataItem)} className={appStyles.button___grid_view}>
                          <DocumentsSVGLogo title="View returns breakdown" height={32} width={32} />
                        </div>
                      </td>
                    )
                  }
            ])}
          </Grid>
        </div>

        {this.selectedReturn ? (
          <BottomButtonContainer backgroundColor="white" layout="left">
            <Button id="buttonViewReturns" buttonStyle="outline_secondary" size="lg" handleClick={this.back}>
              Back to Returns
            </Button>
          </BottomButtonContainer>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default withRouter(ReturnsGrid);
