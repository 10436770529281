import { FC } from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import dialogStyles from './Dialog.module.scss';

Modal.setAppElement('#root');

const Dialog: FC<Modal.Props> = ({ children, overlayClassName, ...restProps }) => (
  <Modal {...restProps} overlayClassName={classNames(dialogStyles.modal__overlay, overlayClassName as string)}>
    {children}
  </Modal>
);

export default Dialog;
