export class LogPageView {
  pageUrl: string | null = null;
  routingSessionId: string | null = null;
  appInsightsSessionId: string | null = null;
  gaClientId: string | null = null;
  gId: string | null = null;
  userAgent: string | null = null;

  constructor(url: string) {
    this.pageUrl = url;
  }
}
