import { BaseModel } from 'models/api/base.model';

export class License extends BaseModel {
  licenseId: number | null = null;
  clientId: number | null = null;
  vendor: string | null = null;
  issueDate: string | number | Date = new Date();
  expiryDate: string | number | Date = new Date();
  updated: string | null = null;
  updatedBy: number | null = null;
  userId: string | null = null;
  password: string | null = null;
  licenseDetails: string | null = null;
  keycodes: string | null = null;
  renewalInvNo: string | null = null;
  hostname: string | null = null;
  status: string | null = null;
  notes: string | null = null;

  static getAllSearchableFieldsAsString(l: License): string {
    return (
      '' +
      l.licenseId +
      ',' +
      l.clientId +
      ',' +
      l.vendor +
      ',' +
      l.issueDate +
      ',' +
      l.expiryDate +
      ',' +
      l.updatedBy +
      ',' +
      l.licenseDetails +
      ',' +
      l.keycodes +
      ',' +
      l.renewalInvNo +
      ',' +
      l.hostname +
      ',' +
      l.notes +
      ',' +
      l.status +
      ',' +
      l.userId
    );
  }
}
