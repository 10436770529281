import {
  AccountInfo,
  AuthenticationResult,
  BrowserCacheLocation,
  EndSessionRequest,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
  EventMessage,
  EventType
} from '@azure/msal-browser';

import { UserTypes } from 'utils/enums';
import logging from 'utils/logging';
import { b2cErrorMessagesMap, defaultErrorKey } from 'utils/messages';
import utils from 'utils/utils';

const clientId = process.env.REACT_APP_CLIENT_ID || '';
const defaultScopes = [`${process.env.REACT_APP_AUTH_SCOPE}`];
const loginAuthority = process.env.REACT_APP_AUTHORITY_LOGIN as string;
const knownAuthorities = [process.env.REACT_APP_KNOWN_AUTHORITY || ''];
const resetPasswordAuthority = process.env.REACT_APP_AUTHORITY_RESET_PASSWORD as string;
const changePasswordAuthority = process.env.REACT_APP_AUTHORITY_CHANGE_PASSWORD as string;
const editProfileAuthority = process.env.REACT_APP_AUTHORITY_EDIT_PROFILE as string;
const employeeLoginAuthority = process.env.REACT_APP_AUTHORITY_EMPLOYEE_LOGIN as string;
const employeeAuthorityDomain = process.env.REACT_APP_AUTHORITY_EMPLOYEE_DOMAIN || '';

const msalInstance = getMsalInstance('/login', loginAuthority);

// A list of error messages those require any manual handling
const msalErrorNamesManualStepsRequired = ['invalid_grant', 'interaction_required', 'monitor_window_timeout'];

let refreshSilentInProgress = false;
let refreshPopupInProgress = false;
let refreshingPromiseSilent: Promise<AuthenticationResult> | null = null;
let refreshingPromisePopup: Promise<AuthenticationResult> | null = null;

interface ICustomLogoutProps {
  logOutFromNoAccess?: boolean;
  noAccessMode?: string;
}

function getMsalInstance(redirectUri: string, authority?: string): PublicClientApplication {
  const appUrl = document.location.origin;

  return new PublicClientApplication({
    auth: {
      authority,
      clientId,
      knownAuthorities,
      navigateToLoginRequestUrl: false,
      redirectUri: appUrl + redirectUri,
      postLogoutRedirectUri: appUrl + redirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: true,
      secureCookies: true
    },
    system: {
      allowRedirectInIframe: true,
      asyncPopups: false
    }
  });
}

// Util methods
const handleMsalEvent = async (event: EventMessage) => {
  switch (event.eventType) {
    case EventType.ACQUIRE_TOKEN_FAILURE:
      logging.error(EventType.ACQUIRE_TOKEN_FAILURE, event);

      if (event.error && msalErrorNamesManualStepsRequired.includes(event.error.name)) {
        const result = await auth.acquireTokenPopup();
        logging.log(`After ${EventType.ACQUIRE_TOKEN_FAILURE}`, result);
      }

      break;
  }
};

function registerRedirectHandlers(
  successHandler: (response: AuthenticationResult | null) => void | Promise<void>,
  failureHandler: (error: Error) => void | Promise<void>
): void {
  msalInstance.handleRedirectPromise().then(successHandler).catch(failureHandler);
}

function getMessageFromError(error: string): string | null | undefined {
  const parts = error.split(':');
  const errorKey = parts.reduce((currentKey, possibleKey) => (b2cErrorMessagesMap.has(possibleKey.trim()) ? possibleKey.trim() : currentKey), defaultErrorKey);

  return b2cErrorMessagesMap.get(errorKey);
}

const isB2CUser = (account: AccountInfo | null): boolean => {
  const claims = (account?.idTokenClaims ?? {}) as Record<string, string>;

  return !(claims.myportal_usertype?.toLowerCase() === UserTypes.Employee);
};

const areCustomClaimsValid = (account: AccountInfo): boolean => {
  const { userType, accounts, roles } = auth.parseAccountClaims(account);

  return Boolean((userType === UserTypes.Customer || userType === UserTypes.Employee) && accounts && roles?.length);
};

const getExtraQueryParameters = (isB2C: boolean, additionalProps: Partial<RedirectRequest> = {}) => {
  const extraQueryParameters = additionalProps.extraQueryParameters ?? {};

  if (!isB2C) {
    extraQueryParameters.domain_hint = employeeAuthorityDomain;
  }

  return extraQueryParameters;
};

function getLoginAuthority(isB2C: boolean): string {
  return isB2C ? loginAuthority : employeeLoginAuthority;
}

const isPasswordReset = (url: string): boolean => url.includes(resetPasswordAuthority.toLowerCase());

// Redirect methods

function redirectToEmployeeLogin(): void {
  const employeeLoginRequest: RedirectRequest = {
    loginHint: '',
    extraQueryParameters: { domain_hint: employeeAuthorityDomain },
    scopes: defaultScopes,
    authority: employeeLoginAuthority
  };

  msalInstance.loginRedirect(employeeLoginRequest);
}

function redirectToLogin(additionalProps: Partial<RedirectRequest> = {}): void {
  const loginRequest: RedirectRequest = {
    scopes: defaultScopes,
    authority: loginAuthority,
    ...additionalProps
  };

  msalInstance.loginRedirect(loginRequest);
}

function redirectToResetPassword(additionalProps: Partial<RedirectRequest> = {}): void {
  const request: RedirectRequest = {
    authority: resetPasswordAuthority,
    scopes: defaultScopes,
    redirectUri: '/account',
    ...additionalProps
  };

  msalInstance.loginRedirect(request);
}

function redirectToChangePassword(additionalProps: Partial<RedirectRequest> = {}): void {
  const request: RedirectRequest = {
    authority: changePasswordAuthority,
    scopes: defaultScopes,
    redirectUri: '/account',
    ...additionalProps
  };

  msalInstance.loginRedirect(request);
}

function redirectToEditProfile(additionalProps: Partial<RedirectRequest> = {}): void {
  const request: RedirectRequest = {
    authority: editProfileAuthority,
    scopes: defaultScopes,
    redirectUri: '/account',
    ...additionalProps
  };

  msalInstance.loginRedirect(request);
}

function logoutRedirect({ logOutFromNoAccess = true, noAccessMode = 'hasNoRoles', ...additionalProps }: Partial<EndSessionRequest & ICustomLogoutProps> = {}) {
  const account = getActiveAccount();
  const isB2C = isB2CUser(account);

  const request: EndSessionRequest = {
    authority: getLoginAuthority(isB2C),
    postLogoutRedirectUri: isB2C ? '/logout' : '/logout?employee',
    ...additionalProps
  };

  // clear session and local storage
  sessionStorage.clear();
  localStorage.clear();

  // when user is logged out due to lack of access then store a value in session to allow a redirect from logout page after log out is complete
  if (logOutFromNoAccess) {
    utils.session.saveToSession('noAccess', true);
    utils.session.saveToSession('noAccessMode', noAccessMode);
  }

  msalInstance.logoutRedirect(request);
}

// Acquire token methods

async function acquireTokenSilent(additionalProps: Partial<SilentRequest> = {}) {
  try {
    const account = getActiveAccount();
    const isB2C = isB2CUser(account);

    const refreshRequest: SilentRequest = {
      account,
      scopes: defaultScopes,
      forceRefresh: false,
      authority: getLoginAuthority(isB2C),
      ...additionalProps,
      extraQueryParameters: getExtraQueryParameters(isB2C, additionalProps)
    };

    if (!refreshSilentInProgress) {
      refreshingPromiseSilent = msalInstance.acquireTokenSilent(refreshRequest);
    }

    refreshSilentInProgress = true;

    return await refreshingPromiseSilent;
  } finally {
    refreshSilentInProgress = false;
    refreshingPromiseSilent = null;
  }
}

async function acquireTokenPopup(additionalProps: Partial<PopupRequest> = {}) {
  try {
    const account = getActiveAccount();
    const isB2C = isB2CUser(account);

    const refreshRequest: SilentRequest = {
      account,
      scopes: defaultScopes,
      loginHint: account?.username,
      authority: getLoginAuthority(isB2C),
      ...additionalProps,
      extraQueryParameters: getExtraQueryParameters(isB2C, additionalProps)
    };

    if (!refreshPopupInProgress) {
      refreshingPromisePopup = msalInstance.acquireTokenPopup(refreshRequest);
    }

    refreshPopupInProgress = true;

    return await refreshingPromisePopup;
  } finally {
    refreshPopupInProgress = false;
    refreshingPromisePopup = null;
  }
}

// Account methods

function getActiveAccount() {
  return msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0];
}

function setActiveAccount(account: AccountInfo) {
  return msalInstance.setActiveAccount(account);
}

function setDefaultActiveAccount() {
  const [account] = msalInstance.getAllAccounts();

  return msalInstance.setActiveAccount(account);
}

function parseAccountClaims(account: AccountInfo) {
  const { roles: claimRoles, name, myportal_usertype, myportal_accounts, given_name, family_name } = (account.idTokenClaims ?? {}) as Record<string, string>;
  const roles = claimRoles ? [...(claimRoles as unknown as string[])] : [];
  const userType = myportal_usertype?.toLowerCase();

  const userData = {
    roles,
    userType,
    accounts: myportal_accounts,
    id: account.localAccountId || account.homeAccountId,
    firstName: given_name,
    lastName: family_name,
    fullName: `${given_name} ${family_name}`,
    b2c: isB2CUser(account),
    email: Array.isArray(name) ? name[0] : name
  };

  return userData;
}

//
msalInstance.addEventCallback((event) => handleMsalEvent(event));

const auth = {
  acquireTokenPopup,
  acquireTokenSilent,
  areCustomClaimsValid,
  getActiveAccount,
  getLoginAuthority,
  getMessageFromError,
  isB2CUser,
  isPasswordReset,
  logoutRedirect,
  parseAccountClaims,
  redirectToChangePassword,
  redirectToEditProfile,
  redirectToEmployeeLogin,
  redirectToLogin,
  redirectToResetPassword,
  registerRedirectHandlers,
  setActiveAccount,
  setDefaultActiveAccount
};

export default auth;
