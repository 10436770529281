import { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import ticketsStore from 'stores/tickets.store';
import { Ticket } from 'models/api/ticket.model';
import TicketsDashboardView from 'components/Dashboard/LatestTickets/TicketsDashboardView';
import appStyles from 'App.module.scss';
import { ReactComponent as ChevronRight } from 'assets/images/svg/chevronRight.svg';
import LinkWithLabel from '../Newsfeed/LinkWithLabel';
import styles from './LatestTickets.module.scss';

@observer
export class LatestTickets extends Component {
  @observable latestTickets: Ticket[] = [];

  async componentDidMount() {
    await this.getLatestTickets();
  }

  async getLatestTickets() {
    const result = await ticketsStore.getLatestTickets();
    this.latestTickets = result;
  }

  render() {
    const linkWithLabel = <LinkWithLabel label="View all" link="/tickets" Icon={ChevronRight} />;

    return this.latestTickets.length === 0 ? (
      <>
        <div className={`${styles.tickets__heading} ${appStyles.text_midBlue}`}>You have no open tickets</div>
        {linkWithLabel}
      </>
    ) : (
      <>
        <TicketsDashboardView header="Latest Tickets" tickets={this.latestTickets} />
        {linkWithLabel}
      </>
    );
  }
}
