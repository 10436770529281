import { Component } from 'react';
import { observer } from 'mobx-react';
import { Switch } from 'react-router';
import { Router } from 'react-router-dom';
import { ContactsGrid } from 'components/Account/Contacts/ContactsGrid';
import { EditContact } from 'components/Account/Contacts/EditContact/EditContact';
import { EditAccount } from 'components/Account/EditAccount/EditAccount';
import { EditAccountConfirmation } from 'components/Account/EditAccount/EditAccountConfirmation';
import { ManageLocations } from 'components/Account/Locations/ManageLocations';
import SwitchAccount from 'components/Account/SwitchAccount/SwitchAccount';
import { Login } from 'components/Authentication/Login/Login';
import { Logout } from 'components/Authentication/Logout/Logout';
import { RenewToken } from 'components/Authentication/RenewToken/RenewToken';
import Dashboard from 'components/Dashboard/Dashboard';
import { AccessDenied } from 'components/Generic/AccessDenied/AccessDenied';
import { CookiePolicy } from 'components/Generic/CookiePolicy/CookiePolicy';
import CookiePopup from 'components/Generic/CookiePopup/CookiePopup';
import Footer from 'components/Generic/Footer/Footer';
import Header from 'components/Generic/Header/Header';
import { PageNotFound } from 'components/Generic/PageNotFound/PageNotFound';
import { ManageDirectDebit } from 'components/Invoices/DirectDebit/ManageDirectDebit';
import NewDirectDebit from 'components/Invoices/DirectDebit/NewDirectDebit/NewDirectDebit';
import { ManageInvoices } from 'components/Invoices/Invoices/ManageInvoices';
import { MakeAPayment } from 'components/Invoices/Payment/MakeAPayment';
import { PaymentFailed } from 'components/Invoices/Payment/PaymentFailed';
import { PaymentSucceeded } from 'components/Invoices/Payment/PaymentSucceeded';
import { PaymentSummary } from 'components/Invoices/Payment/PaymentSummary';
import AllNotifications from 'components/Notifications/AllNotifications';
import NotificationView from 'components/Notifications/NotificationBreakdown/NotificationView';
import { Licenses } from 'components/Products/Licenses/ManageLicenses';
import { ManageQuotes } from 'components/Products/Quotes/ManageQuotes';
import { ManageReturns } from 'components/Products/Returns/ManageReturns';
import AvailabilityChecker from 'components/Services/AvailabilityChecker';
import LineFaults from 'components/Services/LineFaults';
import { ManageTickets } from 'components/Tickets/ManageTickets';
import { NewTicket } from 'components/Tickets/NewTicket/NewTicket';
import NewTicketConfirmation from 'components/Tickets/NewTicket/NewTicketForm/NewTicketConfirmation';
import { Confirmation } from 'components/Generic/Confirmation/Confirmation';
import { App, browserHistory } from 'App';
import { AuthRoute } from 'routing/AuthRoute';
import { Sandbox } from 'Sandbox/Sandbox';
import userStore from 'stores/user.store';
import constants from 'utils/constants';
import utils from 'utils/utils';
import appStyles from 'App.module.scss';
import DevicesRedirect from 'components/Shop/Devices/DevicesRedirect';
import { AnonymousRoute } from 'routing/AnonymousRoute';

@observer
export class AppRouter extends Component {
  render() {
    return (
      <App>
        <Router history={browserHistory}>
          {!!userStore.currentUser && <Header />}
          <main role="main" className={appStyles.main}>
            <Switch>
              <AnonymousRoute exact path="/login" component={Login} pageTitle="Log In" />
              <AnonymousRoute exact path="/logout" component={Logout} pageTitle="Log Out" />
              <AnonymousRoute exact path="/cookies" component={CookiePolicy} pageTitle="Cookies" />
              <AnonymousRoute exact path="/no-access" component={AccessDenied} pageTitle="No Access" />
              <AuthRoute exact path="/renew" component={RenewToken} pageTitle="Renew Token" />
              <AuthRoute exact path="/switch-account" component={SwitchAccount} pageTitle="Switch Account" />
              <AuthRoute exact path="/account" component={EditAccount} pageTitle="Edit Account" />
              <AuthRoute exact path="/account/confirmation" component={EditAccountConfirmation} pageTitle="Account Confirmation" />
              <AuthRoute exact path="/account/contacts" component={ContactsGrid} role={constants.roleNames.MyPortalAdmin} pageTitle="Contacts" />
              <AuthRoute exact path="/account/contacts/new" component={EditContact} role={constants.roleNames.MyPortalAdmin} pageTitle="New Contact" />
              <AuthRoute exact path="/account/contacts/:id" component={EditContact} role={constants.roleNames.MyPortalAdmin} pageTitle="Edit Contact" />
              <AuthRoute exact path="/account/locations" component={ManageLocations} role={constants.roleNames.MyPortalAdmin} pageTitle="Locations" />
              <AuthRoute exact path="/account/locations/new" component={ManageLocations} role={constants.roleNames.MyPortalAdmin} pageTitle="New Location" />
              <AuthRoute exact path="/account/locations/:id" component={ManageLocations} role={constants.roleNames.MyPortalAdmin} pageTitle="Edit Location" />
              <AuthRoute exact path="/" component={Dashboard} pageTitle="Dashboard" />
              <AuthRoute exact path="/dashboard" component={Dashboard} pageTitle="Dashboard" />
              <AuthRoute exact path="/invoices/payment-failed" component={PaymentFailed} role={constants.roleNames.Orders} pageTitle="Payment Failed" />
              <AuthRoute
                exact
                path="/invoices/payment-succeeded"
                component={PaymentSucceeded}
                role={constants.roleNames.Orders}
                pageTitle="Payment Succeeded"
              />
              <AuthRoute exact path="/invoices/payment-summary" component={PaymentSummary} role={constants.roleNames.Orders} pageTitle="Payment Summary" />
              <AuthRoute exact path="/invoices/make-a-payment" component={MakeAPayment} role={constants.roleNames.Orders} pageTitle="Make a Payment" />
              <AuthRoute exact path="/invoices/manage-direct-debit" component={ManageDirectDebit} role={constants.roleNames.Orders} pageTitle="Direct Debits" />
              <AuthRoute
                exact
                path="/invoices/manage-direct-debit/:id"
                component={ManageDirectDebit}
                role={constants.roleNames.Orders}
                pageTitle="View Direct Debit"
              />
              <AuthRoute exact path="/invoices/direct-debit" component={NewDirectDebit} role={constants.roleNames.Orders} pageTitle="New Direct Debit" />
              <AuthRoute exact path="/invoices" component={ManageInvoices} role={constants.roleNames.Orders} pageTitle="Invoices" />
              <AuthRoute exact path="/invoices/:id" component={ManageInvoices} role={constants.roleNames.Orders} pageTitle="View Invoice" />
              <AuthRoute exact path="/tickets" component={ManageTickets} role={constants.roleNames.Support} pageTitle="Tickets" />
              <AuthRoute exact path="/tickets/new" component={NewTicket} role={constants.roleNames.Support} pageTitle="New Ticket" />
              <AuthRoute
                exact
                path="/tickets/confirmation"
                component={NewTicketConfirmation}
                role={constants.roleNames.Support}
                pageTitle="Ticket Confirmation"
              />
              <AuthRoute exact path="/tickets/:id" component={ManageTickets} role={constants.roleNames.Support} pageTitle="Edit Ticket" />
              <AuthRoute exact path="/products/subscriptions" component={Licenses} role={constants.roleNames.Contracts} pageTitle="Subscriptions" />
              <AuthRoute exact path="/products/subscriptions/:id" component={Licenses} role={constants.roleNames.Contracts} pageTitle="Edit Subscription" />
              <AuthRoute exact path="/products/returns" component={ManageReturns} role={constants.roleNames.Returns} pageTitle="Returns" />
              <AuthRoute exact path="/products/returns/:id" component={ManageReturns} role={constants.roleNames.Returns} pageTitle="Edit Return" />
              <AuthRoute exact path="/products/quotes" component={ManageQuotes} role={constants.roleNames.Quotes} pageTitle="Quotes" />
              <AuthRoute exact path="/products/quotes/:id/:mode" component={ManageQuotes} role={constants.roleNames.Quotes} pageTitle="View Quote" />
              <AuthRoute exact path="/products/quotes/:id" component={ManageQuotes} role={constants.roleNames.Quotes} pageTitle="View Quote" />
              <AuthRoute exact path="/shop/devices" component={DevicesRedirect} role={constants.roleNames.DevicesShop} pageTitle="Devices" />
              <AuthRoute exact path="/notifications" component={AllNotifications} pageTitle="Notifications" />
              <AuthRoute exact path="/notifications/:id" component={NotificationView} pageTitle="View Notification" />
              <AuthRoute path="/services/line-faults" component={LineFaults} pageTitle="Line Faults" />
              <AuthRoute path="/services/availability-checker" component={AvailabilityChecker} pageTitle="Availability Checker" />

              {/* Test route for sandbox component, excluded from production build */}
              {!utils.environment.isProduction && <AnonymousRoute exact path="/sandbox" component={Sandbox} pageTitle="Sandbox" />}

              <AnonymousRoute exact path="/error" component={Confirmation} pageTitle="Error" />
              <AuthRoute exact path="/not-found" component={PageNotFound} pageTitle="Page Not Found" />
              <AuthRoute component={PageNotFound} pageTitle="Page Not Found" />
            </Switch>
            <CookiePopup />
          </main>
          <Footer />
        </Router>
      </App>
    );
  }
}
