import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import Icon from 'components/Generic/Icon/icon';
import { observer } from 'mobx-react';
import { Component } from 'react';

@observer
export class CookiePolicy extends Component {
  render() {
    return (
      <>
        <div className={appStyles.container}>
          <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
            <Icon icon={Icon.info} size="heading" color="currentColor" iconName="warning icon" customClass={appStyles.heading__icon} />
            <h1 className={appStyles.heading__text}>Cookie Policy</h1>
          </div>
          <Alert alertType="blank">
            <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>Please take the time to read our cookie policy for the site.</p>
          </Alert>

          <div className={appStyles.text_midBlue}>
            <h2>Cookies & Internet Advertising</h2>
            <p>
              Visiting Chess' websites with your browser settings adjusted to accept cookies tells us that you want to use Chess’ products and services and that
              you consent to our use of cookies and other technologies to provide them to you as described in this notice. See below for information on how to
              modify the settings in your device to notify you when you receive a new cookie and disable cookies altogether.
            </p>

            <h2>Cookies and How We Use Them</h2>
            <p>
              Cookies are unique identifiers that we transfer to your device to enable our systems to recognise your device and to provide features such as our
              Customer Portal and interest-based advertisements on other Web sites (e.g., those on the Google Network).
            </p>

            <p>We also use cookies for additional purposes, including:</p>

            <ul className={appStyles.text_darkSkyBlue}>
              <li>
                Identifying you when you sign-in to our site. This allows us to provide you with product recommendations, display personalised content,
                recognise you as a Prime member, among other customised features and services we provide.
              </li>
              <li>
                Delivering content, including advertisements, relevant to your interests on Chess sites and third-party sites. Our interest-based ads are
                subject to your preferences as described in the next section.
              </li>
              <li>Keeping track of your specified preferences.</li>
              <li>Preventing fraudulent activities.</li>
              <li>Improving security.</li>
            </ul>

            <p>
              Cookies allow you to take advantage of some of Chess's essential features. For instance, if you block or otherwise reject our cookies, you may not
              be able to use our Customer Portal or functionality within it.
            </p>

            <h2>Cookies and Browser Settings</h2>
            <p>
              The Help feature on most browsers will tell you how to prevent your browser from accepting new cookies, how to have the browser notify you when
              you receive a new cookie and how to disable cookies altogether. Additionally, you can disable or delete similar data used by browser add-ons, such
              as Flash cookies, by changing the add-on's settings or visiting the website of its manufacturer. .
            </p>

            <h2>Internet Advertising</h2>
            <p>
              On Chess operated and unaffiliated sites, Chess displays interest-based advertising using information you make available to us when you interact
              with our sites, content, or services. Interest-based ads, also sometimes referred to as personalised or targeted ads, are displayed to you based
              on information from activities such as purchasing on our sites and visiting sites that contain Chess content or ads.
            </p>

            <p>
              Like other online ad networks, we use cookies, web beacons (also known as action tags or single-pixel gifs), and other technologies (collectively,
              "cookies"). Cookies enable us to learn about what ads you see, what ads you click, and other actions you take on our sites and other sites. This
              allows us to provide you with more useful and relevant ads. For example, if we know what ads you are shown we can be careful not to show you the
              same ones repeatedly.
            </p>

            <p>We do not associate your interaction with unaffiliated sites with your identity in providing you with interest-based ads.</p>

            <p>
              We do not provide any personal information to advertisers or to third party sites that display our interest-based ads. However, advertisers and
              other third-parties (including the ad networks, ad-serving companies, and other service providers they may use) may assume that users who interact
              with or click on a personalised ad or content are part of the group that the ad or content is directed towards. Also, some third-parties may
              provide us information about you (such as demographic information or sites where you have been shown ads) from offline and online sources that we
              may use to provide you more relevant and useful advertising.
            </p>

            <p>
              Third party advertisers or advertising companies working on their behalf sometimes use technology to serve the advertisements that appear on our
              sites directly to your browser. They automatically receive your IP address when this happens. They may also use cookies to measure the
              effectiveness of their advertisements and to personalise advertising content. We do not have access to or control over cookies or other features
              that advertisers and third party sites may use, and the information practices of these advertisers and third party websites are not covered by
              this Cookies & Internet Advertising page. Please contact them directly for more information about their privacy practices.
            </p>

            <h2>Advertising Preferences</h2>
            <p>You may modify the settings in most browsers to notify you when you receive a new cookie and disable cookies altogether.</p>

            <p>Reviewed July 2018</p>
          </div>
        </div>
      </>
    );
  }
}
