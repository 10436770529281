import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import constants from 'utils/constants';
import notificationsStore from 'stores/notifications.store';
import userStore from 'stores/user.store';
import appStyles from 'App.module.scss';
import Preloader from 'components/Generic/Preloader/Preloader';
import styles from './SwitchAccount.module.scss';
import AccountsList from './AccountsList';

const NoAccountsInfo: FC = () => (
  <div>
    <p>We could not find any associated account information relevant to the email address you have logged in with.</p>
    <p>
      Your account needs to be verified. Please call us on {constants.keyVariables.helpdeskTelephone}, select the Customer Service option #1 from the main menu,
      followed by the Customer Portal option #4.
    </p>
  </div>
);

const SwitchAccount: FC = () => {
  const accountsAvailable = userStore.currentUser?.clients?.length !== 0;
  const history = useHistory();

  useEffect(() => {
    if (userStore.currentUser) {
      userStore.loadClients();
      notificationsStore.getUnreadNotificationsCountForContactsClients();

      if (userStore.currentUser.clients.length === 1 && !userStore.currentUser.roles.includes(constants.roleNames.MyPortalPendingValidation)) {
        userStore.setSelectedClient(userStore.currentUser.clients[0]);
        history.push('/dashboard');
      }
    }
  }, [history]);

  return notificationsStore.pending ? (
    <div className={styles.preloader}>
      <Preloader />
    </div>
  ) : (
    <div className={`${styles.switch_account}`}>
      <div className={`${styles.switch_account_section}`}>
        <div className={styles.switch_account_section__header}>
          <h2 className={styles.switch_account_section__title}>Choose Account</h2>
        </div>
        <div className={`${appStyles.text_midBlue} ${styles.switch_account_section__content}`}>
          {userStore.currentUser?.clients && accountsAvailable ? <AccountsList clients={userStore.currentUser?.clients} /> : <NoAccountsInfo />}
        </div>
      </div>
    </div>
  );
};

export default observer(SwitchAccount);
