import React, { ChangeEvent, Component, RefObject } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import Alert from 'components/Generic/Alert/Alert';
import Input from 'components/Generic/FormElements/Input/Input';
import ReturnsBreakdown from 'components/Products/Returns/ReturnsBreakdown/ReturnsBreakdown';
import ReturnsGrid from 'components/Products/Returns/ReturnsGrid/ReturnsGrid';
import { Returns } from 'models/api/returns.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import { IMatchResult } from 'utils/routing';
import utils from 'utils/utils';
import appStyles from 'App.module.scss';
import styles from './ManageReturns.module.scss';

@observer
export class ManageReturns extends Component<IBaseProps> {
  @observable breakdownRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  @observable selectedReturn: Returns | null = null;
  @observable searchInput = '';
  subscriptionId = '';

  constructor(props: IBaseProps) {
    super(props);

    this.setSelectedReturn = this.setSelectedReturn.bind(this);

    // monitor route changes
    this.subscriptionId = utils.routing.subscribe((location) => {
      const match = utils.routing.matchRoutes(location, ['/products/returns/:id', '/products/returns']);
      this.setUpView(match);
    });
  }

  componentDidMount() {
    this.setUpView(this.props.match);
  }

  componentWillUnmount() {
    utils.routing.unsubscribe(this.subscriptionId);
  }

  setUpView(match: IMatchResult | null) {
    // if ID present then scroll down to breakdown
    if (match && match.params.id && this.breakdownRef.current) {
      // ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      window.scrollTo(0, this.breakdownRef.current.offsetTop);
    } else {
      // otherwise scroll to top
      window.scrollTo(0, 0);
    }
  }

  handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    this.searchInput = e.target.value;
  };

  setSelectedReturn(value: Returns | null) {
    this.selectedReturn = value;
  }

  render() {
    return (
      <div className={appStyles.container}>
        <div className={appStyles.pageHeading_lg}>
          <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
            <h1 className={appStyles.heading__text}>Returns</h1>
          </div>
          <div className={appStyles.search__container}>
            {!this.selectedReturn && (
              <Input
                elementId="searchBar"
                inputType="search"
                name="searchBar"
                labelText="Search Returns"
                labelHidden={true}
                placeholder="Search Returns"
                value={this.searchInput}
                handleChange={this.handleSearch}
                customLabelClass={appStyles.form__label}
                customInputClass={appStyles.form__input}
                autocomplete="off"
              />
            )}
          </div>
        </div>

        {!this.selectedReturn && (
          <Alert alertType="blank">
            <p className={`${appStyles.text_darkGrey} ${appStyles.info__main}`}>
              View all your returns and click on an individual return to see a full breakdown.
            </p>
          </Alert>
        )}

        <div className={styles.returnsGrid}>
          <ReturnsGrid search={this.searchInput} setSelectedReturn={this.setSelectedReturn} />
        </div>

        <div ref={this.breakdownRef}>{this.selectedReturn ? <ReturnsBreakdown return={this.selectedReturn} /> : <></>}</div>
      </div>
    );
  }
}
