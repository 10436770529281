import { Grid } from '@progress/kendo-react-grid';
import appStyles from 'App.module.scss';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { observer } from 'mobx-react';
import { Returns } from 'models/api/returns.model';
import { Component } from 'react';
import styles from './ProductDetailsGrid.module.scss';

interface IProductDetailsGridProps {
  return: Returns;
}

@observer
export default class ProductDetailsGrid extends Component<IProductDetailsGridProps> {
  render() {
    return (
      <>
        <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
          <h3 id="ProductDetails" className={appStyles.heading__text_sm}>
            Product Details
          </h3>
        </div>

        <Grid className={styles.singleRowGrid} data={[this.props.return]} pageSize={1} resizable>
          {ColumnHelper.getGridColumns([
            { field: 'prodCode', title: 'Product Code', dataType: 'text', size: 'md' },
            { field: 'prodDesc', title: 'Description', dataType: 'text', size: 'lg' },
            { field: 'qty', title: 'Quantity', dataType: 'numeric', size: 'md' },
            { field: 'invNo', title: 'Invoice No', dataType: 'text', size: 'md' },
            { field: 'origDateRecvd', title: 'Date Received', dataType: 'date', size: 'md' }
          ])}
        </Grid>
      </>
    );
  }
}
