import { FC } from 'react';
import classNames from 'classnames';
import Icon from 'components/Generic/Icon/icon';
import appStyles from 'App.module.scss';
import styles from './Header.module.scss';

interface IMobileBottomNavigationProps {
  hidden: boolean;
  onMenuClick: React.MouseEventHandler<HTMLButtonElement>;
  onAccountClick: React.MouseEventHandler<HTMLButtonElement>;
  onInvoicesClick: React.MouseEventHandler<HTMLButtonElement>;
  onClose: React.MouseEventHandler<HTMLDivElement>;
}

const MobileBottomNavigation: FC<IMobileBottomNavigationProps> = ({ hidden, onMenuClick, onAccountClick, onInvoicesClick, onClose }) => (
  <>
    <div className={classNames(styles.mobileNav, { [styles.mobileNav_hide]: hidden })}>
      <button className={styles.mobileNav__button} onClick={onInvoicesClick}>
        <Icon icon={Icon.invoices} color="midBlue" size="md" iconName="View Invoices" />
        <span className={classNames(appStyles.text_midBlue, styles.mobileNav__label)}>Invoices</span>
      </button>
      <button
        className={styles.mobileNav__button}
        type="button"
        data-toggle="collapse"
        data-target="#navbarContent"
        aria-controls="navbarContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={onMenuClick}>
        <Icon icon={Icon.menu} color="midBlue" size="md" iconName="Nav Toggle Icon" />
        <span className={classNames(appStyles.text_midBlue, styles.mobileNav__label)}>Menu</span>
      </button>
      <button className={styles.mobileNav__button} onClick={onAccountClick}>
        <Icon icon={Icon.user} color="midBlue" size="md" iconName="Account information" />
        <span className={classNames(appStyles.text_midBlue, styles.mobileNav__label)}>Account</span>
      </button>
    </div>
    <div onClick={onClose} role="navigation" className={classNames(styles.mobileNav__backdrop, { [styles.mobileNav__backdrop_show]: hidden })} />
  </>
);

export default MobileBottomNavigation;
