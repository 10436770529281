import { BaseGridRequest } from 'models/requests/baseGridRequest.model';

export class QuotesGridRequest extends BaseGridRequest {
  includedStatusCodes: number[] = [];

  constructor(sortBy: string, pageSize: number, statuses: number[]) {
    super(pageSize);

    this.sortBy = sortBy;
    this.includedStatusCodes = statuses;
  }
}
