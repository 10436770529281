import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import NotificationCards from 'components/Notifications/NotificationCards/NotificationCards';
import { BaseApi } from 'api/baseApi';
import { IBaseProps } from 'models/generic/iBaseProps';
import navigationStore from 'stores/navigation.store';
import notificationsStore from 'stores/notifications.store';
import userStore from 'stores/user.store';
import appStyles from 'App.module.scss';
import utils from 'utils/utils';
import styles from './Header.module.scss';
import AccountContentNavigation from './AccountContentNavigation';
import GlobalLoadingIndicator from './GlobalLoadingIndicator';
import GlobalMessages from './GlobalMessages';
import LogoSection from './LogoSection';
import MobileBottomNavigation from './MobileBottomNavigation';
import Navigation from './Navigation';
import NotificationPopup from './NotificationPopup/NotificationPopup';

@observer
class Header extends Component<IBaseProps> {
  @observable navOpen = false;
  @observable scrolled = false;
  @observable accountNavOpen = false;
  @observable notificationsOpen = false;
  @observable selectedId: number | null = null;
  @observable numberOfSageAccounts = 0;
  @observable accountNumberContainerExpandable = false;
  @observable accountNumberContainerExpanded = false;
  @observable currentRoute: string = window.location.pathname;

  async componentDidMount() {
    navigationStore.loadNavigation();

    // initialise monitoring of route changes from a shared component (once user is already authenticated)
    await utils.routing.initialiseMonitoring();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateStickyNav);
  }

  @action onNavClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, route: string, prevent: boolean, index: number) => {
    if (prevent) {
      e.preventDefault();
      this.showSubNav(index);
    } else {
      this.closeSubNav();
      this.navOpen = false;
    }
  };

  @action updateStickyNav = () => {
    this.scrolled = window.pageYOffset > 0;
  };

  @action showSubNav = (index: number) => {
    this.selectedId = this.selectedId === index ? null : index;
  };

  @action closeSubNav = () => {
    this.selectedId = null;
  };

  @action showMobileNav = () => {
    this.closeSubNav();
    this.closeAccountNav();
    this.closeNotificationNav();

    this.navOpen = true;
  };

  @action closeMobileNav = () => {
    this.navOpen = false;

    this.closeAccountNav();
    this.closeNotificationNav();
  };

  @action closeNotificationNav = () => {
    this.notificationsOpen = false;
  };

  @action closeAccountNav = () => {
    this.accountNavOpen = false;
  };

  @action toggleAccountNav = () => {
    this.accountNavOpen = !this.accountNavOpen;

    this.closeNotificationNav();
  };

  @action toggleNotificationNav = () => {
    this.notificationsOpen = !this.notificationsOpen;
  };

  switchAccount = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    this.closeAccountNav();

    this.props.history.push('/switch-account');
  };

  onAccountClick = () => {
    this.showMobileNav();
  };

  goToInvoices = () => {
    this.props.history.push('/invoices');
  };

  get loaderVisible() {
    return Boolean(BaseApi.requestsInProgress);
  }

  render() {
    if (!userStore.currentUser) {
      return;
    }

    return (
      <header className={classNames(styles.header, { [styles.nav_scrolled]: this.scrolled })} onBlur={this.closeSubNav} role="navigation">
        <GlobalLoadingIndicator visible={this.loaderVisible} />

        <nav className={styles.nav} role="navigation" aria-label="Main">
          <MobileBottomNavigation
            hidden={this.navOpen}
            onClose={this.closeMobileNav}
            onMenuClick={this.showMobileNav}
            onAccountClick={this.onAccountClick}
            onInvoicesClick={this.goToInvoices}
          />
          <div id="navbarContent" className={classNames(appStyles.container, styles.nav__container, { [styles.nav_show]: this.navOpen })}>
            <Navigation selected={this.selectedId} onFocus={this.showSubNav} onClick={this.onNavClick} onClose={this.closeMobileNav} />

            <LogoSection
              toggleAccountNav={this.toggleAccountNav}
              toggleNotificationNav={this.toggleNotificationNav}
              unreadNotificationCount={notificationsStore.unreadNotificationCount}
            />

            <NotificationPopup onClose={this.closeMobileNav} onBack={this.closeNotificationNav} visible={this.notificationsOpen} />
            <NotificationCards />

            <AccountContentNavigation
              user={userStore.currentUser}
              visible={this.accountNavOpen}
              onCloseMobileClick={this.closeMobileNav}
              onSwitchAccountClick={this.switchAccount}
              onCloseAccountClick={this.closeAccountNav}
            />
          </div>
        </nav>
        <GlobalMessages />
      </header>
    );
  }
}

export default withRouter(Header);
