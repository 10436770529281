import { Component } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import lineFaultsStore from 'stores/lineFaults.store';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import appStyles from 'App.module.scss';
import submitSuccessStyles from './LineFaultSubmitSuccess.module.scss';

@observer
class LineFaultSubmitSuccess extends Component<RouteComponentProps> {
  onViewTicket = () => {
    this.props.history.push(`/tickets/${lineFaultsStore.troubleReportResult?.supportTicketId}`);
  };

  handleStartAnotherTest = () => {
    const { history } = this.props;
    lineFaultsStore.cleanResults();

    return history.push('/services/line-faults');
  };

  render() {
    return (
      <>
        <div className={submitSuccessStyles.submitSuccess__header}>
          <h1 id="successTitle" className={classNames(appStyles.heading__text, appStyles.text_midBlue)}>
            Success
          </h1>
        </div>
        <div className={submitSuccessStyles.message__container}>
          <div className={submitSuccessStyles.message__title}>Your engineer appointment has been booked.</div>
          <div className={submitSuccessStyles.message__text}>
            Your ticket ID is <span id="ticketID">{lineFaultsStore.troubleReportResult?.supportTicketId ?? ''}</span>. Please visit the tickets page to view
            updates or get in touch.
          </div>
        </div>
        <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
          <Button id="btnPreformAnotherTest" key="btnPreformAnotherTest" buttonStyle="outline_secondary" size="lg" handleClick={this.handleStartAnotherTest}>
            <span>Perform Another Test</span>
          </Button>

          <Button size="lg" buttonStyle="primary" id="btnViewTicket" key="btnViewTicket" handleClick={this.onViewTicket}>
            <span>View Ticket</span>
          </Button>
        </BottomButtonContainer>
      </>
    );
  }
}

export default withRouter(LineFaultSubmitSuccess);
