import { FC, FormEvent } from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { IBaseProps } from 'models/generic/iBaseProps';
import appStyles from 'App.module.scss';

interface IFormWrapperProps extends IBaseProps {
  formTitle: string;
  className: string;
  onSubmit: (event: FormEvent<Element>) => void;
}

const FormWrapper: FC<IFormWrapperProps> = ({ className, onSubmit, formTitle, children }) => (
  <form onSubmit={onSubmit} className={className}>
    <div className={classnames(appStyles.form, appStyles.form_default)}>
      <h1 className={appStyles.form__header}>{formTitle}</h1>
      <div className={appStyles.form__content}>{children}</div>
    </div>
  </form>
);

export default withRouter(FormWrapper);
