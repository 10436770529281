import api from 'api/api';
import { LogDownloadClicked } from 'models/api/logDownloadClicked.model';
import { LogPageView } from 'models/api/logPageView.model';
import { FalconError } from 'models/generic/falconError.model';

class EventLogsStore {
  async logPageView(form: LogPageView): Promise<void | FalconError> {
    await api.post('EventLog/LogPageView', form, 'There was an error logging event.');
  }

  async logDownloadClicked(form: LogDownloadClicked): Promise<void | FalconError> {
    await api.post('EventLog/LogDownloadClicked', form, 'There was an error logging event.');
  }
}

const eventLogsStore = new EventLogsStore();
export default eventLogsStore;
