import { withRouter } from 'react-router-dom';
import { Grid } from '@progress/kendo-react-grid';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { BaseClientGrid } from 'components/Generic/Grid/BaseClientGrid';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { DirectDebit } from 'models/api/directDebit.model';
import { DirectDebitDownload } from 'models/api/directDebitDownloadLog.model';
import { FalconError } from 'models/generic/falconError.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import invoicesStore from 'stores/invoices.store';
import { ReactComponent as EditSVGLogo } from 'assets/images/svg/edit.svg';
import { ReactComponent as DownArrowSVGLogo } from 'assets/images/svg/downarrow.svg';
import appStyles from 'App.module.scss';

interface IDirectDebitGridProps extends IBaseProps {
  searchKeyword: string;
  setSelectedDirectDebit: (invoice: DirectDebit | null) => void;
  selectedDirectDebit?: DirectDebit | null;
}

@observer
class DirectDebitGrid extends BaseClientGrid<DirectDebit, IDirectDebitGridProps> {
  @observable selectedDirectDebit: DirectDebit | null = null;
  ddDownloadForm = new DirectDebitDownload();

  async componentDidMount() {
    // Get Data and bind it to grid
    this.filterData = this.filterData.bind(this);
    this.allData = await invoicesStore.getDirectDebits();
    this.gridData = this.allData;

    // set selected direct debit from url
    const directDebitId = +this.props.match.params.id;
    if (directDebitId) {
      const directDebit = this.allData.find((dd) => dd.directDebitId === directDebitId);
      if (directDebit) {
        this.viewDirectDebit(directDebit);
      }
    }
  }

  componentDidUpdate(prevProps: IDirectDebitGridProps) {
    if (this.props.searchKeyword !== prevProps.searchKeyword) {
      this.filterData(this.props.searchKeyword);
    }

    if (this.props.selectedDirectDebit !== undefined && this.selectedDirectDebit !== this.props.selectedDirectDebit) {
      this.selectedDirectDebit = this.props.selectedDirectDebit;
    }
  }

  filterData(searchKeyword: string) {
    const keyword = searchKeyword.toLowerCase();
    const result = this.allData.filter((c: DirectDebit) => DirectDebit.getAllSearchableFieldsAsString(c).toLowerCase().indexOf(keyword) > -1);

    this.gridData = result;
  }

  viewDirectDebit(directDebit: DirectDebit) {
    this.props.setSelectedDirectDebit(directDebit);

    setTimeout(() => {
      this.props.history.push('/invoices/manage-direct-debit/' + directDebit.directDebitId);
    }, 0);
  }

  letterExists = async (directDebit: DirectDebit) => {
    if (directDebit && directDebit.sageAccountNumber !== null) {
      return await invoicesStore.DirectDebitLetterExists(directDebit.sageAccountNumber as string);
    } else {
      return false;
    }
  };

  downloadLetter = async (directDebit: DirectDebit) => {
    if (directDebit && directDebit.sageAccountNumber !== null) {
      // log download initiated
      await this.logDownloadEvent(directDebit, 'Started', 'OK');

      // get letter
      const letterResult = await invoicesStore.getDirectDebitLetter(directDebit.sageAccountNumber as string);

      // log download completed
      if (letterResult instanceof FalconError) {
        await this.logDownloadEvent(directDebit, 'Completed', 'Failed');
      } else {
        await this.logDownloadEvent(directDebit, 'Completed', 'OK');
      }
    } else {
      throw new Error('No letter available for this Direct Debit.');
    }
  };

  async logDownloadEvent(directDebit: DirectDebit, eventName: string, eventStatus: string) {
    this.ddDownloadForm.directDebitId = directDebit.directDebitId;
    this.ddDownloadForm.eventCorrelationId = directDebit.eventCorrelationId;
    this.ddDownloadForm.eventName = eventName;
    this.ddDownloadForm.eventStatus = eventStatus;

    await invoicesStore.logDirectDebitDownload(this.ddDownloadForm);
  }

  render() {
    // const letterLink: JSX.Element = <Icon icon={Icon.download} iconName="Direct Debit Letter" color="darkSkyBlue" size="xs" />;

    const mainGrid = (
      <Grid
        data={this.getData(this.selectedDirectDebit)}
        filter={this.filter}
        filterable={false}
        pageable={this.selectedDirectDebit ? false : { pageSizes: this.pageSizes }}
        skip={this.skip}
        take={this.take}
        total={this.getTotal(this.selectedDirectDebit)}
        onPageChange={this.pageChange}
        sortable={this.selectedDirectDebit ? false : true}
        sort={this.selectedDirectDebit ? undefined : toJS(this.sort)}
        resizable
        onFilterChange={this.filterChange}
        onSortChange={this.sortChange}>
        {ColumnHelper.getGridColumns([
          { field: 'sageAccountNumber', title: 'Account Number', dataType: 'text', size: 'md', currentFilter: this.filter },
          { field: 'bankSortCode', title: 'Bank Sort Code', dataType: 'text', size: 'md', currentFilter: this.filter },
          { field: 'bankAccountNumber', title: 'Bank Account Number', dataType: 'text', size: 'md', currentFilter: this.filter },
          { field: 'directDebitReference', title: 'Direct Debit Reference', dataType: 'text', size: 'md', currentFilter: this.filter },
          { field: 'bankAccountName', title: 'Account Name', dataType: 'text', size: 'md', currentFilter: this.filter },
          this.selectedDirectDebit
            ? undefined
            : {
                field: '',
                title: 'View',
                dataType: 'icon',
                size: 'xs',
                cell: (props) => (
                  <td className="k-table-td gridColumn_xs gridColumn_button">
                    <div role="button" onClick={() => this.viewDirectDebit(props.dataItem)} className={appStyles.button___grid_view}>
                      <EditSVGLogo title="Edit Direct Debit" height={24} width={24} />
                    </div>
                  </td>
                )
              },
          {
            field: '',
            title: 'Letter',
            dataType: 'icon',
            size: 'xs',
            cell: (props) => (
              <td className="k-table-td gridColumn_xs gridColumn_button">
                {!!this.letterExists(props.dataItem) && (
                  <div role="button" onClick={() => this.downloadLetter(props.dataItem)} className={appStyles.button___grid_view}>
                    <DownArrowSVGLogo title="Direct Debit Letter" height={48} width={48} />
                  </div>
                )}
              </td>
            )
          }
        ])}
      </Grid>
    );

    return mainGrid;
  }
}

export default withRouter(DirectDebitGrid);
