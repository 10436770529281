import { FC } from 'react';
import classNames from 'classnames';
import styles from './Header.module.scss';

interface IAccountNumbersProps {
  items: string[];
  expanded: boolean;
  expandable: boolean;
}

const AccountNumbers: FC<IAccountNumbersProps> = ({ items, expandable, expanded }) => (
  <div
    id="AccountNumbers"
    className={classNames(styles.account__details, {
      [styles.account__details_fixedHeight_expanded]: expandable && expanded,
      [styles.account__details_fixedHeight]: expandable && !expanded,
      [styles.account__details_unfixedHeight]: !(expandable && expanded)
    })}>
    {items.map((item, accountIndex) => (
      <div key={accountIndex}>{item}</div>
    ))}
  </div>
);

export default AccountNumbers;
