import { Component } from 'react';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ListItem } from 'models/generic/listItem.model';
import { IRouteIdProp } from 'routing/types';
import Dialog from 'components/Generic/Dialog/Dialog';
import Button from 'components/Generic/FormElements/Button/Button';
import CheckBox from 'components/Generic/FormElements/Checkbox/Checkbox';
import appStyles from 'App.module.scss';
import agreementDialogStyles from './AgreementDialog.module.scss';

interface IAgreementDialogProps {
  onCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onApply: (event: React.MouseEvent<HTMLButtonElement>) => void;
  visible: boolean;
  message: string;
}

@observer
class AgreementDialog extends Component<IAgreementDialogProps & RouteComponentProps<IRouteIdProp>> {
  @observable accepted = false;

  @computed get checkBoxSelectedName() {
    return this.accepted ? 'Acceptance' : '';
  }

  @action onCheckBoxChanged = () => {
    this.accepted = !this.accepted;
  };

  render() {
    return (
      <Dialog isOpen={this.props.visible} className={agreementDialogStyles.modal__content}>
        <div id="agreementMessage" className={agreementDialogStyles.modal__messageContainer}>
          <div className={agreementDialogStyles.modal__message}>{this.props.message}</div>
          <CheckBox
            name="AgreementDialogueCheck"
            options={[new ListItem('Acceptance', 'I understand and agree')]}
            selectedOptions={[this.checkBoxSelectedName]}
            handleChange={this.onCheckBoxChanged}
            customLabelClass={agreementDialogStyles.checkbox__label}
            customInputClass={appStyles.checkbox}
          />
        </div>
        <div id="agreementControls" className={agreementDialogStyles.modal__buttonsContainer}>
          <Button size="lg" buttonStyle="primary" id="applyAgreementBtn" handleClick={this.props.onApply} disabled={!this.accepted}>
            <span>Proceed</span>
          </Button>
          <Button size="lg" buttonStyle="outline_primary" id="declineAgreementBtn" handleClick={this.props.onCancel}>
            <span>Cancel</span>
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default withRouter(AgreementDialog);
