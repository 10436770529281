import utils from 'utils/utils';

export class LogDownloadClicked {
  baseUrl: string | null = null;
  fileUrl: string | null = null;
  routingSessionId: string | null = null;

  constructor(baseUrl: string, url: string) {
    this.baseUrl = baseUrl;
    this.fileUrl = url;
    this.routingSessionId = utils.routing.routingSessionId;
  }
}
