import { FC } from 'react';
import { IAvailableProduct } from 'models/api/availableProduct.model';
import FttpProduct from './FttpProduct';
import FttpProductsStyles from './FttpProducts.module.scss';

interface IFttpProductsListProps {
  availableProducts: IAvailableProduct[];
  onOrderClick: (product: IAvailableProduct) => void;
}

const FttpProductsList: FC<IFttpProductsListProps> = ({ availableProducts, onOrderClick }) => (
  <div className={FttpProductsStyles.fttp_products_list} id="AvailableProducts">
    {availableProducts.map((product) => (
      <FttpProduct key={product.productName} availableProduct={product} onOrderClick={onOrderClick} />
    ))}
  </div>
);

export default FttpProductsList;
