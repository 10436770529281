import appStyles from 'App.module.scss';
import FileDownloads from 'components/Generic/FormElements/FileDownloads/FileDownloads';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { License } from 'models/api/license.model';
import { Component } from 'react';
import styles from '../ManageLicenses.module.scss';

interface ILicensesBreakdownProps {
  license: License;
}

@observer
export class LicensesBreakdown extends Component<ILicensesBreakdownProps> {
  @observable license: License = this.props.license;

  render() {
    return (
      <>
        <div className={`${styles.licensesSection} ${appStyles.container}`} id="licenseBreakdownSection">
          <div className={`${styles.licensesSection} ${appStyles.row}`}>
            <div className={appStyles.col_md_6}>
              <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
                <h3 className={appStyles.heading__text_sm}>Subscription Breakdown</h3>
              </div>
              <div className={`${appStyles.card} ${styles.card_licenses} ${appStyles.background_white} ${appStyles.card_boxShadow}`}>
                <div className={appStyles.card__row}>
                  <div className={`${appStyles.card__label} ${appStyles.text_midBlue}`}>Vendor</div>
                  <div className={`${appStyles.card__value} ${appStyles.text_marginBlue}`}>{this.license.vendor}</div>
                </div>
                <div className={appStyles.card__row}>
                  <div className={`${appStyles.card__label} ${appStyles.text_midBlue}`}>Username</div>
                  <div className={`${appStyles.card__value} ${appStyles.text_marginBlue}`}>{this.license.userId}</div>
                </div>
                <div className={appStyles.card__row}>
                  <div className={`${appStyles.card__label} ${appStyles.text_midBlue}`}>Password</div>
                  <div className={`${appStyles.card__value} ${appStyles.text_marginBlue}`}>{this.license.password}</div>
                </div>
                <div className={appStyles.card__row}>
                  <div className={`${appStyles.card__label} ${appStyles.text_midBlue}`}>All Keycode Details</div>
                  <div className={`${appStyles.card__value} ${appStyles.text_marginBlue}`}>{this.license.keycodes}</div>
                </div>
              </div>
            </div>

            <div className={appStyles.col_md_6}>
              <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
                <h3 className={appStyles.heading__text_sm}>Download</h3>
              </div>
              <div className={`${appStyles.card} ${appStyles.background_white} ${appStyles.card_boxShadow}`}>
                <div className={appStyles.card__column}>
                  <FileDownloads dataType="license" itemId={this.license.licenseId}></FileDownloads>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
