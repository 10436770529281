import { FC } from 'react';
import Alert from 'components/Generic/Alert/Alert';
import Button from 'components/Generic/FormElements/Button/Button';
import Select from 'components/Generic/FormElements/Select/Select';
import { FalconError } from 'models/generic/falconError.model';
import { ListItem } from 'models/generic/listItem.model';
import { ValidationErrors } from 'models/generic/validationError.model';
import availabilityCheckerStyles from '../AvailabilityChecker.module.scss';

interface IAddressListProps {
  selectedAddressId: string | null;
  handleSelect: React.ChangeEventHandler<HTMLSelectElement>;
  availableAddresses: ListItem[];
  errors: ValidationErrors;
  onContinueClick: React.MouseEventHandler<HTMLButtonElement>;
  continueButtonDisabled: boolean;
  addressesError: string | FalconError;
  isAddressesAvailable: boolean;
  inProgress: boolean;
}

const AddressList: FC<IAddressListProps> = ({
  selectedAddressId,
  handleSelect,
  availableAddresses,
  isAddressesAvailable,
  addressesError,
  errors,
  onContinueClick,
  continueButtonDisabled,
  inProgress
}) => (
  <>
    {addressesError ? (
      <Alert alertType="error" customClass={availabilityCheckerStyles.full_width}>
        <span id="errorsAndWarnings">{addressesError}</span>
      </Alert>
    ) : (
      isAddressesAvailable && (
        <>
          <div className={availabilityCheckerStyles.form__input_container}>
            <Alert alertType="info" customClass={availabilityCheckerStyles.full_width}>
              <span id="errorsAndWarnings">Please select your address from the list below, then click Continue.</span>
            </Alert>
            <Select
              labelText=""
              name="address"
              required={true}
              disablePlaceholder={true}
              value={selectedAddressId}
              handleChange={handleSelect}
              options={availableAddresses}
              elementId="availableAddressesList"
              placeholder="Please select an address"
              validationError={errors.fieldErrors['faultCode']}
              customSelectClass={availabilityCheckerStyles.form__select}
            />
          </div>
          <Button inProgress={inProgress} size="lg" buttonStyle="primary" id="btnContinue" handleClick={onContinueClick} disabled={continueButtonDisabled}>
            <span>Continue</span>
          </Button>
        </>
      )
    )}
  </>
);

export default AddressList;
