import { FC } from 'react';
import constants from 'utils/constants';
import FavoritesLink from 'components/Dashboard/Shortcuts/FavoritesLink';
import { User } from 'models/generic/user.model';
import { ReactComponent as LifeBouy } from 'assets/images/svg/lifeBouy.svg';
import { ReactComponent as DocumentsSVGLogo } from 'assets/images/svg/documents.svg';
import { ReactComponent as CardSVGLogo } from 'assets/images/svg/creditcard.svg';
import { ReactComponent as RocketSvg } from 'assets/images/svg/rocket.svg';
import styles from './Shortcuts.module.scss';

interface IShortcutsProps {
  currentUser: User;
}

const Shortcuts: FC<IShortcutsProps> = ({ currentUser }) => (
  <>
    {currentUser.hasRole(constants.roleNames.Orders) && <FavoritesLink to="/invoices" title="Invoices" hint="Manage Invoices" Picture={DocumentsSVGLogo} />}
    <hr className={styles.horizontal_line} />
    {currentUser.hasRole(constants.roleNames.Support) && <FavoritesLink to="/tickets" title="Tickets" hint="Manage tickets" Picture={LifeBouy} />}
    <hr className={styles.horizontal_line} />
    {currentUser.hasRole(constants.roleNames.Orders) && (
      <FavoritesLink to="/invoices/make-a-payment" title="Make a payment" hint="Pay outstanding invoices" Picture={CardSVGLogo} />
    )}
    <hr className={styles.horizontal_line} />
    <FavoritesLink to="/services/availability-checker" title="FTTP Availability Checker" hint="Check if Ultrafast broadband is available" Picture={RocketSvg} />
  </>
);

export default Shortcuts;
