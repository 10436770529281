import moment from 'moment';
import { ChangeEvent, ReactNode } from 'react';
import { Prompt } from 'react-router';
import { observable } from 'mobx';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import EditContactConfirmation from 'components/Account/Contacts/EditContactConfirmation';
import NewContactConfirmation from 'components/Account/Contacts/NewContactConfirmation';
import Alert from 'components/Generic/Alert/Alert';
import { BaseUnsavedChangesComponent } from 'components/Generic/BaseUnsavedChangesComponent';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import CheckBox from 'components/Generic/FormElements/Checkbox/Checkbox';
import Input from 'components/Generic/FormElements/Input/Input';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import Select from 'components/Generic/FormElements/Select/Select';
import ValidationArea from 'components/Generic/FormElements/ValidationArea/ValidationArea';
import Icon from 'components/Generic/Icon/icon';
import { Contact } from 'models/api/contact.model';
import { ValidationRule } from 'models/api/validationRule.model';
import { FalconError } from 'models/generic/falconError.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import { ListItem } from 'models/generic/listItem.model';
import { ValidationErrors } from 'models/generic/validationError.model';
import accountStore from 'stores/account.store';
import locationsStore from 'stores/locations.store';
import lookupStore from 'stores/lookups.store';
import userStore from 'stores/user.store';
import constants from 'utils/constants';
import utils from 'utils/utils';
import validation from 'utils/validation';
import appStyles from 'App.module.scss';
import preloader from 'assets/images/preloader.gif';
import styles from '../Contacts.module.scss';

@observer
export class EditContact extends BaseUnsavedChangesComponent<IBaseProps> {
  @observable editInProgress = false;
  @observable downloadInProgress = false;
  @observable saveInProgress = false;
  @observable contact = new Contact();
  @observable editMode = false;
  @observable contactErrors: ValidationErrors = new ValidationErrors();
  @observable contactConcurrencyError = '';
  @observable currentRoute: string = window.location.pathname;
  @observable currentClientId: number | null = null;
  @observable editContactId: number | null = null;

  @observable formValid = true;

  @observable showConfirmationView = false;
  @observable showLockedOutCheckbox = false;
  @observable showValidationCheckbox = false;
  @observable showValidationDate = false;
  @observable canEditEmail: boolean | null = null;
  @observable canEditMyPortalRole: boolean | null = null;
  @observable showValidationSummary = false;

  @observable selectedMyPortalRoles: number[] = [];
  @observable myPortalRoles: ListItem[] = [];
  @observable lockedOut = false;
  @observable accountValidated = false;
  @observable contactLoading = true;
  @observable newMyPortalRoleTotal: number | null = null;
  @observable roleDetailsVisible = false;

  @observable accountStatus: string | null = null;
  @observable statusMessage: ReactNode | null = null;

  validationRules: ValidationRule[] = [];
  addresses: ListItem[] = [];
  submitAttempted = false;

  roleDetails: { [key: string]: string } = {};

  constructor(props: IBaseProps) {
    super(props);

    this.returnToEditContact = this.returnToEditContact.bind(this);
    this.showRoleDetails = this.showRoleDetails.bind(this);
    this.onSave = this.onSave.bind(this);

    lookupStore.loadLookups();

    this.roleDetails['Quotes'] = 'Will grant users access to be able to view, request more information about, and accept (e-sign) quotes.';
    this.roleDetails['Orders'] =
      'Will grant users access to be able to view and download invoices, as well as setup a new Direct Debit or make a card payment on the account.';
    this.roleDetails['Contracts'] = 'Will grant users access to be able to view subscriptions.';
    this.roleDetails['Callouts'] = 'Will grant users the ability to view, update and sign-off callouts.';
    this.roleDetails['Support'] = 'Will grant users the ability to view, create and update support tickets.';
    this.roleDetails['MyPortal Admin'] = 'Will grant users the ability to create and edit locations and contacts.';
    this.roleDetails['Projects'] = 'Will grant users the ability to view and edit information relating to projects.';
    this.roleDetails['Returns'] = 'Will grant users the ability to view returns.';
    this.roleDetails['Tasks'] = 'This role is not in use currently';
    this.roleDetails['Daily Summary'] = 'This role is not in use currently';
    this.roleDetails['Repairs'] = 'This role is not in use currently';
    this.roleDetails['Order Online'] = 'This role is not in use currently';
  }

  async componentDidMount() {
    const contactId = +this.props.match.params.id;
    if (contactId) {
      this.editMode = true;
      this.editContactId = +this.props.match.params.id;
    } else {
      this.editMode = false;
    }

    await this.loadContact();
  }

  async loadContact() {
    if (userStore.currentUser && userStore.currentUser.currentClient) {
      this.currentClientId = userStore.currentUser.currentClient.clientId;
      this.addresses = await locationsStore.getLocationsListItems();

      let result;
      if (this.editMode && this.editContactId) {
        result = await accountStore.getContact(this.editContactId);
      } else {
        result = await accountStore.getNewContact();
      }

      if (result instanceof FalconError) {
        return;
      } else {
        this.contact = result;

        if (!this.contact) {
          this.props.history.push('/not-found');

          return;
        } else {
          this.myPortalRoles = await accountStore.getRolesAsListItem(this.contact.contactRoles);

          if (this.editMode) {
            this.selectedMyPortalRoles = accountStore.getSelectedRoles(this.contact.contactRoles);
            if (this.contact.myPortalIsLockedOut) {
              this.lockedOut = this.contact.myPortalIsLockedOut;
            }

            this.setShowLockedOutCheckbox();
            this.setValidatedCheckbox();
            this.setEmailField();
            this.setMyPortalRoleSection();
          } else {
            // hide checkbox when creating new contacts - is this correct?
            this.selectedMyPortalRoles = [];
            this.contact.myPortalIsLockedOut = false;
            this.accountValidated = true;
            this.canEditEmail = true;
            this.canEditMyPortalRole = true;
          }
        }
      }

      this.setAccountStatusMessage();
      this.validationRules = await accountStore.getContactValidationRules();

      // for existing contacts validate everything, for new contacts only fetch info
      if (this.editMode) {
        this.preValidateAll();
      } else {
        this.validateInfoAndWarnings();
      }

      this.contactLoading = false;
    }
  }

  setEmailField() {
    this.canEditEmail = this.contact.canWriteEmailProperty;
  }

  setMyPortalRoleSection() {
    this.canEditMyPortalRole = this.contact.canWriteMyPortalRoleProperty;
  }

  setValidatedCheckbox() {
    if (this.contact.myPortalIsValidated) {
      this.accountValidated = true;
      this.showValidationDate = true;
      this.showValidationCheckbox = false;
    } else if (this.contact.canWriteMyPortalIsValidatedProperty) {
      this.showValidationCheckbox = true;
    }
  }

  setShowLockedOutCheckbox() {
    // show lockedout checkbox if current user !== current contact and current user === myportaladmin
    if (this.contact.canWriteMyPortalIsLockedOutProperty) {
      this.showLockedOutCheckbox = true;
    }
  }

  handleInput = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    if (name === 'lockedOut') {
      // toggle
      this.lockedOut = !this.lockedOut;
    }

    if (name === 'accountValidated') {
      this.accountValidated = !this.accountValidated;
    }

    this.contact = {
      ...this.contact,
      [name]: value
    };

    this.unsavedChange = true;

    if (this.submitAttempted) {
      this.validateAll();
    } else {
      this.validateInfoAndWarnings();
    }
  };

  handleInputAsNumber = (e: ChangeEvent<HTMLSelectElement>) => {
    let value = null;
    if (e.target.value) {
      value = +e.target.value;
    }
    const name = e.target.name;

    this.contact = {
      ...this.contact,
      [name]: value
    };

    this.unsavedChange = true;

    if (this.submitAttempted) {
      this.validateAll();
    } else {
      this.validateInfoAndWarnings();
    }
  };

  handleCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    const newSelection = +e.target.value;
    let newSelectionArray: number[] = [];

    if (this.selectedMyPortalRoles.indexOf(newSelection) > -1) {
      newSelectionArray = this.selectedMyPortalRoles.filter((s) => s !== newSelection);
    } else {
      newSelectionArray = [...this.selectedMyPortalRoles, newSelection];
    }

    this.selectedMyPortalRoles = newSelectionArray;
    this.newMyPortalRoleTotal = newSelectionArray.reduce((a, b) => a + b, 0);
    this.contact.myPortalRole = this.newMyPortalRoleTotal;

    this.unsavedChange = true;

    if (this.submitAttempted) {
      this.validateAll();
    } else {
      this.validateInfoAndWarnings();
    }
  };

  preValidateAll() {
    // get any validation messages from server side
    this.contactErrors = validation.getValidationErrorsFromBrokenRules(this.contact.brokenRulesCollection);
  }

  validateInfoAndWarnings() {
    const infoBrokenRules = this.contact.brokenRulesCollection.filter((rule) => rule.severity === constants.validation.severity.info);
    this.contactErrors = validation.getValidationErrorsFromBrokenRules(infoBrokenRules);
    validation.validate(this.contact, this.validationRules, this.contactErrors, 'warning');
  }

  validateAll(): boolean {
    this.contactErrors = new ValidationErrors();
    // validate info and warnings before also validating errors
    this.validateInfoAndWarnings();
    this.formValid = validation.validate(this.contact, this.validationRules, this.contactErrors);

    // only show summary if there are warnings or errors
    if (!this.contactErrors.hasWarningSeverities && !this.contactErrors.hasErrorSeverities) {
      this.showValidationSummary = false;
    } else {
      this.showValidationSummary = true;
    }

    return this.formValid;
  }

  async returnToEditContact() {
    await this.loadContact();
    this.showConfirmationView = false;
  }

  async onSave() {
    this.saveInProgress = true;
    this.submitAttempted = true;
    this.contact.clientId = this.currentClientId;
    // if myportalrole is null then do something
    if (this.newMyPortalRoleTotal !== null) {
      this.contact.myPortalRole = this.newMyPortalRoleTotal;
    }
    this.contact.myPortalIsLockedOut = this.lockedOut;

    this.contact.myPortalIsValidated = this.accountValidated;

    this.validateAll();

    if (this.showValidationSummary) {
      window.scrollTo(0, 0);
    }

    let result;

    if (this.formValid && (!this.showValidationSummary || this.contact.isSaveConfirmed)) {
      if (this.editMode && this.editContactId) {
        result = await accountStore.updateContact(this.editContactId, this.contact);
      } else {
        result = await accountStore.createNewContact(this.contact);
      }

      // if result is error than check for validation messages
      if (result instanceof FalconError) {
        this.contactErrors = validation.getValidationErrorsFromFalconError(result);

        this.showValidationSummary = true;

        if (result.concurrencyMessage) {
          this.contactConcurrencyError = result.concurrencyMessage;
          window.scrollTo(0, 0);
        }
      } else {
        this.unsavedChange = false;
        this.showConfirmationView = true;
      }
    }

    this.saveInProgress = false;
  }

  showRoleDetails() {
    this.myPortalRoles.forEach((role) => {
      if (this.roleDetails[role.text]) {
        role.text = `<div class='${styles.roleName}'>${role.text}</div><div class='${styles.roleDetail}'>${this.roleDetails[role.text]}</div>`;
      }
    });

    this.roleDetailsVisible = true;
  }

  setConfirmationView() {
    if (this.editMode) {
      return <EditContactConfirmation returnToEditContact={this.returnToEditContact}></EditContactConfirmation>;
    } else {
      return <NewContactConfirmation></NewContactConfirmation>;
    }
  }

  back = () => {
    this.props.history.push('/account/contacts');
  };

  getValidationSummary(): JSX.Element {
    let result: JSX.Element = <></>;

    if (this.showValidationSummary) {
      this.contact.isSaveConfirmed = true;

      if (this.contactErrors.hasWarningSeverities) {
        if (this.contactErrors.hasErrorSeverities) {
          result = (
            <Alert alertType="error" customClass="validationSummary">
              <span id="errorsAndWarnings">
                Please review and resolve the validation errors below. There are also some validation warning messages we'd like to make you aware of before
                saving your changes.
              </span>
            </Alert>
          );
        } else {
          result = (
            <Alert alertType="warning" customClass="validationSummary">
              <span id="warningsOnly">
                Please review the form as there are some validation messages we'd like to make you aware of before saving your changes. If you'd like to save
                without addressing the issues, click the "Save Contact" button again.
              </span>
            </Alert>
          );
        }
      } else if (this.contactErrors.hasErrorSeverities) {
        result = (
          <Alert alertType="error" customClass="validationSummary">
            <span id="errorsOnly">Please resolve the validation errors below before saving.</span>
          </Alert>
        );
      }
    }

    return result;
  }

  setAccountStatusMessage() {
    this.accountStatus = this.contact.loginAccountStatus;

    switch (this.accountStatus) {
      case 'Not registered':
        this.statusMessage = (
          <Alert alertType="info" customClass="accountStatusSummary">
            Please note that this user has not registered an account to log in to the Customer Portal.
          </Alert>
        );
        break;
      case 'Not validated':
        this.statusMessage = (
          <Alert alertType="info" customClass="accountStatusSummary">
            Please note that this user account is not validated. Please check the "Validate account" box at the bottom of the form to make sure they can access
            the Customer Portal.
          </Alert>
        );
        break;
      case 'Locked':
        this.statusMessage = (
          <Alert alertType="info" customClass="accountStatusSummary">
            Please note that this user account is locked out and they will not be able to access the Customer Portal. If you wish to reinstate their access
            please uncheck the "Account locked out" checkbox.
          </Alert>
        );
        break;
      case 'New':
        this.statusMessage = (
          <Alert alertType="info" customClass="accountStatusSummary">
            Please note that this user has registered but has never logged in to the Customer Portal.
          </Alert>
        );
        break;
      case 'Active':
      case '':
      default:
        this.statusMessage = null;
    }
  }

  getConcurrencyMessage(): ReactNode | null {
    if (this.contactConcurrencyError) {
      return (
        <Alert alertType="error" customClass="concurrencyAlert">
          {this.contactConcurrencyError}
          <br />
          {constants.validation.concurrencyMessage}
        </Alert>
      );
    }
  }

  // eslint-disable-next-line complexity
  render() {
    if (this.showConfirmationView) {
      return this.setConfirmationView();
    } else {
      return (
        <>
          <Prompt
            when={this.unsavedChange && BaseUnsavedChangesComponent.enableCheck}
            message="You have unsaved changes. Are you sure you want to navigate away from this page?"
          />

          <div className={appStyles.container}>
            <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
              <h1 className={appStyles.heading__text}>{this.editMode ? 'Edit Contact' : 'Add New Contact'}</h1>
            </div>

            {this.getConcurrencyMessage()}

            {this.getValidationSummary()}

            {this.statusMessage}

            {!this.contactLoading ? (
              <form className={appStyles.row}>
                <div className={appStyles.col_md_6} id="personalDetailsCard">
                  <fieldset>
                    <Legend type="large" text="Details" />
                    <div className={appStyles.form__row}>
                      <Input
                        elementId="salutation"
                        inputType="text"
                        labelText="Title"
                        name="salutation"
                        placeholder="Enter your title"
                        handleChange={this.handleInput}
                        value={this.contact.salutation}
                        autocomplete="honorific-prefix"
                      />
                    </div>
                    <div className={appStyles.form__row}>
                      <Input
                        elementId="firstNames"
                        inputType="text"
                        labelText="First Names"
                        name="firstNames"
                        placeholder="Enter your first name"
                        handleChange={this.handleInput}
                        value={this.contact.firstNames}
                        autocomplete="given-name"
                        required={true}
                        validationError={this.contactErrors.fieldErrors['firstNames']}
                      />
                    </div>
                    <div className={appStyles.form__row}>
                      <Input
                        elementId="surname"
                        inputType="text"
                        labelText="Surname"
                        name="surname"
                        placeholder="Enter your surname"
                        handleChange={this.handleInput}
                        value={this.contact.surname}
                        autocomplete="family-name"
                        required={true}
                        validationError={this.contactErrors.fieldErrors['surname']}
                      />
                    </div>
                  </fieldset>
                </div>

                <div className={appStyles.col_md_6} id="contactDetailsCard">
                  <fieldset>
                    <Legend type="large" text="Contact Details" />
                    <div className={appStyles.form__row}>
                      <Input
                        elementId="telephone"
                        inputType="text"
                        labelText="Telephone"
                        name="telephone"
                        placeholder="Enter your telephone number"
                        handleChange={this.handleInput}
                        value={this.contact.telephone}
                        autocomplete="tel"
                        validationError={this.contactErrors.fieldErrors['telephone']}
                      />
                    </div>
                    <div className={appStyles.form__row}>
                      <Input
                        elementId="mobile"
                        inputType="text"
                        labelText="Mobile"
                        name="mobile"
                        placeholder="Enter your mobile number"
                        handleChange={this.handleInput}
                        value={this.contact.mobile}
                        autocomplete="tel"
                        validationError={this.contactErrors.fieldErrors['mobile']}
                      />
                    </div>
                    <div className={appStyles.form__row}>
                      {this.canEditEmail === true ? (
                        <Input
                          elementId="email"
                          inputType="text"
                          labelText="Email"
                          name="email"
                          placeholder="Enter an email address"
                          handleChange={this.handleInput}
                          value={this.contact.email}
                          autocomplete="email"
                          required={true}
                          validationError={this.contactErrors.fieldErrors['email']}
                        />
                      ) : this.canEditEmail === false ? (
                        <>
                          <div className={appStyles.form__label}>Email</div>
                          <div className={appStyles.form__readOnlyElement} id="email">
                            {this.contact.email}
                          </div>
                          <ValidationArea sectionId="email" validationError={this.contactErrors.fieldErrors['email']}></ValidationArea>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className={appStyles.form__row}>
                      {this.canEditEmail === true ? (
                        <Input
                          elementId="confirmEmail"
                          inputType="text"
                          labelText="Confirm email"
                          name="confirmEmail"
                          placeholder="Confirm an email address"
                          handleChange={this.handleInput}
                          value={this.contact.confirmEmail}
                          autocomplete="email"
                          required={true}
                          validationError={this.contactErrors.fieldErrors['confirmEmail']}
                        />
                      ) : null}
                    </div>
                    <div className={appStyles.form__row}>
                      <Select
                        elementId="addressId"
                        labelText="Address"
                        name="addressId"
                        options={this.addresses}
                        placeholder="Please select..."
                        disablePlaceholder={true}
                        handleChange={this.handleInputAsNumber}
                        value={this.contact.addressId}
                        required={true}
                        validationError={this.contactErrors.fieldErrors['addressId']}
                      />
                    </div>
                  </fieldset>
                </div>

                <div id="securityDetailsCard" className={appStyles.col_md_6}>
                  <fieldset>
                    <div className={appStyles.form__row}>
                      <Input
                        elementId="position"
                        inputType="text"
                        labelText="Position"
                        name="position"
                        placeholder="Enter a position"
                        handleChange={this.handleInput}
                        value={this.contact.position}
                      />
                    </div>
                  </fieldset>
                </div>

                <div className={appStyles.col_md_6}>
                  <div id="permissionsCard" className={`${appStyles.card} ${appStyles.background_white} ${appStyles.card_boxShadow}`}>
                    <fieldset>
                      <Legend type="medium" text="Customer Portal Permissions" />
                      <div className={appStyles.form__row}>
                        <Legend type="small" text="Determine the areas of Customer Portal this contact will be permitted to access" />

                        <div className={appStyles.permissions__checkboxes}>
                          <CheckBox
                            name="myPortalRole"
                            options={this.myPortalRoles}
                            selectedOptions={this.selectedMyPortalRoles}
                            handleChange={this.handleCheckBox}
                            customLabelClass={appStyles.checkbox__label}
                            customInputClass={appStyles.checkbox}
                            disabled={!this.canEditMyPortalRole ? true : false}
                          />
                        </div>

                        {this.roleDetailsVisible ? (
                          ''
                        ) : (
                          <div
                            className={classNames(appStyles.form__legend, appStyles.form__legend_small, styles.showRoles)}
                            role="button"
                            title="Click to view more details about the roles"
                            onClick={this.showRoleDetails}>
                            Show more details about the roles
                          </div>
                        )}
                        <ValidationArea sectionId="myPortalRole" validationError={this.contactErrors.fieldErrors['myPortalRole']}></ValidationArea>
                      </div>
                      {this.showValidationCheckbox ? (
                        <div className={appStyles.form__row}>
                          <div className={appStyles.permissions__checkboxes}>
                            <Legend type="small" text="Account Validation" />
                            <CheckBox
                              name="accountValidated"
                              options={[new ListItem(true, 'Validate account')]}
                              selectedOptions={[this.accountValidated]}
                              handleChange={this.handleInput}
                              customLabelClass={appStyles.checkbox__label}
                              customInputClass={appStyles.checkbox}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {this.showValidationDate ? (
                        <div className={appStyles.form__row}>
                          <div id="accountValidated" className={`${appStyles.form__readOnlyElement} ${styles.form__readOnlyElement_inverse}`}>
                            Account validated on {utils.dateTime.getFormattedMomentDateAndTime(moment(this.contact.myPortalIsValidatedDate))}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {this.showLockedOutCheckbox ? (
                        <div className={appStyles.form__row}>
                          <div className={appStyles.permissions__checkboxes}>
                            <Legend type="small" text="Select to block access to Customer Portal for this user" />
                            <CheckBox
                              name="lockedOut"
                              options={[new ListItem(true, 'Account locked out')]}
                              selectedOptions={[this.lockedOut]}
                              handleChange={this.handleInput}
                              customLabelClass={appStyles.checkbox__label}
                              customInputClass={appStyles.checkbox}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </fieldset>
                  </div>
                </div>
              </form>
            ) : (
              <div>
                <img alt="Loading..." className={appStyles.preloader} src={preloader} />
              </div>
            )}
          </div>
          <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
            <Button buttonStyle="outline_secondary" size="lg" handleClick={this.back}>
              Back to Manage Contacts
            </Button>

            <Button buttonStyle="primary" customClass={appStyles.button_hasIcon} size="lg" handleClick={this.onSave} inProgress={this.saveInProgress}>
              <Icon icon={Icon.check} iconName="check icon" customClass={appStyles.buttonWithIcon__icon} size="sm" color="currentColor" />
              <span className={appStyles.buttonWithIcon__text}>{this.editMode ? 'Save Contact' : 'Save New Contact'}</span>
            </Button>
          </BottomButtonContainer>
        </>
      );
    }
  }
}
