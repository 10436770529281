import { FC, ReactNode, useEffect } from 'react';
import classNames from 'classnames';
import appStyles from 'App.module.scss';
import navigationStore from 'stores/navigation.store';
import styles from './BottomButtonContainer.module.scss';

interface IBottomButtonContainerProps {
  /**
   *@property {string} backgroundColor Set the background colour of the container
   */
  backgroundColor?: 'white' | 'skyBlue' | 'darkSkyBlue' | 'marginBlue' | 'tealish' | 'paleBlue' | 'midBlue';
  /**
   *@property {string} layout Set the layout of the container content (based on flexbox properties set in style.scss). Default is spaceBetween
   */
  layout?: 'left' | 'right' | 'spaceBetween';
  /**
   *@property {ReactNode} children Add elements inside this container
   */
  children: ReactNode;

  /**
   *@property {string} customContainerClass Add custom button container class
   */
  customContainerClass?: string;
}

/**
 * @description Add a bottom button container. Optional properties: backgroundColor (default is transparent), layout (default is spaceBetween)
 */
const BottomButtonContainer: FC<IBottomButtonContainerProps> = ({ backgroundColor, layout, customContainerClass, children }) => {
  useEffect(() => {
    navigationStore.setBottomButtonContainerIsPresent(true);

    return () => navigationStore.setBottomButtonContainerIsPresent(false);
  }, []);

  return (
    <div className={`${styles.containerBottom} ${appStyles[`background_${backgroundColor || 'transparent'}_tablet`]}`}>
      <div className={classNames(appStyles.container, styles.containerBottom__content, styles[`layout_${layout || 'spaceBetween'}`], customContainerClass)}>
        {children}
      </div>
    </div>
  );
};

export default BottomButtonContainer;
