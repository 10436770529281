import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRouteIdProp } from 'routing/types';
import Preloader from 'components/Generic/Preloader/Preloader';
import loadingWrapperStyles from './LoadingWrapper.module.scss';

interface ILoadingWrapperProps {
  id: string;
  title: string;
  visible: boolean;
}

class LoadingWrapper extends Component<ILoadingWrapperProps & RouteComponentProps<IRouteIdProp>> {
  render() {
    return (
      this.props.visible && (
        <div className={loadingWrapperStyles.modal__content}>
          <Preloader id={`${this.props.id}-preloader`} title={this.props.title} titleClass={loadingWrapperStyles.title} />
        </div>
      )
    );
  }
}

export default withRouter(LoadingWrapper);
