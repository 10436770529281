import { FC } from 'react';
import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import Icon from 'components/Generic/Icon/icon';

const LoginWaiting: FC = () => (
  <div className={appStyles.container}>
    <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
      <Icon icon={Icon.user} size="heading" color="currentColor" iconName="user icon" customClass={appStyles.heading__icon} />
      <h1 className={appStyles.heading__text}>Please wait</h1>
    </div>

    <Alert alertType="blank">
      <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>You are being authenticated...</p>
    </Alert>
  </div>
);

export default LoginWaiting;
