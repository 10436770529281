/* eslint-disable max-len */
import { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import Alert from 'components/Generic/Alert/Alert';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import lineFaultsStore from 'stores/lineFaults.store';
import appStyles from 'App.module.scss';

interface ILineCheckErrorState {
  data: string;
}

class LineCheckError extends Component<RouteComponentProps> {
  getErrorMessage() {
    const defaultMessage = 'Something went wrong.';

    const error = this.props.location?.state as ILineCheckErrorState;

    switch (error.data) {
      case '':
      case null:
        return defaultMessage;
      case 'CliCannotBeLinkedToYourAccount':
        return (
          <>
            It is not possible to run a line test on this number as we are unable to link it to your account. Please click <Link to="/tickets/new">here</Link>{' '}
            to raise a support ticket and a member of our faults team will contact you to provide assistance.
          </>
        );
      default:
        return typeof error.data === 'string' ? error.data : defaultMessage;
    }
  }

  renderError() {
    const message = this.getErrorMessage();

    return (
      !!message && (
        <Alert alertType="error">
          <span id="errorsOnly">{message}</span>
        </Alert>
      )
    );
  }

  handleStartAnotherTest = () => {
    const { history } = this.props;
    lineFaultsStore.cleanResults();

    return history.push('/services/line-faults');
  };

  render() {
    return (
      <div>
        <div className={appStyles.heading}>
          <h1 className={classNames(appStyles.heading__text, appStyles.text_midBlue)}>
            <span id="titleText">Something went wrong</span>
          </h1>
        </div>
        {this.renderError()}
        <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
          <Button id="btnPreformAnotherTest" key="btnPreformAnotherTest" buttonStyle="outline_secondary" size="lg" handleClick={this.handleStartAnotherTest}>
            <span>Perform Another Test</span>
          </Button>
        </BottomButtonContainer>
      </div>
    );
  }
}

export default LineCheckError;
