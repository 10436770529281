import { Grid } from '@progress/kendo-react-grid';
import CheckBox from 'components/Generic/FormElements/Checkbox/Checkbox';
import { BaseServerGrid } from 'components/Generic/Grid/BaseServerGrid';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Invoice } from 'models/api/invoice.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import { ListItem } from 'models/generic/listItem.model';
import { ModelCollection } from 'models/generic/modelCollection.model';
import { InvoicesGridRequest } from 'models/requests/InvoicesGridRequest.model';
import { ChangeEvent } from 'react';
import { withRouter } from 'react-router-dom';
import invoicesStore from 'stores/invoices.store';

interface IOutstandingInvoicesGridProps extends IBaseProps {
  setSelectedInvoices: (invoices: string[] | null) => void;
}

@observer
class OutstandingInvoicesGrid extends BaseServerGrid<Invoice, IOutstandingInvoicesGridProps, InvoicesGridRequest> {
  @observable selectedInvoices: string[] = [];
  @observable unpaidInvoicesList: ModelCollection<Invoice> = new ModelCollection<Invoice>();

  constructor(props: IOutstandingInvoicesGridProps) {
    super(props);
    // set default sort
    this.sort = [{ field: 'dateIssued', dir: 'desc' }];

    // set default request - sort order and statuses
    this.request = new InvoicesGridRequest('dateIssued DESC', this.take, [], true);
    this.handleCheckBox = this.handleCheckBox.bind(this);
  }

  async componentDidMount() {
    await this.loadData();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async componentDidUpdate(prevProps: IBaseProps) {
    // reload data if request was updated
    if (this.requestUpdated) {
      await this.loadData();
    }
  }

  getData(): Promise<ModelCollection<Invoice>> {
    return invoicesStore.getInvoices(this.request);
  }

  handleCheckBox(e: ChangeEvent<HTMLInputElement>) {
    const newSelection = e.target.value;
    let newSelectionArray: string[] = [];

    if (this.selectedInvoices.indexOf(newSelection) > -1) {
      newSelectionArray = this.selectedInvoices.filter((i) => i !== newSelection);
      this.unpaidInvoicesList.items = this.unpaidInvoicesList.items.filter((i) => i.invoiceNumber && i.invoiceNumber !== newSelection);
    } else {
      newSelectionArray = [...this.selectedInvoices, newSelection];
      this.unpaidInvoicesList.items.push(this.data.items.filter((i: Invoice) => i.invoiceNumber && i.invoiceNumber === newSelection)[0]);
    }

    this.selectedInvoices = newSelectionArray;

    // TODO: find a better way to update the grid checkbox!
    const data = this.data;
    this.setState({ data });

    this.unpaidInvoicesList.totalCount = this.unpaidInvoicesList.items.length;

    invoicesStore.unpaidInvoices = this.unpaidInvoicesList;
    this.props.setSelectedInvoices(this.selectedInvoices);
  }

  render() {
    const mainGrid = (
      <Grid
        data={this.data.items}
        filter={this.filter}
        filterable={false}
        pageable={{ pageSizes: this.pageSizes }}
        skip={this.skip}
        take={this.take}
        total={this.data.totalCount}
        onPageChange={this.pageChange}
        sortable={true}
        sort={toJS(this.sort)}
        resizable
        onFilterChange={this.filterChange}
        onSortChange={this.sortChange}>
        {ColumnHelper.getGridColumns([
          { field: 'invoiceNumber', title: 'Invoice Number', dataType: 'text', size: 'md', currentFilter: this.filter },
          { field: 'dateIssued', title: 'Date Issued', dataType: 'date', size: 'md', currentFilter: this.filter },
          { field: 'orderNumber', title: 'Order Number', dataType: 'text', size: 'md', currentFilter: this.filter },
          { field: 'accountNumber', title: 'Account Number', dataType: 'text', size: 'md', currentFilter: this.filter },
          { field: 'total', title: 'Total', dataType: 'currency', size: 'md', currentFilter: this.filter },
          { field: 'outstandingAmount', title: 'Outstanding Amount', dataType: 'currency', size: 'md', currentFilter: this.filter },
          {
            field: '',
            title: 'Select',
            dataType: 'text',
            size: 'xs',
            cell: (props) =>
              props.dataItem.isCardPaymentPending ? (
                <td className="gridColumn_xs">Payment processing</td>
              ) : (
                <td className="k-table-td gridColumn_xs gridColumn_button">
                  <CheckBox
                    name="selectedInvoices"
                    options={[new ListItem(props.dataItem.invoiceNumber, '')]}
                    selectedOptions={this.selectedInvoices}
                    handleChange={this.handleCheckBox}
                  />
                </td>
              )
          }
        ])}
      </Grid>
    );

    return mainGrid;
  }
}
export default withRouter(OutstandingInvoicesGrid);
