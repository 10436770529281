import { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import { Notification } from 'models/api/notification.model';
import { NotificationStatuses } from 'utils/enums';

interface IBottomContainerProps {
  notification?: Notification | null;
  backToNotifications: () => void;
  setArchived: (value: number) => void;
  setUnArchived: (value: number) => void;
}

const BottomContainer: FC<IBottomContainerProps> = ({ notification, setArchived, setUnArchived, backToNotifications }) => {
  const buttonArchiveClickHandler = useCallback(() => {
    if (notification?.notificationId) {
      if (notification.status !== NotificationStatuses[NotificationStatuses.Archived]) {
        setArchived(notification.notificationId);
      } else {
        setUnArchived(notification.notificationId);
      }
    }
  }, [notification, setArchived, setUnArchived]);

  const buttonText = notification?.status !== NotificationStatuses[NotificationStatuses.Archived] ? 'Archive Notification' : 'Un-Archive Notification';

  return (
    <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
      <Button id="buttonViewNotifications" buttonStyle="outline_secondary" size="lg" handleClick={backToNotifications}>
        Back to Notifications
      </Button>
      <Button id="buttonArchive" buttonStyle="outline_primary" size="lg" handleClick={buttonArchiveClickHandler}>
        {buttonText}
      </Button>
    </BottomButtonContainer>
  );
};

export default observer(BottomContainer);
