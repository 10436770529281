import { FC } from 'react';
import classNames, { Value } from 'classnames';
import linkButtonStyle from './LinkButton.module.scss';

interface ILinkButtonProps {
  id?: string;
  children: string;
  onClick: React.MouseEventHandler<HTMLSpanElement>;
  className?: Value;
}

const LinkButton: FC<ILinkButtonProps> = ({ children, className, id, onClick }) => (
  <span onClick={onClick} role="button" className={classNames(linkButtonStyle.linkButton__text, className)} id={id}>
    {children}
  </span>
);

export default LinkButton;
