import { FC } from 'react';
import { observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';
import constants from 'utils/constants';
import accountStore from 'stores/account.store';
import navigationStore from 'stores/navigation.store';
import notificationsStore from 'stores/notifications.store';
import userStore from 'stores/user.store';
import AccountLink from 'components/Account/SwitchAccount/AccountLink';
import Alert from 'components/Generic/Alert/Alert';
import Button from 'components/Generic/FormElements/Button/Button';
import { Client } from 'models/api/client.model';
import styles from './SwitchAccount.module.scss';

function isClientIdNotNull(client: Client): client is Client & { clientId: number } {
  return client.clientId !== null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setClient = (clientId: number | null, history: any) => {
  if (userStore.currentUser && clientId) {
    const client = userStore.currentUser.clients.find((c) => c.clientId === clientId);

    if (client) {
      userStore.setSelectedClient(client);

      // redirect to originally requested page or dashboard
      navigationStore.redirectToRequestedUrl(history);
    }
  }
};

let accountSelected = false;

const handleAccountLink = () => {
  if (!accountSelected) {
    accountSelected = true;
  }
};

interface IAccountsListProps {
  clients: Client[];
}

const AccountsList: FC<IAccountsListProps> = ({ clients }) => {
  const history = useHistory();
  const initialAccountSelected = userStore.currentUser?.currentClient !== null;

  return (
    <div>
      <div className={styles.account}>
        {clients.filter(isClientIdNotNull).map((client) => (
          <AccountLink
            key={client.clientId}
            isSelected={client.clientId === accountStore.selectedClientId}
            client={client}
            unreadCount={client.clientId && notificationsStore.unreadNotificationCountForClients[client.clientId.toString()]?.unreadCount}
            onClick={handleAccountLink}
          />
        ))}
        {userStore.currentUser?.clients && userStore.currentUser.roles.includes(constants.roleNames.MyPortalPendingValidation) && (
          <Alert alertType="info" customClass={styles.alertMargin}>
            There are one or more other accounts you may be able to access but your user account is pending validation. Please contact your Customer Portal
            Administrator to gain access.
          </Alert>
        )}
      </div>
      <div className={styles.switch_account_buttons}>
        <Button disabled={!accountSelected} buttonStyle="primary" size="lg" handleClick={() => setClient(accountStore.selectedClientId, history)}>
          Continue
        </Button>
        <Link hidden={!initialAccountSelected} to="/dashboard">
          Back
        </Link>
      </div>
    </div>
  );
};

export default observer(AccountsList);
