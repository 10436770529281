import appStyles from 'App.module.scss';
import { Component } from 'react';

interface ILegendProps {
  /**
   *@property {string} text Set the text to appear in the label
   */
  text: string;
  /**
   *@property {string} type Set the type of legend - large, small, checkbox (based on styles in _forms.scss)
   */
  type: 'large' | 'medium' | 'small';
  /**
   * @property {string} customClass Optional. Use a custom class on the legend - use styles from the current component or appStyles
   */
  customClass?: string;
}
/**
 * @description Add a legend element. Optional properties: customClass
 */
class Legend extends Component<ILegendProps> {
  render() {
    return (
      <legend className={`${appStyles.form__legend} ${appStyles[`form__legend_${this.props.type}`]} ${this.props.customClass || ''}`}>{this.props.text}</legend>
    );
  }
}

export default Legend;
