import { Component } from 'react';
import { computed, observable, toJS } from 'mobx';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import 'scss/kendoImports.scss';
import 'scss/kendoOverrides.scss';

export class BaseGrid<PropsType> extends Component<PropsType> {
  @observable skip = 0;
  @observable take = 8;
  @observable sort: SortDescriptor[] = [];
  @observable exporting = false;

  @observable filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: []
  };

  @computed get pureFilter() {
    return toJS(this.filter);
  }

  gridPDFExport: GridPDFExport | null = null;
  pageSizes = [5, 8, 20, 50, 100];

  isColumnActive = (field: string, filter: CompositeFilterDescriptor) => GridColumnMenuFilter.active(field, filter);

  exportPdf(data: unknown[]) {
    this.exporting = true;

    const exportableData = toJS(data);

    if (this.gridPDFExport) {
      this.gridPDFExport.save(exportableData, this.pdfExportDone);
    }
  }

  pdfExportDone = () => {
    this.exporting = false;
  };
}
