import { AccountInfo } from '@azure/msal-browser';
import { Client } from 'models/api/client.model';
import { Location } from 'models/api/location.model';
import auth from 'utils/auth';

export class User {
  id: string | null = null;
  firstName: string | null = null;
  lastName: string | null = null;
  fullName: string | null = null;
  clients: Client[] = [];
  currentClient: Client | null = null;
  email: string | null = null;
  roles: string[] = [];
  b2c: boolean | null = null; // whether user is b2c or chess employee

  primaryAddress: Location = new Location();
  sageIds: string[] = [];

  static fromAccount(account: AccountInfo): User {
    const user = new User();
    const parsedClaims = auth.parseAccountClaims(account);

    user.id = parsedClaims.id;
    user.firstName = parsedClaims.firstName;
    user.lastName = parsedClaims.lastName;
    user.fullName = parsedClaims.fullName;
    user.email = parsedClaims.email;
    // reading the user type and setting b2c value
    user.b2c = parsedClaims.b2c;
    // set roles
    user.roles = parsedClaims.roles;

    return user;
  }

  public hasRole(role: string): boolean {
    return !!this.currentClient && this.roles.includes(`${this.currentClient.clientId}-${role}`);
  }
}
