import { MaskedTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import appStyles from 'App.module.scss';
import classNames from 'classnames';
import { BaseUnsavedChangesComponent } from 'components/Generic/BaseUnsavedChangesComponent';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import { Step1 } from 'components/Invoices/DirectDebit/NewDirectDebit/FormSteps/Step1';
import { Step2 } from 'components/Invoices/DirectDebit/NewDirectDebit/FormSteps/Step2';
import { Step3 } from 'components/Invoices/DirectDebit/NewDirectDebit/FormSteps/Step3';
import { Step4 } from 'components/Invoices/DirectDebit/NewDirectDebit/FormSteps/Step4';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { DirectDebit } from 'models/api/directDebit.model';
import { ModulusCheck } from 'models/api/modulusCheck.model';
import { ValidationRule } from 'models/api/validationRule.model';
import { FalconError } from 'models/generic/falconError.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import { ListItem } from 'models/generic/listItem.model';
import { ValidationErrors } from 'models/generic/validationError.model';
import { ChangeEvent } from 'react';
import { Link, Prompt, withRouter } from 'react-router-dom';
import invoicesStore from 'stores/invoices.store';
import validation from 'utils/validation';

interface IDirectDebitBreakdownProps extends IBaseProps {
  DirectDebit?: DirectDebit | null;
  handleViewDirectDebits?: () => void;
  refreshSelectedDirectDebit?: (value: DirectDebit | null) => void;
}

@observer
class NewDirectDebit extends BaseUnsavedChangesComponent<IDirectDebitBreakdownProps> {
  @observable editInProgress = false;
  @observable downloadInProgress = false;
  @observable saveInProgress = false;
  @observable step1Valid = false;
  @observable step2Valid = false;
  @observable currentStep = 1;
  @observable step1Errors: ValidationErrors = new ValidationErrors();
  @observable step2Errors: ValidationErrors = new ValidationErrors();
  @observable formValid = true;
  @observable sageAccountsList: ListItem[] = [];
  @observable directDebitLoading = true;
  @observable directDebit = new DirectDebit();

  newDirectDebit = true;
  validationRules: ValidationRule[] = [];

  submitAttempted = false;
  proceedAttempted = false;

  async componentDidMount() {
    this.sageAccountsList = await invoicesStore.getUnusedSageAccounts();

    if (this.props.DirectDebit) {
      this.newDirectDebit = false;
      this.directDebit = this.props.DirectDebit;
      this.updateModulusCheck(); // Force a modulus check on editing an existing DD.
    } else {
      this.newDirectDebit = true;

      // should only call if there are sage accounts
      if (this.sageAccountsList && this.sageAccountsList.length !== 0) {
        const result = await invoicesStore.getNewDirectDebit();

        if (result instanceof FalconError) {
          return;
        } else {
          this.directDebit = result;
        }
      }
    }
    this.validationRules = await invoicesStore.getDirectDebitValidationRules();
    this.directDebitLoading = false;
  }

  handleViewDirectDebits() {
    if (this.props.handleViewDirectDebits !== undefined) {
      this.props.handleViewDirectDebits();
    }
  }

  refreshSelectedDirectDebit(directDebit: DirectDebit) {
    if (this.props.refreshSelectedDirectDebit !== undefined) {
      this.props.refreshSelectedDirectDebit(directDebit);
    }
  }

  handleInput = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    // convert to boolean or number for some fields
    if (name === 'accountHolderConfirm' || name === 'accountAuthConfirm') {
      // assign a bool value
      this.directDebit = {
        ...this.directDebit,
        [name]: value === 'true'
      };
    } else if (name === 'contactId') {
      // assign a numeric value
      this.directDebit = {
        ...this.directDebit,
        [name]: +value
      };
    } else if (name === 'sageAccountSelect') {
      // Separate sage details from the string held in the dropdown and assign to this DD.
      const list = e.target as HTMLSelectElement;
      const sel = list.selectedIndex;
      const opt = list.options[sel];
      this.directDebit = {
        ...this.directDebit,
        selectedSageAccount: value
      };
      const currentSageIds = this.parseSageOptionValues(value);
      this.directDebit.sageAccountLinkId = +currentSageIds[0];
      this.directDebit.sage200CompanyId = +currentSageIds[1];
      const currentSageValues = this.parseSageOptionValues(opt.text);
      this.directDebit.sage200CompanyName = currentSageValues[0] + ' - ' + currentSageValues[1];
      this.directDebit.sageAccountNumber = currentSageValues[2];
    } else {
      // assign a string value
      this.directDebit = {
        ...this.directDebit,
        [name]: value
      };

      this.unsavedChange = true;
    }

    this.step1Valid = this.validateStep1();
    // Only check validation if the user has already tried to submit the form
    if (this.submitAttempted) {
      this.validateStep2();
    }
  };

  parseSageOptionValues(text: string) {
    // Given a sage name in the format '03 - Chess ICT Limited - KASDFDE1', return split values:
    const sageValues = text.split(' - ');
    if (sageValues.length > 3) {
      throw new Error('Could not parse the Sage account details selected');
    }

    return sageValues;
  }

  async updateModulusCheck() {
    // Only check modulus API if:
    //  both account number and sort code are of the correct length, and,
    //  it hasn't already passed, and,
    //  the held values don't equal the current values (prevent multiple retries).

    if (this.directDebit.bankAccountNumber && this.directDebit.bankSortCode) {
      this.directDebit.previousModulusCheck = this.directDebit.modulusCheck;
      const inputAccountNumber = this.directDebit.bankAccountNumber.replace('_', '');
      const inputSortCode = this.directDebit.bankSortCode.replace('_', '');

      if (inputAccountNumber.length === 8 && inputSortCode.length === 6) {
        // Skip check if values have already been checked.
        let check: ModulusCheck | FalconError | null = null;

        if (
          this.directDebit.previousModulusCheck &&
          this.directDebit.previousModulusCheck.AccountDetailsOutput?.AccountNumber === inputAccountNumber &&
          this.directDebit.previousModulusCheck.AccountDetailsOutput?.SortCode === inputSortCode
        ) {
        } else {
          check = await invoicesStore.getModulusCheck(inputSortCode, inputAccountNumber);
        }

        this.actOnModulusCheck(check);
      } else {
        // Clear previous checks:
        this.directDebit.modulusCheck = null;
        this.directDebit.previousModulusCheck = null;
        this.directDebit.modulusFailed = false;
      }
    }
  }

  actOnModulusCheck(check: ModulusCheck | FalconError | null) {
    if (!check) {
      return;
    }
    if (check instanceof FalconError) {
      this.directDebit.modulusFailed = false;
    } else {
      if (check.AccountDetailsOutput) {
        this.directDebit.bankAccountVerificationMAC = check.AccountDetailsOutput?.BankAccountVerificationMAC;
      }

      if (check.Valid) {
        // Passed modulus check.
        this.directDebit.modulusFailed = false;
        if (check.UKBankBranch) {
          this.directDebit.bankName = check.UKBankBranch.BankName;
        }
      } else {
        this.directDebit.modulusFailed = true;
      }
      this.directDebit.modulusCheck = check;
    }
  }

  handleMaskedInput = (event: MaskedTextBoxChangeEvent) => {
    const value = event.target.value.replace(/-/g, '');
    const name = event.target.name as string;
    this.directDebit = {
      ...this.directDebit,
      [name]: value
    };

    if (name === 'bankSortCode' || name === 'bankAccountNumber') {
      this.updateModulusCheck();
    }

    this.unsavedChange = true;

    this.step1Valid = this.validateStep1();
    // Only check validation if the user has already tried to submit the form
    if (this.submitAttempted) {
      this.validateStep2();
    }
  };

  validateStep1(): boolean {
    // reset state
    let isValid = true;
    this.step1Errors = new ValidationErrors();

    if (!this.directDebit.accountHolderConfirm) {
      isValid = false;
      if (this.proceedAttempted) {
        this.step1Errors.addError('accountHolderConfirm', 'Select Yes to continue.');
      }
    }

    if (!this.directDebit.accountAuthConfirm) {
      isValid = false;
      if (this.proceedAttempted) {
        this.step1Errors.addError('accountAuthConfirm', 'Select Yes to continue.');
      }
    }

    return isValid;
  }

  validateStep2(): boolean {
    // reset state
    let isValid = true;
    this.step2Errors = new ValidationErrors();

    // Sage dropdown has selected value:
    if (this.directDebit.sageAccountLinkId === 0 || !this.directDebit.sageAccountNumber) {
      this.step2Errors.addError('sageAccountSelect', 'Select the correct account for this Direct Debit to continue');
    }

    isValid = validation.validate(this.directDebit, this.validationRules, this.step2Errors);

    return isValid;
  }

  validateAll(): boolean {
    // reset state
    this.formValid = true;

    this.step1Valid = this.validateStep1();
    if (!this.step1Valid) {
      this.formValid = false;
    }

    this.step2Valid = this.validateStep2();

    if (!this.step2Valid) {
      this.formValid = false;
    }

    // CODEREVIEW: Including this check outside of the step 2 validation, so that validation still fires on sortcode/bank account
    if (this.directDebit.modulusCheck) {
      if (!this.directDebit.modulusCheck.Valid) {
        this.formValid = false;
      }
    }

    return this.formValid;
  }

  downloadDirectDebitInstruction = () => {
    // download direct debit instruction
  };

  async getThisDDIdAfterNewDDCreation() {
    // CODEREVIEW: Temporary workaround getting the direct debit after newly created, so that we can get the new letter by Id.
    const allDDs = await invoicesStore.getDirectDebits();
    const sortedDDs = allDDs.sort(function (a, b) {
      // Temp sorting function, check that in descending order.
      return (b.directDebitId ? b.directDebitId : -9) - (a.directDebitId ? a.directDebitId : -9);
    });

    const mostRecentDD = sortedDDs[0];
    // Use most recent DD to make sure we hold the dd Id for this new DD.

    return mostRecentDD.directDebitId;
  }

  onSave = async () => {
    this.saveInProgress = true;
    this.submitAttempted = true;
    this.validateAll();
    if (this.formValid && !this.directDebit.modulusFailed) {
      if (this.newDirectDebit) {
        const result = await invoicesStore.setUpDirectDebit(this.directDebit);

        // if result is error than check for validation messages
        if (result instanceof FalconError) {
          this.step2Errors = validation.getValidationErrorsFromFalconError(result);
        } else {
          const mostRecentDDIdOfThisClient = await this.getThisDDIdAfterNewDDCreation();
          this.directDebit.directDebitId = mostRecentDDIdOfThisClient;

          this.unsavedChange = false;

          // Go to next step if successful
          this._next();
        }
      } else {
        const result = await invoicesStore.updateDirectDebit(this.directDebit);

        // if result is error than check for validation messages
        if (result instanceof FalconError) {
          this.step2Errors = validation.getValidationErrorsFromFalconError(result);
        } else {
          // set event correlation id
          this.directDebit.eventCorrelationId = result.eventCorrelationId;

          this.unsavedChange = false;

          // Go to next step if successful
          this.refreshSelectedDirectDebit(this.directDebit as DirectDebit);
          this._next();
        }
      }
    }

    this.saveInProgress = false;
  };

  _next = () => {
    window.scrollTo(0, 0);
    this.currentStep = this.currentStep >= 3 ? 4 : this.currentStep + 1;
  };

  _prev = () => {
    window.scrollTo(0, 0);
    this.currentStep = this.currentStep <= 1 ? 1 : this.currentStep - 1;
  };

  goToStep2 = () => {
    window.scrollTo(0, 0);

    this.proceedAttempted = true;
    this.step1Valid = this.validateStep1();

    if (this.step1Valid) {
      this._next();
    }
  };

  goToStep3 = () => {
    window.scrollTo(0, 0);
    this.step2Valid = this.validateStep2();

    if (this.step2Valid && !this.directDebit.modulusFailed) {
      this._next();
    }
  };

  render() {
    return (
      <>
        <Prompt
          when={this.unsavedChange && BaseUnsavedChangesComponent.enableCheck}
          message="You have unsaved changes. Are you sure you want to navigate away from this page?"
        />

        {this.directDebitLoading ? null : (
          <>
            <Step1
              newDirectDebit={this.newDirectDebit}
              currentStep={this.currentStep}
              handleChange={this.handleInput}
              directDebit={this.directDebit}
              errors={this.step1Errors}
              hasSageAccountsAvailable={true}
              sageAccountsList={this.sageAccountsList}
            />
            <Step2
              newDirectDebit={this.newDirectDebit}
              currentStep={this.currentStep}
              handleChange={this.handleInput}
              handleMaskedInputChange={this.handleMaskedInput}
              directDebit={this.directDebit}
              errors={this.step2Errors}
              sageAccountsList={this.sageAccountsList}
            />
            <Step3 directDebit={this.directDebit} newDirectDebit={this.newDirectDebit} currentStep={this.currentStep}></Step3>
            <Step4 directDebit={this.directDebit} currentStep={this.currentStep} />

            {(this.currentStep === 1 || this.currentStep === 2) && (
              <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
                {!this.newDirectDebit && (
                  <Button id="backToDDs" buttonStyle="outline_primary" handleClick={this.handleViewDirectDebits.bind(this)} size="lg">
                    <span className={appStyles.buttonWithIcon__text}>Back to Direct Debits</span>
                  </Button>
                )}
                <Link
                  to="/dashboard"
                  title="Cancel and return to dashboard"
                  className={`${appStyles.button} ${appStyles.button_outline_secondary} ${appStyles.button_lg}`}>
                  Cancel
                </Link>
                {this.currentStep === 1 ? (
                  <Button
                    id="dd_proceed"
                    title="Proceed to next step"
                    customClass={classNames({ [appStyles.button_disabled]: !this.step1Valid })}
                    buttonStyle="primary"
                    size="lg"
                    handleClick={this.goToStep2}>
                    Proceed
                  </Button>
                ) : (
                  <Button id="dd_proceed" title="Proceed to next step" buttonStyle="primary" size="lg" handleClick={this.goToStep3}>
                    Proceed
                  </Button>
                )}
              </BottomButtonContainer>
            )}

            {this.currentStep === 3 && (
              <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
                {!this.newDirectDebit && (
                  <Button id="backToDDs" buttonStyle="outline_primary" handleClick={this.handleViewDirectDebits.bind(this)} size="lg">
                    <span className={appStyles.buttonWithIcon__text}>Back to Direct Debits</span>
                  </Button>
                )}
                <Button title="Go back a step" buttonStyle="outline_secondary" size="lg" handleClick={this._prev}>
                  Back to Previous Page
                </Button>
                <Button id="dd_complete" title="Submit form" buttonStyle="primary" size="lg" handleClick={this.onSave} disabled={this.saveInProgress}>
                  Complete Direct Debit
                </Button>
              </BottomButtonContainer>
            )}

            {this.currentStep === 4 && (
              <BottomButtonContainer backgroundColor="white" layout="left">
                <Link to="/dashboard" title="link to dashboard" className={`${appStyles.button} ${appStyles.button_outline_secondary} ${appStyles.button_lg}`}>
                  Back to Dashboard
                </Link>
              </BottomButtonContainer>
            )}
          </>
        )}
      </>
    );
  }
}

export default withRouter(NewDirectDebit);
