import { FC } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import utils from 'utils/utils';
import appStyles from 'App.module.scss';
import styles from './Footer.module.scss';

const Footer: FC = () => (
  <footer className={classNames(styles.footer, appStyles.container)}>
    <a target="_blank" rel="noopener noreferrer" title="Chess Terms and Conditions" className={styles.footer__link} href="https://chessict.co.uk/legal">
      Terms & Conditions
    </a>
    <a target="_blank" rel="noopener noreferrer" title="Chess Privacy Policy" className={styles.footer__link} href="https://chessict.co.uk/legal">
      Privacy Policy
    </a>
    <Link to="/cookies" className={styles.footer__link} title="Cookie Policy">
      Cookie Policy
    </Link>
    <a target="_blank" rel="noopener noreferrer" title="View Complaints Procedure" className={styles.footer__link} href="https://chessict.co.uk/legal">
      Complaints Procedure
    </a>
    {!utils.environment.isProduction && <div id="envName" className={styles.info}> {`(${process.env.REACT_APP_ENVIRONMENT_NAME})`}</div>}
  </footer>
);

export default Footer;
