import api from 'api/api';
import { Ticket } from 'models/api/ticket.model';
import { ValidationRule } from 'models/api/validationRule.model';
import { FalconError } from 'models/generic/falconError.model';
import { ICountableArray } from 'models/generic/iCountableArray';
import { ModelCollection } from 'models/generic/modelCollection.model';
import { TicketsGridRequest } from 'models/requests/ticketsGridRequest.model';
import fileApi from '../api/fileApi';

class TicketsStore {
  getRecentlyViewedTickets = async () => {
    // TODO: remove mocked function
    const latestTickets = await this.getLatestTickets();

    return latestTickets.slice(0, 2);
  };

  async getTicketValidationRules(): Promise<ValidationRule[]> {
    return api.getValidationRules('SupportTicket/GetValidationRules');
  }

  async getMyTickets(request: TicketsGridRequest): Promise<ModelCollection<Ticket>> {
    const tickets = new ModelCollection<Ticket>();

    const qs = request.getRequestAsQueryString();

    const result = await api.get<Ticket[]>('SupportTicket/MyTickets?' + qs, {
      friendlyErrorMessage: `There was an error loading tickets.`
    });
    if (result instanceof FalconError) {
    } else {
      result.map((item) => {
        item.time = new Date(item.time);

        return item;
      });

      tickets.items = result;
      tickets.totalCount = (result as unknown as ICountableArray).totalCount;
    }

    return tickets;
  }

  async getTicket(ticketId: number): Promise<Ticket | null> {
    let ticket: Ticket | null = null;

    const result = await api.get<Ticket>('SupportTicket/Details?id=' + ticketId, {
      friendlyErrorMessage: 'There was an error loading ticket.'
    });

    if (result instanceof FalconError) {
    } else {
      ticket = result;

      if (ticket && ticket.time) {
        ticket.time = new Date(ticket.time);
      }
    }

    return ticket;
  }

  async getTicketAttachmentList(ticketId: number) {
    return await fileApi.getFilesList(`/Support_${ticketId}`, 'There was an error getting attachments');
  }

  async getTicketAttachment(ticketId: number, fileName: string) {
    return await fileApi.getFile<ArrayBuffer>(`/Support_${ticketId}/${fileName}`, 'There was an error downloading attachment');
  }

  async getLatestTickets() {
    let tickets: Ticket[] = [];
    const result = await api.get<Ticket[]>('SupportTicket/MyLatestTickets', {
      friendlyErrorMessage: 'There was an error loading latest tickets.'
    });
    if (result instanceof FalconError) {
    } else {
      result.map((item) => {
        item.time = new Date(item.time);

        return item;
      });

      tickets = result;
    }

    return tickets;
  }

  async getCreateNewTicket(): Promise<Ticket | FalconError> {
    const result = await api.get<Ticket>('/SupportTicket/Create', {
      friendlyErrorMessage: 'There was an error loading the form'
    });

    return result;
  }

  async createNewTicket(ticket: Ticket): Promise<number | FalconError> {
    const result = await api.post<number>('/SupportTicket/Create', ticket, 'There was an error creating a ticket.');

    return result;
  }

  async updateTicket(ticket: Ticket): Promise<Ticket | FalconError> {
    const result = await api.put<Ticket>('/SupportTicket/Edit', ticket, 'There was an error updating your ticket.');

    return result;
  }
}

const ticketsStore = new TicketsStore();
export default ticketsStore;
