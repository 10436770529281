import appStyles from 'App.module.scss';
import classNames from 'classnames';
import ValidationArea from 'components/Generic/FormElements/ValidationArea/ValidationArea';
import { ListItem } from 'models/generic/listItem.model';
import { ValidationError } from 'models/generic/validationError.model';
import React, { ChangeEvent, Component } from 'react';

interface IRadioProps {
  /**
   * @property {string} name Set the name attribute
   */
  name: string;
  /**
   * @property {ListItem[]} options Set the available options
   */
  options: ListItem[];
  /**
   *@property {(event: ChangeEvent<HTMLInputElement>) => void} handleChange Set the action onChange
   */
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  /**
   * @property {string} selectedOption Set the selected option
   */
  selectedOption: number | string | boolean | null | undefined;
  /**
   * @property {string} customInputClass Optional. Use a custom class on the input - use styles from the current component or appStyles
   */
  customInputClass?: string;
  /**
   * @property {string} customLabelClass Optional. Use a custom class on the label - use styles from the current component or appStyles
   */
  customLabelClass?: string;
  /**
   * @property {ValidationError} validationError Optional. Any validation messages related to this field
   */
  validationError?: ValidationError;
}

/**
 * @description Add an radio button group. Optional properties: customInputClass, customLabelClass, validationError
 */
class Radio extends Component<IRadioProps> {
  render() {
    return (
      <>
        {this.props.options.map((option) => (
          <React.Fragment key={option.id as string}>
            <input
              className={classNames(
                appStyles.checkbox,
                { [appStyles.form__input_invalid]: (this.props.validationError?.messages.length ?? 0) > 0 }, // add invalid class if errors
                { [this.props.customInputClass as string]: this.props.customInputClass } // add custom class if defined
              )}
              onChange={this.props.handleChange}
              type="radio"
              checked={this.props.selectedOption === (option.id as string)}
              aria-checked={this.props.selectedOption === (option.id as string) ? 'true' : 'false'}
              name={this.props.name}
              value={option.id as string}
              id={this.props.name + option.id}
            />
            <label
              htmlFor={this.props.name + option.id}
              className={classNames(
                appStyles.checkbox__label,
                appStyles.checkbox__label_radio,
                { [appStyles.checkbox__label_invalid]: (this.props.validationError?.messages.length ?? 0) > 0 }, // add invalid class if errors
                { [this.props.customLabelClass as string]: this.props.customLabelClass } // add custom class if defined
              )}>
              {option.text}
            </label>
          </React.Fragment>
        ))}

        <ValidationArea validationError={this.props.validationError}></ValidationArea>
      </>
    );
  }
}

export default Radio;
