import { Link, withRouter } from 'react-router-dom';
import { observable, toJS } from 'mobx';
import FileDownload from 'js-file-download';
import { observer } from 'mobx-react';
import { Grid, GridFilterChangeEvent } from '@progress/kendo-react-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { BaseServerGrid } from 'components/Generic/Grid/BaseServerGrid';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { InvoiceStatusColumnMenu } from 'components/Generic/Grid/InvoiceStatusColumnMenu';
import { FalconError } from 'models/generic/falconError.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import { Invoice } from 'models/api/invoice.model';
import { InvoicesGridRequest } from 'models/requests/InvoicesGridRequest.model';
import { ModelCollection } from 'models/generic/modelCollection.model';
import invoicesStore from 'stores/invoices.store';
import constants from 'utils/constants';
import { ReactComponent as DocumentsSVGLogo } from 'assets/images/svg/documents.svg';
import { ReactComponent as DownArrowSVGLogo } from 'assets/images/svg/download.svg';
import appStyles from 'App.module.scss';

interface IInvoicesGridProps extends IBaseProps {
  searchKeyword: string;
  selectedInvoice: Invoice | null;
}

@observer
class InvoicesGrid extends BaseServerGrid<Invoice, IInvoicesGridProps, InvoicesGridRequest> {
  @observable selectedInvoice: Invoice | null = null;

  constructor(props: IInvoicesGridProps) {
    super(props);

    // set default sort and filter
    this.sort = [{ field: 'dateIssued', dir: 'desc' }];
    this.filter = {
      logic: 'or',
      filters: [
        { field: 'status', operator: 'eq', value: 'Live' },
        { field: 'status', operator: 'eq', value: 'On hold' },
        { field: 'status', operator: 'eq', value: 'Completed' },
        { field: 'status', operator: 'eq', value: 'Disputed' }
      ]
    };

    // set default request - sort order and statuses
    this.request = new InvoicesGridRequest('dateIssued DESC', this.take, []);
  }

  async componentDidMount() {
    await this.loadData();
  }

  async componentDidUpdate(prevProps: IInvoicesGridProps) {
    this.setSearchKeyword(this.props.searchKeyword);

    if (prevProps.selectedInvoice !== this.props.selectedInvoice) {
      this.selectedInvoice = this.props.selectedInvoice;
    }

    // reload data if request was updated
    if (this.requestUpdated) {
      await this.loadData();
    }
  }

  getData(): Promise<ModelCollection<Invoice>> {
    return invoicesStore.getInvoices(this.request);
  }

  filterChange = (event: GridFilterChangeEvent) => {
    super.filterChange(event);
    // update request object
    this.request.includedStatusCodes = [];

    if (event.filter && event.filter.filters) {
      this.parseFilterEvent(event).filters?.forEach((filter) => {
        switch ((filter as FilterDescriptor).value) {
          case 'Live':
            this.request.includedStatusCodes.push(constants.invoiceStatuses.live);
            break;
          case 'On hold':
            this.request.includedStatusCodes.push(constants.invoiceStatuses.onHold);
            break;
          case 'Completed':
            this.request.includedStatusCodes.push(constants.invoiceStatuses.completed);
            break;
          case 'Disputed':
            this.request.includedStatusCodes.push(constants.invoiceStatuses.disputed);
            break;
        }
      });
    }

    // reset to first page
    this.request.pageNumber = 1;
    this.skip = 0;

    // force refresh
    this.requestUpdated = true;
  };

  goToInvoice = (invoice: Invoice) => {
    this.props.history.push('/invoices/' + invoice.invoiceNumber);
  };

  back = () => {
    this.props.history.push('/invoices');
  };

  downloadInvoice = async (invoice: Invoice) => {
    if (invoice.invoiceDownloadUrl) {
      const result = await invoicesStore.getInvoiceDownload(invoice.invoiceDownloadUrl);
      if (result instanceof FalconError) {
      } else if (result instanceof ArrayBuffer) {
        FileDownload(result, `Invoice_${invoice.invoiceNumber}.pdf`);
      }
    }
  };

  render() {
    const mainGrid = (
      <div id="mainGrid">
        <Grid
          data={this.selectedInvoice ? [this.selectedInvoice] : this.data.items}
          filter={this.pureFilter}
          filterable={false}
          pageable={this.selectedInvoice ? false : { pageSizes: this.pageSizes }}
          skip={this.skip}
          take={this.take}
          total={this.selectedInvoice ? 1 : this.data.totalCount}
          onPageChange={this.pageChange}
          sortable={this.selectedInvoice ? false : true}
          sort={this.selectedInvoice ? undefined : toJS(this.sort)}
          resizable
          onFilterChange={this.filterChange}
          onSortChange={this.sortChange}>
          {ColumnHelper.getGridColumns([
            { field: 'invoiceNumber', title: 'Invoice', dataType: 'text', size: 'md', currentFilter: this.filter },
            { field: 'dateIssued', title: 'Date Issued', dataType: 'date', size: 'md', currentFilter: this.filter },
            { field: 'orderNumber', title: 'Order Number', dataType: 'text', size: 'md', currentFilter: this.filter },
            { field: 'accountNumber', title: 'Account Number', dataType: 'text', size: 'md', currentFilter: this.filter },
            {
              field: 'status',
              title: 'Status',
              dataType: 'text',
              size: 'md',
              currentFilter: this.filter,
              columnMenu: this.selectedInvoice ? undefined : InvoiceStatusColumnMenu
            },
            { field: 'total', title: 'Total', dataType: 'currency', size: 'md', currentFilter: this.filter },
            this.selectedInvoice
              ? undefined
              : {
                  field: '',
                  title: 'View',
                  dataType: 'icon',
                  size: 'xs',
                  cell: (props) => (
                    <td className="k-table-td gridColumn_xs gridColumn_button">
                      <div role="button" onClick={() => this.goToInvoice(props.dataItem)} className={appStyles.button___grid_view}>
                        <DocumentsSVGLogo title="View invoice breakdown" height={36} width={36} />
                      </div>
                    </td>
                  )
                },
            {
              field: '',
              title: 'Download',
              dataType: 'icon',
              size: 'xs',
              cell: (props) => (
                <td className="k-table-td gridColumn_xs gridColumn_button">
                  <div role="button" onClick={() => this.downloadInvoice(props.dataItem)} className={appStyles.button___grid_view}>
                    <DownArrowSVGLogo title="Download invoice" height={32} width={32} />
                  </div>
                </td>
              )
            }
          ])}
        </Grid>
      </div>
    );

    return (
      <>
        {mainGrid}

        {!!this.selectedInvoice && (
          <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
            <Button id="buttonViewInvoices" buttonStyle="outline_secondary" size="lg" handleClick={this.back}>
              Back to Invoices
            </Button>
            <Link
              className={`${appStyles.button} ${appStyles.button_primary} ${appStyles.button_lg}`}
              to={'/invoices/direct-debit'}
              title="set up a new Direct Debit">
              Setup new Direct Debit
            </Link>
          </BottomButtonContainer>
        )}
      </>
    );
  }
}

export default withRouter(InvoicesGrid);
