import { withRouter } from 'react-router-dom';
import { Grid, GridFilterChangeEvent } from '@progress/kendo-react-grid';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { BaseServerGrid } from 'components/Generic/Grid/BaseServerGrid';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { IBaseProps } from 'models/generic/iBaseProps';
import { ModelCollection } from 'models/generic/modelCollection.model';
import { Ticket } from 'models/api/ticket.model';
import { TicketsGridRequest } from 'models/requests/ticketsGridRequest.model';
import { TicketStatusColumnMenu } from 'components/Generic/Grid/TicketStatusColumnMenu';
import constants from 'utils/constants';
import ticketsStore from 'stores/tickets.store';
import { ReactComponent as EditSVGLogo } from 'assets/images/svg/edit.svg';
import appStyles from 'App.module.scss';

interface ITicketsGridProps extends IBaseProps {
  selectedTicket: Ticket | null;
  searchKeyword: string;
  ticketUpdated: boolean;
  setRequest: (shouldUpdate: boolean) => void;
}

@observer
class TicketsGrid extends BaseServerGrid<Ticket, ITicketsGridProps, TicketsGridRequest> {
  @observable selectedTicket: Ticket | null = null;

  constructor(props: ITicketsGridProps) {
    super(props);

    // set default sort and filter
    this.sort = [{ field: 'time', dir: 'desc' }];
    this.filter = {
      logic: 'or',
      filters: [
        { field: 'status', operator: 'eq', value: 'New' },
        { field: 'status', operator: 'eq', value: 'In Progress' },
        { field: 'status', operator: 'eq', value: 'Deferred' }
      ]
    };

    // set default request - sort order and statuses
    this.request = new TicketsGridRequest('time DESC', this.take, [
      constants.supportTicketStatuses.new,
      constants.supportTicketStatuses.inProgress,
      constants.supportTicketStatuses.deferred
    ]);
  }

  async componentDidMount() {
    await this.loadData();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async componentDidUpdate(prevProps: ITicketsGridProps) {
    if (this.props.selectedTicket !== this.selectedTicket) {
      this.selectedTicket = this.props.selectedTicket;
    }

    this.setSearchKeyword(this.props.searchKeyword);

    // reload data if request was updated
    if (this.requestUpdated || this.props.ticketUpdated) {
      this.props.setRequest(false);

      await this.loadData();
    }
  }

  getData(): Promise<ModelCollection<Ticket>> {
    return ticketsStore.getMyTickets(this.request);
  }

  filterChange = (event: GridFilterChangeEvent) => {
    super.filterChange(event);

    // update request object
    this.request.includedStatusCodes = [];

    if (event.filter && event.filter.filters) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.parseFilterEvent(event).filters?.forEach((filter: any) => {
        switch (filter.value) {
          case 'New':
            this.request.includedStatusCodes.push(constants.supportTicketStatuses.new);
            break;
          case 'In Progress':
            this.request.includedStatusCodes.push(constants.supportTicketStatuses.inProgress);
            break;
          case 'Deferred':
            this.request.includedStatusCodes.push(constants.supportTicketStatuses.deferred);
            break;
          case 'Closed':
            this.request.includedStatusCodes.push(constants.supportTicketStatuses.closed);
            break;
        }
      });
    }

    // reset to first page
    this.request.pageNumber = 1;
    this.skip = 0;

    // force refresh
    this.requestUpdated = true;
  };

  goToTicket = (ticket: Ticket) => {
    this.props.history.push('/tickets/' + ticket.supportTicketId);
  };

  render() {
    const mainGrid = (
      <div id="mainGrid">
        <Grid
          data={this.selectedTicket ? [this.selectedTicket] : this.data.items}
          filter={this.pureFilter}
          filterable={false}
          pageable={this.selectedTicket ? false : { pageSizes: this.pageSizes }}
          skip={this.skip}
          take={this.take}
          total={this.selectedTicket ? 1 : this.data.totalCount}
          onPageChange={this.pageChange}
          sortable={this.selectedTicket ? false : true}
          sort={this.selectedTicket ? undefined : toJS(this.sort)}
          resizable
          onFilterChange={this.filterChange}
          onSortChange={this.sortChange}>
          {ColumnHelper.getGridColumns([
            { field: 'supportTicketId', title: 'Ticket Number', dataType: 'text', size: 'md', currentFilter: this.filter },
            { field: 'time', title: 'Date Issued', dataType: 'date', size: 'md', currentFilter: this.filter },
            { field: 'assignedToName', title: 'Assigned To', dataType: 'text', size: 'md', currentFilter: this.filter, sortable: false },
            { field: 'details', title: 'Details', dataType: 'text', size: 'lg', currentFilter: this.filter, sortable: false, isHtml: true },
            {
              field: 'status',
              title: 'Status',
              dataType: 'text',
              size: 'md',
              currentFilter: this.filter,
              columnMenu: this.selectedTicket ? undefined : TicketStatusColumnMenu
            },
            this.selectedTicket
              ? undefined
              : {
                  field: '',
                  title: 'View',
                  dataType: 'icon',
                  size: 'xs',
                  cell: (props) => (
                    <td className="k-table-td gridColumn_xs gridColumn_button">
                      <div role="button" onClick={() => this.goToTicket(props.dataItem)} className={appStyles.button___grid_view}>
                        <EditSVGLogo title="View ticket breakdown" height={24} width={24} />
                      </div>
                    </td>
                  )
                }
          ])}
        </Grid>
      </div>
    );

    return mainGrid;
  }
}

export default withRouter(TicketsGrid);
