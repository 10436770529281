import constants from 'utils/constants';
import { ValidationMessage } from './validationMessage.model';

export class ValidationError {
  fieldName: string;
  messages: ValidationMessage[] = [];

  constructor(fieldName: string, message?: ValidationMessage) {
    this.fieldName = fieldName;
    if (message) {
      this.messages.push(message);
    }
  }
}

export class ValidationErrors {
  fieldErrors: { [fieldName: string]: ValidationError } = {};
  hasErrorSeverities = false;
  hasWarningSeverities = false;

  addError(fieldName: string, description: string, severity: number | null = null) {
    // if no severity specified then assume an error
    if (severity === null) {
      severity = constants.validation.severity.error;
    }

    let error = this.fieldErrors[fieldName];
    if (!error) {
      error = new ValidationError(fieldName);
      this.fieldErrors[fieldName] = error;
    }

    // set this true if any item has an error severity
    if (severity === constants.validation.severity.error) {
      this.hasErrorSeverities = true;
    }

    // set this true if any item has a warning severity
    if (severity === constants.validation.severity.warning) {
      this.hasWarningSeverities = true;
    }

    const message = new ValidationMessage(description, severity);
    error.messages.push(message);
  }
}
