import { BaseModel } from 'models/api/base.model';

export class PaymentDetails extends BaseModel {
  ipAddress: string | null = null;
  billingSurname: string | null = null;
  billingFirstnames: string | null = null;
  billingAddress1: string | null = null;
  billingAddress2: string | null = null;
  billingCity: string | null = null;
  billingPostCode: string | null = null;
  billingCountry: string | null = null;
  isSaveConfirmationRequired: boolean | null = null;
  isSaveConfirmed: boolean | null = null;
  paymentAmount: number | null = null;
  invoiceNumbers: string[] | null = null;
}
