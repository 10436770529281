import { FC } from 'react';
import dashboardStyles from '../Dashboard.module.scss';

interface ICustomerAccountProps {
  name: string;
}

const CustomerAccount: FC<ICustomerAccountProps> = ({ name }) => (
  <>
    <div className={dashboardStyles.account__label}>Account Name</div>
    <div className={dashboardStyles.account__value}>{name}</div>
  </>
);

export default CustomerAccount;
