import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import { ValidationError } from 'models/generic/validationError.model';
import { Component } from 'react';
import validation from 'utils/validation';

interface IValidationAreaProps {
  /**
   * @property {ValidationError} validationError Optional. Any validation messages related to an associated field
   */
  validationError?: ValidationError;
  /**
   * @property {sectionId} sectionId Optional. Set an ID on the validation errors element
   */
  sectionId?: string;
}

class ValidationArea extends Component<IValidationAreaProps> {
  render() {
    return (
      <>
        {this.props.validationError && this.props.validationError.messages.length > 0 ? (
          <div className={appStyles.validationAlerts} id={`error_${this.props.sectionId || ''}`}>
            {this.props.validationError.messages.map((error, index) => {
              const validationType = validation.getSeverityFromId(error.severity);

              return (
                <Alert customClass={appStyles.form__error} alertType={validationType} key={index}>
                  {error.message}
                </Alert>
              );
            })}
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default ValidationArea;
