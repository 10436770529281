import { Component } from 'react';
import { Link } from 'react-router-dom';
import chessLogo from 'assets/images/chess_logo.png';
import Button from 'components/Generic/FormElements/Button/Button';
import FormWrapper from 'components/Generic/FormWrapper/FormWrapper';
import { IBaseProps } from 'models/generic/iBaseProps';
import utils from 'utils/utils';
import appStyles from 'App.module.scss';
import styles from 'components/Authentication/Authentication.module.scss';

export class Logout extends Component<IBaseProps> {
  componentDidMount() {
    // check if user was sent here from no access page, if yes then send them back
    const noAccess = utils.session.getFromSession<boolean>('noAccess');
    const noAccessMode = utils.session.getFromSession<string>('noAccessMode');

    sessionStorage.clear();

    if (noAccess) {
      this.props.history.push('/no-access');
      utils.session.saveToSession('noAccessMode', noAccessMode);
    }
  }

  render() {
    const redirectToUrl = `/login${this.props.location.search}`;

    return (
      <div className={styles.loginContainer}>
        <div className={styles.logo_white_wrapper}>
          <img className={styles.login__logo} src={chessLogo} alt="chess logo" />
        </div>
        <div className={styles.login_plate}>
          <FormWrapper formTitle="Logout" onSubmit={(e) => e.preventDefault()} className={styles.logout}>
            <div className={appStyles.form__row}>
              <p className={styles.logout__text}>You have signed out of your account </p>
            </div>
            <div className={appStyles.form__row}>
              <Link to={redirectToUrl} title="Click here to login again">
                <Button buttonStyle="primary" size="lg">
                  Click here to login again
                </Button>
              </Link>
            </div>
          </FormWrapper>
        </div>
      </div>
    );
  }
}
