import { action, observable } from 'mobx';
import { FalconError } from 'models/generic/falconError.model';
import { Message } from 'models/generic/message.model';
import utils from 'utils/utils';

class MessagesStore {
  @observable messages: Message[] = [];
  private removeMessageAfterSeconds = 10;

  @action private addMessage(message: Message) {
    this.messages.push(message);

    setTimeout(() => {
      this.messages.shift();
    }, this.removeMessageAfterSeconds * 1000);
  }

  @action addInfoAsString(message: string) {
    this.addMessage(new Message(message, 'info'));
  }

  @action addSuccessAsString(message: string) {
    this.addMessage(new Message(message, 'success'));
  }

  @action addWarningAsString(message: string) {
    this.addMessage(new Message(message, 'warning'));
  }

  @action addErrorAsString(message: string) {
    const error = new FalconError();
    error.errorMessages.push(message);
    this.addError(error);
  }

  @action addError(error: FalconError) {
    // add an error to the array to be displayed in the header and remove it after pre-defined time
    error.errorMessages.forEach((errorMessage) => {
      this.addMessage(new Message(errorMessage, 'error'));
    });

    // also log the error to app insights
    utils.insights.logErrorToAppInsights(error);
  }

  @action removeMessage(i: number) {
    this.messages.splice(i, 1);
  }
}

const messagesStore = new MessagesStore();
export default messagesStore;
