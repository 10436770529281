import { FC } from 'react';
import { CSSTransition } from 'react-transition-group';
import withBackdrop from 'hoc/withBackdrop';
import LatestNotifications from 'components/Dashboard/LatestNotifications/LatestNotifications';
import MobileControls from 'components/Generic/Header/MobileControls';
import styles from './NotificationPopup.module.scss';

interface INotificationPopupProps extends IPopupProps {
  visible: boolean;
}

interface IPopupProps {
  onBack: () => void;
  onClose: () => void;
  onBackdropClick?: () => void;
}

const Popup: FC<IPopupProps> = ({ onBack, onClose }) => (
  <div className={styles.notificationNav} id="notificationsPopup" key="notificationsPopup">
    <MobileControls onBack={onBack} onClose={onClose} />
    <LatestNotifications onViewAll={onClose} onViewItem={onClose} />
  </div>
);

const PopupWithBackdrop = withBackdrop(Popup);

const NotificationPopup: FC<INotificationPopupProps> = ({ visible, onBack, onClose }) => (
  <CSSTransition in={visible} timeout={500} unmountOnExit>
    <PopupWithBackdrop onBack={onBack} onClose={onClose} onBackdropClick={onClose} />
  </CSSTransition>
);

export default NotificationPopup;
