import appStyles from 'App.module.scss';
import { browserHistory } from 'App';
import Alert from 'components/Generic/Alert/Alert';
import Icon from 'components/Generic/Icon/icon';
import { observer } from 'mobx-react';
import { Component } from 'react';
import Button from 'components/Generic/FormElements/Button/Button';
import userStore from 'stores/user.store';
import ReactHtmlParser from 'react-html-parser';

interface IConfirmationProps {
  mainMessage: string;
  subMessages?: string[];
  containerId: string;
  icon: string;
  iconText: string;
  iconName?: string;
  showLogout?: boolean;
}

@observer
export class Confirmation extends Component<IConfirmationProps> {
  // get details from props or from browser location state
  mainMessage = this.props.mainMessage || (browserHistory.location.state as IConfirmationProps)?.mainMessage;
  subMessages = this.props.subMessages || (browserHistory.location.state as IConfirmationProps)?.subMessages || [];
  containerId = this.props.containerId || (browserHistory.location.state as IConfirmationProps)?.containerId;
  icon = this.props.icon || (browserHistory.location.state as IConfirmationProps)?.icon;
  iconText = this.props.iconText || (browserHistory.location.state as IConfirmationProps)?.iconText;
  iconName = this.props.iconName || (browserHistory.location.state as IConfirmationProps)?.iconName;
  showLogout = this.props.showLogout || (browserHistory.location.state as IConfirmationProps)?.showLogout;

  render() {
    return (
      <>
        <div className={appStyles.container} id={this.containerId}>
          <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
            <Icon
              icon={this.icon}
              size="heading"
              color="currentColor"
              iconName={this.iconName ? this.iconName : 'succcess icon'}
              customClass={appStyles.heading__icon}
            />
            <h1 className={appStyles.heading__text}>{this.iconText}</h1>
          </div>
          <Alert alertType="blank">
            <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>{ReactHtmlParser(this.mainMessage)}</p>
            {this.subMessages.map((message, index) => (
              <p key={`subMessage-${index}`} className={`${appStyles.text_midBlue} ${appStyles.info__sub}`}>
                {ReactHtmlParser(message)}
              </p>
            ))}
            {this.showLogout && (
              <>
                <br />
                Click here to log out and try to log in again.
                <br />
                <br />
                <Button id="btnLogout" buttonStyle="primary" size="lg" handleClick={() => userStore.logOut()}>
                  Logout
                </Button>
              </>
            )}
          </Alert>
        </div>
      </>
    );
  }
}
