import { BaseModel } from 'models/api/base.model';

export class SagePayFormData extends BaseModel {
  paymentId: string | null = null;
  crypt: string | null = null;
  sagePayUrl: string | null = null;
  txType: string | null = null;
  vendor: string | null = null;
  vpsProtocol: string | null = null;
}
