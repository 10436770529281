import { FC } from 'react';
import Alert from 'components/Generic/Alert/Alert';
import styles from 'components/Authentication/Authentication.module.scss';

interface ILoginErrorProps {
  message: string;
}

const LoginError: FC<ILoginErrorProps> = ({ message }) => (
  <div className={styles.fullWidth}>
    <div className={styles.column_centred}>
      <Alert alertType="error" customClass={styles.loginErrorAlert}>
        <span id="errorsAndWarnings">{message}</span>
      </Alert>
    </div>
  </div>
);

export default LoginError;
