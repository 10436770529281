import { MaskedTextBox, MaskedTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import appStyles from 'App.module.scss';
import classNames from 'classnames';
import ValidationArea from 'components/Generic/FormElements/ValidationArea/ValidationArea';
import { ValidationError } from 'models/generic/validationError.model';
import { Component } from 'react';
import './MaskedInput.scss';

interface IMaskedInputProps {
  /**
   * @property {string} name Set the name attribute
   */
  name: string;
  /**
   * @property {string} mask Set the mask pattern for the input
   */
  mask: string;
  /**
   * @property {string} title Set the title attribute
   */
  title?: string;
  /**
   * @property {boolean} Optional. required Set the field to required
   */
  required?: boolean;
  /**
   *@property {string} labelText Set the text to appear in the label
   */
  labelText: string;
  /**
   *@property {string} elementId Set the ID attribute
   */
  elementId: string;
  /**
   *@property {string} value Set the value attribute
   */
  value: string | null;
  /**
   *@property {string} placeholder Set the placeholder
   */
  placeholder: string;
  /**
   *@property {boolean} labelHidden Optional. Hide the label. labelText will be used for screenreaders. Default is false.
   */
  labelHidden?: boolean;
  /**
   *@property {(event: MaskedTextBoxChangeEvent) => void} handleChange Set the action onChange
   */
  handleMaskedInputChange: (event: MaskedTextBoxChangeEvent) => void;
  /**
   * @property {string} customInputClass Optional. Use a custom class on the input - use styles from the current component or appStyles
   */
  customInputClass?: string;
  /**
   * @property {string} customLabelClass Optional. Use a custom class on the label - use styles from the current component or appStyles
   */
  customLabelClass?: string;
  /**
   * @property {ValidationError} validationError Optional. Any validation messages related to this field
   */
  validationError?: ValidationError;
}

/**
 * @description Add an input element with a label. Optional properties: customInputClass, customLabelClass,
 * autocomplete, inputMode, pattern, title, required, maxLength, validationError
 */
class MaskedInput extends Component<IMaskedInputProps> {
  render() {
    return (
      <>
        <label
          htmlFor={this.props.name}
          className={classNames(
            appStyles.form__label,
            { [appStyles.form__label_invalid]: this.props.validationError && this.props.validationError.messages.length > 0 }, // add invalid class if errors
            { [this.props.customLabelClass as string]: this.props.customLabelClass }, // add custom class if defined
            { [appStyles.srOnly]: this.props.labelHidden } // add srOnly class if label hidden
          )}>
          {this.props.labelText}
        </label>

        <MaskedTextBox
          className={classNames(
            appStyles.form__input,
            appStyles.form__input_masked,
            { [appStyles.form__input_invalid]: this.props.validationError && this.props.validationError.messages.length > 0 }, // add invalid class if errors
            { [this.props.customInputClass as string]: this.props.customInputClass } // add custom class if defined
          )}
          width="100%"
          validityStyles={false}
          id={this.props.name}
          name={this.props.name}
          title={this.props.title || ''}
          placeholder={this.props.placeholder}
          value={this.props.value || undefined}
          onChange={this.props.handleMaskedInputChange}
          // seems like the mask property dictates the "type"- if the mask is numeric,
          // a numeric keyboard should be shown (haven't tested on mobile yet, so could be wrong)
          mask={this.props.mask}
          defaultValue=""
          required={this.props.required || undefined}
          aria-required={this.props.required || undefined}
        />

        <ValidationArea sectionId={this.props.elementId} validationError={this.props.validationError}></ValidationArea>
      </>
    );
  }
}

export default MaskedInput;
