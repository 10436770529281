import { FC } from 'react';
import classNames from 'classnames';
import Row, { IRow } from 'components/Dashboard/Table/Row';
import styles from './Table.module.scss';

interface ITableProps {
  columns: IRow[];
  showNumber?: boolean;
  rowTitle?: string;
}

const Table: FC<ITableProps> = ({ columns, showNumber, rowTitle }) => (
  <>
    <div className={styles.tickets__row}>
      {showNumber && <h2 className={classNames(styles.tickets__col_first, styles.tickets_padding)}>Number</h2>}
      <h2 className={classNames(styles.tickets__col_second, styles.tickets_padding)}>Date Issued</h2>
      <h2 className={classNames(styles.tickets__col_third, styles.tickets_padding)}>Subject</h2>
      <div className={styles.tickets__icon_heading} />
    </div>
    {columns.map((column) => (
      <Row key={column.id} column={column} showNumber={showNumber} title={rowTitle} />
    ))}
  </>
);

export default Table;
