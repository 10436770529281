import appInsights, { browserHistory } from 'App';
import { LogPageView } from 'models/api/logPageView.model';
import { matchPath } from 'react-router-dom';
import eventLogsStore from 'stores/eventLogs.store';
import { v4 as uuidv4 } from 'uuid';
import ReactGA from 'react-ga';
import userStore from 'stores/user.store';

const defaultLinkCreator = (info: INotificationInfo) => `/notifications/${info.notificationId}`;

const notificationsLinkCreators: Record<string, (a: INotificationInfo) => string> = {
  LineFaultCheck: (info: INotificationInfo) => `/services/line-faults/${info.payload?.lineFaultCheckId}`,
  FailedLineFaultCheck: () => `/services/line-faults/error`
};

interface IParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface INotificationInfo {
  notificationId: number | null;
  payload: INotificationInfoPayload;
}

interface INotificationInfoPayload {
  lineFaultCheckId: string;
}

export interface IMatchResult {
  params: IParams;
  isExact: boolean;
  path: string;
  url: string;
}

export class Routing {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  subscriptions: { [key: string]: (location: string) => void } = {};
  currentLocation = browserHistory.location.pathname;
  routingSessionId = uuidv4();
  appInsightsSessionId = appInsights.context.getSessionId();
  gaClientId = '';
  gId = '';
  userAgent = window.navigator.userAgent;

  async initialiseMonitoring() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ReactGA.ga((tracker: any) => {
      this.gaClientId = tracker.get('clientId');
      this.gId = tracker.get('_gid');

      if (userStore.currentUser && userStore.currentUser.clients && userStore.currentUser.clients.length > 0) {
        // logging initial page view
        this.logPageView();
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    browserHistory.listen(async (location, action) => {
      if (location.pathname !== this.currentLocation) {
        this.currentLocation = location.pathname;

        if (userStore.currentUser && userStore.currentUser.clients && userStore.currentUser.clients.length > 0) {
          // loading subsequent page views
          this.logPageView();
        }

        for (const id in this.subscriptions) {
          if (Object.prototype.hasOwnProperty.call(this.subscriptions, id) && this.subscriptions[id]) {
            // call callback
            this.subscriptions[id](this.currentLocation);
          }
        }
      }
    });
  }

  subscribe(callback: (location: string) => void): string {
    const id = uuidv4();
    this.subscriptions[id] = callback;

    return id;
  }

  unsubscribe(id: string) {
    if (this.subscriptions[id]) {
      delete this.subscriptions[id];
    }
  }

  matchRoutes(location: string, routes: string[]): IMatchResult | null {
    let match: IMatchResult | null = null;

    if (routes) {
      routes.forEach((route) => {
        match = match || matchPath(location, route);
      });
    }

    return match;
  }

  notificationsLinkCreator(notificationType: string | null) {
    return notificationType && notificationsLinkCreators[notificationType] ? notificationsLinkCreators[notificationType] : defaultLinkCreator;
  }

  private logPageView() {
    const form = new LogPageView(this.currentLocation);

    form.routingSessionId = this.routingSessionId;
    form.appInsightsSessionId = this.appInsightsSessionId;
    form.gaClientId = this.gaClientId;
    form.gId = this.gId;
    form.userAgent = this.userAgent;

    eventLogsStore.logPageView(form); // this is async, but no need to await
  }
}
