import { Component } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import Alert from 'components/Generic/Alert/Alert';
import Icon from 'components/Generic/Icon/icon';
import { DirectDebit } from 'models/api/directDebit.model';
import constants from 'utils/constants';
import appStyles from 'App.module.scss';
import styles from '../../ManageDirectDebit.module.scss';

interface IStep3Props {
  directDebit: DirectDebit;
  currentStep: number;
  newDirectDebit: boolean;
}

@observer
export class Step3 extends Component<IStep3Props> {
  render() {
    if (this.props.currentStep !== 3) {
      // Prop: The current step
      return null;
    }

    return (
      <div className={classNames({ [appStyles.container]: this.props.newDirectDebit })}>
        {!!this.props.newDirectDebit && (
          <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
            <Icon icon={Icon.invoices} size="heading" color="currentColor" iconName="invoice icon" customClass={appStyles.heading__icon} />
            <h1 className={appStyles.heading__text}>New Direct Debit</h1>
          </div>
        )}
        <Alert alertType="blank">
          <p className={`${appStyles.text_tangaroaBlue} ${appStyles.info__main} ${appStyles.info_bold}`}>Are you sure you want to proceed?</p>
          <p className={`${appStyles.text_darkGrey} ${appStyles.info__sub}`}>
            To complete your Direct Debit, please confirm that the details below are correct, and then click the complete Direct Debit button on the bottom
            right of your screen.
          </p>
          <p className={`${appStyles.text_darkGrey} ${appStyles.info__sub}`}>
            You can contact {constants.keyVariables.customerServicesName} on {constants.keyVariables.helpdeskTelephone} if you require support with setting up
            or changing a Direct Debit.
          </p>
          <p className={`${appStyles.text_darkGrey} ${appStyles.info__sub}`}>
            Our correspondence address is Bridgford House, Heyes Lane, Alderley Edge, Cheshire SK9 7JP.
          </p>
        </Alert>
        <form className={`${appStyles.row} ${styles.ddForm}`} id="ddStep2">
          <div className={`${appStyles.col_md_6} ${appStyles.col_lg_5} ${styles.ddColumn}`}>
            <fieldset>
              <div className={appStyles.form__row}>
                <div className={appStyles.form__label}>Sage Account</div>
                <div className={appStyles.form__readOnlyElement}>
                  {this.props.directDebit.sage200CompanyName + ' - ' + this.props.directDebit.sageAccountNumber}
                </div>
              </div>
              <div className={appStyles.form__row}>
                <div className={appStyles.form__label}>Sort Code</div>
                <div className={appStyles.form__readOnlyElement}>{this.props.directDebit.bankSortCode}</div>
              </div>
              <div className={appStyles.form__row}>
                <div className={appStyles.form__label}>Bank Account Number</div>
                <div className={appStyles.form__readOnlyElement}>{this.props.directDebit.bankAccountNumber}</div>
              </div>
              <div className={appStyles.form__row}>
                <div className={appStyles.form__label}>Account Name</div>
                <div className={appStyles.form__readOnlyElement}>{this.props.directDebit.bankAccountName}</div>
              </div>
            </fieldset>
          </div>

          <div className={`${appStyles.col_md_6} ${appStyles.col_lg_7}`}>
            <Alert alertType="info">
              <div>
                <div className={styles.dd_header}>
                  <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>Confirmation letter for your Direct Debit</p>
                </div>
                <div className={`${styles.dd_content} ${appStyles.text_black}`}>
                  <p className={`${appStyles.text_midBlue} ${appStyles.info__sub}`}>
                    A letter confirming your Direct Debit will be issued and available to download on the next page. This will also be available to download at
                    any point from the "Manage Direct Debit" page on the portal.
                  </p>
                </div>
              </div>
            </Alert>
          </div>
        </form>
      </div>
    );
  }
}
