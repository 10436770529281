import { Component } from 'react';
import classNames from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import Slider from 'react-slick';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import AgreementDialog from 'components/Generic/AgreementDialog/AgreementDialog';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import LinkButton from 'components/Generic/LinkButton/LinkButton';
import Preloader from 'components/Generic/Preloader/Preloader';
import AppointmentDay from 'components/Services/LineFaults/EngineerAppointmentSelection/AppointmentDay';
import CarouselArrow from 'components/Services/LineFaults/EngineerAppointmentSelection/CarouselArrow';
import withMediaQueryInfo from 'hoc/withMediaQueryInfo';
import { Contact } from 'models/api/contact.model';
import accountStore from 'stores/account.store';
import appointmentsStore from 'stores/appointments.store';
import lineFaultsStore from 'stores/lineFaults.store';
import messagesStore from 'stores/messages.store';
import userStore from 'stores/user.store';
import constants from 'utils/constants';
import { CareLevel } from 'utils/enums';
import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import lineFaultsStyles from '../LineFaults.module.scss';
import engineerAppointmentStyles from './EngineerAppointment.module.scss';
import 'slick-carousel/slick/slick-theme.scss';
import 'slick-carousel/slick/slick.scss';

interface IEngineerAppointmentSelectionProps {
  isTablet: boolean;
  isDesktop: boolean;
}

@observer
class EngineerAppointmentSelection extends Component<IEngineerAppointmentSelectionProps & RouteComponentProps> {
  contact: Contact | null = null;

  @observable selected: string | null = null;
  @observable showChargeableSlots = false;
  @observable timeRelatedChargesDialogVisible = false;

  async componentDidMount() {
    if (lineFaultsStore.correlationId) {
      await appointmentsStore.getAppointmentAvailability(lineFaultsStore.correlationId);
      this.contact = await accountStore.getCurrentContact();
    }
  }

  @action handleSelected = (selected: string, { date, slot }: { date: string; slot: string }) => {
    this.selected = selected;
    appointmentsStore.selectedAppointment =
      appointmentsStore.availableAppointments.find((a) => a.appointmentDate === date && a.appointmentTimeslot === slot) || null;
  };

  @action toggleChargeableSlots = () => {
    appointmentsStore.resetSelectedAppointment();
    this.showChargeableSlots = !this.showChargeableSlots;

    this.selected = null;
  };

  get toggleChargeableText() {
    return this.showChargeableSlots ? 'Hide chargeable slots' : 'Show chargeable slots';
  }

  get slidesToShow() {
    return this.props.isDesktop ? 5 : 3;
  }

  @computed get formattedAppointments() {
    const showWeekends = (lineFaultsStore.lineFaultCheck?.careLevel ?? CareLevel.Low) !== CareLevel.Low;

    return appointmentsStore.getFormattedAppointments(showWeekends, this.showChargeableSlots);
  }

  @computed get timeRelatedAgreementMessage() {
    // eslint-disable-next-line max-len
    return `Chargeable appointments carry a cost of £${constants.appointmentCost} plus VAT, which will be added to your Chess invoice. By proceeding with a chargeable appointment, you acknowledge this cost and confirm that you are authorised to make this booking on behalf of ${userStore.currentUser?.currentClient?.name}.`;
  }

  @computed get showAgreementDialog() {
    return this.showChargeableSlots && this.timeRelatedChargesDialogVisible;
  }

  handleStartAnotherTest = () => {
    const { history } = this.props;
    lineFaultsStore.cleanResults();

    return history.push('/services/line-faults');
  };

  toggleTimeChargesAcknowledgmentDialog = () => {
    this.timeRelatedChargesDialogVisible = !this.timeRelatedChargesDialogVisible;
  };

  navigateToCollectAdditionalData = async () => {
    try {
      const { history } = this.props;
      const result = await lineFaultsStore.updateAcknowledgeAcceptance({ chargeableAppointmentAccepted: true });

      if (!result.chargeableAppointmentAccepted) {
        messagesStore.addErrorAsString('Something went wrong during accepting chargeable appointments agreement. Please, try again later');
        throw new Error('Something went wrong during accepting chargeable appointments agreement. Please, try again later');
      }

      return history.push(`/services/line-faults/${lineFaultsStore.correlationId}/collect-data`, { contact: this.contact });
    } finally {
      this.toggleTimeChargesAcknowledgmentDialog();
    }
  };

  selectAppointmentClick = () => (this.showChargeableSlots ? this.toggleTimeChargesAcknowledgmentDialog() : this.navigateToCollectAdditionalData());

  render() {
    return (
      <>
        <div className={classNames(lineFaultsStyles.header__container, appStyles.heading)}>
          <h1 className={classNames(appStyles.heading__text, appStyles.text_midBlue)}>Book an engineer appointment</h1>
        </div>

        {!appointmentsStore.pending ? (
          <>
            <Alert alertType="blank">
              <p className={`${appStyles.text_darkGrey} ${appStyles.info__main}`}>Please select a time slot from the list below.</p>
            </Alert>
            {this.showChargeableSlots && (
              <Alert alertType="warning">
                <p id="chargeableWarning" className={`${appStyles.text_warning} ${engineerAppointmentStyles.message_warning}`}>
                  Please note that the slots below are chargeable.
                </p>
              </Alert>
            )}
            <Slider
              infinite={false}
              dots={false}
              slidesToScroll={this.slidesToShow}
              slidesToShow={this.slidesToShow}
              className={engineerAppointmentStyles.days__container}
              nextArrow={<CarouselArrow direction="right" className={engineerAppointmentStyles.carousel__arrow} />}
              prevArrow={<CarouselArrow direction="left" className={engineerAppointmentStyles.carousel__arrow} />}>
              {this.formattedAppointments.map((d) => (
                <AppointmentDay date={d.date} key={d.date} onSelected={this.handleSelected} selectedSlot={this.selected} slots={d.slots} />
              ))}
            </Slider>
            <LinkButton onClick={this.toggleChargeableSlots} className={engineerAppointmentStyles.button_link} id="btnChargeableSlots">
              {this.toggleChargeableText}
            </LinkButton>
          </>
        ) : (
          <div className={engineerAppointmentStyles.preloader__container}>
            <Preloader />
          </div>
        )}
        <AgreementDialog
          message={this.timeRelatedAgreementMessage}
          visible={this.showAgreementDialog}
          onCancel={this.toggleTimeChargesAcknowledgmentDialog}
          onApply={this.navigateToCollectAdditionalData}
        />
        <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
          <Button id="btnPreformAnotherTest" key="btnPreformAnotherTest" buttonStyle="outline_secondary" size="lg" handleClick={this.handleStartAnotherTest}>
            <span>Perform Another Test</span>
          </Button>
          <Button
            id="btnSelectAppointment"
            key="btnSelectAppointment"
            buttonStyle="primary"
            size="lg"
            handleClick={this.selectAppointmentClick}
            disabled={!appointmentsStore.selectedAppointment}>
            <span>Select Appointment</span>
          </Button>
        </BottomButtonContainer>
      </>
    );
  }
}

export default withMediaQueryInfo(EngineerAppointmentSelection);
