import { BlobServiceClient, BlockBlobParallelUploadOptions, Pipeline, StorageRetryOptions, StorageRetryPolicyFactory } from '@azure/storage-blob';
import { BaseApi } from 'api/baseApi';
import messagesStore from 'stores/messages.store';
import userStore from 'stores/user.store';
import auth from 'utils/auth';
import { AuthorizationPolicyFactory } from './AzureCustomPolicies';

class AzureUpload {
  private async getBlobService(itemType: string, itemId: number) {
    const key = process.env.REACT_APP_API_KEY;
    const baseApiUrl = process.env.REACT_APP_UPLOAD_API_BASE_URL;
    const clientId = userStore.userSelectedClientId;
    const blobUri = `${baseApiUrl}/${clientId}/${itemType}_${itemId.toString()}`;

    const token = await auth.acquireTokenSilent();

    const sasKey = `?Subscription-Key=${key}`;

    const retryOptions: StorageRetryOptions = {
      maxTries: 3,
      retryDelayInMs: 3000,
      maxRetryDelayInMs: 5000
    };

    const pipeline = new Pipeline([new AuthorizationPolicyFactory('', token?.idToken ?? ''), new StorageRetryPolicyFactory(retryOptions)], {});

    return new BlobServiceClient(`${blobUri}${sasKey}`, pipeline);
  }

  private async uploadBlobByStream(files: File[], itemId: number, itemType: string) {
    const blobService = await this.getBlobService(itemType, itemId);
    const containerName = Date.now().toString();
    const containerClient = blobService.getContainerClient(containerName);

    if (!files || !files.length) {
      return;
    }

    if (!blobService) {
      return;
    }

    for (const file of files) {
      const blobClient = containerClient.getBlockBlobClient(file.name);

      // Make a smaller block size when uploading small blobs
      const blockSize = file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 256;
      const options: BlockBlobParallelUploadOptions = { blockSize: blockSize };

      BaseApi.requestsInProgress++;

      await blobClient
        .uploadData(file, options)
        .then((response) => {
          if (response && response._response.status >= 200 && response._response.status < 400) {
            messagesStore.addSuccessAsString(`${file.name} was uploaded successfully to ${itemType} ${itemId}.`);
          } else {
            throw response._response.status;
          }
        })
        .catch(() =>
          messagesStore.addErrorAsString(`We're sorry, ${file.name} failed to upload to ${itemType} ${itemId}. Please return to the item to try again.`)
        )
        .finally(() => BaseApi.requestsInProgress--);
    }
  }

  public async fileUpload(files: File[], itemId: number, itemType: string) {
    const results = await this.uploadBlobByStream(files, itemId, itemType);

    return results;
  }
}

export default new AzureUpload();
