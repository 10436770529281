import { ValidationRule } from 'models/api/validationRule.model';

// base model for data returned from API
// it may be that we don't need all of its properties
export class BaseModel {
  brokenRulesCollection: ValidationRule[] = [];
  isBusy: boolean | null = null;
  isChild: boolean | null = null;
  isDeleted: boolean | null = null;
  isDirty: boolean | null = null;
  isNew: boolean | null = null;
  isSavable: boolean | null = null;
  isSelfBusy: boolean | null = null;
  isSelfDirty: boolean | null = null;
  isSelfValid: boolean | null = null;
  isValid: boolean | null = null;
  parent: unknown = null;
}
