import { browserHistory } from 'App';
import messagesStore from 'stores/messages.store';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handleBrowserNotificationClick = (redirectUrl: string) => (event: Event) => {
  window.focus();

  browserHistory.push(redirectUrl);
};

export const checkNotificationsAvailability = async () => {
  const notificationIsNotSupported = !('Notification' in window);

  if (notificationIsNotSupported) {
    return false;
  }

  const result = await Notification.requestPermission();

  if (result === 'denied') {
    messagesStore.addWarningAsString('To be able to see Browser Notifications please enable Notifications permissions for Chess Customer portal');
  }

  return result === 'granted';
};

export const raiseNotification = ({ title, redirectUrl, ...options }: { title: string; redirectUrl?: string } & Partial<NotificationOptions>) => {
  const n = new Notification(title, options);

  if (redirectUrl) {
    n.onclick = () => {
      handleBrowserNotificationClick(redirectUrl);
    };
  }
};

export interface INotificationPayload {
  lineFaultCheckId: string;
}
