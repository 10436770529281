import appStyles from 'App.module.scss';
import Icon from 'components/Generic/Icon/icon';
import NewTicketBox from 'components/Tickets/NewTicket/NewTicketBox';
import { NewTicketOptions } from 'models/generic/newTicketOptions.model';
import React, { Component } from 'react';
import styles from '../NewTicket.module.scss';

interface INewTicketServiceProps {
  services: NewTicketOptions[];
  selectedServiceId: string;
  setService: (service: string) => void;
  sectionId: string;
}

export class NewTicketService extends Component<INewTicketServiceProps> {
  render() {
    return (
      <div className={`${appStyles.row} ${styles.ticketBoxes}`} id={this.props.sectionId}>
        {this.props.services.map((item) => {
          const serviceBlock: React.RefObject<HTMLDivElement> = React.createRef();

          return (
            <div
              ref={serviceBlock}
              key={item.id}
              className={`${appStyles.col_md_6} ${appStyles.col_lg_3} ${
                !this.props.selectedServiceId || this.props.selectedServiceId === item.id ? '' : styles.deselectedItem
              }`}>
              <NewTicketBox
                boxId={item.id}
                handleClick={() => {
                  this.props.setService(item.id);
                  if (serviceBlock && serviceBlock.current) {
                    serviceBlock.current.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start'
                    });
                  }
                }}
                headingText={item.headingText}
                description={item.description}
                buttonColour={item.buttonColour}
                isDisabled={item.isDisabled}>
                {item.buttonIcon !== undefined ? (
                  <Icon icon={item.buttonIcon} iconName={item.headingText} size="sm" color="currentColor" customClass={styles.ticketBoxes__icon} />
                ) : (
                  ''
                )}
              </NewTicketBox>
            </div>
          );
        })}
      </div>
    );
  }
}
