import { FC } from 'react';
import classNames from 'classnames';
import { Ticket } from 'models/api/ticket.model';
import appStyles from 'App.module.scss';
import Table from 'components/Dashboard/Table/Table';
import styles from './LatestTickets.module.scss';

interface ITicketsDashboardViewProps {
  header: string;
  tickets: Ticket[];
}

const TicketsDashboardView: FC<ITicketsDashboardViewProps> = ({ header, tickets }) => (
  <div className={styles.tickets_dashboard_view}>
    <div className={classNames(styles.tickets__heading, appStyles.text_midBlue)}>{header}</div>
    <Table
      showNumber
      rowTitle="View Ticket"
      columns={tickets.map((ticket) => ({
        id: ticket.supportTicketId,
        subject: ticket.subject,
        time: new Date(ticket.time),
        to: `/tickets/${ticket.supportTicketId}`
      }))}
    />
  </div>
);

export default TicketsDashboardView;
