import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Prompt, withRouter } from 'react-router-dom';
import Alert from 'components/Generic/Alert/Alert';
import { BaseUnsavedChangesComponent } from 'components/Generic/BaseUnsavedChangesComponent';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import { Quote } from 'models/api/quote.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import appStyles from 'App.module.scss';
import styles from './../MyQuotes.module.scss';

interface IAcceptQuoteProps extends IBaseProps {
  quote: Quote;
  showAcceptConfirmation: () => void;
}

@observer
class AcceptQuote extends BaseUnsavedChangesComponent<IAcceptQuoteProps> {
  @observable quote: Quote = this.props.quote;

  backToBreakdown = () => {
    this.props.history.push(`/products/quotes/${this.quote.quoteId}`);
  };

  render() {
    return (
      <>
        <Prompt
          when={this.unsavedChange && BaseUnsavedChangesComponent.enableCheck}
          message="You have unsaved changes. Are you sure you want to navigate away from this page?"
        />

        <div className={`${styles.quotesSection}`} id="quotesAcceptSection">
          <div className={appStyles.pageHeading_lg}>
            <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
              <h1 className={appStyles.heading__text}>Ready to Accept?</h1>
            </div>
          </div>
          <Alert alertType="blank">
            <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>If you wish to accept this quotation, please carry out the following actions:</p>
            <ul>
              <li>
                Write an email to <a href={'mailto:' + this.quote.preparedBy?.email}>{this.quote.preparedBy?.email}</a>, using only these words: "Please accept
                this email as an electronic signature and acceptance of the attached Order, Statement of Works (if applicable) and Chess’ terms and conditions
                referred to in the Order".
              </li>
              <li>
                Download the quotation PDF document you want to accept, and attach it unamended to your email. If there is more than one quote version, attach
                only the quote you wish to accept.
              </li>
            </ul>
            <p className={`${appStyles.text_midBlue} ${appStyles.info__sub}`}>
              By doing those actions, {this.quote.contactName} will be deemed to have accepted the Order, any associated Statement of Works, and the applicable
              terms and conditions of the Supplier linked within the attached documents. That acceptance is to the exclusion of all others, in the same way it
              would have had {this.quote.contactName} physically signed the Order.
            </p>
          </Alert>
        </div>

        <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
          <Button id="buttonBack" buttonStyle="outline_secondary" size="lg" handleClick={this.backToBreakdown}>
            Back to Quote Breakdown
          </Button>
        </BottomButtonContainer>
      </>
    );
  }
}

export default withRouter(AcceptQuote);
