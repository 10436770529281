import { Prompt, withRouter } from 'react-router-dom';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { ChangeEvent, ReactNode } from 'react';
import classNames from 'classnames';
import Alert from 'components/Generic/Alert/Alert';
import { BaseUnsavedChangesComponent } from 'components/Generic/BaseUnsavedChangesComponent';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import Input from 'components/Generic/FormElements/Input/Input';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import TextArea from 'components/Generic/FormElements/TextArea/TextArea';
import Icon from 'components/Generic/Icon/icon';
import { Location } from 'models/api/location.model';
import { ValidationRule } from 'models/api/validationRule.model';
import { FalconError } from 'models/generic/falconError.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import { ValidationErrors } from 'models/generic/validationError.model';
import locationsStore from 'stores/locations.store';
import constants from 'utils/constants';
import validation from 'utils/validation';
import appStyles from 'App.module.scss';
import { AddressFinder } from '@ideal-postcodes/address-finder';
import styles from '../Locations.module.scss';

interface IEditLocationProps extends IBaseProps {
  seletedLocation: Location;
}

@observer
class EditLocation extends BaseUnsavedChangesComponent<IEditLocationProps> {
  @observable location: Location = this.props.seletedLocation;
  @observable errors: ValidationErrors = new ValidationErrors();
  @observable locationConcurrencyError = '';
  @observable isValid = true;

  validationRules: ValidationRule[] = [];
  submitAttempted = false;

  constructor(props: IEditLocationProps) {
    super(props);

    this.goToNewLocationForm = this.goToNewLocationForm.bind(this);
    this.back = this.back.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    this.validationRules = await locationsStore.getLocationValidationRules();

    this.viewLocation();

    AddressFinder.setup({
      apiKey: 'idkfa',
      baseUrl: `${process.env.REACT_APP_ADDRESS_FINDER_API}`,
      header: { Accept: 'application/json', 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_API_KEY}` },
      removeOrganisation: true,
      msgFallback: 'Sorry, the address search function is unavailable at the moment. Please enter address manually.',
      inputField: '#searchField',
      outputFields: {
        line_1: '#line1',
        line_2: '#line2',
        post_town: '#town',
        postcode: '#postCode',
        county: '#county'
      }
    });
  }

  async componentDidUpdate(prevProps: IEditLocationProps) {
    if (this.props.seletedLocation.addressId !== prevProps.seletedLocation.addressId) {
      // reset validation state
      this.errors = new ValidationErrors();
      this.isValid = true;
      this.submitAttempted = false;

      this.viewLocation();
    }
  }

  async viewLocation() {
    if (this.props.seletedLocation) {
      this.location = this.props.seletedLocation;
    } else {
      this.location = await locationsStore.getNewLocation();
    }
  }

  handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    this.location = {
      ...this.location,
      [name]: value
    };

    this.unsavedChange = true;

    if (this.submitAttempted) {
      this.validateAll();
    }
  };

  handleAreaInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    this.location = {
      ...this.location,
      [name]: value
    };

    this.unsavedChange = true;

    if (this.submitAttempted) {
      this.validateAll();
    }
  };

  validateAll() {
    this.isValid = true;
    this.errors = new ValidationErrors();
    this.isValid = validation.validate(this.location, this.validationRules, this.errors);
  }

  getConcurrencyMessage(): ReactNode | null {
    if (this.locationConcurrencyError) {
      return (
        <Alert alertType="error" customClass="concurrencyAlert">
          {this.locationConcurrencyError}
          <br />
          {constants.validation.concurrencyMessage}
        </Alert>
      );
    }
  }

  async submit() {
    this.submitAttempted = true;
    this.validateAll();

    if (this.isValid) {
      let result: Location | FalconError;
      if (this.location.addressId > 0) {
        result = await locationsStore.updateLocation(this.location);
      } else {
        result = await locationsStore.createLocation(this.location);
      }

      // if result is error then check for validation messages
      if (result instanceof FalconError) {
        this.errors = validation.getValidationErrorsFromFalconError(result);
        if (result.concurrencyMessage) {
          this.locationConcurrencyError = result.concurrencyMessage;
          window.scrollTo(0, 0);
        }
      } else {
        this.unsavedChange = false;
        this.back();
      }
    }
  }

  back() {
    this.props.history.push('/account/locations');
  }

  goToNewLocationForm() {
    this.props.history.push('/account/locations/new');
  }

  render() {
    return (
      <>
        <Prompt
          when={this.unsavedChange && BaseUnsavedChangesComponent.enableCheck}
          message="You have unsaved changes. Are you sure you want to navigate away from this page?"
        />

        <form className={classNames(appStyles.card, appStyles.form)} id="locationDetailsForm" onSubmit={this.submit}>
          <Legend text={this.location.addressId === -1 ? 'Add New Location' : 'Edit Location Details'} type="large" />
          {this.getConcurrencyMessage()}
          <div className={appStyles.row}>
            <div className={appStyles.col_md_6}>
              <div className={appStyles.form__row}>
                <Input
                  inputType="text"
                  name="searchField"
                  labelText="Search with autocomplete or type address manually"
                  elementId="searchField"
                  value={this.location.searchField}
                  placeholder="Start typing to find address"
                  customLabelClass={styles.searchField__fontStyle}
                  handleChange={this.handleInput}></Input>
              </div>
            </div>
          </div>
          <div className={classNames(appStyles.row, styles.manageLocation__form)}>
            <div className={appStyles.col_md_6}>
              <div className={appStyles.form__row}>
                <Input
                  inputType="text"
                  name="line1"
                  labelText="Address Line 1"
                  elementId="line1"
                  value={this.location.line1}
                  placeholder=""
                  handleChange={this.handleInput}
                  validationError={this.errors.fieldErrors['line1']}></Input>
              </div>
              <div className={appStyles.form__row}>
                <Input
                  inputType="text"
                  name="line2"
                  labelText="Address Line 2"
                  elementId="line2"
                  value={this.location.line2}
                  placeholder=""
                  handleChange={this.handleInput}></Input>
              </div>
              <div className={appStyles.form__row}>
                <Input
                  inputType="text"
                  name="town"
                  labelText="Town"
                  elementId="town"
                  value={this.location.town}
                  placeholder=""
                  handleChange={this.handleInput}
                  validationError={this.errors.fieldErrors['town']}></Input>
              </div>
              <div className={appStyles.form__row}>
                <Input
                  inputType="text"
                  name="county"
                  labelText="County"
                  elementId="county"
                  value={this.location.county}
                  placeholder=""
                  handleChange={this.handleInput}></Input>
              </div>
              <div className={appStyles.form__row}>
                <Input
                  inputType="text"
                  name="postCode"
                  labelText="Postcode"
                  elementId="postCode"
                  value={this.location.postCode}
                  placeholder=""
                  handleChange={this.handleInput}
                  autocomplete="address-line1"
                  validationError={this.errors.fieldErrors['postCode']}></Input>
              </div>
            </div>
            <div className={appStyles.col_md_6}>
              <TextArea
                name="notes"
                labelText="Notes"
                elementId="notes"
                value={this.location.notes}
                placeholder=""
                rows={8}
                resize="vertical"
                handleChange={this.handleAreaInput}></TextArea>
            </div>
          </div>
        </form>

        <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
          <Button id="buttonViewLocations" buttonStyle="outline_secondary" size="lg" handleClick={this.back}>
            Cancel Without Saving
          </Button>

          <Button id="saveLocation" buttonStyle="primary" size="lg" handleClick={this.submit}>
            <Icon icon={Icon.check} customClass={appStyles.buttonWithIcon__icon} color="currentColor" size="sm" iconName="addIcon" />
            <span className={appStyles.buttonWithIcon__text}>Save Location</span>
          </Button>

          {this.location.addressId > 0 ? (
            <Button id="newLocation" buttonStyle="primary" size="lg" handleClick={this.goToNewLocationForm}>
              <Icon icon={Icon.plus} customClass={appStyles.buttonWithIcon__icon} color="currentColor" size="sm" iconName="addIcon" />
              <span className={appStyles.buttonWithIcon__text}>Add New Location</span>
            </Button>
          ) : (
            ''
          )}
        </BottomButtonContainer>
      </>
    );
  }
}

export default withRouter(EditLocation);
