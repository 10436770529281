import appStyles from 'App.module.scss';
import preloader from 'assets/images/preloader.gif';
import classNames from 'classnames';
import Icon from 'components/Generic/Icon/icon';
import FileDownload from 'js-file-download';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Invoice } from 'models/api/invoice.model';
import { FalconError } from 'models/generic/falconError.model';
import { FalconFile } from 'models/generic/falconFile.model';
import { Component } from 'react';
import invoicesStore from 'stores/invoices.store';
import productsStore from 'stores/products.store';
import ticketsStore from 'stores/tickets.store';
import styles from './FileDownloads.module.scss';

interface IFileDownloadsProps {
  dataType: 'ticket' | 'license' | 'return' | 'quote' | 'invoice';
  itemId: number | string | null;
  invoice?: Invoice | null;
}

@observer
class FileDownloads extends Component<IFileDownloadsProps> {
  @observable attachmentsLoading = false;
  @observable files: FalconFile[] = [];
  @observable showAllFiles = false;

  constructor(props: IFileDownloadsProps) {
    super(props);

    this.downloadFile = this.downloadFile.bind(this);
  }

  componentDidMount() {
    this.loadFiles();
  }

  componentDidUpdate(prevProps: IFileDownloadsProps) {
    if (this.props.itemId !== prevProps.itemId) {
      this.loadFiles();
    }
  }

  async loadFiles() {
    if (this.props.itemId) {
      this.attachmentsLoading = true;

      switch (this.props.dataType) {
        case 'ticket':
          this.files = await ticketsStore.getTicketAttachmentList(+this.props.itemId);
          break;
        case 'license':
          this.files = await productsStore.getLicenseDownloads(+this.props.itemId);
          break;
        case 'return':
          this.files = await productsStore.getReturnsDownloads(+this.props.itemId);
          break;
        case 'quote':
          this.files = await productsStore.getQuoteDownloads(+this.props.itemId);
          break;
        case 'invoice':
          if (this.props.invoice) {
            this.files = await invoicesStore.getInvoiceDownloads(this.props.invoice);
          }
          break;
      }

      this.attachmentsLoading = false;
    }
  }

  async downloadFile(file: FalconFile) {
    let result: ArrayBuffer | FalconError | null = null;

    if (this.props.itemId) {
      switch (this.props.dataType) {
        case 'ticket':
          result = await ticketsStore.getTicketAttachment(+this.props.itemId, file.name);
          break;
        case 'license':
          result = await productsStore.getLicenseDownload(+this.props.itemId, file.name);
          break;
        case 'return':
          result = await productsStore.getReturnsDownload(+this.props.itemId, file.name);
          break;
        case 'quote':
          result = await productsStore.getQuoteDownload(+this.props.itemId, file.name);
          break;
        case 'invoice':
          if (file.url) {
            result = await invoicesStore.getInvoiceDownload(file.url);
          }
          break;
      }

      if (result instanceof FalconError) {
      } else if (result instanceof ArrayBuffer) {
        FileDownload(result, file.name);
      }
    }
  }

  render() {
    return (
      <>
        {this.attachmentsLoading ? <img alt="Loading..." className={styles.preloader} src={preloader} /> : <></>}

        {this.files.length === 0 && this.attachmentsLoading === false ? (
          <div className={`${styles.files__heading} ${appStyles.text_darkSkyBlue}`}>There are no files to download.</div>
        ) : (
          ''
        )}

        {this.files.map((file, index) => (
          <div className={classNames(appStyles.files, { [styles.hide]: !this.showAllFiles && index >= 10 })} key={'file' + index + '_' + file.name}>
            <div className={styles.files__left}>
              <button
                type="button"
                className={styles.files__download}
                id={`fileDownload_button_${index}`}
                onClick={() => {
                  this.downloadFile(file);
                }}>
                <Icon icon={Icon.download} iconName="Download attachment" color="lightBlue" size="xs" />
              </button>

              <p id={`fileDownload_name_${index}`} className={appStyles.files__name}>
                {file.name}
              </p>
            </div>
          </div>
        ))}

        {this.files.length >= 10 ? (
          <button
            className={styles.toggleFiles}
            type="button"
            onClick={() => {
              this.showAllFiles = !this.showAllFiles;
            }}>
            {this.showAllFiles ? 'Collapse' : 'View more'}
          </button>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default FileDownloads;
