import { ChangeEventHandler, Component } from 'react';
import classNames from 'classnames';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import Input from 'components/Generic/FormElements/Input/Input';
import TextArea from 'components/Generic/FormElements/TextArea/TextArea';
import Icon from 'components/Generic/Icon/icon';
import Preloader from 'components/Generic/Preloader/Preloader';
import { TroubleReportRequest } from 'models/api/troubleReportRequest.model';
import { ValidationErrors } from 'models/generic/validationError.model';
import appointmentsStore from 'stores/appointments.store';
import lineFaultsStore from 'stores/lineFaults.store';
import userStore from 'stores/user.store';
import constants from 'utils/constants';
import { errorMessages, messages } from 'utils/messages';
import validation, { ValidationHandler } from 'utils/validation';
import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import contactDataFormStyles from './ContactDataForm.module.scss';

interface IContactDataFormState {
  contact: IContact;
}
interface IContact {
  telephone: string;
}

@observer
export default class ContactDataForm extends Component<RouteComponentProps> {
  @observable errors = new ValidationErrors();
  @observable form: Record<string, string> = {};

  runValidationOnChange = false;

  @computed get validationRules(): Record<string, Array<ValidationHandler<string>>> {
    return {
      firstName: [(value) => !value && 'Enter first name'],
      lastName: [(value) => !value && 'Enter last name'],
      contactTelephone: [
        (value) => lineFaultsStore.lineFaultCheck?.cli === value && errorMessages.cliSameAsTelephoneNumber,
        ({ length }) => (length < constants.telephoneNumberMinLength || length > constants.telephoneNumberMaxLength) && 'Enter a valid phone number'
      ],
      problemDescription: [(value) => !value && 'Enter fault description']
    };
  }

  @computed get inputReplacePatterns(): Record<string, RegExp | string> {
    return {
      lastName: validation.regexPatterns.name,
      firstName: validation.regexPatterns.name,
      contactTelephone: /[^0-9]/g,
      problemDescription: validation.regexPatterns.name
    };
  }

  @computed get appointmentDisplayTime() {
    const date = appointmentsStore.selectedAppointment?.appointmentDate;
    const timeslot = appointmentsStore.selectedAppointment?.appointmentTimeslot;
    const timeslotTitle = timeslot && constants.engineerAppointmentSlots[timeslot].title;
    const timerange = timeslotTitle && constants.engineerAppointmentTimeRanges[timeslotTitle];

    return timeslot && date ? `${new Date(date).toDateString()} ${timeslotTitle} ${timerange ? `(${timerange})` : ''}` : '-';
  }

  @action submitTroubleReport = async () => {
    this.runValidationOnChange = true;

    if (!this.validateForm()) {
      return;
    }

    if (lineFaultsStore.correlationId) {
      const params = { ...this.form, appointment: appointmentsStore.selectedAppointment } as TroubleReportRequest;

      await lineFaultsStore.addTroubleReport(lineFaultsStore.correlationId, params);

      return this.props.history.push(`/services/line-faults/${lineFaultsStore.correlationId}/success`);
    }
  };

  @action validateForm = () => {
    this.errors = new ValidationErrors();

    const failedValidations = Object.keys(this.form)
      .map((key) => ({ errors: this.validationRules[key].map((rule) => rule(this.form[key])).filter(Boolean), key }))
      .filter(({ errors }) => errors.length);

    failedValidations.forEach((validatedField) =>
      validatedField.errors.forEach((errorMessage) => this.errors.addError(validatedField.key, errorMessage as string))
    );

    return !failedValidations.length;
  };

  @action handleInput: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    const replacePatters = this.inputReplacePatterns[name] ?? '';

    this.form[name] = value.replace(replacePatters, '');

    if (this.runValidationOnChange) {
      this.validateForm();
    }
  };

  componentDidMount() {
    const { contact } = (this.props.location?.state as IContactDataFormState) ?? {};

    this.form = {
      firstName: userStore.currentUser?.firstName?.replace(validation.regexPatterns.name, '') ?? '',
      lastName: userStore.currentUser?.lastName?.replace(validation.regexPatterns.name, '') ?? '',
      // TODO: check if we have test cases for this
      contactTelephone: contact?.telephone?.replaceAll(/[^0-9+]/g, '').replace(/^(\+?0{0,2}440?)/g, '0') ?? '',
      problemDescription: ''
    };
  }

  navigateToEngineerAppointmentSelection = () => {
    appointmentsStore.resetSelectedAppointment();

    return this.props.history.push(`/services/line-faults/${lineFaultsStore.correlationId}/engineer-appointment`);
  };

  render() {
    return (
      <>
        <div className={classNames(appStyles.heading, appStyles.text_midBlue)}>
          <h1 className={appStyles.heading__text}>Confirm Site Details</h1>
        </div>
        <Alert alertType="blank">
          <p className={classNames(appStyles.text_darkGrey, appStyles.info__main)}>Please complete the form below to book your engineer appointment</p>
        </Alert>
        {!lineFaultsStore.pending ? (
          <form className={contactDataFormStyles.collectData__form}>
            <div className={contactDataFormStyles.collectData__formRow}>
              <div className={contactDataFormStyles.collectData__formRowHeader}>Contact Details</div>
              <div className={contactDataFormStyles.form__inputContainer}>
                <Input
                  name="firstName"
                  inputType="text"
                  labelText="First Name"
                  elementId="firstName"
                  value={this.form.firstName}
                  handleChange={this.handleInput}
                  placeholder=""
                  required={true}
                  validationError={this.errors.fieldErrors['firstName']}
                />
              </div>
              <div className={contactDataFormStyles.form__inputContainer}>
                <Input
                  name="lastName"
                  inputType="text"
                  labelText="Last Name"
                  elementId="lastName"
                  value={this.form.lastName}
                  handleChange={this.handleInput}
                  placeholder=""
                  required={true}
                  validationError={this.errors.fieldErrors['lastName']}
                />
              </div>
              <div className={contactDataFormStyles.form__inputContainer}>
                <Input
                  name="contactTelephone"
                  minLength={10}
                  maxLength={11}
                  inputType="tel"
                  labelText="Contact Phone"
                  elementId="contactTelephone"
                  value={this.form.contactTelephone}
                  handleChange={this.handleInput}
                  placeholder=""
                  required={true}
                  validationError={this.errors.fieldErrors['contactTelephone']}
                />
              </div>
              <div className={contactDataFormStyles.form__inputContainer}>
                <TextArea
                  name="problemDescription"
                  labelText="Fault Description"
                  elementId="problemDescription"
                  value={this.form.problemDescription}
                  placeholder="Please provide a comprehensive description of the fault."
                  rows={8}
                  resize="vertical"
                  handleChange={this.handleInput}
                  validationError={this.errors.fieldErrors['problemDescription']}
                />
              </div>
            </div>
            <div className={classNames(contactDataFormStyles.collectData__formCard, contactDataFormStyles.collectData__formRow)}>
              <div className={contactDataFormStyles.collectData__formRowHeader}>Engineer Appointment Summary</div>
              {this.appointmentDisplayTime && (
                <div className={contactDataFormStyles.collectData_formRowDataContainer}>
                  <div className={contactDataFormStyles.collectData__formRowSubheader}>Appointment</div>
                  <p id="appointmentSlotInfo" className={classNames(contactDataFormStyles.collectData__formRowData, appStyles.text_darkGrey)}>
                    {this.appointmentDisplayTime}
                  </p>
                </div>
              )}
              <div className={contactDataFormStyles.collectData_formRowDataContainer}>
                <div className={contactDataFormStyles.collectData__formRowSubheader}>Address</div>
                <p id="cliThoroughfareName" className={classNames(contactDataFormStyles.collectData__formRowData, appStyles.text_darkGrey)}>
                  {lineFaultsStore.lineFaultCheck?.thoroughfareNumber ?? ''} {lineFaultsStore.lineFaultCheck?.thoroughfareName ?? ''}
                </p>
                <p id="cliPostTown" className={classNames(contactDataFormStyles.collectData__formRowData, appStyles.text_darkGrey)}>
                  {lineFaultsStore.lineFaultCheck?.postTown ?? ''}
                </p>
                <p id="cliCounty" className={classNames(contactDataFormStyles.collectData__formRowData, appStyles.text_darkGrey)}>
                  {lineFaultsStore.lineFaultCheck?.county ?? ''}
                </p>
                <p id="cliPostcode" className={classNames(contactDataFormStyles.collectData__formRowData, appStyles.text_darkGrey)}>
                  {lineFaultsStore.lineFaultCheck?.postcode ?? ''}
                </p>
              </div>
              <div className={contactDataFormStyles.collectData_formRowDataContainer}>
                <div className={contactDataFormStyles.collectData__formRowSubheader}>Fault Description</div>
                <p id="faultDescription" className={classNames(contactDataFormStyles.collectData__formRowData, appStyles.text_darkGrey)}>
                  {lineFaultsStore.faultDescription}
                </p>
              </div>
            </div>
          </form>
        ) : (
          <div className={contactDataFormStyles.preloader__container}>
            <Preloader id="trouble-report" title={messages.raiseTroubleReportLoaderTitle} />
          </div>
        )}
        <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
          <Button key="btnBack" size="lg" buttonStyle="outline_secondary" id="btnBack" handleClick={this.navigateToEngineerAppointmentSelection}>
            <span>Previous Step</span>
          </Button>
          <Button size="lg" buttonStyle="primary" id="btnSubmit" key="btnSubmit" handleClick={this.submitTroubleReport} disabled={lineFaultsStore.pending}>
            <Icon icon={Icon.check} customClass={classNames(appStyles.noPointerEvents, appStyles.button__icon)} size="sm" color="white" iconName="check" />
            <span>Submit</span>
          </Button>
        </BottomButtonContainer>
      </>
    );
  }
}
