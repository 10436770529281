import { errorMessages, messages } from 'utils/messages';
import { booleanToStringKeyGenerator } from 'utils/string';

class Constants {
  applicationTitle = 'Chess Customer Portal';

  appointmentCost = '145';

  roleNames = {
    Quotes: 'Quotes',
    Orders: 'Orders',
    Contracts: 'Contracts',
    Callouts: 'Callouts',
    Support: 'Support',
    Tasks: 'Tasks',
    DailySummary: 'Daily Summary',
    MyPortalAdmin: 'MyPortal Admin',
    Projects: 'Projects',
    Returns: 'Returns',
    Repairs: 'Repairs',
    OrderOnline: 'Order Online',
    MyPortalSwitchAccount: 'MyPortal Switch Account',
    MyPortalNoAccess: 'MyPortal No Access',
    MyPortalPendingValidation: 'MyPortal Pending Validation',
    MyPortalLockedOut: 'MyPortal Locked Out',
    DevicesShop: 'Devices Shop'
  };

  validation = {
    rules: {
      selectedClientContact: 'rule://businesslibrary.validators.selectedclientcontactvalidator',
      bankAccountNumber: 'rule://businesslibrary.validators.bankaccountnumbervalidator',
      bankSortCode: 'rule://businesslibrary.validators.banksortcodevalidator',
      email: 'rule://businesslibrary.validators.emailvalidator',
      fileExtension: 'rule://businesslibrary.validators.fileextensionvalidator',
      fileName: 'rule://businesslibrary.validators.filenamevalidator',
      fileSize: 'rule://businesslibrary.validators.filesizevalidator',
      indenticalFields: 'identicalfieldsvalidator',
      numericRange: 'rule://businesslibrary.validators.numericrangevalidator',
      phone: 'rule://businesslibrary.validators.phonevalidator',
      required: 'rule://businesslibrary.validators.requiredvalidator',
      signature: 'rule://businesslibrary.validators.signaturevalidator',
      stringLength: 'rule://businesslibrary.validators.stringlengthvalidator',
      true: 'rule://businesslibrary.validators.truevalidator',
      url: 'rule://businesslibrary.validators.urlvalidator',
      postCode: 'rule://businesslibrary.validators.postcodevalidator',
      digit: 'rule://businesslibrary.validators.digitvalidator',
      emailChangeWarning: 'rule://businesslibrary.validators.emailchangewarning',
      emailChangeInfo: 'rule://businesslibrary.validators.emailchangeinfo',
      contactNoRolesWarning: 'rule://businesslibrary.validators.contactnoroleswarning'
    },
    severity: {
      error: 0,
      warning: 1,
      info: 2,
      success: 3
    },
    concurrencyMessage:
      // eslint-disable-next-line max-len
      'In order to save the record you will need to reload the page to get the latest changes. If you want to keep your changes please open the page in a new tab and manually copy your edits into the updated record.'
  };

  supportTicketStatuses = {
    new: 0,
    inProgress: 1,
    deferred: 2,
    closed: 3
  };

  quoteStatuses = {
    pending: 1,
    ordered: 2,
    declined: 3
  };

  invoiceStatuses = {
    live: 0,
    onHold: 1,
    completed: 2,
    disputed: 3
  };

  searchKeywordMinimumLength = 3; // minimum number of characters for search requests

  addressesApiErrorCodes = {
    noMatchingAddressesFound: 'AMWS2056',
    theSpecifiedValueIsNotValid: 'AMWS1000'
  };

  addressesApiErrorCodesDescription: Record<string, string> = {
    [this.addressesApiErrorCodes.noMatchingAddressesFound]: errorMessages.noMatchingAddressesFound,
    [this.addressesApiErrorCodes.theSpecifiedValueIsNotValid]: errorMessages.theSpecifiedValueIsNotValid
  };

  AvailableProductResultMessages: Record<string, { heading: string; mainText: string; callBackText: string }> = {
    [booleanToStringKeyGenerator(true, true)]: {
      heading: messages.productAvailableHeading,
      mainText: messages.productAvailableMainText,
      callBackText: messages.productAvailableCallBackText
    },
    [booleanToStringKeyGenerator(false, true)]: {
      heading: messages.noProductAvailableHeading,
      mainText: messages.noProductAvailableMainText,
      callBackText: messages.noProductAvailableCallBackText
    },
    [booleanToStringKeyGenerator(false, false)]: {
      heading: messages.noAddressAvailableHeading,
      mainText: messages.noAddressAvailableMainText,
      callBackText: messages.noAddressAvailableCallBackText
    }
  };

  keyVariables = {
    helpdeskTelephone: '0344 770 6000',
    salesTeamTelephone: '0344 700 6000',
    customerServicesName: 'Chess Customer Services',
    helpDeskEmail: 'customerservices@chessict.co.uk',
    registrationEmail: 'registration@chessict.co.uk'
  };

  engineerAppointmentSlots = {
    AM: {
      chargeable: false,
      title: 'Morning'
    },
    PM: {
      chargeable: false,
      title: 'Afternoon'
    },
    EARLY_MORNING: {
      chargeable: true,
      title: 'Early Morning'
    },
    LATE_MORNING: {
      chargeable: true,
      title: 'Late Morning'
    },
    EARLY_AFTERNOON: {
      chargeable: true,
      title: 'Early Afternoon'
    },
    EVENING: {
      chargeable: true,
      title: 'Evening'
    }
  } as {
    [key: string]: { chargeable: boolean; title: string };
  };

  engineerAppointmentSlotKeys = Object.entries(this.engineerAppointmentSlots);

  engineerAppointmentTimeRanges: { [name: string]: string } = {
    Morning: '08:00 - 13:00',
    Afternoon: '13:00 - 18:00'
  };

  notificationTitles: Record<string, string> = {
    LineFaultCheck: 'View line check results'
  };

  telephoneNumberMinLength = 10;
  telephoneNumberMaxLength = 11;
  postcodeMinLength = 5;
  postcodeMaxLength = 8;

  notificationsHubUrl = '/Hub/myportalalerts';
  notificationMethods = {
    LineFaultCheck: 'LineFaultCheck',
    FailedLineFaultCheck: 'FailedLineFaultCheck'
  };

  notificationTypes = {
    LineFaultCheck: 'LineFaultCheck',
    FailedLineFaultCheck: 'FailedLineFaultCheck',
    Payments: 'Payments'
  };

  lineCheckResultStatuses = {
    Finished: 'Finished',
    Failed: 'Failed'
  };
}

export default new Constants();
