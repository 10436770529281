import { AuthenticationResult, AuthError, InteractionRequiredAuthError } from '@azure/msal-browser';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { observable } from 'mobx';
import qs from 'qs';
import { FalconError } from 'models/generic/falconError.model';
import messagesStore from 'stores/messages.store';
import userStore from 'stores/user.store';
import { IGetRequestConfig } from 'api/types';
import auth from 'utils/auth';
import logging from 'utils/logging';

export abstract class BaseApi {
  @observable static requestsInProgress = 0;

  protected baseApiUrl = '';
  // TODO: resolve circular dependency with utils
  protected timeoutInMinutes = process.env.REACT_APP_ENVIRONMENT_NAME !== 'Production' ? 0.5 : 10;
  protected axiosInstance: AxiosInstance;

  constructor(config?: AxiosRequestConfig) {
    // calculate requests on in progress on new request
    this.axiosInstance = axios.create(config);

    this.axiosInstance.interceptors.request.use((request) => {
      BaseApi.requestsInProgress++;

      return request;
    });

    // calculate requests on in progress on response/error
    this.axiosInstance.interceptors.response.use(
      (response) => {
        BaseApi.requestsInProgress--;

        return response;
      },
      (error) => {
        BaseApi.requestsInProgress--;

        return error;
      }
    );
  }

  isError(responseOrError: AxiosResponse | AxiosError): responseOrError is AxiosError {
    return (responseOrError as AxiosError).stack !== undefined;
  }

  isResponse(responseOrError: AxiosResponse | AxiosError): responseOrError is AxiosResponse {
    return (responseOrError as AxiosResponse).headers !== undefined;
  }

  getResult(responseOrError: AxiosResponse | AxiosError, friendlyErrorMessage: string | null | undefined, decodeResponse: boolean, silent = false) {
    if (this.isResponse(responseOrError)) {
      // handle success response
      return this.getResultFromResponse(responseOrError as AxiosResponse, decodeResponse);
    } else if (this.isError(responseOrError)) {
      // handle error and return result
      return this.getResultFromError(responseOrError as AxiosError, friendlyErrorMessage, silent);
    }
  }

  getResultFromExternal(responseOrError: AxiosResponse | AxiosError, friendlyErrorMessage: string | null) {
    if (this.isResponse(responseOrError)) {
      // handle success response
      return this.getResultFromResponse(responseOrError as AxiosResponse, false);
    } else if (this.isError(responseOrError)) {
      // show error message
      if (friendlyErrorMessage) {
        messagesStore.addErrorAsString(friendlyErrorMessage);
      }
    }
  }

  getResultFromResponse(response: AxiosResponse, decodeResponse: boolean) {
    const result = response.data;

    const totalCount = response.headers['list-total-count'];
    const unreadCount = response.headers['list-total-unread-count'];

    if (totalCount) {
      result.totalCount = +totalCount;
    }

    if (unreadCount) {
      result.unreadCount = +unreadCount;
    }

    return typeof result === 'string' && decodeResponse ? JSON.parse(result) : result;
  }

  private async getToken() {
    let tokenResponse: AuthenticationResult | null = null;

    try {
      tokenResponse = await auth.acquireTokenSilent();
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        logging.error('acquireTokenSilent error', error);

        // fallback to interaction when silent call fails
        try {
          tokenResponse = await auth.acquireTokenPopup();
        } catch (error) {
          logging.error('acquireTokenPopup error', error);

          if (error instanceof AuthError) {
            userStore.logOut({ invalidateSessions: false });
          } else {
            throw error;
          }
        }
      } else if (error instanceof AuthError) {
        userStore.logOut({ invalidateSessions: false });
      } else {
        throw error;
      }
    }

    return tokenResponse;
  }

  protected abstract getResultFromError(error: AxiosError, friendlyErrorMessage: string | null | undefined, silent: boolean): FalconError;

  async getFromExternal<T>(url: string, friendlyErrorMessage: string | null) {
    const response = await this.axiosInstance.get<T>(url);

    return this.getResultFromExternal(response, friendlyErrorMessage) as T | FalconError;
  }

  protected async getConfig(): Promise<AxiosRequestConfig> {
    // documentation here: https://github.com/axios/axios

    const config: AxiosRequestConfig = {
      baseURL: this.baseApiUrl,
      timeout: this.timeoutInMinutes * 60 * 1000
    };

    config.headers = {
      'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_KEY || ''
    };

    config.headers['Chess-Customer-Portal-Selected-Account'] = userStore.userSelectedClientId;

    const tokenResponse = await this.getToken();

    if (tokenResponse?.idToken) {
      config.headers.Authorization = 'Bearer ' + tokenResponse.idToken;
    }

    return config;
  }

  async get<T>(url: string, { friendlyErrorMessage, decodeResponse = true, silent = false, ...customConfig }: IGetRequestConfig = {}) {
    const config = await this.getConfig();
    const response = await this.axiosInstance.get<T>(url, { paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }), ...config, ...customConfig });

    return this.getResult(response, friendlyErrorMessage, decodeResponse, silent) as T | FalconError;
  }

  async post<T>(url: string, data: unknown, friendlyErrorMessage: string | null, silent = false) {
    const config = await this.getConfig();
    const response = await this.axiosInstance.post<T>(url, data, config);

    return this.getResult(response, friendlyErrorMessage, false, silent) as T | FalconError;
  }

  async put<T>(url: string, data: unknown, friendlyErrorMessage: string | null, silent = false) {
    const config = await this.getConfig();
    const response = await this.axiosInstance.put<T>(url, data, config);

    return this.getResult(response, friendlyErrorMessage, false, silent) as T | FalconError;
  }

  async delete<T>(url: string, friendlyErrorMessage: string | null, silent = false) {
    const config = await this.getConfig();
    const response = await this.axiosInstance.delete<T>(url, config);

    return this.getResult(response, friendlyErrorMessage, false, silent) as T | FalconError;
  }

  async fileExists<T>(url: string, customConfig: IGetRequestConfig = {}): Promise<boolean> {
    let exists = false;
    const config = await this.getConfig();

    const response = await this.axiosInstance.head<T>(url, { ...config, ...customConfig });
    exists = response && response.status === 200;

    return exists;
  }
}
