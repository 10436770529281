import appStyles from 'App.module.scss';
import NewTicketBox from 'components/Tickets/NewTicket/NewTicketBox';
import { newTicketCategories } from 'components/Tickets/NewTicket/NewTicketService/services';
import { Component } from 'react';
import styles from '../NewTicket.module.scss';

interface INewTicketCategoryProps {
  setCategory: (category: string, showForm?: boolean) => void;
}

export class NewTicketCategory extends Component<INewTicketCategoryProps> {
  render() {
    return (
      <>
        <div className={`${styles.newTicket} ${appStyles.container}`}>
          <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
            <h1 className={appStyles.heading__text}>Select the service to log a support ticket</h1>
          </div>
          <div className={`${appStyles.row} ${styles.ticketBoxes}`}>
            <div className={`${appStyles.col_md_4} ${appStyles.col_lg_3}`}>
              <NewTicketBox
                boxId={newTicketCategories[0].id}
                handleClick={() => this.props.setCategory(newTicketCategories[0].id)}
                headingText={newTicketCategories[0].headingText}
                description={newTicketCategories[0].description}
                buttonColour={newTicketCategories[0].buttonColour}
                isDisabled={newTicketCategories[0].isDisabled}></NewTicketBox>
            </div>
            <div className={`${appStyles.col_md_4} ${appStyles.col_lg_3}`}>
              <NewTicketBox
                boxId={newTicketCategories[1].id}
                handleClick={() => {
                  this.props.setCategory(newTicketCategories[1].id, true);
                }}
                headingText={newTicketCategories[1].headingText}
                description={newTicketCategories[1].description}
                buttonColour={newTicketCategories[1].buttonColour}
                isDisabled={newTicketCategories[1].isDisabled}></NewTicketBox>
            </div>
          </div>
        </div>
      </>
    );
  }
}
