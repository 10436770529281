import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as ArrowRight } from 'assets/images/svg/arrowRight.svg';
import appStyles from 'App.module.scss';
import dashboardStyles from '../Dashboard.module.scss';
import styles from './Shortcuts.module.scss';

interface IFavoritesLinkProps {
  to: string;
  title: string;
  hint: string;
  Picture: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const FavoritesLink: FC<IFavoritesLinkProps> = ({ to, title, hint, Picture }) => (
  <NavLink className={dashboardStyles.shortcuts__button} to={to} title={hint}>
    <Picture className={classNames(appStyles.noPointerEvents, styles.icon)} />
    <p className={classNames(appStyles.text_midBlue, dashboardStyles.shortcuts__text)}>{title}</p>
    <div className={appStyles.button__icon_mini}>
      <ArrowRight width="50px" height="50px" />
    </div>
  </NavLink>
);

export default FavoritesLink;
