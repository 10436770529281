import { action, observable } from 'mobx';
import api from 'api/api';
import { LineCheckRequestResponse } from 'models/api/lineCheckRequestResponse.model';
import { LineFaultCheck } from 'models/api/lineFaultCheck.model';
import { LineFaultCode } from 'models/api/lineFaultCode.model';
import { TroubleReportRequest } from 'models/api/troubleReportRequest.model';
import { TroubleReportRequestResponse } from 'models/api/troubleReportRequstResponse.model';
import { FalconError } from 'models/generic/falconError.model';
import messagesStore from 'stores/messages.store';
import notificationsStore from 'stores/notifications.store';
import { browserHistory } from 'App';
import accountStore from 'stores/account.store';
import constants from 'utils/constants';
import { CareLevel } from 'utils/enums';
import { errorMessages } from 'utils/messages';

interface IAcknowledgeAcceptanceForm {
  timeRelatedChargesAccepted?: boolean;
  chargeableAppointmentAccepted?: boolean;
  noFaultCheckListConfirmed?: boolean;
}

class LineFaultsStore {
  submitTroubleReportAttempted = false;

  @observable isLineFaultFlowStarted = false;
  @observable pending = false;
  @observable readyToContinueLineFaultFlow = false;
  @observable needToSkipEngineerAppointmentStep = false;

  @observable lineFaultCheck: LineFaultCheck | null = null;
  @observable troubleReportResult: TroubleReportRequestResponse | null = null;

  get troubleReportRaised() {
    return !!this.lineFaultCheck?.troubleReportReference;
  }

  get faultDescription(): string {
    return (this.isFault && this.lineFaultCheck?.info) || 'No fault was found';
  }

  get isFault() {
    return this.lineFaultCheck?.isFault ?? false;
  }

  get correlationId() {
    return this.lineFaultCheck?.id;
  }

  get faultCheckResponseMessage() {
    return this.isFault
      ? `A fault has been found on this line. ${this.lineFaultCheck?.info ?? ''}`
      : 'You can still book an engineer appointment, but you will be charged for call out and on site work carried out.';
  }

  @action lineFaultCheckFinished = async (lineFaultId?: string) => {
    if (this.isLineFaultFlowStarted) {
      this.cleanResults();
      this.readyToContinueLineFaultFlow = true;

      if (lineFaultId) {
        await this.fetchLineCheck(lineFaultId);

        if (this.needToSkipEngineerAppointmentStep) {
          await this.skipEngineerAppointment();
        }

        await notificationsStore.markAsRead(notificationsStore.getNotificationByLineFaultId(lineFaultId) ?? 0);

        browserHistory.push(`/services/line-faults/${lineFaultId}`);
      }
    }
  };

  @action skipEngineerAppointment = async () => {
    messagesStore.addWarningAsString(errorMessages.highCareLevelFault);
    const contact = await accountStore.getCurrentContact();
    browserHistory.push(`/services/line-faults/${this.correlationId}/collect-data`, { contact });
  };

  @action updateAcknowledgeAcceptance = async ({
    noFaultCheckListConfirmed,
    timeRelatedChargesAccepted,
    chargeableAppointmentAccepted
  }: IAcknowledgeAcceptanceForm) => {
    if (this.lineFaultCheck) {
      const result = await api.put<LineFaultCheck>(
        '/LineFault/AcknowledgeAcceptance',
        { timeRelatedChargesAccepted, chargeableAppointmentAccepted, noFaultCheckListConfirmed, faultCheckId: this.lineFaultCheck.id },
        'There was an error fetching acceptance details.'
      );

      if (result instanceof FalconError) {
        throw result;
      }

      this.lineFaultCheck = result;

      return result;
    }

    throw new Error('Operation cannot be started - there is no line check results');
  };

  @action addTroubleReport = async (id: string, data: TroubleReportRequest) => {
    try {
      this.pending = true;
      const result = await api.post<TroubleReportRequestResponse>(`LineFault/TroubleReport/${id}`, data, 'There was an error booking new appointment.');

      if (result instanceof FalconError) {
        throw result;
      }

      this.troubleReportResult = result;

      await this.fetchLineCheck(id);

      return result;
    } finally {
      this.pending = false;
    }
  };

  @action getLineFaultCodes = async () => {
    const result = await api.get<LineFaultCode[]>(`LineFault/FaultCodes`, {
      friendlyErrorMessage: 'There was an error getting Fault Codes.'
    });

    if (result instanceof FalconError) {
      throw result;
    }

    return result;
  };

  @action requestLineCheck = async (cli: string, postcode: string, faultCode: string) => {
    // don't run action if there is already a running task or empty cli
    if (!(!cli || this.pending)) {
      // reset store values on new check request
      this.cleanResults();
      this.pending = true;
      this.isLineFaultFlowStarted = true;

      const lineCheckRequestResults = await api.post<LineCheckRequestResponse>(
        'LineFault/LineFaultCheck',
        { cli, postcode, faultCode },
        'There was an error requesting line check.',
        true
      );

      if (lineCheckRequestResults instanceof FalconError) {
        this.pending = false;

        if (this.isLineFaultFlowStarted) {
          browserHistory.push(`/services/line-faults/error`, { ...lineCheckRequestResults });
        }
      }

      return lineCheckRequestResults;
    }

    throw new Error('Line check cannot run right now');
  };

  @action fetchLineCheck = async (lineFaultId: string) => {
    this.pending = true;
    this.needToSkipEngineerAppointmentStep = false;

    const lineCheckResult = await api.get<LineFaultCheck>(`LineFault/LineFaultCheckStatus/${lineFaultId}`, {
      friendlyErrorMessage: 'There was an error requesting line check status',
      silent: true
    });

    if (lineCheckResult instanceof FalconError) {
      messagesStore.addError(lineCheckResult);

      return;
    }

    this.lineFaultCheck = lineCheckResult;

    if (
      lineCheckResult.status === constants.lineCheckResultStatuses.Finished &&
      lineCheckResult.isFault &&
      lineCheckResult.careLevel === CareLevel.ExtraHigh &&
      !lineCheckResult.troubleReportReference
    ) {
      this.needToSkipEngineerAppointmentStep = true;
    }

    if (lineCheckResult.status === constants.lineCheckResultStatuses.Failed) {
      const formattedError = new FalconError();
      formattedError.errorMessages.push(lineCheckResult.info || 'Something went wrong.');
      messagesStore.addError(formattedError);
    }

    this.pending = false;
  };

  @action cleanResults = () => {
    this.submitTroubleReportAttempted = false;
    this.pending = false;
    this.needToSkipEngineerAppointmentStep = false;
    this.lineFaultCheck = null;
    this.troubleReportResult = null;
    this.isLineFaultFlowStarted = false;
  };
}

export default new LineFaultsStore();
