import { FC } from 'react';
import classNames from 'classnames';
import preloader from 'assets/images/preloader.gif';
import appStyles from 'App.module.scss';

interface IPreloaderProps {
  title?: string;
  titleClass?: string;
  loaderClass?: string;
  id?: string;
}

const Preloader: FC<IPreloaderProps> = ({ title, titleClass, loaderClass, ...rest }) => (
  <>
    {!!title && <p className={titleClass}>{title}</p>}
    <img {...rest} alt="Loading..." className={classNames(loaderClass, appStyles.preloader)} src={preloader} />
  </>
);

export default Preloader;
