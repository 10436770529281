import appStyles from 'App.module.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import styles from './FileUpload.module.scss';

interface IFileMultiUploadProps {
  itemId: number | null;
  passResults: (results: File[]) => void;
  files: File[];
  maxFilesToUpload?: number;
}

@observer
class FileMultiUpload extends Component<IFileMultiUploadProps> {
  fileInputRef: React.RefObject<HTMLInputElement>;
  @observable files: File[] = [];

  constructor(props: IFileMultiUploadProps) {
    super(props);

    this.fileInputRef = React.createRef();

    this.handleFiles = this.handleFiles.bind(this);
    this.clearFile = this.clearFile.bind(this);
  }

  async componentDidUpdate(prevProps: IFileMultiUploadProps) {
    if (this.props.itemId !== prevProps.itemId) {
      this.files = [];

      if (this.fileInputRef.current && this.fileInputRef.current.files) {
        this.fileInputRef.current.value = '';
      }
    }
  }

  handleFiles() {
    if (this.fileInputRef.current && this.fileInputRef.current.files) {
      const fileInputFiles = Array.from(this.fileInputRef.current.files);

      fileInputFiles.map((file: File) => {
        if (this.files.filter((f: File) => f.name === file.name).length === 0) {
          this.files = [...this.files, file];
        }

        return this.files;
      });

      this.fileInputRef.current.value = '';

      this.props.passResults(this.files);
    }
  }

  clearFile(file: File) {
    this.files = this.files.filter((f: File) => f.name !== file.name);
  }

  render() {
    return (
      <div>
        <div>
          <input
            ref={this.fileInputRef}
            type="file"
            className={styles.fileInput__input}
            role="button"
            id="files"
            name="file"
            onChange={() => {
              this.handleFiles();
            }}
            multiple
          />
          <label
            htmlFor="files"
            className={`${styles.fileInput__label} ${appStyles.button} ${appStyles.fileUpload_multiple} ${appStyles.form__input} ${appStyles.button_lg}`}>
            Attach files {this.props.maxFilesToUpload ? `(${this.props.maxFilesToUpload} maximum)` : ''}
          </label>

          {this.files.map((f, index) => (
            <div className={appStyles.files} key={index}>
              <div className={styles.files__left}>
                <p id={`fileDownload_name_${index}`} className={appStyles.files__name}>
                  {f.name}
                </p>
              </div>
              <button title="remove file" className={appStyles.files__delete} type="button" onClick={() => this.clearFile(f)}>
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default FileMultiUpload;
