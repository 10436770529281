import { ChangeEvent, FC, useCallback, useState } from 'react';
import debounce from 'lodash.debounce';
import Alert from 'components/Generic/Alert/Alert';
import Input from 'components/Generic/FormElements/Input/Input';
import Icon from 'components/Generic/Icon/icon';
import appStyles from 'App.module.scss';
import NotificationsGrid from './NotificationsGrid/NotificationsGrid';
import styles from './Notifications.module.scss';

const AllNotifications: FC = () => {
  const [search, setSearch] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');

  const updateSearchDebounced = useCallback(
    debounce((value: string) => setSearch(value), 300),
    []
  );

  const handleSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(event.target?.value);
    updateSearchDebounced(searchInputValue);
  };

  return (
    <div className={appStyles.container}>
      <div className={appStyles.pageHeading_lg}>
        <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
          <Icon icon={Icon.notification} size="heading" color="currentColor" iconName="notification icon" customClass={appStyles.heading__icon} />
          <h1 className={appStyles.heading__text}>Notifications</h1>
        </div>

        <div className={styles.search__container}>
          <Input
            elementId="searchBar"
            inputType="search"
            name="searchBar"
            labelText="Search Notifications"
            labelHidden={true}
            placeholder="Search Notifications"
            value={searchInputValue}
            handleChange={handleSearchInput}
            customLabelClass={appStyles.form__label}
            customInputClass={appStyles.form__input}
            autocomplete="off"
          />
        </div>
      </div>

      <Alert alertType="blank">
        <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>
          View your notifications here. Click on an individual notification to see the full breakdown.
        </p>
        <p className={`${appStyles.text_midBlue} ${appStyles.info__sub}`}>
          Please note, notifications are archived automatically after 30 days. To view archived notifications, click on the three dots next to the Status column
          to adjust the filter.
        </p>
      </Alert>

      <div className={styles.notificationsGrid}>
        <NotificationsGrid searchKeyword={search} />
      </div>
    </div>
  );
};

export default AllNotifications;
