import { ChangeEvent, Component } from 'react';
import { observer } from 'mobx-react';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import userStore from 'stores/user.store';
import appStyles from 'App.module.scss';
import { MarketingPreference } from 'models/api/marketingPreference.model';
import CheckBox from 'components/Generic/FormElements/Checkbox/Checkbox';
import { ListItem } from 'models/generic/listItem.model';
import Radio from 'components/Generic/FormElements/Radio/Radio';
import { observable } from 'mobx';
import Alert from 'components/Generic/Alert/Alert';
import utils from 'utils/utils';
import styles from './EditAccount.module.scss';

interface IEditMarketingPrefenceProps {
  preferences: MarketingPreference[];
  marketingPreferenceUpdated: () => void;
  reviewWarning: string | null;
}

@observer
export class EditMarketingPreference extends Component<IEditMarketingPrefenceProps> {
  // check if subscribed to anything
  @observable unsubscribed =
    this.props.preferences.filter((p) => p.isOptedInForEmail || p.isOptedInForPhone || p.isOptedInForPostal || p.isOptedInForPostal).length === 0;

  @observable changed = false;

  onCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.changed = true;

    const name = e.target.name;
    const preferenceName = name.split('_')[0];
    const method = name.split('_')[1];
    const selected = e.target.checked;

    const preference = this.props.preferences.find((p) => p.displayLabel === preferenceName) as MarketingPreference;
    switch (method) {
      case 'email':
        preference.isOptedInForEmail = selected;
        break;
      case 'phone':
        preference.isOptedInForPhone = selected;
        break;
      case 'sms':
        preference.isOptedInForSms = selected;
        break;
      case 'post':
        preference.isOptedInForPostal = selected;
        break;
    }

    let unsubscribedFromAll = true;

    this.props.preferences.forEach((p) => {
      if (p.isOptedInForEmail || p.isOptedInForPhone || p.isOptedInForPostal || p.isOptedInForSms) {
        unsubscribedFromAll = false;
      }
    });

    this.unsubscribed = unsubscribedFromAll;

    if (unsubscribedFromAll) {
      utils.session.saveToSession('marketingPreferences', 'unsubscribed');
    } else {
      utils.session.saveToSession('marketingPreferences', 'updated');
    }

    this.props.marketingPreferenceUpdated();
  };

  onUnsubscribeChange = (e: ChangeEvent<HTMLInputElement>) => {
    // if unsubscribe then unselect everything
    if (e.target.checked) {
      const confirmed = window.confirm('Are you sure you want to opt out of all marketing communication?');
      if (confirmed) {
        this.changed = true;
        this.unsubscribed = true;

        utils.session.saveToSession('marketingPreferences', 'unsubscribed');

        this.props.preferences.forEach((p) => {
          p.isOptedInForEmail = false;
          p.isOptedInForSms = false;
          p.isOptedInForPostal = false;
          p.isOptedInForPhone = false;
        });
      }
    }
  };

  getSection(heading: string, subheading: string) {
    // get items from a category and sort by display order
    const items = this.props.preferences.filter((p) => p.categoryLabel === heading).sort((a, b) => ((a.displayOrder || 0) > (b.displayOrder || 0) ? 1 : -1));

    return (
      <div className={appStyles.form__row}>
        <Legend type="medium" text={heading} />
        {subheading ? <Legend type="small" text={subheading} customClass={styles.legend_withSpacing} /> : null}

        {items.map((item) => (
          <div className={styles.preference__row} key={item.displayLabel}>
            <Legend type="small" customClass={styles.preference__label} text={item.displayLabel || ''} />

            <div className={styles.preference__checks}>
              {item.appliesToEmail ? (
                <div className={styles.preference__check}>
                  <CheckBox
                    name={`${item.displayLabel}_email`}
                    options={[new ListItem('email', 'Email')]}
                    selectedOptions={item.isOptedInForEmail ? ['email'] : []}
                    handleChange={this.onCheckBoxChange}
                  />
                </div>
              ) : null}

              {item.appliesToPhone ? (
                <div className={styles.preference__check}>
                  <CheckBox
                    name={`${item.displayLabel}_phone`}
                    options={[new ListItem('phone', 'Phone')]}
                    selectedOptions={item.isOptedInForPhone ? ['phone'] : []}
                    handleChange={this.onCheckBoxChange}
                  />
                </div>
              ) : null}

              {item.appliesToSms ? (
                <div className={styles.preference__check}>
                  <CheckBox
                    name={`${item.displayLabel}_sms`}
                    options={[new ListItem('sms', 'SMS')]}
                    selectedOptions={item.isOptedInForSms ? ['sms'] : []}
                    handleChange={this.onCheckBoxChange}
                  />
                </div>
              ) : null}

              {item.appliesToPostal ? (
                <div className={styles.preference__check}>
                  <CheckBox
                    name={`${item.displayLabel}_post`}
                    options={[new ListItem('post', 'Post')]}
                    selectedOptions={item.isOptedInForPostal ? ['post'] : []}
                    handleChange={this.onCheckBoxChange}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    );
  }

  render() {
    if (userStore.currentUser) {
      return (
        <>
          {this.props.reviewWarning ? (
            <div className={appStyles.form__row}>
              <Alert alertType="warning" customClass={styles.changesWarning}>
                {this.props.reviewWarning}
              </Alert>
            </div>
          ) : null}

          {this.changed ? (
            <div className={appStyles.form__row}>
              <Alert alertType="info" customClass={styles.changesWarning}>
                Any changes to marketing preferences may take up to 72 hours.
              </Alert>
            </div>
          ) : null}

          {this.getSection('Service', '')}
          {this.getSection(
            'Updates, Alerts and Insights',
            `Tell us what topics you're interested in and get the latest news, product updates, guides, 
            events and webinars from our experts and vendor partners.`
          )}

          <div className={appStyles.form__row}>
            <Legend type="medium" text="Unsubscribe from all" />
            <Legend
              type="small"
              text="Operational updates will continue to be sent by email to the Primary Account address"
              customClass={styles.legend_withSpacing}
            />
            <Radio name="unsubscribe" options={[new ListItem(true, 'Opt Out')]} selectedOption={this.unsubscribed} handleChange={this.onUnsubscribeChange} />
          </div>
        </>
      );
    }
  }
}
