import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Confirmation } from 'components/Generic/Confirmation/Confirmation';
import Button from 'components/Generic/FormElements/Button/Button';
import Icon from 'components/Generic/Icon/icon';
import { IBaseProps } from 'models/generic/iBaseProps';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class NewContactConfirmation extends Component<IBaseProps> {
  back = () => {
    this.props.history.push('/account/contacts');
  };

  render() {
    return (
      <>
        <Confirmation
          mainMessage="New Contact Added Successfully."
          subMessages={['If you would like to edit this contact please select edit on the contact within the Manage Contacts section of Customer Portal.']}
          containerId="account_success"
          icon={Icon.success}
          iconText="New Contact Added"
        />

        <BottomButtonContainer backgroundColor="white" layout="right">
          <Button buttonStyle="outline_secondary" size="lg" handleClick={this.back}>
            Back to Manage Contacts
          </Button>
        </BottomButtonContainer>
      </>
    );
  }
}

export default withRouter(NewContactConfirmation);
