import appStyles from 'App.module.scss';
import classNames from 'classnames';
import ValidationArea from 'components/Generic/FormElements/ValidationArea/ValidationArea';
import Icon from 'components/Generic/Icon/icon';
import { FalconFile } from 'models/generic/falconFile.model';
import { ValidationError } from 'models/generic/validationError.model';
import Upload from 'rc-upload';
import { Component } from 'react';

interface IFileUploadProps {
  /**
   * @property {string} name Set the name attribute
   */
  name: string;
  /**
   * @property {string} title Set the title attribute
   */
  title?: string;
  /**
   * @property {boolean} required Optional. Set the field to required
   */
  required?: boolean;
  /**
   *@property {string} labelText Set the text to appear in the label
   */
  labelText: string;
  /**
   *@property {string} elementId Set the ID attribute
   */
  elementId: string;
  /**
   *@property {FalconFile} value Set the value attribute
   */
  value?: FalconFile | null;
  /**
   *@property {string} placeholder Set the placeholder
   */
  placeholder: string;
  /**
   *@property {boolean} labelHidden Optional. Hide the label. labelText will be used for screenreaders. Default is false.
   */
  labelHidden?: boolean;
  /**
   *@property {(fileString: string) => void} handleChange Set the action onChange
   */
  handleChange: (falconFile: FalconFile | null, file: File | null) => void;
  /**
   * @property {string} customInputClass Optional. Use a custom class on the input - use styles from the current component or appStyles
   */
  customInputClass?: string;
  /**
   * @property {string} customLabelClass Optional. Use a custom class on the label - use styles from the current component or appStyles
   */
  customLabelClass?: string;
  /**
   * @property {ValidationError} validationError Optional. Any validation messages related to this field
   */
  validationError?: ValidationError;
}

/**
 * @description Add a file upload element with a label. Optional properties: customInputClass, customLabelClass,
 * title, required, validationError
 */
class FileUpload extends Component<IFileUploadProps> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  instance: any | null = null;

  constructor(props: IFileUploadProps) {
    super(props);

    this.clearFile = this.clearFile.bind(this);
  }

  clearFile(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();

    this.props.handleChange(null, null);
  }

  render() {
    return (
      <>
        <label
          htmlFor={this.props.name}
          className={classNames(
            appStyles.form__label,
            { [appStyles.form__label_invalid]: this.props.validationError && this.props.validationError.messages.length > 0 }, // add invalid class if errors
            { [this.props.customLabelClass as string]: this.props.customLabelClass }, // add custom class if defined
            { [appStyles.srOnly]: this.props.labelHidden } // add srOnly class if label hidden
          )}>
          {this.props.labelText}
        </label>

        <div className={appStyles.form__fileUpload_div}>
          <Upload
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ref={(ref: any) => {
              this.instance = ref;
            }}
            onStart={(file: File) => {
              this.instance.abort(file);

              const reader = new FileReader();

              reader.onload = () => {
                const data = reader.result as string;
                const falconFile = new FalconFile(file.name, data);
                this.props.handleChange(falconFile, file);
              };

              reader.readAsDataURL(file);
            }}>
            <input
              className={classNames(
                appStyles.form__input,
                appStyles.form__input_text,
                appStyles.form__input_fileUpload,
                { [appStyles.form__input_invalid]: this.props.validationError && this.props.validationError.messages.length > 0 }, // invalid class if errors
                { [this.props.customInputClass as string]: this.props.customInputClass } // add custom class if defined
              )}
              id={this.props.name}
              name={this.props.name}
              type="text"
              value={this.props.value ? this.props.value.name : ''}
              placeholder={this.props.placeholder}
              title={this.props.title || 'Click to upload a file'}
              required={this.props.required || undefined}
              readOnly={true}
              aria-required={this.props.required || undefined}
            />
          </Upload>

          {this.props.value ? (
            <button onClick={this.clearFile} className={appStyles.form__fileUpload_button}>
              <Icon icon={Icon.cross} iconName="Remove selected file" color="darkSkyBlue" size="xs" />
            </button>
          ) : (
            <Icon customClass={appStyles.fileUpload__icon} icon={Icon.plus} iconName="Add a file" color="darkSkyBlue" size="xs" />
          )}

          <ValidationArea sectionId={this.props.elementId} validationError={this.props.validationError}></ValidationArea>
        </div>
      </>
    );
  }
}

export default FileUpload;
