import { ChangeEvent, FC } from 'react';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import ValidationArea from 'components/Generic/FormElements/ValidationArea/ValidationArea';
import { ListItem } from 'models/generic/listItem.model';
import { ValidationError } from 'models/generic/validationError.model';
import appStyles from 'App.module.scss';

interface ICheckBoxGroupProps extends ICheckBoxProps {
  /**
   * @property {ListItem[]} options Set the available options
   */
  options: ListItem[];
}

interface ICheckBoxProps {
  /**
   * @property {string} name Set the name attribute
   */
  name: string;
  /**
   *@property {(event: ChangeEvent<HTMLInputElement>) => void} handleChange Set the action onChange
   */
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  /**
   * @property {string} selectedOptions Set the selected options
   */
  selectedOptions: number[] | string[] | boolean[];
  /**
   * @property {string} customInputClass Optional. Use a custom class on the input - use styles from the current component or appStyles
   */
  customInputClass?: string;
  /**
   * @property {string} customLabelClass Optional. Use a custom class on the label - use styles from the current component or appStyles
   */
  customLabelClass?: string;
  /**
   * @property {string} customSelectedLabelClass Optional. Use a custom class on the selected label - use styles from the current component or appStyles
   */
  customSelectedLabelClass?: string;
  /**
   * @property {string} customDisabledLabelClass Optional. Use a custom class on the disabled label - use styles from the current component or appStyles
   */
  customDisabledLabelClass?: string;
  /**
   * @property {string} customLabelClass Optional. Use a custom class on the label - use styles from the current component or appStyles
   */
  customContainerClass?: string;
  /**
   * @property {ValidationError} validationError Optional. Any validation messages related to this field
   */
  validationError?: ValidationError;
  /**
   * @property {boolean} disabled Optional. Set the checkbox to disabled
   */
  disabled?: boolean;
  /**
   * @property {boolean} className Optional. Set the checkbox div wrapper class
   */
  className?: string;
  /**
   * @property {() => React.ReactNode} renderInnerComponent Optional. Renders inner component with text
   */
  renderInnerComponent?: (text: string) => React.ReactNode;
}

function CheckBoxItem({
  id,
  name,
  text,
  renderInnerComponent,
  visible,
  disabled,
  className,
  handleChange,
  validationError,
  selectedOptions,
  customInputClass,
  customLabelClass,
  customDisabledLabelClass,
  customSelectedLabelClass
}: ICheckBoxProps & ListItem) {
  const uniqueId = name + id;
  const errorExists = validationError && validationError.messages.length > 0;
  const selected = selectedOptions.some((selectedId: number | string | boolean) => selectedId.toString() === id.toString());

  return (
    <div className={className}>
      <input
        className={classNames(
          appStyles.checkbox,
          { [appStyles.hidden]: !visible },
          { [appStyles.form__input_invalid]: errorExists }, // add invalid class if errors
          { [customInputClass as string]: customInputClass } // add custom class if defined
        )}
        name={name}
        id={uniqueId}
        type="checkbox"
        checked={selected}
        disabled={disabled}
        value={id as string}
        onChange={handleChange}
        aria-checked={selected}
      />
      <label
        htmlFor={uniqueId}
        className={classNames(
          appStyles.checkbox__label,
          { [appStyles.hidden]: !visible },
          { [customSelectedLabelClass as string]: selected },
          { [customDisabledLabelClass as string]: disabled },
          { [customLabelClass as string]: customLabelClass }, // add custom class if defined
          { [appStyles.checkbox__label_invalid]: errorExists } // add invalid class if errors
        )}>
        {visible && renderCheckBoxInnerItem(text, renderInnerComponent)}
      </label>
    </div>
  );
}

const renderCheckBoxInnerItem = (text: string, renderInnerComponent?: (text: string) => React.ReactNode) =>
  renderInnerComponent ? renderInnerComponent(text) : <span>{ReactHtmlParser(text)}</span>;

/**
 * @description Add an checkbox group. Optional properties: customInputClass, customLabelClass, validationError
 */
const CheckBox: FC<ICheckBoxGroupProps> = ({ options, className, disabled, ...props }) => {
  const singleMode = options.length === 1;

  return (
    <>
      {options.map((option) => (
        <CheckBoxItem
          key={option.id as string}
          {...props}
          {...option}
          disabled={disabled ?? option.disabled}
          className={classNames(className, { [appStyles.checkbox__wrapper]: !singleMode }, { [appStyles.checkbox__wrapper_single]: singleMode })}
        />
      ))}

      <ValidationArea sectionId={props.name} validationError={props.validationError} />
    </>
  );
};

export default CheckBox;
