import { FC, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { observer } from 'mobx-react';
import { IBaseProps } from 'models/generic/iBaseProps';
import notificationsStore from 'stores/notifications.store';
import styles from './NotificationCards.module.scss';
import NotificationCardsItem from './NotificationCardsItem';

const NotificationCards: FC<IBaseProps> = () => {
  const viewNotification = useCallback((notificationId: number | null) => {
    notificationsStore.removeNotificationToDisplay(notificationId);

    if (notificationId) {
      notificationsStore.markAsRead(notificationId);
    }
  }, []);

  return (
    <TransitionGroup className={styles.container}>
      {notificationsStore.notificationsToDisplay.map((notification) => (
        <NotificationCardsItem
          item={notification}
          onViewClick={viewNotification}
          onCloseClick={notificationsStore.removeNotificationToDisplay}
          key={notification.notificationId}
        />
      ))}
    </TransitionGroup>
  );
};

export default withRouter(observer(NotificationCards));
