import { FC, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { match as matchInterface, useHistory } from 'react-router-dom';
import notificationsStore from 'stores/notifications.store';
import { NotificationStatuses } from 'utils/enums';
import Icon from 'components/Generic/Icon/icon';
import LoadingWrapper from 'components/Generic/LoadingWrapper/LoadingWrapper';
import BottomContainer from 'components/Notifications/NotificationBreakdown/BottomContainer';
import NotificationInfo from 'components/Notifications/NotificationBreakdown/NotificationInfo';
import NotificationsGrid from 'components/Notifications/NotificationsGrid/NotificationsGrid';
import appStyles from 'App.module.scss';
import styles from '../Notifications.module.scss';

interface INotificationViewProps {
  match: matchInterface<{ id?: string }>;
}

const NotificationView: FC<INotificationViewProps> = ({ match }) => {
  const history = useHistory();

  const backToNotifications = useCallback(() => {
    history.push('/notifications');
  }, [history]);

  const notification = notificationsStore.lastFetchedNotification;

  useEffect(() => {
    const notificationsId = match.params.id && parseInt(match.params.id, 10);

    if (notificationsId && notificationsStore.lastFetchedNotification?.notificationId !== notificationsId) {
      notificationsStore.getNotification(notificationsId).then(() => {
        const lastFetchedNotification = notificationsStore.lastFetchedNotification;

        if (lastFetchedNotification?.notificationId && lastFetchedNotification.status === NotificationStatuses[NotificationStatuses.Unread]) {
          notificationsStore.markAsRead(lastFetchedNotification.notificationId);
        }
      });
    }
    if (notification?.notificationId && notification?.status === NotificationStatuses[NotificationStatuses.Unread]) {
      notificationsStore.markAsRead(notification.notificationId);
    }
  }, [match.params.id, notification]);

  return (
    <div className={appStyles.container}>
      <div className={classNames(appStyles.pageHeading_lg)}>
        <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
          <Icon icon={Icon.notification} size="heading" color="currentColor" iconName="notification icon" customClass={appStyles.heading__icon} />
          <h1 className={appStyles.heading__text}>Notifications</h1>
        </div>
      </div>
      <div className={styles.notificationsGrid}>{<NotificationsGrid notificationsToShow={notification ? [notification] : []} />}</div>
      <NotificationInfo notification={notification} />

      <BottomContainer
        notification={notification}
        setArchived={notificationsStore.archive}
        setUnArchived={notificationsStore.unarchive}
        backToNotifications={backToNotifications}
      />
      <LoadingWrapper id="notification-loading" title="" visible={notificationsStore.pending} />
    </div>
  );
};

export default observer(NotificationView);
