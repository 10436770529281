// base model for requests made to API for paged data
export class BaseGridRequest {
  pageNumber = 1; // set to 0 to disable paging and get all results
  pageSize = 100;
  sortBy = '';
  searchKeyword = '';

  constructor(pageSize: number) {
    this.pageSize = pageSize;
  }

  getRequestAsQueryString(): string {
    let qs = '';

    for (const prop in this) {
      if (Object.prototype.hasOwnProperty.call(this, prop)) {
        if (Array.isArray(this[prop])) {
          const array = this[prop] as unknown as [];
          for (let i = 0; i < array.length; i++) {
            qs += prop + '=' + array[i] + '&';
          }
        } else {
          qs += prop + '=' + this[prop] + '&';
        }
      }
    }

    return qs;
  }
}
