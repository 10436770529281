import { FC } from 'react';
import classNames from 'classnames';
import InvoiceDetail from 'components/Invoices/Invoices/InvoiceDetails/InvoiceDetail';
import FileDownloads from 'components/Generic/FormElements/FileDownloads/FileDownloads';
import CardRow from 'components/Invoices/Invoices/InvoiceDetails/CardRow';
import utils from 'utils/utils';
import { Location } from 'models/api/location.model';
import { Invoice } from 'models/api/invoice.model';
import appStyles from 'App.module.scss';
import styles from './InvoiceDetails.module.scss';

interface IInvoiceDetailsProps {
  invoice: Invoice;
  downloadPath: string;
}

const InvoiceDetails: FC<IInvoiceDetailsProps> = ({ invoice, downloadPath }) => (
  <div className={classNames(styles.invoicesSection, appStyles.row)}>
    <InvoiceDetail title="Payment Details">
      <CardRow label={'Bank'}>{invoice.paymentDetails.bankName}</CardRow>
      <CardRow label={'Sort Code'}>{invoice.paymentDetails.bankSortCode}</CardRow>
      <CardRow label={'Account Number'}>{invoice.paymentDetails.bankAccountNumber}</CardRow>
      <CardRow label={'Net'}>{utils.currency.formatNumberAsCurrency(invoice.paymentDetails.net)}</CardRow>
      <CardRow label={'VAT'}>{utils.currency.formatNumberAsCurrency(invoice.paymentDetails.vat)}</CardRow>
      <CardRow label={'Gross'}>{utils.currency.formatNumberAsCurrency(invoice.paymentDetails.gross)}</CardRow>
    </InvoiceDetail>
    <InvoiceDetail title="Delivery Details">
      <CardRow label={'Delivery Address'}>{Location.getFormattedAsMultilineHtml(invoice.deliveryAddress)}</CardRow>
      <CardRow label={'Invoice To'}>{Location.getFormattedAsMultilineHtml(invoice.invoiceAddress)}</CardRow>
    </InvoiceDetail>
    <InvoiceDetail title="Download">
      <CardRow>
        <FileDownloads dataType="invoice" itemId={downloadPath} invoice={invoice}></FileDownloads>
      </CardRow>
    </InvoiceDetail>
    {!!invoice.companyDetails?.companyName && (
      <InvoiceDetail title="Delivery Details">
        <CardRow label="Company Details">
          <p className={appStyles.text_marginBlue}>{invoice.companyDetails.companyName}</p>
          <p className={appStyles.text_marginBlue}>
            {invoice.companyDetails.countryCode} {invoice.companyDetails.taxRegistrationNumber}
          </p>
          <p className={appStyles.text_marginBlue}>
            Registered in {invoice.companyDetails.countryOfRegistration}, Company Number {invoice.companyDetails.companyRegistrationNumber}
          </p>
          {!!invoice.companyDetails.webSite && (
            <a href={invoice.companyDetails.webSite} target="_blank" rel="noopener noreferrer" title="Company website">
              {invoice.companyDetails.webSite}
            </a>
          )}
        </CardRow>
        <CardRow label={'Company Address'}>{Location.getFormattedAsMultilineHtml(invoice.companyDetails.address)}</CardRow>
      </InvoiceDetail>
    )}
  </div>
);

export default InvoiceDetails;
