import { withRouter } from 'react-router-dom';
import { Component } from 'react';
import classNames from 'classnames';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Confirmation } from 'components/Generic/Confirmation/Confirmation';
import Button from 'components/Generic/FormElements/Button/Button';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import Icon from 'components/Generic/Icon/icon';
import { PaymentResponse } from 'models/api/paymentResponse.model';
import { PaymentResult } from 'models/api/paymentResult.model';
import { FalconError } from 'models/generic/falconError.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import utils from 'utils/utils';
import invoicesStore from 'stores/invoices.store';
import appStyles from 'App.module.scss';
import preloader from 'assets/images/preloader.gif';

@observer
export class PaymentSucceeded extends Component<IBaseProps> {
  @observable paymentResult = new PaymentResult();
  @observable storedPaymentIds: string[] | null = null;
  @observable showErrorMessage = false;
  @observable resultLoading = true;
  @observable paymentResponse = new PaymentResponse();

  constructor(props: IBaseProps) {
    super(props);

    this.sendPaymentResult = this.sendPaymentResult.bind(this);
  }

  componentDidMount() {
    utils.session.removeFromSession('unpaidInvoices');
    utils.session.removeFromSession('paymentDetails');
    utils.session.removeFromSession('invoiceNumbers');

    this.storedPaymentIds = utils.session.getFromSession('paymentIds');

    this.sendPaymentResult();
  }

  async sendPaymentResult() {
    const params = new URLSearchParams(window.location.search);

    const paymentId = params.get('paymentId');
    const crypt = params.get('crypt');

    if (!paymentId) {
      this.resultLoading = false;

      // don't do anything if no paymentid param
      return;
    }

    this.paymentResult.paymentId = paymentId;
    this.paymentResult.ipAddress = await utils.ip.getCurrentIpAddress();
    this.paymentResult.crypt = crypt;

    params.delete('crypt');
    params.delete('paymentId');

    this.props.history.replace(window.location.pathname);

    if (this.storedPaymentIds && this.storedPaymentIds.includes(paymentId)) {
      this.resultLoading = false;

      return;
    }

    const result = await invoicesStore.completePaymentTransaction(this.paymentResult);

    if (result instanceof FalconError) {
      this.showErrorMessage = true;
    } else {
      this.paymentResponse = result;
    }

    this.resultLoading = false;

    // save payment id to session to ensure completion isn't logged multiple times
    let updatedPaymentIds: string[];
    if (this.storedPaymentIds) {
      this.storedPaymentIds.push(this.paymentResult.paymentId);
      updatedPaymentIds = this.storedPaymentIds;
    } else {
      updatedPaymentIds = [this.paymentResult.paymentId];
    }

    utils.session.saveToSession('paymentIds', updatedPaymentIds);
  }

  render() {
    return (
      <>
        {this.resultLoading === true ? (
          <div>
            <img alt="Loading..." className={appStyles.preloader} src={preloader} />
          </div>
        ) : this.showErrorMessage ? (
          <Confirmation
            mainMessage="Thank you, your payment has been received. A copy of your receipt has been emailed to you."
            subMessages={['Please note, there may be a delay before the invoice(s) show as Paid on your account.']}
            containerId="payment_succeeded"
            icon={Icon.success}
            iconText="Success"
            iconName="success icon"
          />
        ) : (
          <>
            <Confirmation
              mainMessage="Thank you, your payment has been received. A copy of your receipt has been emailed to you."
              subMessages={[
                // eslint-disable-next-line max-len
                'Please note, there may be a delay before the invoice(s) show as Paid on your account. Details of this transaction can be found below. We recommend making a note of them before navigating away from this page.'
              ]}
              containerId="payment_succeeded"
              icon={Icon.success}
              iconText="Success"
              iconName="success icon"
            />
            <div className={`${appStyles.paddingTop} ${appStyles.container}`}>
              <div className={appStyles.row}>
                <div className={appStyles.col_md_6}>
                  <div id="paymentResponseCard" className={classNames(appStyles.card, appStyles.card_form)}>
                    <Legend type="large" text="Transaction Details" />
                    <div className={appStyles.form__row}>
                      <div className={appStyles.form__label}>Date</div>
                      <div className={appStyles.form__readOnlyElement} id="paymentDate">
                        {this.paymentResponse.paymentDate}
                      </div>
                    </div>
                    <div className={appStyles.form__row}>
                      <div className={appStyles.form__label}>Transaction ID</div>
                      <div className={appStyles.form__readOnlyElement} id="vendorTxCode">
                        {this.paymentResponse.vendorTxCode}
                      </div>
                    </div>
                    <div className={appStyles.form__row}>
                      <div className={appStyles.form__label}>Amount</div>
                      <div className={appStyles.form__readOnlyElement} id="paymentAmount">
                        £{this.paymentResponse.amount}
                      </div>
                    </div>
                    <div className={appStyles.form__row}>
                      <div className={appStyles.form__label}>Payment Type</div>
                      <div className={appStyles.form__readOnlyElement} id="cardType">
                        {this.paymentResponse.cardType}
                      </div>
                    </div>
                    <div className={appStyles.form__row}>
                      <div className={appStyles.form__label}>Authorisation Code</div>
                      <div className={appStyles.form__readOnlyElement} id="txAuthNo">
                        {this.paymentResponse.txAuthNo}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <BottomButtonContainer backgroundColor="white" layout="right">
          <Button buttonStyle="outline_secondary" size="lg" handleClick={() => this.props.history.push('/invoices')}>
            Back to Invoices
          </Button>
        </BottomButtonContainer>
      </>
    );
  }
}

export default withRouter(PaymentSucceeded);
