import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import Icon from 'components/Generic/Icon/icon';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Component, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import userStore from 'stores/user.store';
import constants from 'utils/constants';
import utils from 'utils/utils';

@observer
export class AccessDenied extends Component {
  @observable mode: 'hasNoRoles' | 'generic' | 'pendingValidation' | 'lockedOut' = 'generic';
  @observable statusMessage: ReactNode | null = null;

  async componentDidMount() {
    // if user is not logged in it means they have been logged out due to lack of roles
    if (userStore.currentUser === null) {
      this.mode = 'hasNoRoles';
      const savedMode: 'hasNoRoles' | 'generic' | 'pendingValidation' | 'lockedOut' | null = utils.session.getFromSession('noAccessMode');
      if (savedMode) {
        this.mode = savedMode;
        utils.session.removeFromSession('noAccessMode');
      }
    }

    // if user is logged in and they have MyPortalNoAccess role then log them out and take them back to this page unathenticated
    if (userStore.currentUser) {
      if (userStore.currentUser.roles.length === 0 || userStore.currentUser.roles.includes(constants.roleNames.MyPortalNoAccess)) {
        if (userStore.currentUser.roles.includes(constants.roleNames.MyPortalPendingValidation)) {
          this.mode = 'pendingValidation';
        } else if (userStore.currentUser.roles.includes(constants.roleNames.MyPortalLockedOut)) {
          this.mode = 'lockedOut';
        } else {
          this.mode = 'hasNoRoles';
        }

        userStore.logOut({ logOutFromNoAccess: true, noAccessMode: this.mode });
      }
    }
    this.setMessage();
  }

  setMessage() {
    switch (this.mode) {
      case 'hasNoRoles':
        this.statusMessage = (
          <Alert alertType="blank" customClass="accessDeniedText">
            <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>
              We could not find any associated account information relevant to the email address you have logged in with.
            </p>
            <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>
              Your account needs to be verified. Please call us on {constants.keyVariables.helpdeskTelephone}, select the Customer Service option #1 from the
              main menu, followed by the Customer Portal option #3.
            </p>
            <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>
              <Link to="/login">Click here to log in again once your account has been updated.</Link>
            </p>
          </Alert>
        );
        break;
      case 'pendingValidation':
        this.statusMessage = (
          <Alert alertType="blank" customClass="accessDeniedText">
            <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>Your account has not been validated.</p>
            <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>
              We have notified your Customer Portal Administrator of your login attempt. If you need any further assistance, ask your Customer Portal
              Administrator or contact Chess at <a href={`mailto:${constants.keyVariables.registrationEmail}`}>{constants.keyVariables.registrationEmail}</a>.
            </p>
            <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>
              <Link to="/login">Click here to log in again once your account has been updated.</Link>
            </p>
          </Alert>
        );
        break;
      case 'lockedOut':
        this.statusMessage = (
          <Alert alertType="blank" customClass="accessDeniedText">
            <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>Your account has been locked out.</p>
            <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>
              We have notified your Customer Portal Administrator of your login attempt. If you need any further assistance, ask your Customer Portal
              Administrator or contact Chess at <a href={`mailto:${constants.keyVariables.registrationEmail}`}>{constants.keyVariables.registrationEmail}</a>.
            </p>
            <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>
              <Link to="/login">Click here to log in again once your account has been updated.</Link>
            </p>
          </Alert>
        );
        break;
      case 'generic':
      default:
        this.statusMessage = (
          // generic message when trying to access page that requires a role user is missing
          <Alert alertType="blank" customClass="accessDeniedText">
            <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>
              Sorry, but your sign in does not have access to that section. Please contact your account manager for further assistance.
            </p>
          </Alert>
        );
    }
  }

  render() {
    return (
      <div className={appStyles.container}>
        <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
          <Icon icon={Icon.warning} size="heading" color="currentColor" iconName="warning icon" customClass={appStyles.heading__icon} />
          <h1 className={appStyles.heading__text}>{this.mode === 'generic' ? 'Additional Permissions Required' : 'Request Access'}</h1>
        </div>
        <div id="accessDeniedMessage">{this.statusMessage}</div>
      </div>
    );
  }
}
