import { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import InvoiceDetails from 'components/Invoices/Invoices/InvoiceDetails/InvoiceDetails';
import InvoicesBreakdownGrid from 'components/Invoices/Invoices/InvoicesBreakdownGrid/InvoicesBreakdownGrid';
import { Invoice } from 'models/api/invoice.model';
import appStyles from 'App.module.scss';
import styles from '../ManageInvoices.module.scss';

interface IInvoicesBreakdownProps {
  invoice: Invoice;
}

@observer
export class InvoicesBreakdown extends Component<IInvoicesBreakdownProps> {
  @observable invoice: Invoice = this.props.invoice;
  @observable downloadPath = '';

  constructor(props: IInvoicesBreakdownProps) {
    super(props);

    this.setDownloadPath();
  }

  setDownloadPath() {
    const year = (this.invoice.dateIssued as Date).getFullYear();
    const month = (this.invoice.dateIssued as Date).getMonth() + 1;
    const yearAndMonth = month < 10 ? `${year}/0${month}` : `${year}/${month}`;

    this.downloadPath = `${this.invoice.accountNumber}/${yearAndMonth}`;
  }

  render() {
    return (
      <div className={`${styles.invoicesSection} ${appStyles.container}`} id="invoiceBreakdownSection">
        <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
          <h3 className={appStyles.heading__text_sm}>Invoice Breakdown</h3>
        </div>
        <div className={styles.invoicesBreakdownGrid}>
          <InvoicesBreakdownGrid invoice={this.invoice} />
        </div>
        <InvoiceDetails invoice={this.invoice} downloadPath={this.downloadPath} />
      </div>
    );
  }
}
