import { FC, MouseEventHandler } from 'react';
import classNames from 'classnames';
import { ReactComponent as ChevronRight } from 'assets/images/svg/chevronRight.svg';
import styles from './Ad.module.scss';

interface IArrowProps {
  onClick?: MouseEventHandler;
  direction: 'left' | 'right';
}

const Arrow: FC<IArrowProps> = ({ direction, onClick }) => (
  <ChevronRight onClick={onClick} className={classNames(direction === 'left' ? styles.arrow_left : styles.arrow_right, styles.arrow)} />
);

export default Arrow;
