import { FC } from 'react';
import Icon from 'components/Generic/Icon/icon';
import styles from './Header.module.scss';

interface IMobileControlsProps {
  onClose: React.MouseEventHandler;
  onBack: React.MouseEventHandler;
}

const MobileControls: FC<IMobileControlsProps> = ({ onBack, onClose }) => (
  <div className={styles.mobile_buttons__container}>
    <button onClick={onBack} className={styles.accountNav__back}>
      <Icon icon={Icon.arrowLeft} color="darkSkyBlue" size="md" iconName="back to menu" />
    </button>
    <button onClick={onClose} className={styles.accountNav__close}>
      <Icon icon={Icon.cross} color="darkSkyBlue" size="md" iconName="close menu" />
    </button>
  </div>
);

export default MobileControls;
