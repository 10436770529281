import { FC, MouseEventHandler } from 'react';
import classNames from 'classnames';
import Icon from 'components/Generic/Icon/icon';
import engineerAppointmentStyles from './EngineerAppointment.module.scss';

interface ICarouselArrowProps {
  direction: 'right' | 'left';
  className?: string;
  onClick?: MouseEventHandler;
}

// TODO: Add chevron left icon to Icon component
const CarouselArrow: FC<ICarouselArrowProps> = ({ className, direction, onClick }) => (
  <div onClick={onClick} role="button">
    <Icon
      size="xl"
      color="darkSkyBlue"
      iconName="carouselArrow"
      icon={Icon.chevronRight}
      customClass={classNames(
        className,
        { [engineerAppointmentStyles.icon__left]: direction === 'left' },
        { [engineerAppointmentStyles.icon__right]: direction === 'right' }
      )}
    />
  </div>
);

export default CarouselArrow;
