export class FalconFile {
  name: string;
  contents: string; // base64 string
  url: string | null = null;

  constructor(name: string, contents: string, url: string | null = null) {
    this.name = name;
    this.contents = contents;
    this.url = url;
  }
}
