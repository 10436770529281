import { BaseModel } from 'models/api/base.model';
import { Contract } from 'models/api/contract.model';
import { ContractItem } from 'models/api/contractItem.model';

export class Ticket extends BaseModel {
  supportTicketId: number | null = null;
  rowVersion: unknown = null;
  clientId: number | null = null;
  addressId: number | null = null;
  contactId: number | null = null;
  contactName: string | null = null;
  handler: number | null = null;
  time: string | number | Date = new Date();
  notes: string | null = null;
  message: string | null = null;
  assignedTo: number | null = null;
  assignedToName: string | null = null;
  affectedUsers: number | null = null;
  handled: boolean | null = null;
  status: string | null = null;
  statusId: number | null = null;
  subject: string | null = null;
  lastUpdated: string | null = null;
  timeTaken: string | null = null;
  details: string | null = null;
  priorityId: number | null = null;
  priority: string | null = null;
  errorcodes: string | null = null;
  category: string | null = null;
  relatedDevice: string | null = null;
  contract: string | null = null;
  contractCover: number | null = null;
  contractItemId: number | null = null;
  contractList: Contract[] | null = null;
  deviceList: ContractItem[] | null = null;
  formattedNotes: string[] | null = null;
  attachedFileNames: string[] | null = null;

  static getAllSearchableFieldsAsString(t: Ticket): string {
    return (
      '' +
      t.supportTicketId +
      ',' +
      t.clientId +
      ',' +
      t.addressId +
      ',' +
      t.contactId +
      ',' +
      t.handler +
      ',' +
      t.time +
      ',' +
      t.notes +
      ',' +
      t.status +
      ',' +
      t.subject +
      ',' +
      t.assignedTo +
      ',' +
      t.priority +
      ',' +
      t.errorcodes
    );
  }
}
