import { AxiosResponse } from 'axios';
import { ValidationRule } from 'models/api/validationRule.model';
import { ValidationError } from 'models/generic/validationError.model';
import messagesStore from 'stores/messages.store';
import constants from 'utils/constants';
import { ValidationMessage } from './validationMessage.model';

export class FalconError {
  data: unknown;
  code: number | undefined;
  statusText: string | undefined;
  errorMessages: string[];
  concurrencyMessage: string;
  validationMessages: ValidationError[];

  constructor(error?: AxiosResponse, silent?: boolean) {
    this.errorMessages = [];
    this.validationMessages = [];
    this.concurrencyMessage = '';

    if (error) {
      this.code = error.status;
      this.statusText = error.statusText;
      this.data = error.data;

      if (this.code === 400 && error.data) {
        // for BadRequest data property will be an object with brokenRulesCollection
        if (error.data.brokenRulesCollection?.length > 0) {
          (error.data.brokenRulesCollection as []).forEach((element: ValidationRule) => {
            if (element.property) {
              const errorObject: ValidationError = {
                fieldName: element.property.charAt(0).toLowerCase() + element.property.slice(1),
                messages: [new ValidationMessage(element.description || 'Value is invalid', element.severity || constants.validation.severity.error)]
              };
              this.addValidationErrorFromObject(errorObject);
            }
          });
        } else if ((error.data as string).endsWith('by another user.')) {
          // add to falcon error so we can provide more information on concurrency errors in the component
          this.concurrencyMessage = error.data;
        } else if (!silent) {
          messagesStore.addErrorAsString(error.data);
        }
      }
    }
  }

  addValidationErrorFromObject(item: ValidationError) {
    const validationError = new ValidationError(item.fieldName || '');
    item.messages.forEach((message: ValidationMessage) => {
      validationError.messages.push(message);
    });

    this.validationMessages.push(validationError);
  }
}
