import { ComponentType } from 'react';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridColumn, GridColumnMenuFilter, GridColumnMenuProps } from '@progress/kendo-react-grid';

export interface IColumn {
  field: string;
  title: string;
  dataType: 'text' | 'date' | 'numeric' | 'currency' | 'icon';
  size: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  sortable?: boolean; // defaults to true
  isHtml?: boolean; // defaults to false
  minResizableWidth?: number; // defaults to 40
  columnMenu?: ComponentType<GridColumnMenuProps>; // optional
  currentFilter?: CompositeFilterDescriptor; // optional
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cell?: (props: any) => JSX.Element; // optional
}

export class ColumnHelper {
  static isColumnActive(field: string, filter: CompositeFilterDescriptor | undefined) {
    return GridColumnMenuFilter.active(field, filter);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getCellContentsAsHtml(column: IColumn, dataItem: any) {
    return <td className={classNames('k-table-td', `gridColumn_${column.size}`)}>{ReactHtmlParser(dataItem[column.field])}</td>;
  }

  static getFormat(dataType: string): string | undefined {
    let format: string | undefined;

    switch (dataType) {
      case 'date':
        format = '{0:dd/MM/yyyy}';
        break;
      case 'numeric':
        format = '{0:n2}';
        break;
      case 'currency':
        format = '£{0:n2}';
        break;
    }

    return format;
  }

  static getFilter(dataType: string): 'text' | 'date' | 'numeric' | 'boolean' | undefined {
    let filter: 'text' | 'date' | 'numeric' | 'boolean' | undefined;

    switch (dataType) {
      case 'text':
        filter = 'text';
        break;
      case 'date':
        filter = 'date';
        break;
      case 'numeric':
      case 'currency':
        filter = 'numeric';
        break;
      case 'icon':
        filter = undefined;
        break;
    }

    return filter;
  }

  static getMinResizableWidth(size: string): number {
    let width = 40;

    switch (size) {
      case 'xs':
        width = 40;
        break;

      case 'sm':
      case 'md':
      case 'lg':
        width = 80;
        break;

      case 'xl':
        width = 120;
        break;
    }

    return width;
  }

  static getGridColumns(columns: Array<IColumn | undefined>): JSX.Element[] {
    const array: JSX.Element[] = [];

    columns.forEach((column) => {
      if (column) {
        array.push(
          <GridColumn
            key={column.field}
            field={column.field}
            className={classNames(`gridColumn_${column.size}`, { gridColumn_currency: column.dataType === 'currency' })}
            headerClassName={classNames(
              `gridColumn_${column.size}`,
              { active: this.isColumnActive(column.field, column.currentFilter) }, // add active conditionally
              { text_center: column.dataType === 'icon' } // add text_center for icon columns
            )}
            filter={ColumnHelper.getFilter(column.dataType)}
            title={column.title}
            minResizableWidth={ColumnHelper.getMinResizableWidth(column.size)}
            width={column.dataType === 'icon' ? 80 : undefined}
            columnMenu={column.columnMenu}
            format={ColumnHelper.getFormat(column.dataType)}
            sortable={!(column.sortable === false || column.dataType === 'icon')}
            cell={column.cell ? column.cell : column.isHtml ? (props) => ColumnHelper.getCellContentsAsHtml(column, props.dataItem) : undefined}
          />
        );
      }
    });

    return array;
  }
}
