import { AxiosError, AxiosRequestConfig } from 'axios';
import { browserHistory } from 'App';
import { BaseApi } from 'api/baseApi';
import { ValidationRule } from 'models/api/validationRule.model';
import { FalconError } from 'models/generic/falconError.model';
import messagesStore from 'stores/messages.store';
import utils from 'utils/utils';

export class Api extends BaseApi {
  constructor(customBaseUrl?: string, customConfigs?: AxiosRequestConfig) {
    super(customConfigs);

    this.baseApiUrl = customBaseUrl || process.env.REACT_APP_API_BASE_URL || '';
  }

  protected getResultFromError(error: AxiosError, friendlyErrorMessage: string | null, silent = false): FalconError {
    let apiError = new FalconError();

    if (error.response) {
      apiError = new FalconError(error.response, silent);

      switch (error.response.status) {
        // Bad Request
        case 400:
          // no need to handle bad request errors, these should be handled by the caller component
          break;

        // Unauthorized
        case 401:
          // handle unauthorized errors globally
          apiError.errorMessages.push(`Unauthorized user.`);
          messagesStore.addError(apiError);

          break;

        // Forbidden
        case 403:
          // handle forbidden errors globally
          apiError.errorMessages.push(`Requested action is forbidden.`);
          messagesStore.addError(apiError);
          break;

        // Not Found
        case 404:
          // handle not found errors globally
          apiError.errorMessages.push(`We couldn't find what you requested.`);
          messagesStore.addError(apiError);

          // navigate to not found page
          browserHistory.push('/not-found');
          break;

        // Internal Server Error
        case 500:
          // handle internal server errors globally
          if (friendlyErrorMessage) {
            apiError.errorMessages.push(friendlyErrorMessage);
          } else {
            // if no message is provided then don't display anything
            // assuming the error is handled elsewhere
          }

          messagesStore.addError(apiError);
          break;
      }
    } else {
      if (error.message === 'Network Error') {
        // return network error
        apiError.statusText = error.name;
        apiError.errorMessages.push(error.message);
        messagesStore.addError(apiError);
      }
    }

    return apiError;
  }

  async getValidationRules(url: string): Promise<ValidationRule[]> {
    // validation rules are cached against an application version (commit hash)
    const prefix = process.env.REACT_APP_VERSION;
    const key = prefix + '_validationRules_' + url;
    // check if rules in cache
    let rules: ValidationRule[] | null = utils.session.getFromSession(key);
    if (!rules) {
      // load rules from API
      const result = await this.get<ValidationRule[]>(url, {
        friendlyErrorMessage: 'There was an error loading validation rules.'
      });
      if (result instanceof FalconError) {
      } else {
        rules = result;

        // save rules to cache
        utils.session.saveToSession(key, rules);
      }
    }

    // return rules or empty array
    return rules || [];
  }

  async getByteArrayPdf<T>(url: string, friendlyErrorMessage: string | null, decodeResponse: boolean = true) {
    const config = await this.getConfig();
    if (url.split('?')[0] === '/DirectDebit/GetDirectDebitLetter') {
      config.responseType = 'arraybuffer';
    }
    const response = await this.axiosInstance.get<T>(url, config);

    return this.getResult(response, friendlyErrorMessage, decodeResponse) as T | FalconError;
  }
}

export default new Api();
