import { BaseModel } from 'models/api/base.model';
import ReactHtmlParser from 'react-html-parser';

export class Location extends BaseModel {
  addressId = -1;
  rowVersion: unknown = null;
  contactName: string | null = null;
  clientId: number | null = null;
  line1: string | null = null;
  line2: string | null = null;
  town: string | null = null;
  county: string | null = null;
  postCode: string | null = null;
  notes: string | null = null;
  searchField: string | null = null;

  fullAddressAsString: string | null = null;
  fullAddressAsStringWithoutPostCode: string | null = null;

  static getAllSearchableFieldsAsString(l: Location): string {
    return '' + l.addressId + ',' + l.line1 + ',' + l.line2 + ',' + l.town + ',' + l.county + ',' + l.postCode + ',' + l.notes;
  }

  static getFormattedAsMultilineHtml(location: Location) {
    let html = '';

    if (location.contactName) {
      html += '<br />' + location.contactName;
    }
    if (location.line1) {
      html += '<br />' + location.line1;
    }
    if (location.line2) {
      html += '<br />' + location.line2;
    }
    if (location.town) {
      html += '<br />' + location.town;
    }
    if (location.county) {
      html += '<br />' + location.county;
    }
    if (location.postCode) {
      html += '<br />' + location.postCode;
    }

    if (html) {
      // remove first <br />
      html = html.substring(6);
    }

    return ReactHtmlParser(html);
  }

  static getFormattedAsString(location: Location, includePostCode: boolean) {
    let locationString = '';

    if (location.line1) {
      locationString += ', ' + location.line1;
    }
    if (location.line2) {
      locationString += ', ' + location.line2;
    }
    if (location.town) {
      locationString += ', ' + location.town;
    }
    if (location.county) {
      locationString += ', ' + location.county;
    }
    if (includePostCode && location.postCode) {
      locationString += ', ' + location.postCode;
    }

    if (locationString) {
      // remove first ,
      locationString = locationString.substring(2);
    }

    return locationString;
  }
}
