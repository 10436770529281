import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import Button from 'components/Generic/FormElements/Button/Button';
import { observable } from 'mobx';
import { IBaseProps } from 'models/generic/iBaseProps';
import { Component } from 'react';
import ExampleFormContainer from 'Sandbox/ExampleFormContainer/ExampleFormContainer';
import ExampleKendoGrid from 'Sandbox/ExampleKendoGrid/ExampleKendoGrid';
import userStore from 'stores/user.store';

export class Sandbox extends Component<IBaseProps> {
  @observable inProgress = false;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  testClick = async (e: React.FormEvent) => {
    this.inProgress = true;
    await userStore.getNewToken();
    this.inProgress = false;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  test2Click = async (e: React.FormEvent) => {
    this.inProgress = true;
    await userStore.testLoadData();
    this.inProgress = false;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  test3Click = async (e: React.FormEvent) => {
    this.inProgress = true;

    // const x = await invoicesStore.getModulusCheck('180002', '12345678');
    // console.log('ValidateUKBankAccount', x);

    // const y = await invoicesStore.getUKBranch('180002');
    // console.log('getUKBranch', y);

    this.inProgress = false;
  };

  render() {
    return (
      <>
        <div className={appStyles.container}>
          <div>
            <Button buttonStyle="primary" inProgress={this.inProgress} handleClick={this.testClick}>
              Renew
            </Button>

            <Button buttonStyle="primary" inProgress={this.inProgress} handleClick={this.test2Click}>
              Get Data
            </Button>

            <Button buttonStyle="primary" inProgress={this.inProgress} handleClick={this.test3Click}>
              Banking API
            </Button>
            <hr />
          </div>

          <ExampleFormContainer></ExampleFormContainer>

          <ExampleKendoGrid></ExampleKendoGrid>

          <Alert alertType="error">Oh no! Something went wrong!</Alert>
          <Alert alertType="info">This is just for info</Alert>
          <Alert alertType="success">Success! Something went right!</Alert>
          <Alert alertType="warning">Warning! This is not the end of the world.</Alert>
        </div>
      </>
    );
  }
}
