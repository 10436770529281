import { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import appStyles from 'App.module.scss';
import styles from './Newsfeed.module.scss';

interface ILinkWithLabelProps {
  link: LocationDescriptor;
  target?: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconClass?: string;
  label: string;
}

const LinkWithLabel: FC<ILinkWithLabelProps> = ({ link, target, Icon, iconClass, label }) => (
  <Link to={link} target={target} className={styles.view_more}>
    <p className={classNames(appStyles.text_tangaroaBlue, styles.view_more_label)}>{label}</p>
    <Icon width="50px" height="50px" className={classNames(styles.image, iconClass)} />
  </Link>
);

export default LinkWithLabel;
