import { BaseApi } from 'api/baseApi';
import { IGetFileRequestConfig, IResponseFile } from 'api/types';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { LogDownloadClicked } from 'models/api/logDownloadClicked.model';
import { FalconError } from 'models/generic/falconError.model';
import { FalconFile } from 'models/generic/falconFile.model';
import eventLogsStore from 'stores/eventLogs.store';
import messagesStore from 'stores/messages.store';
import userStore from 'stores/user.store';
import auth from 'utils/auth';

interface IFileResponse {
  EnumerationResults: IFileResponseEnumerationResults;
}
interface IFileResponseEnumerationResults {
  Entries: IFileResponseEntries;
}
interface IFileResponseEntries {
  File: IResponseFile[];
}

export class FileApi extends BaseApi {
  constructor() {
    super();

    this.baseApiUrl = process.env.REACT_APP_FILE_API_BASE_URL || '';
  }

  private async getFileDownloadConfig(fileType: string): Promise<AxiosRequestConfig> {
    // documentation here: https://github.com/axios/axios

    const config: AxiosRequestConfig = {
      baseURL: this.baseApiUrl,
      timeout: this.timeoutInMinutes * 60 * 1000,
      responseType: 'arraybuffer'
    };

    const token = await auth.acquireTokenSilent();

    config.headers = {
      Authorization: 'Bearer ' + token?.idToken,
      'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_KEY || '',
      'Chess-Customer-Portal-Selected-Account': userStore.userSelectedClientId,
      'Content-Type': 'application/json',
      Accept: fileType
    };

    return config;
  }

  async get<T>(url: string, { injectClientId, ...customConfig }: IGetFileRequestConfig = {}) {
    // add client ID at the beginning of request URL
    if (injectClientId) {
      const clientId = userStore.userSelectedClientId;
      url = `/${clientId}${url}`;
    }

    return await super.get<T>(url, { decodeResponse: false, ...customConfig });
  }

  async fileExists<T>(url: string, { injectClientId, ...customConfig }: IGetFileRequestConfig = {}): Promise<boolean> {
    // add client ID at the beginning of request URL
    if (injectClientId) {
      const clientId = userStore.currentUser && userStore.currentUser.currentClient ? userStore.currentUser.currentClient.clientId : '';
      url = `/${clientId}${url}`;
    }

    return await super.fileExists<T>(url, customConfig);
  }

  async getFile<T>(url: string, friendlyErrorMessage: string | null, injectClientId = true) {
    const fileExtension = url.split('.').pop();
    const fileType = `application/${fileExtension}`;
    const config = await this.getFileDownloadConfig(fileType);

    // add client ID at the beginning of request URL
    if (injectClientId) {
      const clientId = userStore.currentUser && userStore.currentUser.currentClient ? userStore.currentUser.currentClient.clientId : '';
      url = `/${clientId}${url}`;
    }

    const form = new LogDownloadClicked(this.baseApiUrl, url);
    eventLogsStore.logDownloadClicked(form); // this is async, but no need to await

    const result = await this.axiosInstance.get<T>(url, config);

    return this.getResult(result, friendlyErrorMessage, false) as T | FalconError;
  }

  async getFilesList(url: string, errorMessage: string, injectClientId = true): Promise<FalconFile[]> {
    const files: FalconFile[] = [];
    const result = await fileApi.get<IFileResponse>(url, { friendlyErrorMessage: errorMessage, injectClientId });

    if (result instanceof FalconError) {
    } else {
      if (result && result.EnumerationResults) {
        const responseFiles: IResponseFile[] = result.EnumerationResults.Entries.File;

        responseFiles.forEach((f: IResponseFile) => {
          files.push(new FalconFile(f.Name.$t, ''));
        });
      }
    }

    return files;
  }

  protected getResultFromError(error: AxiosError, friendlyErrorMessage: string | null): FalconError {
    const apiError = new FalconError();

    apiError.errorMessages.push(friendlyErrorMessage || `Something went wrong.`);
    messagesStore.addError(apiError);

    return apiError;
  }
}

const fileApi = new FileApi();
export default fileApi;
