import React from 'react';
import styles from 'App.module.scss';

interface IBackdropProps {
  onBackdropClick?: React.MouseEventHandler<HTMLDivElement>;
}

const withBackdrop =
  <P extends IBackdropProps>(Component: React.ComponentType<P>) =>
  (props: P) =>
    (
      <>
        <Component {...props} />
        <div role="navigation" onClick={props.onBackdropClick} className={styles.backdrop} />
      </>
    );

export default withBackdrop;
