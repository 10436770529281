import { observable } from 'mobx';
import { Component } from 'react';

export class BaseUnsavedChangesComponent<TProps = Record<string, unknown>> extends Component<TProps> {
  @observable unsavedChange = false;

  static get enableCheck(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return !!(window as any).enableUnsavedChangesCheck;
  }
  static set enableCheck(value: boolean) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).enableUnsavedChangesCheck = value;
  }

  constructor(props: TProps) {
    super(props);

    BaseUnsavedChangesComponent.enableCheck = true;
  }

  render(): JSX.Element | undefined | null {
    return <></>;
  }
}
