import { FC, useCallback } from 'react';
import UserGuideTemplate from 'assets/images/user_guide_template.png';
import styles from 'components/Authentication/Authentication.module.scss';
import Button from 'components/Generic/FormElements/Button/Button';

const UserGuideWidget: FC = () => {
  const download = useCallback(() => window.open('https://chessict.co.uk/media/0duhu1vz/portal-user-guide-oct-2021.pdf', '_black')?.focus(), []);

  return (
    <div className={styles.user_guide__container}>
      <h2>New to the Portal</h2>
      <p>Read our User Guide for answers to our most common questions</p>

      <div className={styles.user_guide__content_container}>
        <img src={UserGuideTemplate} alt="user_guide" className={styles.user_guide__img} />
        <Button buttonStyle="primary" size="md" customClass={styles.user_guide__download_button} handleClick={download}>
          Download
        </Button>
      </div>
    </div>
  );
};
export default UserGuideWidget;
