import { Component } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { NavLink, withRouter } from 'react-router-dom';
import Icon from 'components/Generic/Icon/icon';
import { IBaseProps } from 'models/generic/iBaseProps';
import { NavItem } from 'models/generic/navItem.model';
import navigationStore from 'stores/navigation.store';
import styles from './Header.module.scss';

interface INavigationProps {
  selected: number | null;
  onFocus: (index: number) => void;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, route: string, prevent: boolean, index: number) => void;
}

@observer
class Navigation extends Component<IBaseProps & INavigationProps> {
  isItemActive(item: NavItem) {
    return this.props.location.pathname.startsWith(item.route);
  }

  render() {
    const { selected, onFocus, onClick, onClose } = this.props;

    return (
      <ul className={styles.nav__list}>
        <button onClick={onClose} className={styles.mobileNav__close}>
          <Icon icon={Icon.cross} color="midBlue" size="md" iconName="close menu" />
        </button>
        {navigationStore.navigation.map((item, index) => (
          <li className={classNames(styles.nav__item, { [styles.nav__item_active]: this.isItemActive(item) })} key={index}>
            <NavLink
              to={item.route}
              title={item.tooltip}
              activeClassName={styles.active}
              onClick={(e) => onClick(e, item.route, item.children.length > 0, index)}
              className={classNames(styles.nav__link, { [styles.nav__link_active]: selected === index })}>
              {item.title}
            </NavLink>

            {!!item.children.length && (
              <div className={styles.subNav} role="navigation" aria-label={`Sub-${index}`}>
                {item.children.map((subItem, subIndex) => (
                  <NavLink
                    exact
                    key={subIndex}
                    to={subItem.route}
                    title={subItem.tooltip}
                    className={styles.subNav__link}
                    activeClassName={styles.active}
                    onClick={(e) => onClick(e, subItem.route, false, subIndex)}
                    onFocus={() => onFocus(index)}>
                    {subItem.title}
                  </NavLink>
                ))}
              </div>
            )}
          </li>
        ))}
      </ul>
    );
  }
}

export default withRouter(Navigation);
