import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import Icon from 'components/Generic/Icon/icon';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { IBaseProps } from 'models/generic/iBaseProps';
import { Component } from 'react';
import userStore from 'stores/user.store';

@observer
export class RenewToken extends Component<IBaseProps> {
  @observable renewed = false;

  // blank component just to make token renewing testable
  componentDidMount = async () => {
    await userStore.getNewToken();
    this.renewed = true;

    setTimeout(() => {
      this.props.history.push('/dashboard');
    }, 5000);
  };

  render() {
    return (
      <>
        <div className={appStyles.container}>
          <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
            <Icon icon={Icon.warning} size="heading" color="currentColor" iconName="warning icon" customClass={appStyles.heading__icon} />
            <h1 className={appStyles.heading__text}>{this.renewed ? 'Token renewed' : 'Renewing...'}</h1>
          </div>

          {this.renewed && (
            <Alert alertType="blank">
              <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>You will now be redirected to the dashboard page...</p>
            </Alert>
          )}
        </div>
      </>
    );
  }
}
