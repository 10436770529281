import appStyles from 'App.module.scss';
import Icon from 'components/Generic/Icon/icon';
import { Component, ReactNode } from 'react';
import styles from './NewTicket.module.scss';

interface INewTicketBoxProps {
  buttonColour: 'connect' | 'cloud' | 'security' | 'devices' | 'orange' | 'marginBlue';
  headingText: string;
  description?: string;
  children?: ReactNode;
  isDisabled?: boolean;
  handleClick?: () => void;
  boxId: string;
}

/**
 * @description Add an alert. Optional properties: customClass
 */
class NewTicketBox extends Component<INewTicketBoxProps> {
  render() {
    return (
      <div
        id={this.props.boxId}
        className={`${styles.ticketBoxes__box} ${this.props.isDisabled ? styles.ticketBoxes__box_disabled : ''}`}
        title={this.props.isDisabled ? "This option isn't available" : ''}>
        <button
          onClick={this.props.handleClick}
          className={`${appStyles.button} ${styles.ticketBoxes__header} ${appStyles[`button_${this.props.buttonColour}`]}`}>
          <h2 className={styles.ticketBoxes__heading}>
            {this.props.children}

            {this.props.headingText}
          </h2>
          <Icon icon={Icon.arrowRight} iconName="proceed" size="xs" color="currentColor" />
        </button>
        {this.props.description !== '' ? (
          <div className={styles.ticketBoxes__content}>
            <p className={styles.ticketBoxes__description}>{this.props.description}</p>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default NewTicketBox;
