import appStyles from 'App.module.scss';
import FileDownloads from 'components/Generic/FormElements/FileDownloads/FileDownloads';
import ContactDetails from 'components/Products/Returns/ReturnsBreakdown/ContactDetails/ContactDetails';
import ProductDetailsGrid from 'components/Products/Returns/ReturnsBreakdown/ProductDetailsGrid/ProductDetailsGrid';
import ReturnReasonsGrid from 'components/Products/Returns/ReturnsBreakdown/ReturnReasonsGrid/ReturnReasonsGrid';
import { Returns } from 'models/api/returns.model';
import { Component } from 'react';

interface IReturnsBreakdownProps {
  return: Returns;
}

export default class ReturnsBreakdown extends Component<IReturnsBreakdownProps> {
  render() {
    return (
      <>
        <div id="returnBreakdownSection">
          <div className={appStyles.row}>
            <div className={appStyles.col_md_12}>
              <ProductDetailsGrid return={this.props.return} />
            </div>
          </div>

          <div className={appStyles.row}>
            <div className={appStyles.col_md_12}>
              <ReturnReasonsGrid return={this.props.return} />
            </div>
          </div>

          <div className={appStyles.row}>
            <div className={appStyles.col_md_6}>
              <ContactDetails return={this.props.return} />
            </div>

            <div className={appStyles.col_md_6}>
              <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
                <h3 className={appStyles.heading__text_sm}>Download</h3>
              </div>
              <div className={`${appStyles.card} ${appStyles.background_white} ${appStyles.card_boxShadow}`}>
                <div className={appStyles.card__column}>
                  <FileDownloads dataType="return" itemId={this.props.return.rma}></FileDownloads>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
