import { BaseApi } from 'api/baseApi';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { FalconError } from 'models/generic/falconError.model';

export class NotificationApi extends BaseApi {
  constructor(customBaseUrl?: string, customConfigs?: AxiosRequestConfig) {
    super(customConfigs);

    this.baseApiUrl = customBaseUrl || process.env.REACT_APP_API_BASE_URL || '';
  }
  protected getResultFromError(error: AxiosError<unknown>, friendlyErrorMessage: string | null | undefined, silent: boolean): FalconError {
    let apiError = new FalconError();

    if (error.response) {
      apiError = new FalconError(error.response, silent);

      switch (error.response.status) {
        case 404:
          apiError.errorMessages.push(`We couldn't find what you requested.`);
          break;

        case 500:
          if (friendlyErrorMessage) {
            apiError.errorMessages.push(friendlyErrorMessage);
          } else {
            apiError.errorMessages.push(`Something went wrong.`);
          }

          break;
      }
    } else {
      if (error.message === 'Network Error') {
        apiError.statusText = error.name;
        apiError.errorMessages.push(error.message);
      }
    }

    return apiError;
  }
}

export default new NotificationApi();
