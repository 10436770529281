import { ChangeEventHandler, Component, FocusEvent } from 'react';
import classNames from 'classnames';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { ListItem } from 'models/generic/listItem.model';
import { ValidationErrors } from 'models/generic/validationError.model';
import lineFaultsStore from 'stores/lineFaults.store';
import constants from 'utils/constants';
import validation, { ValidationHandler } from 'utils/validation';
import Button from 'components/Generic/FormElements/Button/Button';
import Input from 'components/Generic/FormElements/Input/Input';
import Select from 'components/Generic/FormElements/Select/Select';
import Preloader from 'components/Generic/Preloader/Preloader';
import appStyles from 'App.module.scss';
import lineDataFormStyles from './LineDataForm.module.scss';

interface IValidationSeverity {
  validation: ValidationHandler<string>;
  severity: number;
}

@observer
export default class LineFaultForm extends Component<RouteComponentProps> {
  runValidationOnChange = false;

  @observable form: Record<string, string | null> = {
    cli: '',
    postcode: '',
    faultCode: null
  };

  validationRules: Record<string, Array<IValidationSeverity>> = {
    cli: [
      {
        validation: ({ length }: string) =>
          (length < constants.telephoneNumberMinLength || length > constants.telephoneNumberMaxLength) && 'Enter a valid phone number',
        severity: constants.validation.severity.error
      },
      {
        validation: (value: string) =>
          value.length >= constants.telephoneNumberMinLength &&
          value.length <= constants.telephoneNumberMaxLength &&
          value[0] !== '0' &&
          'Enter a valid phone number: it must have a leading zero',
        severity: constants.validation.severity.error
      }
    ],
    postcode: [
      {
        validation: ({ length }: string) => (length < constants.postcodeMinLength || length > constants.postcodeMaxLength) && 'Enter a valid UK postcode',
        severity: constants.validation.severity.error
      },
      {
        validation: (value: string) =>
          value.length >= constants.postcodeMinLength &&
          value.length <= constants.postcodeMaxLength &&
          !new RegExp(validation.regexPatterns.postCode).test(value) &&
          'This postcode does not appear to be valid',
        severity: constants.validation.severity.warning
      }
    ],
    faultCode: [{ validation: (value: string | null) => !value && 'Select line test reason', severity: constants.validation.severity.error }]
  };

  inputReplacePatterns: Record<string, RegExp | string> = {
    cli: /[^0-9]/g,
    postcode: /[^a-z0-9 ]/gi,
    faultCode: ''
  };

  @observable errors: ValidationErrors = new ValidationErrors();
  @observable reasonsList: ListItem[] = [];

  async componentDidMount() {
    lineFaultsStore.cleanResults();

    const reasons = await lineFaultsStore.getLineFaultCodes();

    this.reasonsList = reasons.map((r) => new ListItem(r.code ?? '', r.description ?? ''));
  }

  @action handleInput: ChangeEventHandler<HTMLSelectElement | HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    const replacePatterns = this.inputReplacePatterns[name] ?? '';

    this.form[name] = value.replace(replacePatterns, '').toUpperCase();

    if (this.runValidationOnChange) {
      this.validateForm();
    }
  };

  checkValidationRules = (fieldName: string) =>
    this.validationRules[fieldName]
      .map((rule) => ({ message: rule.validation(this.form[fieldName] as string), severity: rule.severity }))
      .filter((result) => result.message);

  @action validateField = (event: FocusEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      return;
    }

    const fieldName: string = event.target.name;
    const fieldErrors = this.checkValidationRules(fieldName);

    // cutting-off current field validation rules
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [fieldName]: currentField, ...restFields } = this.errors.fieldErrors;

    const errors = new ValidationErrors();
    errors.fieldErrors = restFields;

    fieldErrors.forEach((result) => errors.addError(fieldName, result.message as string, result.severity));

    this.errors = errors;
  };

  @action validateForm = () => {
    this.errors = new ValidationErrors();

    const failedValidations = Object.keys(this.form)
      .map((key) => ({ errors: this.checkValidationRules(key), key }))
      .filter(({ errors }) => errors.length);

    failedValidations.forEach((validatedField) =>
      validatedField.errors.forEach((result) => this.errors.addError(validatedField.key, result.message as string, result.severity))
    );

    return !failedValidations.filter((a) => a.errors.some((error) => error.severity === constants.validation.severity.error)).length;
  };

  onBeginLineCheck = async () => {
    try {
      this.runValidationOnChange = true;

      if (!this.validateForm() || !(this.form.cli && this.form.postcode && this.form.faultCode)) {
        return;
      }

      await lineFaultsStore.requestLineCheck(this.form.cli, this.form.postcode, this.form.faultCode);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return this.props.history.push(`/services/line-faults/error`, { ...error });
    }
  };

  render() {
    return (
      <div className={lineDataFormStyles.form__container}>
        {!lineFaultsStore.pending && (
          <div className={appStyles.heading}>
            <h1 className={classNames(appStyles.heading__text, appStyles.text_midBlue)}>Run a Line Test</h1>
          </div>
        )}

        <div className={lineDataFormStyles.form}>
          {!lineFaultsStore.pending ? (
            <>
              <p className={lineDataFormStyles.form__title}>To check for a fault on a telephone line, enter your details below.</p>
              <div className={lineDataFormStyles.form__inputContainer}>
                <Input
                  name="cli"
                  labelText=""
                  minLength={constants.telephoneNumberMinLength}
                  maxLength={constants.telephoneNumberMaxLength}
                  inputType="tel"
                  elementId="cli"
                  value={this.form.cli}
                  handleChange={this.handleInput}
                  handleBlur={this.validateField}
                  placeholder="Enter a telephone number"
                  required={true}
                  customInputClass={lineDataFormStyles.form__input}
                  validationError={this.errors.fieldErrors['cli']}
                />
              </div>
              <div className={lineDataFormStyles.form__inputContainer}>
                <Input
                  name="postcode"
                  labelText=""
                  minLength={constants.postcodeMinLength}
                  maxLength={constants.postcodeMaxLength}
                  inputType="text"
                  elementId="postcode"
                  value={this.form.postcode}
                  handleChange={this.handleInput}
                  handleBlur={this.validateField}
                  placeholder="Enter a postcode"
                  customInputClass={lineDataFormStyles.form__input}
                  required={true}
                  validationError={this.errors.fieldErrors['postcode']}
                />
              </div>
              <div className={lineDataFormStyles.form__inputContainer}>
                <Select
                  elementId="lineTestReasonList"
                  labelText=""
                  name="faultCode"
                  options={this.reasonsList}
                  placeholder="Select test reason "
                  disablePlaceholder={true}
                  handleChange={this.handleInput}
                  value={this.form.faultCode}
                  required={true}
                  customSelectClass={lineDataFormStyles.form__input}
                  validationError={this.errors.fieldErrors['faultCode']}
                />
              </div>
              <Button size="lg" buttonStyle="primary" id="btnBeginLineCheck" handleClick={this.onBeginLineCheck} disabled={lineFaultsStore.pending}>
                <span>Run Test</span>
              </Button>
            </>
          ) : (
            <Preloader
              id="lineTestPreloader"
              // eslint-disable-next-line max-len
              title="Please wait while we run a line test. This can take up to 3 minutes, and during this time you may experience some line disruption. You can leave this page and will receive a notification when the line test has finished."
              titleClass={lineDataFormStyles.form__title}
            />
          )}
        </div>
      </div>
    );
  }
}
