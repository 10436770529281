import constants from 'utils/constants';
import { BaseHub } from 'websockets/baseHub';

export default class NotificationHub extends BaseHub {
  addLineFaultCheckHandler = (eventHandler: (payload: string) => void) => {
    this.connection?.on(constants.notificationMethods.LineFaultCheck, eventHandler);
    this.connection?.on(constants.notificationMethods.FailedLineFaultCheck, eventHandler);
  };

  removeLineFaultCheckHandlers = (eventHandler?: (payload: string) => void) => {
    if (eventHandler) {
      this.connection?.off(constants.notificationMethods.LineFaultCheck, eventHandler);
      this.connection?.off(constants.notificationMethods.FailedLineFaultCheck, eventHandler);
    } else {
      this.connection?.off(constants.notificationMethods.LineFaultCheck);
      this.connection?.off(constants.notificationMethods.FailedLineFaultCheck);
    }
  };

  replaceLineFaultCheckHandler = (eventHandler: (payload: string) => void) => {
    this.connection?.off(constants.notificationMethods.LineFaultCheck, eventHandler);
    this.connection?.off(constants.notificationMethods.FailedLineFaultCheck, eventHandler);
    this.connection?.on(constants.notificationMethods.LineFaultCheck, eventHandler);
    this.connection?.on(constants.notificationMethods.FailedLineFaultCheck, eventHandler);
  };
}
