import { FC, HTMLProps, useCallback } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Client } from 'models/api/client.model';
import Icon from 'components/Generic/Icon/icon';
import accountStore from 'stores/account.store';
import styles from './SwitchAccount.module.scss';

interface IAccountLinkProps {
  client: Client;
  unreadCount?: number | null;
  isSelected: boolean;
  onClick: () => void;
}

const AccountLink: FC<IAccountLinkProps & HTMLProps<HTMLDivElement>> = ({ client, unreadCount, isSelected, ...props }) => {
  const onClickCallback = useCallback(() => {
    props.onClick();
    accountStore.selectClientId(client.clientId);
  }, [client.clientId]);

  if (!client.clientId) {
    return null;
  }

  return (
    <div className={classNames(styles.account__row, { [styles.account__row_active]: isSelected })} {...props} onClick={onClickCallback} role="navigation">
      <div className={styles.account__label}>
        <Icon icon={Icon.user} size="lg" color="currentColor" iconName="user icon" customClass={styles.switch_account_icon} />
        {client.name}
      </div>
      <div className={styles.notification_container}>
        {unreadCount && unreadCount !== 0 && <span className={styles.notification__count}>{unreadCount}</span>}
        <div className={styles.account__arrow}>
          <Icon icon={Icon.arrowRight} size="sm" color="currentColor" iconName="arrow icon" customClass={styles.switch_account_section__icon} />
        </div>
      </div>
    </div>
  );
};

export default observer(AccountLink);
