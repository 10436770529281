import classNames from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import availabilityChecksStore from 'stores/availabilityChecks.store';
import { AuthRoute } from 'routing/AuthRoute';
import { GuardedRoute } from 'routing/GuardedRoute';
import appStyles from 'App.module.scss';
import styles from './AvailabilityChecker.module.scss';
import AvailabilityCheckerForm from './AvailabilityCheckerForm/AvailabilityCheckerForm';
import ConfirmCallbackRequest from './ConfirmCallbackRequest/ConfirmCallbackRequest';
import ProductAvailability from './ProductAvailability/ProductAvailability';
import RequestCallback from './RequestCallback/RequestCallback';

export default function AvailabilityChecker({ match }: RouteComponentProps) {
  return (
    <div className={classNames(appStyles.container, styles.availabilityChecker__container)}>
      <AuthRoute exact path={match.path} component={AvailabilityCheckerForm} pageTitle="Availability Checker Form" />
      <GuardedRoute
        exact
        path={`${match.path}/product-availability`}
        component={ProductAvailability}
        condition={!!availabilityChecksStore.correlationId}
        redirectPath={match.path}
        pageTitle="Product Availability"
      />
      <GuardedRoute
        exact
        path={`${match.path}/request-callback`}
        component={RequestCallback}
        condition={!!availabilityChecksStore.correlationId}
        redirectPath={match.path}
        pageTitle="Request Callback"
      />
      <GuardedRoute
        exact
        path={`${match.path}/confirm`}
        component={ConfirmCallbackRequest}
        condition={availabilityChecksStore.callbackRequested}
        redirectPath={match.path}
        pageTitle="Request Callback Confirmation"
      />
    </div>
  );
}
