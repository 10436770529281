import { ChangeEvent } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid } from '@progress/kendo-react-grid';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import Alert from 'components/Generic/Alert/Alert';
import Input from 'components/Generic/FormElements/Input/Input';
import { BaseClientGrid } from 'components/Generic/Grid/BaseClientGrid';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { Location } from 'models/api/location.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import locationsStore from 'stores/locations.store';
import { IMatchResult } from 'utils/routing';
import utils from 'utils/utils';
import { ReactComponent as EditSVGLogo } from 'assets/images/svg/edit.svg';
import appStyles from 'App.module.scss';
import styles from './Locations.module.scss';

@observer
class LocationsGrid extends BaseClientGrid<Location, IBaseProps> {
  @observable selectedLocation: Location | null = null;
  @observable searchInput = '';
  subscriptionId = '';

  constructor(props: IBaseProps) {
    super(props);

    this.sort = [{ field: 'address', dir: 'asc' }];

    // monitor route changes
    this.subscriptionId = utils.routing.subscribe((location) => {
      const match = utils.routing.matchRoutes(location, ['/account/locations/:id', '/account/locations/new', '/account/locations']);
      this.setUpView(match);
    });
  }

  async componentDidMount() {
    this.setUpView(this.props.match);
  }

  componentWillUnmount() {
    utils.routing.unsubscribe(this.subscriptionId);
  }

  filterData = (event: ChangeEvent<HTMLInputElement>) => {
    this.searchInput = event.target.value;
    const keyword = this.searchInput.toLowerCase();

    const result = this.allData.filter((c) => Location.getAllSearchableFieldsAsString(c).toLowerCase().indexOf(keyword) > -1);

    this.gridData = result;
  };

  async setUpView(match: IMatchResult | null) {
    // get location ID from match params (query string)
    let locationId: number | null = null;
    if (match && match.params) {
      locationId = +match.params.id || null;
    }

    // if no ID then load/refresh list
    if (locationId === null) {
      this.allData = await locationsStore.getLocations();
      this.gridData = this.allData;
    }
  }

  goToLocation = (location: Location) => {
    this.props.history.push('/account/locations/' + location.addressId);
  };

  render() {
    const mainGrid = (
      <div id="mainGrid">
        <Grid
          data={this.getData(this.selectedLocation)}
          filter={this.filter}
          filterable={false}
          pageable={this.getPageable(this.selectedLocation)}
          skip={this.skip}
          take={this.take}
          total={this.getTotal(this.selectedLocation)}
          onPageChange={this.pageChange}
          sortable={this.getSortable(this.selectedLocation)}
          sort={this.getSort(this.selectedLocation)}
          resizable
          onFilterChange={this.filterChange}
          onSortChange={this.sortChange}>
          {ColumnHelper.getGridColumns([
            { field: 'fullAddressAsStringWithoutPostCode', title: 'Address', dataType: 'text', size: 'lg', currentFilter: this.filter },
            { field: 'postCode', title: 'Post Code', dataType: 'text', size: 'md', currentFilter: this.filter },
            { field: 'notes', title: 'Notes', dataType: 'text', size: 'lg', currentFilter: this.filter, sortable: false },
            this.selectedLocation
              ? undefined
              : {
                  field: '',
                  title: 'Edit',
                  dataType: 'icon',
                  size: 'xs',
                  cell: (props) => (
                    <td className="k-table-td gridColumn_xs gridColumn_button">
                      <div role="button" onClick={() => this.goToLocation(props.dataItem)} className={appStyles.button___grid_view}>
                        <EditSVGLogo title="Edit location breakdown" height={24} width={24} />
                      </div>
                    </td>
                  )
                }
          ])}
        </Grid>
      </div>
    );

    return (
      <>
        <div className={appStyles.pageHeading_lg}>
          <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
            <h1 className={appStyles.heading__text}>Manage Locations</h1>
          </div>

          {!this.selectedLocation && (
            <div className={styles.search__container}>
              <Input
                elementId="searchBar"
                inputType="search"
                name="searchBar"
                labelText="Search Locations"
                labelHidden={true}
                placeholder="Search Locations"
                value={this.searchInput}
                handleChange={this.filterData}
                customLabelClass={appStyles.form__label}
                customInputClass={appStyles.form__input}
                autocomplete="off"></Input>
            </div>
          )}
        </div>

        {!this.selectedLocation && (
          <Alert alertType="blank">
            <p className={`${appStyles.info__main} ${appStyles.text_darkGrey}`}>Select a Location to update address information.</p>
          </Alert>
        )}

        {mainGrid}

        <GridPDFExport ref={(pdfExport) => (this.gridPDFExport = pdfExport)}>{mainGrid}</GridPDFExport>
      </>
    );
  }
}

export default withRouter(LocationsGrid);
