import { ChangeEvent, Component } from 'react';
import classNames from 'classnames';
import { MaskedTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import DD_Logo from 'assets/images/DirectDebitLogo.png';
import Alert from 'components/Generic/Alert/Alert';
import Input from 'components/Generic/FormElements/Input/Input';
import MaskedInput from 'components/Generic/FormElements/MaskedInput/MaskedInput';
import Select from 'components/Generic/FormElements/Select/Select';
import Icon from 'components/Generic/Icon/icon';
import { DirectDebit } from 'models/api/directDebit.model';
import { ModulusCheck } from 'models/api/modulusCheck.model';
import { ListItem } from 'models/generic/listItem.model';
import { ValidationErrors } from 'models/generic/validationError.model';
import invoicesStore from 'stores/invoices.store';
import constants from 'utils/constants';
import appStyles from 'App.module.scss';
import styles from '../../ManageDirectDebit.module.scss';

interface IStep2Props {
  newDirectDebit: boolean;
  currentStep: number;
  directDebit: DirectDebit;
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handleMaskedInputChange: (event: MaskedTextBoxChangeEvent) => void;
  errors: ValidationErrors;
  sageAccountsList: ListItem[];
}

@observer
export class Step2 extends Component<IStep2Props> {
  @observable sageAccountsList: ListItem[] = this.props.sageAccountsList;
  @observable showExtraExplanation = false;

  mandateHref = '';

  async componentDidMount() {
    this.mandateHref = await invoicesStore.getDirectDebitMandateLink();
  }

  modulusNotification = (): JSX.Element | null => {
    let modulusNotification: JSX.Element | null;

    if (this.props.directDebit.modulusCheck === null || this.props.directDebit.modulusCheck === undefined) {
      modulusNotification = null;
    } else if (this.props.directDebit.modulusCheck.Valid) {
      modulusNotification = (
        <div id="successDiv">
          <Alert alertType="success">
            <p className={`${appStyles.info__main}`}>Your details passed our external checks.</p>
            <p>Bank Name: {this.props.directDebit.modulusCheck.UKBankBranch?.BankName}</p>
          </Alert>
        </div>
      );
    } else {
      modulusNotification = (
        <div>
          <Alert alertType="error">
            <div className={`${appStyles.info__main}`}>Please double check your account number and sort code:</div>
            <div className={`${appStyles.info__sub}`}>{ModulusCheck.parsedInvalidReason(this.props.directDebit.modulusCheck)}</div>
          </Alert>
        </div>
      );
    }

    return modulusNotification;
  };

  showExtraExplanationClick = () => {
    this.showExtraExplanation = true;
  };

  render() {
    if (this.props.currentStep !== 2) {
      // Prop: The current step
      return null;
    }
    const modulusNotification = this.modulusNotification();

    return (
      <div className={classNames({ [appStyles.container]: this.props.newDirectDebit })}>
        {!!this.props.newDirectDebit && (
          <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
            <Icon icon={Icon.invoices} size="heading" color="currentColor" iconName="invoice icon" customClass={appStyles.heading__icon} />
            <h1 className={appStyles.heading__text}>New Direct Debit</h1>
          </div>
        )}
        <Alert alertType="blank">
          <p className={`${appStyles.text_tangaroaBlue} ${appStyles.info__main} ${appStyles.info_bold}`}>
            To {this.props.newDirectDebit ? 'set up ' : 'edit '} a Direct Debit please fill out the form below and click <i>Proceed</i>
          </p>
          <p className={`${appStyles.text_darkGrey} ${appStyles.info__sub}`}>
            By completing this form you agree that you have the permission of all parties needed to authorise the Direct Debit.
          </p>
          <p className={`${appStyles.text_darkGrey} ${appStyles.info__sub}`}>
            You can contact {constants.keyVariables.customerServicesName} on {constants.keyVariables.helpdeskTelephone} if you require support with setting up
            or changing a Direct Debit.
          </p>
          <p className={`${appStyles.text_darkGrey} ${appStyles.info__sub}`}>
            Our correspondence address is Bridgford House, Heyes Lane, Alderley Edge, Cheshire SK9 7JP.
          </p>
        </Alert>
        <form className={`${appStyles.row} ${styles.ddForm}`} id="ddStep2">
          <div className={`${appStyles.col_md_6} ${appStyles.col_lg_5} ${styles.ddColumn}`}>
            <fieldset>
              {this.props.newDirectDebit ? (
                <div className={appStyles.form__row}>
                  <Select
                    elementId="sageAccountSelect"
                    labelText="Please select the account that this Direct Debit should be linked to"
                    name="sageAccountSelect"
                    options={this.sageAccountsList}
                    placeholder="Please select..."
                    disablePlaceholder={true}
                    handleChange={this.props.handleChange}
                    value={this.props.directDebit.selectedSageAccount}
                    validationError={this.props.errors.fieldErrors['sageAccountSelect']}
                  />
                  {this.showExtraExplanation ? (
                    <div className={classNames(appStyles.form__legend, appStyles.form__legend_small)}>
                      <p>This dropdown menu lets us know which product you would like to pay for with this Direct Debit.</p>
                      <p>You can match the last section of the text on each dropdown option to the account number on one of your recent invoices.</p>
                      <p>We cannot currently set up Direct Debits for products and services billed by Chess Cybersecurity Ltd and Chess Digital Ltd.</p>
                    </div>
                  ) : (
                    <div
                      className={classNames(appStyles.form__legend, appStyles.form__legend_small, appStyles.form__help, styles.showExplanation)}
                      role="button"
                      title="Not sure what to select? Click here for more information."
                      onClick={this.showExtraExplanationClick}>
                      Not sure what to select? Click here for more information.
                    </div>
                  )}
                </div>
              ) : (
                <div className={appStyles.form__row}>
                  <div className={appStyles.form__row}>
                    <div className={appStyles.form__label}>Sage Account</div>
                    <div className={appStyles.form__readOnlyElement}>
                      {this.props.directDebit.sage200CompanyName + ' - ' + this.props.directDebit.sageAccountNumber}
                    </div>
                  </div>
                  <Input
                    elementId="sageAccountValue"
                    inputType="hidden"
                    labelText=""
                    name="sageAccountValue"
                    placeholder=""
                    handleChange={this.props.handleChange}
                    value={this.props.directDebit.sageAccountLinkId}
                    readonly={true}
                  />
                </div>
              )}
              <div className={appStyles.form__row}>
                <MaskedInput
                  elementId="bankSortCode"
                  labelText="Bank Sort Code"
                  name="bankSortCode"
                  placeholder="Enter your bank sort code"
                  handleMaskedInputChange={this.props.handleMaskedInputChange}
                  value={this.props.directDebit.bankSortCode || null}
                  required={true}
                  title="Must be 6 digits"
                  validationError={this.props.errors.fieldErrors['bankSortCode']}
                  mask="000000"
                />
              </div>

              <div className={appStyles.form__row}>
                <MaskedInput
                  elementId="bankAccountNumber"
                  labelText="Bank Account Number"
                  name="bankAccountNumber"
                  placeholder="Enter your bank account number"
                  handleMaskedInputChange={this.props.handleMaskedInputChange}
                  value={this.props.directDebit.bankAccountNumber || null}
                  required={true}
                  title="Must be 8 digits"
                  validationError={this.props.errors.fieldErrors['bankAccountNumber']}
                  mask="00000000"
                />
              </div>
              <div className={appStyles.form__row}>
                <Input
                  elementId="bankAccountName"
                  inputType="text"
                  labelText="Account Name"
                  name="bankAccountName"
                  placeholder="Enter your bank account name"
                  handleChange={this.props.handleChange}
                  value={this.props.directDebit.bankAccountName}
                  required={true}
                  validationError={this.props.errors.fieldErrors['bankAccountName']}
                />
              </div>

              <div className={`${appStyles.form__row} ${appStyles.hidden}`}>
                <Input
                  elementId="bankName"
                  inputType="hidden"
                  labelText=""
                  name="bankName"
                  placeholder=""
                  handleChange={this.props.handleChange}
                  value={this.props.directDebit.bankName}
                  readonly={true}
                />
              </div>
            </fieldset>

            <a
              className={`${appStyles.button} ${appStyles.button_primary} ${appStyles.button_lg} ${styles.downloadButton}`}
              href={this.mandateHref}
              id="downloadDirectDebit"
              title="Download instructions in PDF format"
              download>
              Download Direct Debit Instruction
            </a>
          </div>

          <div className={`${appStyles.col_md_6} ${appStyles.col_lg_7}`}>
            {!!modulusNotification && <div>{modulusNotification}</div>}
            <div className={styles.dd_header}>
              <p className={`${appStyles.text_midBlue} ${appStyles.heading__text_sm}`}>The Direct Debit Guarantee</p>
              <img className={styles.dd_image} alt="Direct Debit logo" src={DD_Logo} />
            </div>
            <div className={`${styles.dd_content} ${appStyles.text_black}`}>
              <div className={styles.dd_item}>This Guarantee is offered by all banks and building societies that accept instructions to pay Direct Debits</div>
              <div className={styles.dd_item}>
                If there are any changes to the amount, date or frequency of your Direct Debit Chess ICT Limited will notify you 5 working days in advance of
                your account being debited or as otherwise agreed. If you request Chess ICT Limited to collect a payment, confirmation of the amount and date
                will be given to you at the time of the request
              </div>
              <div className={styles.dd_item}>
                If an error is made in the payment of your Direct Debit, by Chess ICT Limited or your bank or building society, you are entitled to a full and
                immediate refund of the amount paid from your bank or building society
              </div>
              <div className={styles.dd_item}>If you receive a refund you are not entitled to, you must pay it back when Chess ICT Limited asks you to</div>
              <div className={styles.dd_item}>
                You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written confirmation may be required. Please also
                notify us.
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
