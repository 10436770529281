import { BaseRequestPolicy, RequestPolicy, RequestPolicyOptions, WebResource } from '@azure/storage-blob';

export class AuthorizationPolicyFactory {
  schema: string;
  value: string;

  // Constructor to accept parameters
  constructor(schema: string, value: string) {
    this.value = value;
    this.schema = schema;
  }

  // create() method needs to create a new AuthorizationPolicy object
  create(nextPolicy: RequestPolicy, options: RequestPolicyOptions) {
    return new AuthorizationPolicy(nextPolicy, options, this.schema, this.value);
  }
}

// Create a policy by extending from BaseRequestPolicy
export class AuthorizationPolicy extends BaseRequestPolicy {
  schema: string;
  value: string;

  constructor(nextPolicy: RequestPolicy, options: RequestPolicyOptions, schema: string, value: string) {
    super(nextPolicy, options);
    this.schema = schema;
    this.value = value;
  }

  // Customize HTTP requests and responses by overriding sendRequest
  // Parameter request is WebResource type
  async sendRequest(request: WebResource) {
    // Customize client Authorization header
    request.headers.set('Authorization', `${this.schema} ${this.value}`.trim());

    // response is HttpOperationResponse type
    const response = await this._nextPolicy.sendRequest(request);

    // Modify response here if needed
    return response;
  }
}
