import appStyles from 'App.module.scss';
import Icon from 'components/Generic/Icon/icon';
import { observer } from 'mobx-react';
import { Component } from 'react';

@observer
export class PageNotFound extends Component {
  render() {
    return (
      <div className={appStyles.container}>
        <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
          <Icon icon={Icon.warning} size="heading" color="currentColor" iconName="warning icon" customClass={appStyles.heading__icon} />
          <h1 className={appStyles.heading__text}>Page Not Found</h1>
        </div>
      </div>
    );
  }
}
