import { Grid } from '@progress/kendo-react-grid';
import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import FileDownloads from 'components/Generic/FormElements/FileDownloads/FileDownloads';
import { BaseClientGrid } from 'components/Generic/Grid/BaseClientGrid';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Quote } from 'models/api/quote.model';
import { QuoteItem } from 'models/api/quoteItem.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import { withRouter } from 'react-router-dom';
import utils from 'utils/utils';
import styles from './../MyQuotes.module.scss';

interface IQuotesBreakdownProps extends IBaseProps {
  quote: Quote;
}

@observer
class QuotesBreakdown extends BaseClientGrid<QuoteItem, IQuotesBreakdownProps> {
  @observable quote: Quote = this.props.quote;

  constructor(props: IQuotesBreakdownProps) {
    super(props);

    this.sort = [{ field: 'optional', dir: 'asc' }];

    this.allData = props.quote.items;
    this.gridData = this.allData;
  }

  back = () => {
    this.props.history.push(`/products/quotes/`);
  };

  goToAccept = () => {
    this.props.history.push(`/products/quotes/${this.quote.quoteId}/accept`);
  };

  goToQuestion = () => {
    this.props.history.push(`/products/quotes/${this.quote.quoteId}/question`);
  };

  // eslint-disable-next-line complexity
  render() {
    const breakdownGrid = (
      <Grid
        data={this.getData()}
        filter={this.filter}
        filterable={false}
        pageable={{ pageSizes: this.pageSizes }}
        skip={this.skip}
        take={this.take}
        total={this.gridData.length}
        onPageChange={this.pageChange}
        sortable
        sort={toJS(this.sort)}
        resizable
        onFilterChange={this.filterChange}
        onSortChange={this.sortChange}>
        {ColumnHelper.getGridColumns([
          { field: 'description', title: 'Description', dataType: 'text', size: 'lg', isHtml: true, currentFilter: this.filter },
          { field: 'batchSize', title: 'Batch Size', dataType: 'numeric', size: 'sm', currentFilter: this.filter },
          { field: 'units', title: 'Units', dataType: 'numeric', size: 'xs', currentFilter: this.filter },
          { field: 'price', title: 'Price', dataType: 'currency', size: 'xs', currentFilter: this.filter },
          { field: 'total', title: 'Line Total', dataType: 'currency', size: 'sm', currentFilter: this.filter },
          this.quote.items.some((i) => i.optional === 'Yes')
            ? { field: 'optional', title: 'Optional', dataType: 'text', size: 'sm', currentFilter: this.filter }
            : undefined,
          { field: 'recurring', title: 'Recurring', dataType: 'text', size: 'sm', currentFilter: this.filter },
          // show V1 column only if there are other versions
          this.quote.lineItemsV2Count || this.quote.lineItemsV3Count || this.quote.lineItemsV4Count || this.quote.lineItemsV5Count
            ? { field: 'v1', title: 'V1', dataType: 'text', size: 'xs', currentFilter: this.filter }
            : undefined,
          // show V2, V3, V4 and V5 only if any of them exists
          this.quote.lineItemsV2Count ? { field: 'v2', title: 'V2', dataType: 'text', size: 'xs', currentFilter: this.filter } : undefined,
          this.quote.lineItemsV3Count ? { field: 'v3', title: 'V3', dataType: 'text', size: 'xs', currentFilter: this.filter } : undefined,
          this.quote.lineItemsV4Count ? { field: 'v4', title: 'V4', dataType: 'text', size: 'xs', currentFilter: this.filter } : undefined,
          this.quote.lineItemsV5Count ? { field: 'v5', title: 'V5', dataType: 'text', size: 'xs', currentFilter: this.filter } : undefined
        ])}
      </Grid>
    );

    return (
      <>
        <div className={`${styles.quotesSection}`} id="quotesBreakdownSection">
          <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
            <h3 className={appStyles.heading__text_sm}>Quote Breakdown (including optional items)</h3>
          </div>

          <div className={styles.quotesBreakdownGrid}>{breakdownGrid}</div>

          <div className={`${styles.quotesSection} ${appStyles.row}`}>
            {this.quote.validThrough <= new Date() && (
              <div className={`${appStyles.col_md_7} ${styles.alert_warning}`}>
                <Alert alertType="warning">
                  <div id="warningsOnly" className={`${appStyles.text_warning} ${styles.message_warning}`}>
                    We're sorry your quote has expired. Please call our Sales Team on 0344 770 6000, option 4 for sales.
                  </div>
                </Alert>
              </div>
            )}

            <div className={appStyles.col_md_6}>
              <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
                <h3 className={appStyles.heading__text_sm}>Details</h3>
              </div>
              <div className={`${appStyles.card} ${styles.card_quotes} ${appStyles.background_white} ${appStyles.card_boxShadow}`}>
                <div className={appStyles.card__row}>
                  <div className={`${appStyles.card__label} ${appStyles.text_midBlue}`}>Prepared for</div>
                  <div className={`${appStyles.card__value} ${appStyles.text_marginBlue}`}>
                    {this.quote.contactName}
                    <br />
                    {this.quote.clientName}
                    {!!this.quote.address && (
                      <>
                        <br />
                        {this.quote.address.line1}
                        <br />
                        {this.quote.address.line2}
                        <br />
                        {this.quote.address.town}
                        <br />
                        {this.quote.address.postCode}
                      </>
                    )}
                  </div>
                </div>
                <hr />
                <div className={appStyles.card__row}>
                  <div className={`${appStyles.card__label} ${appStyles.text_midBlue}`}>Prepared by</div>
                  <div className={`${appStyles.card__value} ${appStyles.text_marginBlue}`}>
                    {!!this.quote.preparedBy && (
                      <>
                        {this.quote.preparedBy.name}
                        <br />
                        {this.quote.preparedBy.position}
                        <br />
                        {this.quote.preparedBy.email}
                        <br />
                        {this.quote.preparedBy.phone}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={appStyles.col_md_6}>
              <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
                <h3 className={appStyles.heading__text_sm}>Total</h3>
              </div>
              <div className={`${appStyles.card} ${styles.card_total} ${appStyles.background_white} ${appStyles.card_boxShadow}`}>
                <div className={appStyles.card__row}>
                  <div className={`${appStyles.card__label} ${appStyles.text_white}`}>Quote Total</div>
                </div>
                <div className={styles.card__row} id="version1">
                  <div className={`${appStyles.card__label} ${appStyles.text_white} ${styles.card__label}`}>
                    Version 1 ({this.quote.lineItemsV1Count} items)
                  </div>
                  <div className={`${appStyles.card__value} ${appStyles.text_white}`}>{this.quote.valueV1Label}</div>
                </div>
                {this.quote.lineItemsV2Count && this.quote.lineItemsV2Count > 0 ? (
                  <div className={styles.card__row} id="version2">
                    <div className={`${appStyles.card__label} ${appStyles.text_white} ${styles.card__label}`}>
                      Version 2 ({this.quote.lineItemsV2Count} items)
                    </div>
                    <div className={`${appStyles.card__value} ${appStyles.text_white}`}>{this.quote.valueV2Label}</div>
                  </div>
                ) : (
                  ''
                )}
                {this.quote.lineItemsV3Count && this.quote.lineItemsV3Count > 0 ? (
                  <div className={styles.card__row} id="version3">
                    <div className={`${appStyles.card__label} ${appStyles.text_white} ${styles.card__label}`}>
                      Version 3 ({this.quote.lineItemsV3Count} items)
                    </div>
                    <div className={`${appStyles.card__value} ${appStyles.text_white}`}>{this.quote.valueV3Label}</div>
                  </div>
                ) : (
                  ''
                )}
                {this.quote.lineItemsV4Count && this.quote.lineItemsV4Count > 0 ? (
                  <div className={styles.card__row} id="version4">
                    <div className={`${appStyles.card__label} ${appStyles.text_white} ${styles.card__label}`}>
                      Version 4 ({this.quote.lineItemsV4Count} items)
                    </div>
                    <div className={`${appStyles.card__value} ${appStyles.text_white}`}>{this.quote.valueV4Label}</div>
                  </div>
                ) : (
                  ''
                )}
                {this.quote.lineItemsV5Count && this.quote.lineItemsV5Count > 0 ? (
                  <div className={styles.card__row} id="version5">
                    <div className={`${appStyles.card__label} ${appStyles.text_white} ${styles.card__label}`}>
                      Version 5 ({this.quote.lineItemsV5Count} items)
                    </div>
                    <div className={`${appStyles.card__value} ${appStyles.text_white}`}>{this.quote.valueV5Label}</div>
                  </div>
                ) : (
                  ''
                )}
                <div className={appStyles.card__row}>
                  <div className={`${appStyles.card__label} ${appStyles.text_white} ${styles.card__label_light}`}>
                    Quote valid through
                    {this.quote.validThrough ? ' ' + utils.dateTime.getFormattedDate(this.quote.validThrough) : ''}
                  </div>
                </div>
              </div>

              <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
                <h3 className={appStyles.heading__text_sm}>Download</h3>
              </div>
              <div className={`${appStyles.card} ${appStyles.background_white} ${appStyles.card_boxShadow}`}>
                <div className={appStyles.card__column}>
                  <FileDownloads dataType="quote" itemId={this.quote.quoteId}></FileDownloads>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
          <Button buttonStyle="outline_secondary" size="lg" handleClick={this.back}>
            Back to Quotes
          </Button>

          <Button id="buttonQuestion" buttonStyle="outline_primary" size="lg" handleClick={this.goToQuestion}>
            Have a question?
          </Button>

          {
            // Accept available only for Pending quotes, which are not expired
            this.quote.status === 'Pending' && this.quote.validThrough > new Date() && (
              <Button id="showAcceptForm" buttonStyle="primary" size="lg" handleClick={this.goToAccept}>
                Accept Quote
              </Button>
            )
          }
        </BottomButtonContainer>
      </>
    );
  }
}

export default withRouter(QuotesBreakdown);
