import { FC } from 'react';
import preloader from 'assets/images/preloader.gif';
import styles from './Header.module.scss';

interface IGlobalLoadingIndicatorProps {
  visible: boolean;
}

const GlobalLoadingIndicator: FC<IGlobalLoadingIndicatorProps> = ({ visible }) => {
  if (!visible) {
    return null;
  }

  return (
    <div id="headerLoader" className={styles.header__loader}>
      <img alt="Loading..." className={styles.preloader} src={preloader} />
    </div>
  );
};

export default GlobalLoadingIndicator;
