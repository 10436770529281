import { observable } from 'mobx';
import { BaseModel } from 'models/api/base.model';
import { MarketingPreference } from 'models/api/marketingPreference.model';
import { Role } from 'models/api/role.model';

export class Contact extends BaseModel {
  contactId: number | null = null;
  rowVersion: unknown = null;
  clientId: number | null = null;
  salutation: string | null = null;
  firstNames: string | null = null;
  surname: string | null = null;
  fullName: string | null = null;
  position: string | null = null;
  telephone: string | null = null;
  mobile: string | null = null;
  email: string | null = null;
  confirmEmail: string | null = null;
  addressId: number | null = null;
  notes: string | null = null;
  myPortalRole: number | null = null;
  myPortalIsLockedOut: boolean | null = null;
  formattedContactDetails: string | null = null;
  billingPreferences: {
    isPaperBilling: boolean | null;
  } = { isPaperBilling: null };
  @observable marketingPreferences: MarketingPreference[] = [];
  contactRoles: Role[] = [];
  myPortalIsValidated: boolean | null = null;
  myPortalIsValidatedDate: string | Date = new Date();
  canWriteMyPortalIsLockedOutProperty: boolean | null = null;
  canWriteMyPortalIsValidatedProperty: boolean | null = null;
  canWriteEmailProperty: boolean | null = null;
  canWriteMyPortalRoleProperty: boolean | null = null;
  originalEmail: string | null = null;
  isSaveConfirmationRequired: boolean | null = null;
  isSaveConfirmed: boolean | null = null;
  loginAccountStatus: string | null = null;
  lastLogin: string | Date = new Date();

  static getAllSearchableFieldsAsString(c: Contact): string {
    return '' + c.email + ',' + c.fullName + ',' + c.mobile + ',' + c.position + ',' + c.telephone + ',' + c.salutation + ',' + c.notes;
  }
}
