import React from 'react';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridFilterChangeEvent } from '@progress/kendo-react-grid';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { BaseServerGrid } from 'components/Generic/Grid/BaseServerGrid';
import { IColumn, ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { NotificationStatusColumnMenu } from 'components/Generic/Grid/NotificationStatusColumnMenu';
import { Notification } from 'models/api/notification.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import { ModelCollection } from 'models/generic/modelCollection.model';
import { NotificationsGridRequest } from 'models/requests/notificationsGridRequest.model';
import notificationsStore from 'stores/notifications.store';
import { NotificationStatuses } from 'utils/enums';
import appStyles from 'App.module.scss';
import { ReactComponent as DocumentsSVGLogo } from 'assets/images/svg/documents.svg';

interface INotificationsGridProps extends IBaseProps {
  notificationsToShow?: Notification[];
  searchKeyword?: string;
}

@observer
class NotificationsGrid extends BaseServerGrid<Notification, INotificationsGridProps, NotificationsGridRequest> {
  @observable sort: SortDescriptor[] = [{ field: 'time', dir: 'desc' }];
  @observable request = new NotificationsGridRequest('issuedOn DESC', this.take, [NotificationStatuses.Unread, NotificationStatuses.Read]);
  @observable filter: CompositeFilterDescriptor = {
    logic: 'or',
    filters: [
      { field: 'status', operator: 'eq', value: 'Unread' },
      { field: 'status', operator: 'eq', value: 'Read' }
    ]
  };
  @observable updatedRequest = false;

  setRequest = (shouldUpdate: boolean = false) => {
    this.updatedRequest = shouldUpdate;
  };

  async componentDidMount() {
    await this.loadData();
    notificationsStore.updateHandleLineFaultCheck(this.loadData);
  }

  componentWillUnmount() {
    notificationsStore.stopHandleLineFaultCheck(this.loadData);
  }

  async componentDidUpdate(prevProps: INotificationsGridProps) {
    const { searchKeyword } = this.props;

    if (searchKeyword && searchKeyword !== prevProps.searchKeyword) {
      this.setSearchKeyword(searchKeyword);
    }

    // reload data if request was updated
    this.handleReload(false, this.setRequest);
  }

  handleReload = async (notificationUpdated: boolean, setRequest: (shouldUpdate: boolean) => void) => {
    if (this.requestUpdated || notificationUpdated) {
      setRequest(false);

      await this.loadData();
    }
  };

  async getData(): Promise<ModelCollection<Notification>> {
    return await notificationsStore.getMyNotifications(undefined, this.request);
  }

  filterChange = (event: GridFilterChangeEvent) => {
    super.filterChange(event);

    // update request object
    this.request.includedStatusCodes = [];

    if (event.filter?.filters) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.parseFilterEvent(event).filters?.forEach((filter: any) => {
        switch (filter.value) {
          case 'Unread':
            this.request.includedStatusCodes.push(NotificationStatuses.Unread);
            break;
          case 'Read':
            this.request.includedStatusCodes.push(NotificationStatuses.Read);
            break;
          case 'Archived':
            this.request.includedStatusCodes.push(NotificationStatuses.Archived);
            break;
        }
      });
    }

    // reset to first page
    this.request.pageNumber = 1;
    this.skip = 0;

    // force refresh
    this.requestUpdated = true;
  };

  viewNotification = async (e: React.MouseEvent<HTMLDivElement, MouseEvent> | null, notification: Notification) => {
    notificationsStore.lastFetchedNotification = notification;

    this.props.history.push(`/notifications/${notification.notificationId}`);
  };

  render() {
    const columns: Array<IColumn> = [
      { field: 'issuedOn', title: 'Date Issued', dataType: 'date', size: 'md', currentFilter: this.filter },
      { field: 'priority', title: 'Priority', dataType: 'text', size: 'md', currentFilter: this.filter },
      { field: 'subject', title: 'Subject', dataType: 'text', size: 'lg', currentFilter: this.filter, isHtml: true },
      {
        field: 'status',
        title: 'Status',
        dataType: 'text',
        size: 'md',
        currentFilter: this.filter,
        columnMenu: this.props.notificationsToShow ? undefined : NotificationStatusColumnMenu
      }
    ];

    if (!this.props.notificationsToShow) {
      columns.push({
        field: '',
        title: 'View',
        dataType: 'icon',
        size: 'xs',
        cell: (props) => (
          <td className="k-table-td gridColumn_xs gridColumn_button">
            <div role="button" onClick={(e) => this.viewNotification(e, props.dataItem)} className={appStyles.button___grid_view}>
              <DocumentsSVGLogo title="View notification breakdown" height={32} width={32} />
            </div>
          </td>
        )
      });
    }

    const notifications = this.props.notificationsToShow?.map((notification) => ({
      ...notification,
      issuedOn: new Intl.DateTimeFormat('en-gb', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false
      }).format(new Date(notification?.issuedOn))
    }));

    return (
      <Grid
        resizable
        filterable={false}
        take={this.take}
        skip={this.skip}
        filter={this.pureFilter}
        data={this.props.notificationsToShow ? notifications : this.data.items}
        pageable={!this.props.notificationsToShow && { pageSizes: this.pageSizes }}
        total={this.props.notificationsToShow ? 1 : this.data.totalCount}
        onPageChange={this.pageChange}
        sortable={!this.props.notificationsToShow}
        sort={this.props.notificationsToShow ? undefined : toJS(this.sort)}
        onFilterChange={this.filterChange}
        onSortChange={this.sortChange}>
        {ColumnHelper.getGridColumns(columns)}
      </Grid>
    );
  }
}

export default withRouter(NotificationsGrid);
