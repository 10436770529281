import { BaseModel } from 'models/api/base.model';
import { ModulusCheck } from 'models/api/modulusCheck.model';

export class DirectDebit extends BaseModel {
  // Can we remove some of these properties from this model?

  directDebitId: number | null = null;
  contactId: number | null = null;
  clientId: number | null = null;
  bankSortCode: string | null = null;
  bankAccountNumber: string | null = null;
  bankAccountName: string | null = null;
  bankName: string | null = null;
  bankAccountVerificationMAC: string | null = null;
  directDebitReference: string | null = null;
  sageAccountNumber: string | null = null;
  sageAccountLinkId: number | null = null;
  sage200CompanyId: number | null = null;
  sage200CompanyName: string | null = null;
  selectedSageAccount: string | null = null;

  accountHolderConfirm: boolean | null = null;
  accountAuthConfirm: boolean | null = null;
  previousModulusCheck: ModulusCheck | null = null;
  modulusCheck: ModulusCheck | null = null;
  modulusFailed = false;
  eventCorrelationId: string | null = null;

  static getAllSearchableFieldsAsString(d: DirectDebit): string {
    return (
      '' + d.sageAccountNumber + ',' + d.bankSortCode + ',' + d.bankAccountNumber + ',' + d.bankAccountName + ',' + d.bankName + ',' + d.directDebitReference
    );
  }
}
