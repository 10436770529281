import { FC } from 'react';
import classNames from 'classnames';
import appStyles from 'App.module.scss';
import styles from './DashboardWidget.module.scss';

interface IDashboardWidgetProps {
  id?: string;
  SvgPicture: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  customClass?: string;
  childrenCustomClass?: string;
}

const DashboardWidget: FC<IDashboardWidgetProps> = ({ id, children, SvgPicture, title, customClass, childrenCustomClass }) => (
  <div className={classNames(appStyles.background_midBlue, styles.section, customClass)}>
    <div className={classNames(styles.header, appStyles.text_white)}>
      <SvgPicture className={classNames(styles.icon)} />
      <h2 className={styles.title}>{title}</h2>
    </div>
    <div className={classNames(appStyles.text_midBlue, styles.content, childrenCustomClass)} id={id}>
      {children}
    </div>
  </div>
);

export default DashboardWidget;
