import { FC } from 'react';
import moment from 'moment';
import { ReactComponent as ChevronRight } from 'assets/images/svg/chevronRight.svg';
import styles from './Newsfeed.module.scss';

interface INewsArticleProps {
  key: number;
  url: string;
  title: string;
  date: string;
  timeToRead: number;
}

const NewsArticle: FC<INewsArticleProps> = ({ url, title, date, timeToRead, ...rest }) => (
  <div className={styles.newsFeedLink} {...rest}>
    <a className={styles.newsFeedLink__title} target="_blank" rel="noopener noreferrer" href={url} title={title}>
      {title}
    </a>
    <div className={styles.newsFeedLink__details}>
      <span className={styles.newsFeedLink__details_text}>
        {moment(date, 'D MMMM YYYY').format('D MMM')} | Read Time: {timeToRead} Minutes
      </span>
      <ChevronRight width="50px" height="50px" className={styles.chevron_right} />
    </div>
  </div>
);

export default NewsArticle;
