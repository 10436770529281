import { FC } from 'react';
import classNames from 'classnames';
import appStyles from 'App.module.scss';
import styles from './InvoiceDetails.module.scss';

interface IInvoiceDetailProps {
  title: string;
}

const InvoiceDetail: FC<IInvoiceDetailProps> = ({ title, children }) => (
  <div className={appStyles.col_md_6}>
    <div className={classNames(appStyles.heading, appStyles.text_midBlue)}>
      <h3 className={appStyles.heading__text_sm}>{title}</h3>
    </div>
    <div className={classNames(appStyles.card, styles.card_invoices, appStyles.background_white, appStyles.card_boxShadow)}>{children}</div>
  </div>
);

export default InvoiceDetail;
