import Icon from 'components/Generic/Icon/icon';
import { NewTicketOptions } from 'models/generic/newTicketOptions.model';

export const newTicketServices: NewTicketOptions[] = [
  {
    id: 'Connect',
    headingText: 'Connect',
    description: 'Phone, Internet Access, Mobile, Data & Networks',
    buttonColour: 'connect',
    buttonIcon: Icon.connect
  },
  {
    id: 'Connect-Calls-Lines',
    headingText: 'Calls & Lines',
    description: 'Single Analogue Lines, ISDN, Non Geo Numbers & SIP',
    buttonColour: 'connect',
    parentId: 'Connect'
  },
  {
    id: 'Connect-Broadband',
    headingText: 'Broadband',
    description: 'Broadband, Fibre & Ethernet',
    buttonColour: 'connect',
    parentId: 'Connect'
  },
  {
    id: 'Connect-Mobile-Phone',
    headingText: 'Mobile Phone',
    description: 'Calls & Data',
    buttonColour: 'connect',
    parentId: 'Connect'
  },
  {
    id: 'Connect-Data-Networks',
    headingText: 'Data & Networks',
    description: 'MPLS, VPLS, P2P, SD & WAN',
    buttonColour: 'connect',
    parentId: 'Connect'
  },
  {
    id: 'Cloud',
    headingText: 'Cloud & ICT Infrastructure',
    description: 'Telephony Systems, Infrastructure & Software Services',
    buttonColour: 'cloud',
    buttonIcon: Icon.cloud
  },
  {
    id: 'Cloud-Voice',
    headingText: 'Voice',
    description: 'Telephony Systems',
    buttonColour: 'cloud',
    parentId: 'Cloud'
  },
  {
    id: 'Cloud-Network-Infrastructure',
    headingText: 'Network & Infrastructure',
    description: 'Azure, IaaS, WiFi & Infrastructure',
    buttonColour: 'cloud',
    parentId: 'Cloud'
  },
  {
    id: 'Cloud-Software',
    headingText: 'Software',
    description: 'Office 365, Microsoft Dynamics, Microsoft Forms, SharePoint & Sage',
    buttonColour: 'cloud',
    parentId: 'Cloud'
  },
  {
    id: 'Security',
    headingText: 'Security',
    description: 'Security Services',
    buttonColour: 'security',
    buttonIcon: Icon.lock
  },
  {
    id: 'Security-Breach',
    headingText: 'Security Breach',
    description: 'Report a security breach',
    buttonColour: 'security',
    parentId: 'Security'
  },
  {
    id: 'Security-Product',
    headingText: 'Product Support',
    description: 'Security, Software & Hardware Issues',
    buttonColour: 'security',
    parentId: 'Security'
  },
  {
    id: 'Security-Product-Endpoint',
    headingText: 'Endpoint',
    description: 'PCs, Servers & Mobile Devices',
    buttonColour: 'security',
    parentId: 'Security-Product'
  },
  {
    id: 'Security-Product-Gateways',
    headingText: 'Gateways',
    description: 'Firewall, Email & Web',
    buttonColour: 'security',
    parentId: 'Security-Product'
  },
  {
    id: 'Security-Product-Infrastructure',
    headingText: 'Infrastructure',
    description: 'Siem & Rapid 7',
    buttonColour: 'security',
    parentId: 'Security-Product'
  },
  {
    id: 'Devices',
    headingText: 'Devices',
    description: 'Tablets, Laptops, Mobile Phones & Desktops',
    buttonColour: 'devices',
    buttonIcon: Icon.laptop
  },
  {
    id: 'Devices-Tablets',
    headingText: 'Tablets',
    description: '',
    buttonColour: 'devices',
    parentId: 'Devices'
  },
  {
    id: 'Devices-Laptops',
    headingText: 'Laptops',
    description: '',
    buttonColour: 'devices',
    parentId: 'Devices'
  },
  {
    id: 'Devices-Mobile-Phones',
    headingText: 'Mobile Phones',
    description: '',
    buttonColour: 'devices',
    parentId: 'Devices'
  },
  {
    id: 'Devices-Desktops',
    headingText: 'Desktops',
    description: '',
    buttonColour: 'devices',
    parentId: 'Devices'
  }
];

export const newTicketCategories: NewTicketOptions[] = [
  {
    id: 'Technical-Services',
    headingText: 'Technical Services',
    description: 'IT Support or to report a fault',
    buttonColour: 'orange'
  },
  {
    id: 'Account-Billing',
    headingText: 'Account & Billing',
    description: 'General account queries',
    buttonColour: 'marginBlue'
  }
];
