export class Returns {
  rma: number | null = null;
  clientId: number | null = null;
  addressId: number | null = null;
  contactId: number | null = null;
  origDateRecvd: Date | null = null;
  recordType: string | null = null;
  status: string | null = null;
  prodCode: string | null = null;
  prodDesc: string | null = null;
  qty: number | null = null;
  invNo: string | null = null;
  reason: string | null = null;
  addNotes: string | null = null;

  static getAllSearchableFieldsAsString(r: Returns): string {
    return '' + r.rma + ',' + r.recordType + ',' + r.status + ',' + r.prodCode + ',' + r.prodDesc + ',' + r.invNo + ',' + r.reason + ',' + r.addNotes;
  }
}
