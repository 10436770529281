import { FC } from 'react';
import { observer } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';
import classNames from 'classnames';
import { Notification } from 'models/api/notification.model';
import appStyles from 'App.module.scss';
import styles from '../Notifications.module.scss';

interface INotificationInfoProps {
  notification?: Notification | null;
}

const NotificationInfo: FC<INotificationInfoProps> = ({ notification }) => (
  <div className={styles.notificationsSection} id="notificationBreakdownSection">
    <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
      <h3 id="notificationBreakdownTitle" className={appStyles.heading__text_sm}>
        Notification Breakdown
      </h3>
    </div>

    <form className={appStyles.row}>
      <div id="notificationDetailsCard" className={appStyles.col_md_6}>
        <div className={appStyles.form__row}>
          <div className={appStyles.form__label}>Subject</div>
          <div className={appStyles.form__readOnlyElement}>{ReactHtmlParser(notification?.subject || '')}</div>
        </div>
        {notification?.issuedOn && (
          <div className={appStyles.form__row}>
            <div className={appStyles.form__label}>Date Issued</div>
            <div className={appStyles.form__readOnlyElement}>
              {new Intl.DateTimeFormat('en-gb', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: false
              }).format(new Date(notification.issuedOn))}
            </div>
          </div>
        )}
        <div className={appStyles.form__row}>
          <div className={appStyles.form__label}>Status</div>
          <div className={appStyles.form__readOnlyElement}>{notification?.status}</div>
        </div>
        <div className={appStyles.form__row}>
          <div className={appStyles.form__label}>Issued By</div>
          <div className={appStyles.form__readOnlyElement}>{notification?.issuedBy}</div>
        </div>
      </div>
      <div id="notificationDetailsCard" className={appStyles.col_md_6}>
        <div className={appStyles.form__row}>
          <div className={appStyles.form__label}>Message</div>
          <div className={classNames(appStyles.form__readOnlyElement, appStyles.form__message)}>{ReactHtmlParser(notification?.message ?? '')}</div>
        </div>
      </div>
    </form>
  </div>
);

export default observer(NotificationInfo);
