import { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import availabilityChecksStore from 'stores/availabilityChecks.store';
import constants from 'utils/constants';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import appStyles from 'App.module.scss';
import availabilityCheckerStyles from '../AvailabilityChecker.module.scss';

export default function ConfirmCallbackRequest() {
  const history = useHistory();

  useEffect(() => {
    availabilityChecksStore.cleanUpStore();
  }, []);

  const handleCheckAnotherAddress: React.MouseEventHandler<HTMLButtonElement> = useCallback(async () => {
    availabilityChecksStore.cleanUpStore();
    history.push('/services/availability-checker');
  }, [history]);

  return (
    <div className={classNames(appStyles.container_center, availabilityCheckerStyles.availabilityChecker__container)}>
      <div className={appStyles.pageHeading_lg}>
        <h1 className={classNames(appStyles.heading__text, appStyles.text_midBlue, availabilityCheckerStyles.center_text)}>FTTP Availability Checker</h1>
      </div>
      <p className={availabilityCheckerStyles.subheading}>We will be in touch shortly.</p>
      <p className={classNames(availabilityCheckerStyles.container__boxing, availabilityCheckerStyles.center_text)}>
        Thank you for your interest in our FTTP products. One of our experienced product specialists will be in touch soon. In the meantime, if you would like
        to contact our sales team directly, please call {constants.keyVariables.salesTeamTelephone} and choose option 4.
      </p>
      <BottomButtonContainer backgroundColor="white" layout="left">
        <Button size="lg" buttonStyle="primary" id="btnSearchAddresses" handleClick={handleCheckAnotherAddress}>
          <span>Check Another Address</span>
        </Button>
      </BottomButtonContainer>
    </div>
  );
}
