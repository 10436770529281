import { Component } from 'react';
import classNames from 'classnames';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { ListItem } from 'models/generic/listItem.model';
import lineFaultsStore from 'stores/lineFaults.store';
import CheckBox from 'components/Generic/FormElements/Checkbox/Checkbox';
import appStyles from 'App.module.scss';
import lineCheckResultsStyles from './LineCheckResults.module.scss';

interface INoFaultFoundProps {
  hasLineAssurance: boolean;
}

const options = [
  new ListItem(
    'FirstLineChecksConfirmed',
    'I confirm that I have carried out the above checks but still have a fault on my line and wish to proceed with booking an engineer appointment.'
  )
];

@observer
class NoFaultFound extends Component<INoFaultFoundProps> {
  @observable accepted = false;

  componentDidMount() {
    this.accepted = lineFaultsStore.lineFaultCheck?.noFaultCheckListConfirmed ?? false;
  }

  @computed get checkBoxSelectedName() {
    return this.accepted ? 'FirstLineChecksConfirmed' : '';
  }

  @action onCheckBoxChanged = () => {
    this.accepted = !this.accepted;
    lineFaultsStore.updateAcknowledgeAcceptance({ noFaultCheckListConfirmed: this.accepted });
  };

  render() {
    return (
      <div className={classNames(lineCheckResultsStyles.message__container, lineCheckResultsStyles.message__default)}>
        <p className={classNames(lineCheckResultsStyles.message__general, appStyles.text_tangaroaBlue)} id="lineFaultCheckResultsHeader">
          No fault has been found on this line.
        </p>
        <p className={appStyles.text_darkGrey} id="lineFaultCheckResultsMessage">
          You can still book an engineer appointment, but please first study the following diagnostic checklist.
        </p>

        <div className={lineCheckResultsStyles.firstLineChecklist}>
          <ul>
            <li>
              <p className={appStyles.text_darkGrey}>Check that you have power to the device exhibiting the fault. If not, correct this issue first.</p>
            </li>
            <li>
              <p className={appStyles.text_darkGrey}>
                If you have not already tried powering down your router, please do so. It can often help to leave it off for at least 30 minutes in some
                instances, before powering it back on.
              </p>
            </li>
            <li>
              <p className={appStyles.text_darkGrey}>
                If you are experiencing a Wi-Fi issue, try powering down the device trying to connect to the Internet, then powering it back on. If it is a
                desktop or laptop computer please use the restart option from the Start menu power options.
              </p>
            </li>
            <li>
              <p className={appStyles.text_darkGrey}>In case of a Wi-Fi issue, try connecting the device to your network using an Ethernet cable.</p>
            </li>
            <li>
              <p className={appStyles.text_darkGrey}>
                Try swapping the microfilter your router is plugged into for a new one, where applicable. Please note, a microfilter needs to be plugged in to
                all phone sockets in the premises even if you're only using one phone socket.
              </p>
            </li>
          </ul>
        </div>

        <div id="firstLineSupportConfirmed" className={lineCheckResultsStyles.checkbox__container}>
          <CheckBox
            name="FirstLineChecksConfirmed"
            options={options}
            selectedOptions={[this.checkBoxSelectedName]}
            handleChange={this.onCheckBoxChanged}
            customLabelClass={lineCheckResultsStyles.checkbox__label}
            customInputClass={appStyles.checkbox}
          />
        </div>
        <p className={classNames(appStyles.text_tangaroaBlue, appStyles.info_bold)}>
          {this.props.hasLineAssurance
            ? 'Great news, this engineer appointment would normally be chargeable but because you have Line Assurance it will be free!'
            : 'You can still book an engineer appointment, but you will be charged for call out and on site work carried out.'}
        </p>
      </div>
    );
  }
}

export default NoFaultFound;
