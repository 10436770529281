import { BaseApi } from 'api/baseApi';
import { AxiosError } from 'axios';
import { FalconError } from 'models/generic/falconError.model';
import messagesStore from 'stores/messages.store';

export class BankingApi extends BaseApi {
  constructor() {
    super();

    this.baseApiUrl = process.env.REACT_APP_BANKING_API_BASE_URL || '';
  }

  protected getResultFromError(error: AxiosError, friendlyErrorMessage: string | null): FalconError {
    const apiError = new FalconError();

    apiError.errorMessages.push(friendlyErrorMessage || `Something went wrong.`);
    messagesStore.addError(apiError);

    return apiError;
  }
}

const bankingApi = new BankingApi();
export default bankingApi;
