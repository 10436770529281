import React, { ChangeEvent, Component, RefObject } from 'react';
import classNames from 'classnames';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Alert from 'components/Generic/Alert/Alert';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Input from 'components/Generic/FormElements/Input/Input';
import { InvoicesBreakdown } from 'components/Invoices/Invoices/InvoicesBreakdown/InvoicesBreakdown';
import InvoicesGrid from 'components/Invoices/Invoices/InvoicesGrid/InvoicesGrid';
import { IBaseProps } from 'models/generic/iBaseProps';
import { Invoice } from 'models/api/invoice.model';
import invoicesStore from 'stores/invoices.store';
import { IMatchResult } from 'utils/routing';
import utils from 'utils/utils';
import appStyles from 'App.module.scss';
import styles from './ManageInvoices.module.scss';

@observer
export class ManageInvoices extends Component<IBaseProps> {
  @observable selectedInvoice: Invoice | null = null;
  @observable searchInputValue = ''; // current search box value
  @observable searchKeyword = ''; // value passed into grid for search once user finished typing
  @observable breakdownRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  subscriptionId = '';

  constructor(props: IBaseProps) {
    super(props);

    // monitor route changes
    this.subscriptionId = utils.routing.subscribe((location) => {
      const match = utils.routing.matchRoutes(location, ['/invoices/:id', '/invoices']);
      this.setUpView(match);
    });
  }

  componentDidMount() {
    this.setUpView(this.props.match);
  }

  componentWillUnmount() {
    utils.routing.unsubscribe(this.subscriptionId);
  }

  // eslint-disable-next-line complexity
  async setUpView(match: IMatchResult | null) {
    let invoiceNumber: string | null = null;
    if (
      match &&
      match.params.id !== 'direct-debit' &&
      match.params.id !== 'make-a-payment' &&
      match.params.id !== 'manage-direct-debit' &&
      match.params.id !== 'payment-summary' &&
      match.params.id !== 'payment-succeeded' &&
      match.params.id !== 'payment-failed'
    ) {
      invoiceNumber = match.params.id;
    }

    // if ID present then load invoice and scroll down to breakdown
    if (invoiceNumber) {
      this.selectedInvoice = await invoicesStore.getInvoice(invoiceNumber);

      if (this.breakdownRef.current) {
        window.scrollTo(0, this.breakdownRef.current.offsetTop);
      }
    } else {
      // otherwise show grid and scroll to top
      this.selectedInvoice = null;
      window.scrollTo(0, 0);
    }
  }

  handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    // update value displayed in search box
    this.searchInputValue = e.target.value;
  };

  handleSearchFinishedTyping = (value: string) => {
    // pass value to grid for search once user stopped typing
    this.searchKeyword = value;
  };

  render() {
    return (
      <>
        <div className={appStyles.container}>
          <div className={appStyles.pageHeading_lg}>
            <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
              <h1 className={appStyles.heading__text}>Invoices</h1>
            </div>

            {!this.selectedInvoice && (
              <div className={styles.search__container}>
                <Input
                  elementId="searchBar"
                  inputType="search"
                  name="searchBar"
                  labelText="Search Invoices"
                  labelHidden={true}
                  placeholder="Search Invoices"
                  value={this.searchInputValue}
                  handleChange={this.handleSearchChange}
                  handleFinishedTyping={this.handleSearchFinishedTyping}
                  customLabelClass={appStyles.form__label}
                  customInputClass={appStyles.form__input}
                  autocomplete="off"
                />
              </div>
            )}
          </div>
          {!this.selectedInvoice && (
            <Alert alertType="blank">
              <p className={classNames(appStyles.text_darkGrey, appStyles.info__main)}>
                You can view all your invoices, simply select an invoice to see a full breakdown.
              </p>
            </Alert>
          )}
          <InvoicesGrid searchKeyword={this.searchKeyword} selectedInvoice={this.selectedInvoice} />
        </div>
        <div ref={this.breakdownRef}>{!!this.selectedInvoice && <InvoicesBreakdown invoice={this.selectedInvoice} />}</div>

        {!this.selectedInvoice && (
          <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
            <Link
              className={`${appStyles.button} ${appStyles.button_outline_secondary} ${appStyles.button_lg}`}
              to={'/invoices/manage-direct-debit'}
              title="manage direct debits">
              Manage Direct Debits
            </Link>
            <Link
              className={`${appStyles.button} ${appStyles.button_primary} ${appStyles.button_lg}`}
              to={'/invoices/make-a-payment'}
              title="pay outstanding invoices">
              Make a Payment
            </Link>
          </BottomButtonContainer>
        )}
      </>
    );
  }
}
