import React, { ChangeEvent } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link, Prompt } from 'react-router-dom';
import Alert from 'components/Generic/Alert/Alert';
import { BaseUnsavedChangesComponent } from 'components/Generic/BaseUnsavedChangesComponent';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import Input from 'components/Generic/FormElements/Input/Input';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import Icon from 'components/Generic/Icon/icon';
import { PaymentDetails } from 'models/api/paymentDetails.model';
import { SagePayFormData } from 'models/api/sagePayFormData.model';
import { ValidationRule } from 'models/api/validationRule.model';
import { FalconError } from 'models/generic/falconError.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import { ValidationErrors } from 'models/generic/validationError.model';
import invoicesStore from 'stores/invoices.store';
import constants from 'utils/constants';
import utils from 'utils/utils';
import validation from 'utils/validation';
import appStyles from 'App.module.scss';
import styles from '../Invoices/ManageInvoices.module.scss';
import SelectedInvoicesGrid from '../SelectedInvoicesGrid/SelectedInvoicesGrid';

@observer
export class PaymentSummary extends BaseUnsavedChangesComponent<IBaseProps> {
  @observable totalToPay = 0;
  @observable paymentDetails = new PaymentDetails();
  @observable paymentDetailsErrors: ValidationErrors = new ValidationErrors();
  @observable showValidationSummary = false;
  @observable formValid = true;
  @observable saveInProgress = false;
  @observable sagePayInfo = new SagePayFormData();
  @observable sagePayUrl = '';
  @observable vpsProtocol = '';
  @observable txType = '';
  @observable vendor = '';
  @observable crypt = '';

  submitAttempted = false;
  validationRules: ValidationRule[] = [];
  formRef: React.RefObject<HTMLFormElement>;

  constructor(props: IBaseProps) {
    super(props);

    this.formRef = React.createRef();

    if (!invoicesStore.selectedInvoices) {
      this.props.history.push('/invoices/make-a-payment');
    }

    this.setTotalAmount = this.setTotalAmount.bind(this);
    this.goToSagePage = this.goToSagePage.bind(this);
    this.setPaymentDetails = this.setPaymentDetails.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  async componentDidMount() {
    this.validationRules = await invoicesStore.getPaymentDetailsValidationRules();

    if (invoicesStore.paymentDetails) {
      this.paymentDetails = invoicesStore.paymentDetails;
    }
  }

  setTotalAmount(value: number) {
    this.totalToPay = value;
  }

  handleInput = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    this.unsavedChange = true;

    this.paymentDetails = {
      ...this.paymentDetails,
      [name]: value
    };

    if (this.submitAttempted) {
      this.validateAll();
    } else {
      this.validateInfoAndWarnings();
    }
  };

  validateInfoAndWarnings() {
    const infoBrokenRules = this.paymentDetails.brokenRulesCollection.filter((rule) => rule.severity === constants.validation.severity.info);
    this.paymentDetailsErrors = validation.getValidationErrorsFromBrokenRules(infoBrokenRules);
    validation.validate(this.paymentDetails, this.validationRules, this.paymentDetailsErrors, 'warning');
  }

  validateAll(): boolean {
    this.paymentDetailsErrors = new ValidationErrors();
    // validate info and warnings before also validating errors
    this.validateInfoAndWarnings();
    this.formValid = validation.validate(this.paymentDetails, this.validationRules, this.paymentDetailsErrors);

    // only show summary if there are warnings or errors
    if (!this.paymentDetailsErrors.hasWarningSeverities && !this.paymentDetailsErrors.hasErrorSeverities) {
      this.showValidationSummary = false;
    } else {
      this.showValidationSummary = true;
    }

    return this.formValid;
  }

  getValidationSummary(): JSX.Element {
    let result: JSX.Element = <></>;

    if (this.showValidationSummary) {
      this.paymentDetails.isSaveConfirmed = true;

      if (this.paymentDetailsErrors.hasWarningSeverities) {
        if (this.paymentDetailsErrors.hasErrorSeverities) {
          result = (
            <Alert alertType="error" customClass="validationSummary">
              <span id="errorsAndWarnings">
                Please review and resolve the validation errors below. There are also some validation warning messages we'd like to make you aware of before
                submitting your request.
              </span>
            </Alert>
          );
        } else {
          result = (
            <Alert alertType="warning" customClass="validationSummary">
              <span id="warningsOnly">
                Please review the form as there are some validation messages we'd like to make you aware of before submitting your request. If you'd like to
                save without addressing the issues, click the "Pay now" button again.
              </span>
            </Alert>
          );
        }
      } else if (this.paymentDetailsErrors.hasErrorSeverities) {
        result = (
          <Alert alertType="error" customClass="validationSummary">
            <span id="errorsOnly">Please resolve the validation errors below before submitting.</span>
          </Alert>
        );
      }
    }

    return result;
  }

  async setPaymentDetails() {
    invoicesStore.paymentDetails = this.paymentDetails;

    this.paymentDetails.ipAddress = await utils.ip.getCurrentIpAddress();
    this.paymentDetails.paymentAmount = parseFloat(this.totalToPay.toFixed(2));

    this.paymentDetails.billingCountry = 'GB';
    this.paymentDetails.isSaveConfirmed = false;
    this.paymentDetails.isSaveConfirmationRequired = false;
  }

  submitForm(result: SagePayFormData) {
    this.sagePayUrl = result.sagePayUrl ? result.sagePayUrl : '';
    this.vpsProtocol = result.vpsProtocol ? result.vpsProtocol : '';
    this.txType = result.txType ? result.txType : '';
    this.vendor = result.vendor ? result.vendor : '';
    this.crypt = result.crypt ? result.crypt : '';

    if (this.formRef.current) {
      this.formRef.current.submit();
    }
  }

  async goToSagePage() {
    await this.setPaymentDetails();
    const invoiceIds = invoicesStore.selectedInvoices;

    if (!invoiceIds || (invoiceIds && invoiceIds.length === 0)) {
      this.saveInProgress = false;

      return;
    }

    if (invoiceIds) {
      this.paymentDetails.invoiceNumbers = invoiceIds.map((i) => i.toString());
    }

    this.saveInProgress = true;
    this.submitAttempted = true;
    this.validateAll();
    if (this.showValidationSummary) {
      window.scrollTo(0, 0);
    }

    let result;

    if (this.formValid && (!this.showValidationSummary || this.paymentDetails.isSaveConfirmed)) {
      result = await invoicesStore.getSagePayUrl(this.paymentDetails);

      // if result is error than check for validation messages
      if (result instanceof FalconError) {
        this.paymentDetailsErrors = validation.getValidationErrorsFromFalconError(result);
        this.showValidationSummary = true;
      } else {
        this.unsavedChange = false;
        this.submitForm(result);
      }
    }

    this.saveInProgress = false;
  }

  render() {
    return (
      <>
        <Prompt
          when={this.unsavedChange && BaseUnsavedChangesComponent.enableCheck}
          message="You have unsaved changes. Are you sure you want to navigate away from this page?"
        />

        <div className={appStyles.container}>
          <div className={appStyles.pageHeading_lg}>
            <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
              <Icon icon={Icon.invoices} size="heading" color="currentColor" iconName="invoice icon" customClass={appStyles.heading__icon} />
              <h1 className={appStyles.heading__text}>Review your payment</h1>
            </div>
          </div>
          {this.getValidationSummary()}
          <Alert alertType="blank">
            <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>
              Below is a summary of your transaction. Please review, fill out the address of the cardholder and then click Pay Now to proceed to our secure
              payment page and complete the transaction.
            </p>
          </Alert>

          <div className={styles.paymentSummary}>
            <div className={appStyles.row}>
              <div className={appStyles.col_md_12}>
                <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
                  <h2 className={appStyles.heading__text_sm}>Breakdown</h2>
                </div>
                <SelectedInvoicesGrid setTotalAmount={this.setTotalAmount} />
              </div>
            </div>
            <div className={`${appStyles.row}`}>
              <form className={`${appStyles.col_md_6} ${styles.addressCard}`}>
                <div id="addressDetailsCard">
                  <fieldset>
                    <Legend type="large" text="Cardholder Details" />
                    <div className={appStyles.form__row}>
                      <Input
                        elementId="billingFirstnames"
                        inputType="text"
                        labelText="First Name"
                        name="billingFirstnames"
                        placeholder="Enter your first name"
                        handleChange={this.handleInput}
                        value={this.paymentDetails.billingFirstnames}
                        autocomplete="given-name"
                        validationError={this.paymentDetailsErrors.fieldErrors['billingFirstnames']}
                      />
                    </div>
                    <div className={appStyles.form__row}>
                      <Input
                        elementId="billingSurname"
                        inputType="text"
                        labelText="Surname"
                        name="billingSurname"
                        placeholder="Enter your surname"
                        handleChange={this.handleInput}
                        value={this.paymentDetails.billingSurname}
                        autocomplete="family-name"
                        validationError={this.paymentDetailsErrors.fieldErrors['billingSurname']}
                      />
                    </div>
                    <div className={appStyles.form__row}>
                      <Input
                        elementId="billingAddress1"
                        inputType="text"
                        labelText="Address Line 1"
                        name="billingAddress1"
                        placeholder="Enter the first line of your address"
                        handleChange={this.handleInput}
                        value={this.paymentDetails.billingAddress1}
                        autocomplete="address-line1"
                        validationError={this.paymentDetailsErrors.fieldErrors['billingAddress1']}
                      />
                    </div>
                    <div className={appStyles.form__row}>
                      <Input
                        elementId="billingAddress2"
                        inputType="text"
                        labelText="Address Line 2"
                        name="billingAddress2"
                        placeholder="Enter the second line of your address"
                        handleChange={this.handleInput}
                        value={this.paymentDetails.billingAddress2}
                        autocomplete="address-line2"
                        validationError={this.paymentDetailsErrors.fieldErrors['billingAddress2']}
                      />
                    </div>
                    <div className={appStyles.form__row}>
                      <Input
                        elementId="billingCity"
                        inputType="text"
                        labelText="City"
                        name="billingCity"
                        placeholder="Enter the first line of your address"
                        handleChange={this.handleInput}
                        value={this.paymentDetails.billingCity}
                        autocomplete="address-level2"
                        validationError={this.paymentDetailsErrors.fieldErrors['billingCity']}
                      />
                    </div>
                    <div className={appStyles.form__row}>
                      <Input
                        elementId="billingPostCode"
                        inputType="text"
                        labelText="Postcode"
                        name="billingPostCode"
                        placeholder="Enter the first line of your address"
                        handleChange={this.handleInput}
                        value={this.paymentDetails.billingPostCode}
                        autocomplete="postal-code"
                        validationError={this.paymentDetailsErrors.fieldErrors['billingPostCode']}
                      />
                    </div>
                  </fieldset>
                </div>
              </form>
              <div className={appStyles.col_md_6}>
                <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
                  <h3 className={appStyles.heading__text_sm}>To Pay</h3>
                </div>
                <div className={`${appStyles.card} ${styles.card_total} ${appStyles.background_midBlue} ${appStyles.card_boxShadow}`}>
                  <div className={appStyles.card__row}>
                    <div className={`${appStyles.card__label} ${appStyles.text_white}`}>Total Amount</div>
                    <div className={`${appStyles.card__value} ${appStyles.text_white}`}>{utils.currency.formatNumberAsCurrency(this.totalToPay)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <form method="POST" action={this.sagePayUrl} id="sageForm" ref={this.formRef}>
            <input type="hidden" id="VPSProtocol" name="VPSProtocol" value={this.vpsProtocol} />
            <input type="hidden" id="TxType" name="TxType" value={this.txType} />
            <input type="hidden" id="Vendor" name="Vendor" value={this.vendor} />
            <input type="hidden" id="Crypt" name="Crypt" value={this.crypt} />
          </form>
        </div>

        <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
          <Link
            className={`${appStyles.button} ${appStyles.button_outline_secondary} ${appStyles.button_lg}`}
            to={'/invoices/make-a-payment'}
            title="back to selection">
            Back
          </Link>
          <Button
            id="payNow"
            title="Continue to payment details"
            buttonStyle="primary"
            size="lg"
            handleClick={this.goToSagePage}
            inProgress={this.saveInProgress}>
            Pay Now
          </Button>
        </BottomButtonContainer>
      </>
    );
  }
}
