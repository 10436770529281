import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthenticationResult } from '@azure/msal-browser';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { IBaseProps } from 'models/generic/iBaseProps';
import navigationStore from 'stores/navigation.store';
import userStore from 'stores/user.store';
import auth from 'utils/auth';
import constants from 'utils/constants';
import { errorMessages } from 'utils/messages';
import LoginForm from './LoginForm';
import LoginWaiting from './LoginWaiting';

@observer
export class Login extends Component<IBaseProps> {
  @observable error: Error | null = null;
  @observable inProgress = false;
  @observable firstLoading = true;

  get employeeLogin() {
    return this.props.location.search?.includes('employee');
  }

  get isWaitingView() {
    return this.employeeLogin || this.firstLoading;
  }

  get errorMessage() {
    return this.error ? this.error.message || errorMessages.defaultLoginErrorMessage : null;
  }

  get referrer() {
    return document.referrer.toLowerCase();
  }

  componentDidMount = () => {
    auth.registerRedirectHandlers(this.handleB2CRedirectSuccess, this.handleB2CAuthError);

    if (this.employeeLogin) {
      this.firstLoading = false;

      auth.redirectToEmployeeLogin();
    }
  };

  @action logIn = (e: React.FormEvent) => {
    e.preventDefault();
    this.error = null;
    this.inProgress = true;

    auth.redirectToLogin();
  };

  @action handleB2CRedirectSuccess = async (response: AuthenticationResult | null) => {
    if (!response) {
      this.firstLoading = false;

      return;
    }

    this.error = null;

    const { history } = this.props;

    // callback to fire only when it is for Login and not from Reset Password
    if (response.account && !auth.isPasswordReset(this.referrer)) {
      this.inProgress = true;

      try {
        // store account/user details
        userStore.logIn(response.account);

        if (userStore.currentUser) {
          await userStore.loadClients();

          if (userStore.currentUser.roles.includes(constants.roleNames.MyPortalSwitchAccount)) {
            history.push('/switch-account');
          } else if (userStore.currentUser.clients.length > 0) {
            // select first client and navigate to dashboard
            userStore.setSelectedClient(userStore.currentUser.clients[0]);

            // redirect to originally requested page or dashboard
            navigationStore.redirectToRequestedUrl(history);
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        this.error = error;
      } finally {
        this.inProgress = false;
        this.firstLoading = false;
      }
    } else {
      // clear storage in callback after reset password
      localStorage.clear();
    }
  };

  handleB2CAuthError = (error: Error) => {
    this.firstLoading = false;

    if (error.message.includes('The user has forgotten their password')) {
      auth.redirectToResetPassword();
    } else {
      this.setError(error);
    }
  };

  setError = (error: Error) => {
    const errorMessage = auth.getMessageFromError(error.message);

    if (errorMessage) {
      this.error = new Error(errorMessage);
    }
  };

  render() {
    if (userStore.currentUser) {
      return <Redirect to="/dashboard" />;
    }

    return this.isWaitingView ? <LoginWaiting /> : <LoginForm onSubmit={this.logIn} inProgress={this.inProgress} errorMessage={this.errorMessage} />;
  }
}
