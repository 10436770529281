import React, { FC } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { IBaseProps } from 'models/generic/iBaseProps';
import constants from 'utils/constants';

export interface IAnonymousGuardProps extends RouteProps {
  pageTitle: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AnonymousRoute: FC<IAnonymousGuardProps> = ({ component, ...rest }: any) => {
  // eslint-disable-next-line complexity
  const routeComponent = (props: IBaseProps) => {
    // set page title
    if (rest.pageTitle) {
      document.title = constants.applicationTitle + ': ' + rest.pageTitle;
    }

    return React.createElement(component, props);
  };

  return <Route {...rest} render={routeComponent} />;
};
