import { FC, useMemo } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import lineCheckResultsStyles from './LineCheckResults.module.scss';

interface IFaultFoundProps {
  automaticallyRaised: boolean;
  ticketId?: number | null;
  message: string;
}

const FaultFound: FC<IFaultFoundProps> = ({ automaticallyRaised, ticketId, message }) => {
  const output = useMemo(
    () =>
      !automaticallyRaised ? (
        message
      ) : (
        <>
          A fault has been found on your line and will be passed to an engineer immediately. The fault will be resolved in line with your service level
          agreement. We have opened a ticket for you with reference number {ticketId} which can be found
          <Link to={`/tickets/${ticketId}`}> here</Link>.
        </>
      ),
    [automaticallyRaised, ticketId, message]
  );

  return (
    <div className={classNames(lineCheckResultsStyles.message__container, lineCheckResultsStyles.message__warning)}>
      <p className={lineCheckResultsStyles.message__details} id="lineFaultCheckResultsMessage">
        {output}
      </p>
    </div>
  );
};

export default FaultFound;
