import { FC } from 'react';
import classNames from 'classnames';
import { IAvailableProduct } from 'models/api/availableProduct.model';
import Alert from 'components/Generic/Alert/Alert';
import appStyles from 'App.module.scss';
import { getProductSpeedString } from 'utils/string';
import availabilityCheckerStyles from '../AvailabilityChecker.module.scss';
import FttpProductsList from './FttpProductsList/FttpProductsList';

interface IAvailableProductProps {
  errorMessage: string;
  availableProducts: IAvailableProduct[];
  onOrderClick: (product: IAvailableProduct) => void;
}

const AvailableProduct: FC<IAvailableProductProps> = ({ errorMessage, availableProducts, onOrderClick }) =>
  availableProducts.length ? (
    <>
      <Alert alertType="blank" customClass={availabilityCheckerStyles.alertFullWidth}>
        <p className={classNames(appStyles.text_tangaroaBlue, appStyles.info__sub, availabilityCheckerStyles.boldText)}>
          Choose from our latest full fibre offers.
        </p>
        <p className={classNames(appStyles.text_darkGrey, appStyles.info__sub)}>
          Enjoy speeds of up to {getProductSpeedString(Math.max(...availableProducts.map((o) => o.downstreamSpeed)))} so you have uninterrupted video calling,
          unlimited people and devices on the network and consistent connection quality. If you would like to proceed with placing an order for Ultrafast Fibre,
          please click the 'Ready to order?' button below.
        </p>
      </Alert>

      <FttpProductsList availableProducts={availableProducts} onOrderClick={onOrderClick} />
    </>
  ) : (
    <p className={availabilityCheckerStyles.center_text}>{errorMessage}</p>
  );

export default AvailableProduct;
