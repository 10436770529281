import { Component } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { IBaseProps } from 'models/generic/iBaseProps';
import userStore from 'stores/user.store';
import notificationsStore from 'stores/notifications.store';
import constants from 'utils/constants';
import { checkNotificationsAvailability } from 'utils/notifications';
import appStyles from 'App.module.scss';
import { ReactComponent as NotificationImage } from 'assets/images/svg/bell.svg';
import { ReactComponent as CaseSVGLogo } from 'assets/images/svg/case.svg';
import { ReactComponent as InfoSVGLogo } from 'assets/images/svg/info.svg';
import { ReactComponent as LifeBouy } from 'assets/images/svg/lifeBouy.svg';
import { ReactComponent as StartSVGLogo } from 'assets/images/svg/start.svg';
import chessLogo from 'assets/images/chess_logo.png';
import LatestNotifications from './LatestNotifications/LatestNotifications';
import DashboardWidget from './DashboardWidget/DashboardWidget';
import CustomerAccount from './CustomerAccount/CustomerAccount';
import { LatestTickets } from './LatestTickets/LatestTickets';
import Shortcuts from './Shortcuts/Shortcuts';
import Newsfeed from './Newsfeed/Newsfeed';
import Ad from './Ad/Ad';
import BannerNotification from './BannerNotification/BannerNotification';
import styles from './Dashboard.module.scss';
import shortcutsStyles from './Shortcuts/Shortcuts.module.scss';
import newsfeedStyles from './Newsfeed/Newsfeed.module.scss';
import accountStyles from './CustomerAccount/CustomerAccount.module.scss';

@observer
export default class Dashboard extends Component<IBaseProps> {
  handleRequestNotificationPermissions = async () => {
    if (location.search.includes('enableNotifications')) {
      await checkNotificationsAvailability();
    }
  };

  componentDidMount() {
    this.handleRequestNotificationPermissions();
    notificationsStore.getMyLatestNotifications();
  }

  componentDidUpdate() {
    this.handleRequestNotificationPermissions();
  }

  render() {
    if (!userStore.currentUser?.currentClient) {
      return null;
    }

    return (
      <div className={styles.dashboard}>
        <h1 className={appStyles.srOnly}>Dashboard</h1>
        <div className={styles.dashboard__logoContainer}>
          <img className={styles.dashboard__logo} src={chessLogo} alt="chess logo" />
        </div>
        <BannerNotification />
        <div className={styles.row}>
          <div className={styles.dashboard__column}>
            {userStore.currentUser.currentClient.name && (
              <DashboardWidget
                SvgPicture={CaseSVGLogo}
                title="Customer Details"
                customClass={styles.column_default_bottom_space}
                childrenCustomClass={accountStyles.client_name}>
                <CustomerAccount name={userStore.currentUser.currentClient.name} />
              </DashboardWidget>
            )}
            {userStore.currentUser.hasRole(constants.roleNames.Support) && (
              <DashboardWidget
                id="latestTicketsSection"
                SvgPicture={LifeBouy}
                title="Tickets"
                customClass={styles.max_height_section}
                childrenCustomClass={classNames(styles.tickets_icon, styles.latestTickets__container)}>
                <LatestTickets />
              </DashboardWidget>
            )}
          </div>
          <div className={styles.dashboard__column}>
            <DashboardWidget
              SvgPicture={InfoSVGLogo}
              title="Blogs & News"
              customClass={classNames(styles.column_default_bottom_space, styles.max_height_section)}
              childrenCustomClass={newsfeedStyles.news_container}>
              <Newsfeed />
            </DashboardWidget>
            <DashboardWidget
              id="latestNotificationsSection"
              title="Notifications"
              SvgPicture={NotificationImage}
              childrenCustomClass={styles.latestNotifications__container}>
              <LatestNotifications />
            </DashboardWidget>
          </div>
          <div className={styles.dashboard__column}>
            <Ad />
            {userStore.currentUser && (
              <DashboardWidget
                title="Shortcuts"
                SvgPicture={StartSVGLogo}
                customClass={classNames(styles.column_default_bottom_space, styles.max_height_section)}
                childrenCustomClass={classNames(shortcutsStyles.links_palette, appStyles.text_midBlue)}>
                <Shortcuts currentUser={userStore.currentUser} />
              </DashboardWidget>
            )}
          </div>
        </div>
      </div>
    );
  }
}
