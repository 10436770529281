import { FileApi } from 'api/fileApi';
import { FalconFile } from 'models/generic/falconFile.model';

class InvoicesFileApi extends FileApi {
  constructor() {
    super();

    this.baseApiUrl = process.env.REACT_APP_INVOICES_FILE_API_BASE_URL || '';
  }

  getFile<T>(url: string, friendlyErrorMessage: string | null) {
    return super.getFile<T>(url, friendlyErrorMessage, false);
  }

  getFilesList(url: string, errorMessage: string): Promise<FalconFile[]> {
    return super.getFilesList(url, errorMessage, false);
  }
}

const invoicesFileApi = new InvoicesFileApi();
export default invoicesFileApi;
