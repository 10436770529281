import { ChangeEvent, ReactNode } from 'react';
import { Prompt } from 'react-router-dom';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import Alert from 'components/Generic/Alert/Alert';
import { BaseUnsavedChangesComponent } from 'components/Generic/BaseUnsavedChangesComponent';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import Input from 'components/Generic/FormElements/Input/Input';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import Radio from 'components/Generic/FormElements/Radio/Radio';
import Select from 'components/Generic/FormElements/Select/Select';
import ValidationArea from 'components/Generic/FormElements/ValidationArea/ValidationArea';
import { Contact } from 'models/api/contact.model';
import { ValidationRule } from 'models/api/validationRule.model';
import { FalconError } from 'models/generic/falconError.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import { ListItem } from 'models/generic/listItem.model';
import { ValidationErrors } from 'models/generic/validationError.model';
import accountStore from 'stores/account.store';
import locationsStore from 'stores/locations.store';
import lookupStore from 'stores/lookups.store';
import messagesStore from 'stores/messages.store';
import userStore from 'stores/user.store';
import auth from 'utils/auth';
import constants from 'utils/constants';
import validation from 'utils/validation';
import appStyles from 'App.module.scss';
import preloader from 'assets/images/preloader.gif';
import { EditMarketingPreference } from 'components/Account/EditAccount/EditMarketingPreference';
import classNames from 'classnames';
import styles from './EditAccount.module.scss';

@observer
export class EditAccount extends BaseUnsavedChangesComponent<IBaseProps> {
  @observable editInProgress = false;
  @observable changePasswordInProgress = false;
  @observable memorableWordInProgress = false;
  @observable saveInProgress = false;
  @observable formValid = true;
  @observable userContactErrors: ValidationErrors = new ValidationErrors();
  @observable userContactConcurrencyError = '';
  validationRules: ValidationRule[] = [];
  submitAttempted = false;
  @observable userContactLoading = true;
  addresses: ListItem[] = [];
  @observable showValidationSummary = false;

  @observable userContact = new Contact();

  async componentDidMount() {
    auth.registerRedirectHandlers(this.authCallbackSuccess, this.authCallbackError);

    lookupStore.loadLookups();
    if (userStore.currentUser) {
      this.userContact.fullName = userStore.currentUser.fullName;
      this.addresses = await locationsStore.getLocationsListItems();
    }

    this.userContact = await accountStore.getCurrentContact();
    this.validationRules = await accountStore.getContactValidationRules();

    // validate warnings and info
    this.preValidateAll();

    this.userContactLoading = false;
  }

  handleInput = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    this.userContact = {
      ...this.userContact,
      [name]: value
    };

    this.unsavedChange = true;

    if (this.submitAttempted) {
      this.validateAll();
    } else {
      this.validateInfoAndWarnings();
    }
  };

  handleInputAsNumber = (e: ChangeEvent<HTMLSelectElement>) => {
    let value = null;
    if (e.target.value) {
      value = +e.target.value;
    }
    const name = e.target.name;

    this.userContact = {
      ...this.userContact,
      [name]: value
    };

    this.unsavedChange = true;

    if (this.submitAttempted) {
      this.validateAll();
    } else {
      this.validateInfoAndWarnings();
    }
  };

  onMarketingPreferenceUpdated = () => {
    this.unsavedChange = true;

    if (this.submitAttempted) {
      this.validateAll();
    } else {
      this.preValidateAll();
    }
  };

  handleBillingPreferencesInputAsBool = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === 'true' ? true : e.target.value === 'false' ? false : null;

    this.userContact = {
      ...this.userContact,
      billingPreferences: {
        isPaperBilling: value
      }
    };

    this.unsavedChange = true;

    if (this.submitAttempted) {
      this.validateAll();
    } else {
      this.preValidateAll();
    }
  };

  changePassword = async (e: React.FormEvent) => {
    this.changePasswordInProgress = true;
    e.preventDefault();

    auth.redirectToChangePassword();
  };

  memorableWord = async (e: React.FormEvent) => {
    this.memorableWordInProgress = true;
    e.preventDefault();

    auth.redirectToEditProfile();
  };

  authCallbackSuccess = () => {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  authCallbackError = (error: any) => {
    const message = auth.getMessageFromError(error.errorMessage);

    if (message) {
      messagesStore.addErrorAsString(message);
    }
  };

  preValidateAll() {
    // get any validation messages from server side
    this.userContactErrors = validation.getValidationErrorsFromBrokenRules(this.userContact.brokenRulesCollection);
  }

  validateInfoAndWarnings() {
    const infoBrokenRules = this.userContact.brokenRulesCollection.filter((rule) => rule.severity === constants.validation.severity.info);
    this.userContactErrors = validation.getValidationErrorsFromBrokenRules(infoBrokenRules);
    validation.validate(this.userContact, this.validationRules, this.userContactErrors, 'warning');
  }

  validateAll(): boolean {
    this.userContactErrors = new ValidationErrors();
    // validate info and warnings before also validating errors
    this.validateInfoAndWarnings();
    this.formValid = validation.validate(this.userContact, this.validationRules, this.userContactErrors);

    // only show summary if there are warnings or errors
    if (!this.userContactErrors.hasWarningSeverities && !this.userContactErrors.hasErrorSeverities) {
      this.showValidationSummary = false;
    } else {
      this.showValidationSummary = true;
    }

    return this.formValid;
  }

  onSave = async () => {
    this.saveInProgress = true;
    this.submitAttempted = true;

    this.validateAll();

    if (this.showValidationSummary) {
      window.scrollTo(0, 0);
    }

    let result;

    if (this.formValid && (!this.showValidationSummary || this.userContact.isSaveConfirmed)) {
      result = await accountStore.updateCurrentContact(this.userContact);

      // if result is error than check for validation messages
      if (result instanceof FalconError) {
        this.userContactErrors = validation.getValidationErrorsFromFalconError(result);
        this.showValidationSummary = true;
        if (result.concurrencyMessage) {
          this.userContactConcurrencyError = result.concurrencyMessage;
          window.scrollTo(0, 0);
        }
      } else {
        this.unsavedChange = false;
        this.props.history.push('/account/confirmation');
      }
    }

    this.saveInProgress = false;
  };

  getValidationSummary(): JSX.Element {
    let result: JSX.Element = <></>;

    if (this.showValidationSummary) {
      this.userContact.isSaveConfirmed = true;

      if (this.userContactErrors.hasWarningSeverities) {
        if (this.userContactErrors.hasErrorSeverities) {
          result = (
            <Alert alertType="error" customClass="validationSummary">
              <span id="errorsAndWarnings">
                Please review and resolve the validation errors below. There are also some validation warning messages we'd like to make you aware of before
                saving your changes.
              </span>
            </Alert>
          );
        } else {
          result = (
            <Alert alertType="warning" customClass="validationSummary">
              <span id="warningsOnly">
                Please review the form as there are some validation messages we'd like to make you aware of before saving your changes. If you'd like to save
                without addressing the issues, click the "Save Contact" button again.
              </span>
            </Alert>
          );
        }
      } else if (this.userContactErrors.hasErrorSeverities) {
        result = (
          <Alert alertType="error" customClass="validationSummary">
            <span id="errorsOnly">Please resolve the validation errors below before saving.</span>
          </Alert>
        );
      }
    }

    return result;
  }

  getConcurrencyMessage(): ReactNode | null {
    if (this.userContactConcurrencyError) {
      return (
        <Alert alertType="error" customClass="concurrencyAlert">
          {this.userContactConcurrencyError}
          <br />
          {constants.validation.concurrencyMessage}
        </Alert>
      );
    }
  }

  render() {
    if (userStore.currentUser) {
      return (
        <>
          <Prompt
            when={this.unsavedChange && BaseUnsavedChangesComponent.enableCheck}
            message="You have unsaved changes. Are you sure you want to navigate away from this page?"
          />

          <div className={appStyles.container}>
            <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
              <h1 className={appStyles.heading__text}>Account Details</h1>
            </div>
            {this.getConcurrencyMessage()}
            {this.getValidationSummary()}
            {this.userContactLoading === false ? (
              <form className={appStyles.row}>
                <div className={appStyles.col_md_6} id="personalDetailsCard">
                  <fieldset>
                    <Legend type="large" text="Details" />
                    <div className={appStyles.form__row}>
                      <Input
                        elementId="salutation"
                        inputType="text"
                        labelText="Title"
                        name="salutation"
                        placeholder="Enter your title"
                        handleChange={this.handleInput}
                        value={this.userContact.salutation}
                        autocomplete="honorific-prefix"
                      />
                    </div>
                    <div className={appStyles.form__row}>
                      <Input
                        elementId="firstNames"
                        inputType="text"
                        labelText="First Name"
                        name="firstNames"
                        placeholder="Enter your first name"
                        handleChange={this.handleInput}
                        value={this.userContact.firstNames}
                        autocomplete="given-name"
                        required={true}
                        validationError={this.userContactErrors.fieldErrors['firstNames']}
                      />
                    </div>
                    <div className={appStyles.form__row}>
                      <Input
                        elementId="surname"
                        inputType="text"
                        labelText="Surname"
                        name="surname"
                        placeholder="Enter your surname"
                        handleChange={this.handleInput}
                        value={this.userContact.surname}
                        autocomplete="family-name"
                        required={true}
                        validationError={this.userContactErrors.fieldErrors['surname']}
                      />
                    </div>
                    <div className={appStyles.form__row}>
                      <Input
                        elementId="position"
                        inputType="text"
                        labelText="Position"
                        name="position"
                        placeholder="Enter your position"
                        handleChange={this.handleInput}
                        value={this.userContact.position}
                        autocomplete="organization-title"
                      />
                    </div>
                  </fieldset>
                </div>

                <div className={appStyles.col_md_6} id="contactDetailsCard">
                  <fieldset>
                    <Legend type="large" text="Contact Details" />
                    <div className={appStyles.form__row}>
                      <Input
                        elementId="telephone"
                        inputType="text"
                        labelText="Telephone"
                        name="telephone"
                        placeholder="Enter your telephone number"
                        handleChange={this.handleInput}
                        value={this.userContact.telephone}
                        autocomplete="tel"
                        validationError={this.userContactErrors.fieldErrors['telephone']}
                      />
                    </div>
                    <div className={appStyles.form__row}>
                      <Input
                        elementId="mobile"
                        inputType="text"
                        labelText="Mobile"
                        name="mobile"
                        placeholder="Enter your mobile number"
                        handleChange={this.handleInput}
                        value={this.userContact.mobile}
                        autocomplete="tel"
                        validationError={this.userContactErrors.fieldErrors['mobile']}
                      />
                    </div>
                    <div className={appStyles.form__row}>
                      <div className={appStyles.form__label}>Email</div>
                      <div className={appStyles.form__readOnlyElement} id="email">
                        {this.userContact.email}
                      </div>
                      <ValidationArea sectionId="email" validationError={this.userContactErrors.fieldErrors['email']}></ValidationArea>
                    </div>
                    <div className={appStyles.form__row}>
                      <Select
                        elementId="addressId"
                        labelText="Address"
                        name="addressId"
                        options={this.addresses}
                        placeholder="Please choose..."
                        disablePlaceholder={true}
                        handleChange={this.handleInputAsNumber}
                        value={this.userContact.addressId}
                        validationError={this.userContactErrors.fieldErrors['addressId']}
                      />
                    </div>
                  </fieldset>
                </div>

                <div className={classNames(appStyles.col_lg_6)}>
                  <div id="billingDetailsCard" className={`${appStyles.card} ${appStyles.background_white} ${appStyles.card_boxShadow}`}>
                    <fieldset>
                      <Legend type="large" text="Billing Preferences" />
                      <div className={appStyles.form__row}>
                        <Legend type="small" text="How would you like to receive your bills?" />
                        <Radio
                          name="isPaperBilling"
                          options={lookupStore.billingOptions}
                          selectedOption={this.userContact.billingPreferences.isPaperBilling}
                          handleChange={this.handleBillingPreferencesInputAsBool}
                        />
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div className={classNames(appStyles.col_lg_6)}>
                  <div
                    id="marketingDetailsCard"
                    className={classNames(appStyles.card, appStyles.background_white, appStyles.card_boxShadow, styles.card_marketing)}>
                    <fieldset>
                      <Legend type="large" text="Marketing Preferences" />
                      <EditMarketingPreference
                        preferences={this.userContact.marketingPreferences}
                        reviewWarning={
                          this.userContact.brokenRulesCollection.find(
                            (i) => i.ruleName === 'rule://businesslibrary.validators.marketingpreferenceswarning/MarketingPreferences'
                          )?.description || null
                        }
                        marketingPreferenceUpdated={this.onMarketingPreferenceUpdated}
                      />
                    </fieldset>
                  </div>
                </div>
              </form>
            ) : (
              <div>
                <img alt="Loading..." className={appStyles.preloader} src={preloader} />
              </div>
            )}
          </div>

          <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
            <Button
              buttonStyle="outline_secondary"
              size="lg"
              handleClick={this.changePassword}
              inProgress={this.changePasswordInProgress}
              disabled={userStore.currentUser.b2c === false}
              title={userStore.currentUser.b2c ? '' : "This option isn't available when logged in with an employee account"}>
              Change Password
            </Button>

            <Button
              buttonStyle="outline_secondary"
              size="lg"
              handleClick={this.memorableWord}
              inProgress={this.memorableWordInProgress}
              disabled={userStore.currentUser.b2c === false}
              title={userStore.currentUser.b2c ? '' : "This option isn't available when logged in with an employee account"}>
              Update Memorable Word
            </Button>

            <Button buttonStyle="primary" size="lg" handleClick={this.onSave} inProgress={this.saveInProgress}>
              Save Changes
            </Button>
          </BottomButtonContainer>
        </>
      );
    }
  }
}
