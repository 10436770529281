import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import SwitchAccount from 'components/Account/SwitchAccount/SwitchAccount';
import { AccessDenied } from 'components/Generic/AccessDenied/AccessDenied';
import { IBaseProps } from 'models/generic/iBaseProps';
import navigationStore from 'stores/navigation.store';
import userStore from 'stores/user.store';
import constants from 'utils/constants';

export interface IAuthGuardProps extends RouteProps {
  role?: string;
  pageTitle: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AuthRoute: FC<IAuthGuardProps> = ({ component, ...rest }: any) => {
  // eslint-disable-next-line complexity
  const routeComponent = (props: IBaseProps) => {
    // set page title
    if (rest.pageTitle) {
      document.title = constants.applicationTitle + ': ' + rest.pageTitle;
    }

    // check if user logged in
    if (userStore.currentUser) {
      // if user has no roles or has NoAccess role then go to no access page
      if (userStore.currentUser.roles.includes(constants.roleNames.MyPortalNoAccess) || userStore.currentUser.roles.length === 0) {
        return <AccessDenied />;
      }

      // go to switch account if no account is selected
      if (!userStore.currentUser.currentClient && component !== SwitchAccount) {
        if (userStore.currentUser.roles.includes(constants.roleNames.MyPortalSwitchAccount)) {
          return <Redirect to={{ pathname: '/switch-account' }} />;
        } else {
          return <Redirect to={{ pathname: '/dashboard' }} />;
        }
      }

      // if role specified then check if user has it
      if (rest.role) {
        if (userStore.currentUser.currentClient && userStore.currentUser.hasRole(rest.role)) {
          // user has required role, so go to requested component
          return React.createElement(component, props);
        } else {
          // user has no role, so go to page not found
          return <AccessDenied />;
          // props.history.push('/404');
        }
      } else {
        // no role specified, so take user to component
        return React.createElement(component, props);
      }
    } else {
      // save requested page URL before redirecting to login
      navigationStore.saveRequestedUrl(rest.location.pathname + rest.location.search);

      // user not logged in, so go to login page
      return <Redirect to={{ pathname: '/login' }} />;
    }
  };

  return <Route {...rest} render={routeComponent} />;
};
