import { Component } from 'react';
import classNames from 'classnames';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { User } from 'models/generic/user.model';
import userStore from 'stores/user.store';
import constants from 'utils/constants';
import Button from 'components/Generic/FormElements/Button/Button';
import AccountNumbers from 'components/Generic/Header/AccountNumbers';
import MobileControls from 'components/Generic/Header/MobileControls';
import Icon from 'components/Generic/Icon/icon';
import appStyles from 'App.module.scss';
import styles from './Header.module.scss';

interface IAccountContentNavigationProps {
  user: User;
  visible: boolean;
  onCloseMobileClick: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
  onCloseAccountClick: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
  onSwitchAccountClick: React.MouseEventHandler<HTMLButtonElement>;
}

@observer
export default class AccountContentNavigation extends Component<IAccountContentNavigationProps> {
  @observable accountsExpanded = false;
  @observable logoutInProgress = false;

  @action toggleAccountsExpanded = () => {
    this.accountsExpanded = !this.accountsExpanded;
  };

  get canSwitchAccounts() {
    return this.props.user.roles.includes(constants.roleNames.MyPortalSwitchAccount) ?? false;
  }

  get accountsExpandable() {
    return this.props.user.sageIds.length > 5;
  }

  @action logout = async () => {
    try {
      this.logoutInProgress = true;

      await userStore.logOut();
    } finally {
      this.logoutInProgress = false;
    }
  };

  render() {
    const { visible, user, onCloseAccountClick, onCloseMobileClick, onSwitchAccountClick } = this.props;

    return (
      <>
        <div className={classNames(styles.accountNav, { [styles.accountNav_show]: visible })} id="navbarAccountContent">
          <div className={classNames(styles.accountNav__details, appStyles.text_midBlue)}>
            <MobileControls onBack={onCloseAccountClick} onClose={onCloseMobileClick} />
            <div className={styles.accountNav__section}>
              <div className={classNames(styles.account__label, appStyles.text_darkGrey)}>Your Details</div>
            </div>
            <div className={styles.accountNav__section}>
              <div className={styles.account__sublabel}>Account Name</div>
              <div id="clientName" className={styles.account__details}>
                {user.currentClient?.name}
              </div>
            </div>
            <div className={classNames(styles.accountNav__section, styles.account__details)}>
              <div className={styles.account__sublabel}>Account Numbers</div>
              <button
                aria-controls="AccountNumbers"
                aria-expanded={false}
                aria-label="Toggle Account Numbers"
                onClick={this.toggleAccountsExpanded}
                className={classNames({
                  [styles.account__expanderButton_open]: this.accountsExpandable && this.accountsExpanded,
                  [styles.account__expanderButton_closed]: this.accountsExpandable && !this.accountsExpanded,
                  [styles.account__details_hidden]: !this.accountsExpandable
                })}>
                <Icon
                  icon={Icon.ellipses}
                  color="darkSkyBlue"
                  size="sm"
                  iconName={this.accountsExpanded ? 'Contract Account Number List' : 'Expand Account Number List'}
                />
              </button>
              <AccountNumbers items={user.sageIds} expandable={this.accountsExpandable} expanded={this.accountsExpanded} />
            </div>
            <div className={styles.accountNav__section}>
              <div className={styles.account__sublabel}>Primary Address</div>
              <div id="primaryAddress" className={styles.account__details}>
                {user.primaryAddress.line1} <br />
                {user.primaryAddress.line2} <br />
                {user.primaryAddress.town} <br />
                {user.primaryAddress.county} <br />
                {user.primaryAddress.postCode} <br />
              </div>
            </div>
          </div>
          <div className={styles.accountNav__bottom}>
            <div className={styles.accountNav__section}>
              <div className={styles.account__sublabel}>Contact Us</div>
              <div className={styles.account__details}>{constants.keyVariables.helpDeskEmail}</div>
            </div>
            {this.canSwitchAccounts && (
              <Button buttonStyle="outline_primary" customClass={styles.accountNav__button} handleClick={onSwitchAccountClick}>
                Switch Accounts
              </Button>
            )}
            <Button buttonStyle="primary" handleClick={this.logout} customClass={styles.accountNav__button} inProgress={this.logoutInProgress}>
              Logout
            </Button>
          </div>
        </div>
        <div
          role="navigation"
          onClick={onCloseMobileClick}
          className={classNames(styles.accountNav__backdrop, { [styles.accountNav__backdrop_show]: visible })}
        />
      </>
    );
  }
}
