import { action, observable } from 'mobx';
import api from 'api/api';
import { Appointment } from 'models/api/appointment.model';
import { FalconError } from 'models/generic/falconError.model';
import { ListItem } from 'models/generic/listItem.model';
import constants from 'utils/constants';
import utils from 'utils/utils';

class AppointmentsStore {
  @observable pending = false;
  @observable selectedAppointment: Appointment | null = null;
  @observable availableAppointments: Array<Appointment> = [];

  getFormattedAppointments = (showWeekends: boolean, showChargeableSlots: boolean) =>
    [...new Set(this.availableAppointments.map((a) => a.appointmentDate || ''))]
      .filter((date) => date && (showWeekends || !utils.dateTime.isWeekend(date)))
      .map((date) => {
        const availableDateSlots = this.availableAppointments.filter((a) => a.appointmentDate === date);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const slotsEntries = constants.engineerAppointmentSlotKeys.filter(([_, value]) => value.chargeable === showChargeableSlots);
        const slots = slotsEntries.map(([key, value]) => new ListItem(key, value.title, !availableDateSlots.some((a) => a.appointmentTimeslot === key)));

        return { date, slots };
      });

  @action getAppointmentAvailability = async (id: string) => {
    this.pending = true;

    const result = await api.get<Appointment[]>(`/LineFault/AvailableAppointments/${id}`, {
      friendlyErrorMessage: 'There was an error requesting available engineer appointments.'
    });

    this.pending = false;

    if (result instanceof FalconError) {
      this.availableAppointments = [];

      throw result;
    }

    this.availableAppointments = result;

    return result;
  };

  @action resetSelectedAppointment() {
    this.selectedAppointment = null;
  }
}

export default new AppointmentsStore();
