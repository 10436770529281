import { FC, useEffect } from 'react';
import Slider from 'react-slick';
import { observer } from 'mobx-react';
import marketingStore from 'stores/marketing.store';
import Arrow from 'components/Dashboard/Ad/Arrow';
import styles from './Ad.module.scss';

const Ad: FC = () => {
  useEffect(() => {
    marketingStore.getPictures();
  }, []);

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    pauseOnHover: true,
    speed: 500,
    arrows: !marketingStore.dots,
    dots: marketingStore.dots,
    nextArrow: <Arrow direction="right" />,
    prevArrow: <Arrow direction="left" />
  };

  if (!marketingStore.items || marketingStore.items.length === 0) {
    return <div></div>;
  } else {
    return (
      <div className={styles.slider}>
        <Slider {...settings}>
          {marketingStore.items?.map((item) =>
            item.link && item.link.replace(/\s/g, '').length > 0 ? (
              <a key={item.imageUrl} className={styles.slider_image} href={item.link} target="_blank">
                <img className={styles.slider_image} title={item.title} src={item.imageUrl} alt={item.altText}></img>
              </a>
            ) : (
              <img key={item.imageUrl} title={item.title} className={styles.slider_image} src={item.imageUrl} alt={item.altText} />
            )
          )}
        </Slider>
      </div>
    );
  }
};

export default observer(Ad);
