import { FC } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { LocationDescriptor } from 'history';
import { ReactComponent as ChevronRight } from 'assets/images/svg/chevronRight.svg';
import styles from './Table.module.scss';

export interface IRow {
  id?: number | null;
  time: Date;
  subject: string | null;
  unread?: boolean;
  to: LocationDescriptor;
  clickHandler?: () => Promise<void>;
}

interface IRowProps {
  column: IRow;
  showNumber?: boolean;
  title?: string;
}

const Row: FC<IRowProps> = ({ column, showNumber, title }) => (
  <Link
    title={title}
    onClick={column.clickHandler}
    to={column.to}
    className={classNames(styles.tickets__row, styles.tickets__link, { [styles.unread]: column.unread })}
    key={column.id}>
    {showNumber && <p className={classNames(styles.tickets__col_first)}>{column.id}</p>}
    <p className={classNames(styles.tickets__col_second)}>{moment(column.time).format('D MMM')}</p>
    <p className={classNames(styles.tickets__col_third, styles.no_overflow_line_text)}>{column.subject && ReactHtmlParser(column.subject)}</p>
    <ChevronRight width="50px" height="50px" className={styles.chevron_right} />
  </Link>
);

export default Row;
