import { action, observable } from 'mobx';
import { NavItem } from 'models/generic/navItem.model';
import userStore from 'stores/user.store';
import constants from 'utils/constants';
import utils from 'utils/utils';

class NavigationStore {
  @observable bottomButtonContainerIsPresent = false;
  @observable navigation: NavItem[] = [];

  public readonly storageRequestedUrlKey = 'chess.falcon.path';

  // eslint-disable-next-line complexity
  @action loadNavigation() {
    const result: NavItem[] = [];

    // set nav links based on role
    if (userStore.currentUser) {
      if (userStore.currentUser.currentClient) {
        // Dashboard - for all
        result.push(new NavItem('Dashboard', '/dashboard', 'Dashboard'));

        // Account - for all
        result.push(new NavItem('Account', '/account', 'Edit Your Account', [new NavItem('Account Overview', '/account', '')]));

        // Account - contacts and locations if admin role is present
        if (userStore.currentUser.hasRole(constants.roleNames.MyPortalAdmin)) {
          result[result.length - 1].children.push(new NavItem('Manage Contacts', '/account/contacts', ''));
          result[result.length - 1].children.push(new NavItem('Manage Locations', '/account/locations', ''));
        }

        // Account - add switch if user has more than one
        if (userStore.currentUser.roles.includes(constants.roleNames.MyPortalSwitchAccount)) {
          result[result.length - 1].children.push(new NavItem('Switch Account', '/switch-account', ''));
        }

        // Invoices - for Orders role
        if (userStore.currentUser.hasRole(constants.roleNames.Orders)) {
          result.push(
            new NavItem('Invoices', '/invoices', 'View Recent Invoices', [
              new NavItem('Manage Invoices', '/invoices', 'View all invoices'),
              new NavItem('Make a Payment', '/invoices/make-a-payment', 'Pay an invoice'),
              new NavItem('Manage Direct Debits', '/invoices/manage-direct-debit', ''),
              new NavItem('Setup Direct Debit', '/invoices/direct-debit', '')
            ])
          );
        }

        // Tickets - for Support role
        if (userStore.currentUser.hasRole(constants.roleNames.Support)) {
          const tickets = new NavItem('Tickets', '/tickets', 'View Your Tickets', []);

          tickets.children.push(new NavItem('Manage Tickets', '/tickets', ''));
          tickets.children.push(new NavItem('Log New Ticket', '/tickets/new', ''));

          // Callouts - for Callouts role
          // if (userStore.currentUser.hasRole(constants.roleNames.Callouts)) {
          // tickets.children.push(new NavItem('Callouts', '/tickets/callouts', ''));
          // }

          result.push(tickets);
        }

        // Products - for all, but some children only for selected roles
        const products = new NavItem('Products', '/products', 'View Our Products');

        // if (userStore.currentUser.hasRole(constants.roleNames.Orders)) {
        // products.children.push(new NavItem('Orders', '/products/orders', ''));
        // }

        if (userStore.currentUser.hasRole(constants.roleNames.Quotes)) {
          products.children.push(new NavItem('Quotes', '/products/quotes', ''));
        }

        // if (userStore.currentUser.hasRole(constants.roleNames.Projects)) {
        // products.children.push(new NavItem('Projects', '/products/projects', ''));
        // }

        // if (navigationStore.hasRole(constants.roleNames.Orders)) {
        //   products.children.push(new NavItem('Orders', '/products/orders', ''));
        // }

        // if (navigationStore.hasRole(constants.roleNames.Projects)) {
        //   products.children.push(new NavItem('Projects', '/products/projects', ''));
        // }

        if (userStore.currentUser.hasRole(constants.roleNames.Contracts)) {
          products.children.push(new NavItem('Subscriptions', '/products/subscriptions', ''));
        }

        if (userStore.currentUser.hasRole(constants.roleNames.Returns)) {
          products.children.push(new NavItem('Returns', '/products/returns', ''));
        }

        // if (navigationStore.hasRole(constants.roleNames.Contracts)) {
        //    products.children.push(new NavItem('Contracts', '/products/contracts', ''));
        // }

        // products.children.push(new NavItem('Monitoring', '/products/monitoring', ''));
        // products.children.push(new NavItem('Service Usage', '/products/service-usage', ''));

        // add products if has any children
        if (products.children.length > 0) {
          result.push(products);
        }

        // Services - for all users
        const services = new NavItem('Services', '/services', '');
        services.children.push(new NavItem('Line Faults', '/services/line-faults', ''));
        services.children.push(new NavItem('FTTP Availability Checker', '/services/availability-checker', ''));

        result.push(services);

        // Shop - only for Devices Shop role
        if (userStore.currentUser.hasRole(constants.roleNames.DevicesShop)) {
          const shop = new NavItem('Devices Shop', '/shop', '');
          shop.children.push(new NavItem('Device Solutions', '/shop/devices', ''));

          result.push(shop);
        }
      }
    }
    this.navigation = result;
  }

  @action setBottomButtonContainerIsPresent = (value: boolean): void => {
    this.bottomButtonContainerIsPresent = value;
  };

  saveRequestedUrl(url: string) {
    utils.session.saveToSession(this.storageRequestedUrlKey, url);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  redirectToRequestedUrl(history: any) {
    // redirect to originally requested page or dashboard
    const path = utils.session.getFromSession(this.storageRequestedUrlKey) as string;
    if (path) {
      utils.session.removeFromSession(this.storageRequestedUrlKey);
      history.push(path);
    } else {
      history.push('/dashboard');
    }
  }
}

const navigationStore = new NavigationStore();
export default navigationStore;
