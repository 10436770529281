import { ChangeEvent, Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import Alert from 'components/Generic/Alert/Alert';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import Input from 'components/Generic/FormElements/Input/Input';
import Icon from 'components/Generic/Icon/icon';
import DirectDebitGrid from 'components/Invoices/DirectDebit/DirectDebitGrid/DirectDebitGrid';
import NewDirectDebit from 'components/Invoices/DirectDebit/NewDirectDebit/NewDirectDebit';
import { DirectDebit } from 'models/api/directDebit.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import constants from 'utils/constants';
import appStyles from 'App.module.scss';
import styles from './ManageDirectDebit.module.scss';

@observer
export class ManageDirectDebit extends Component<IBaseProps> {
  @observable selectedDirectDebit: DirectDebit | null = null;
  @observable searchInputValue = ''; // current search box value

  constructor(props: IBaseProps) {
    super(props);

    this.setSelectedDirectDebit = this.setSelectedDirectDebit.bind(this);
    this.handleViewDirectDebits = this.handleViewDirectDebits.bind(this);
    this.refreshSelectedDirectDebit = this.refreshSelectedDirectDebit.bind(this);
  }
  handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    // update value displayed in search box
    this.searchInputValue = e.target.value;
  };

  setSelectedDirectDebit(value: DirectDebit | null) {
    this.selectedDirectDebit = value;
  }

  refreshSelectedDirectDebit(value: DirectDebit | null) {
    this.setSelectedDirectDebit(value);
  }

  addNewDirectDebit() {
    this.props.history.push('/invoices/direct-debit');
  }

  handleViewDirectDebits() {
    this.props.history.push('/invoices/manage-direct-debit');
    this.selectedDirectDebit = null;
  }

  render() {
    return (
      <>
        <div className={appStyles.container}>
          <div className={appStyles.pageHeading_lg}>
            <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
              <h1 className={appStyles.heading__text}>Direct Debits</h1>
            </div>

            {!this.selectedDirectDebit && (
              <div className={styles.search__container}>
                <Input
                  elementId="searchBar"
                  inputType="search"
                  name="searchBar"
                  labelText="Search Direct Debits"
                  labelHidden={true}
                  placeholder="Search Direct Debits"
                  value={this.searchInputValue}
                  handleChange={this.handleSearch}
                  customLabelClass={appStyles.form__label}
                  customInputClass={appStyles.form__input}
                  autocomplete="off"
                />
              </div>
            )}
          </div>

          {!this.selectedDirectDebit && (
            <Alert alertType="blank">
              <p className={`${appStyles.text_darkGrey} ${appStyles.info__main}`}>
                You can view all your Direct Debits, simply select a Direct Debit to see a full breakdown.
              </p>
              <p className={`${appStyles.text_darkGrey} ${appStyles.info__main}`}>
                You can contact {constants.keyVariables.customerServicesName} on {constants.keyVariables.helpdeskTelephone} if you require support with setting
                up or changing a Direct Debit.
              </p>
              <p className={`${appStyles.text_darkGrey} ${appStyles.info__main}`}>
                Our correspondance address is Bridgford House, Heyes Lane, Alderley Edge, Cheshire SK9 7JP.
              </p>
            </Alert>
          )}

          <div className={styles.directDebitGrid}>
            <DirectDebitGrid
              searchKeyword={this.searchInputValue}
              setSelectedDirectDebit={this.setSelectedDirectDebit}
              selectedDirectDebit={this.selectedDirectDebit}
            />
          </div>

          {this.selectedDirectDebit ? (
            <div className={styles.invoicesGrid}>
              <NewDirectDebit
                DirectDebit={this.selectedDirectDebit}
                handleViewDirectDebits={this.handleViewDirectDebits}
                refreshSelectedDirectDebit={this.refreshSelectedDirectDebit}></NewDirectDebit>
            </div>
          ) : (
            <BottomButtonContainer backgroundColor="white" layout="right">
              <Button id="newDD" buttonStyle="primary" handleClick={this.addNewDirectDebit.bind(this)} size="lg">
                <Icon icon={Icon.plus} customClass={appStyles.buttonWithIcon__icon} color="currentColor" size="sm" iconName="addIcon" />
                <span className={appStyles.buttonWithIcon__text}>Add Direct Debit</span>
              </Button>
            </BottomButtonContainer>
          )}
        </div>
      </>
    );
  }
}
