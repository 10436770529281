import { Grid } from '@progress/kendo-react-grid';
import appStyles from 'App.module.scss';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { observer } from 'mobx-react';
import { Returns } from 'models/api/returns.model';
import { Component } from 'react';
import styles from './ReturnReasonsGrid.module.scss';

interface IReturnReasonsGridProps {
  return: Returns;
}

@observer
export default class ReturnReasonsGrid extends Component<IReturnReasonsGridProps> {
  render() {
    return (
      <>
        <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
          <h3 className={appStyles.heading__text_sm}>Return Reasons</h3>
        </div>
        <Grid className={styles.singleRowGrid} data={[this.props.return]} pageSize={1} resizable>
          {ColumnHelper.getGridColumns([
            { field: 'reason', title: 'Reason Code', dataType: 'text', size: 'md' },
            { field: 'addNotes', title: 'Other', dataType: 'text', size: 'lg' }
          ])}
        </Grid>
      </>
    );
  }
}
