import { useCallback, useMemo } from 'react';
import classNames, { Value } from 'classnames';
import moment from 'moment';
import { ListItem } from 'models/generic/listItem.model';
import CheckBox from 'components/Generic/FormElements/Checkbox/Checkbox';
import constants from 'utils/constants';
import engineerAppointmentStyles from './EngineerAppointment.module.scss';

interface IAppointmentDayProps {
  date: string;
  slots: Array<ListItem>;
  selectedSlot: string | null;
  className?: Value;
  onSelected: (slot: string, additionalInfo: { date: string; slot: string }) => void;
  nameExtractor?: (date: string) => string;
}

function AppointmentDay({ date, slots, selectedSlot, className, onSelected, nameExtractor }: IAppointmentDayProps) {
  const formattedDate = useMemo(() => moment.utc(date), [date]);
  const namePrefix = useMemo(
    () => (nameExtractor ? nameExtractor(date) : `appointment-slot-${formattedDate.format('DD-MM-YYYY')}-`),
    [date, nameExtractor, formattedDate]
  );
  const selected = useMemo(() => {
    const selectedSlotId = selectedSlot && selectedSlot.split(namePrefix)[1];

    return selectedSlotId ? [selectedSlotId] : [];
  }, [selectedSlot, namePrefix]);

  const onCheckBoxChanged = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => onSelected(event.target.id, { date, slot: event.target.id.split(namePrefix)[1] }),
    [date, namePrefix, onSelected]
  );

  const renderSlot = (text: string): React.ReactNode => {
    const timeRange = constants.engineerAppointmentTimeRanges[text];

    return (
      <div>
        <div>{text}</div>
        {!!timeRange && <div>{timeRange}</div>}
      </div>
    );
  };

  return (
    <div className={classNames(engineerAppointmentStyles.container, className)}>
      <div className={engineerAppointmentStyles.day__container}>
        <span className={engineerAppointmentStyles.day__title_day}>{formattedDate.format('dddd')}</span>
        <span className={engineerAppointmentStyles.day__title_date}>{formattedDate.format('DD')}</span>
        <span className={engineerAppointmentStyles.day__title_month}>{formattedDate.format('MMMM')}</span>
      </div>

      <CheckBox
        name={namePrefix}
        options={slots}
        renderInnerComponent={renderSlot}
        handleChange={onCheckBoxChanged}
        selectedOptions={selected}
        customInputClass={engineerAppointmentStyles.checkbox}
        customLabelClass={engineerAppointmentStyles.checkbox__label}
        customDisabledLabelClass={engineerAppointmentStyles.checkbox__label_disabled}
        customSelectedLabelClass={engineerAppointmentStyles.checkbox__label_selected}
      />
    </div>
  );
}

export default AppointmentDay;
