import { ChangeEventHandler, Component } from 'react';
import classNames from 'classnames';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import constants from 'utils/constants';
import { booleanToStringKeyGenerator, formatString, getProductSpeedString } from 'utils/string';
import validation from 'utils/validation';
import { ValidationErrors } from 'models/generic/validationError.model';
import availabilityChecksStore from 'stores/availabilityChecks.store';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import Input from 'components/Generic/FormElements/Input/Input';
import Preloader from 'components/Generic/Preloader/Preloader';
import appStyles from 'App.module.scss';
import availabilityCheckerStyles from '../AvailabilityChecker.module.scss';
import confirmCallBackStyles from '../RequestCallback/RequestCallback.module.scss';

@observer
export default class RequestCallback extends Component<RouteComponentProps> {
  runValidationOnChange = false;

  state = {
    disabled: false
  };

  @observable errors: ValidationErrors = new ValidationErrors();

  @action handleInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    availabilityChecksStore.setContactNumber(e.target.value.replace(validation.regexPatterns.nonDigit, ''));
    if (this.runValidationOnChange) {
      this.validateForm();
    }
  };

  @action validateForm = () => {
    this.errors = new ValidationErrors();

    if (!availabilityChecksStore.isContactNumberValid) {
      this.errors.addError('contactNumber', 'Input valid phone number');
    }

    return availabilityChecksStore.isContactNumberValid;
  };

  @action handleCheckAnotherAddress: React.MouseEventHandler<HTMLButtonElement> = async () => {
    const { history } = this.props;

    if (!!availabilityChecksStore.addresses.length) {
      availabilityChecksStore.cleanUpStore();
    } else {
      availabilityChecksStore.cleanUpProduct();
    }

    history.push('/services/availability-checker');
  };

  @computed get heading() {
    return constants.AvailableProductResultMessages[
      booleanToStringKeyGenerator(!!availabilityChecksStore.availableProducts.length, !!availabilityChecksStore.addresses.length)
    ]?.heading;
  }

  @computed get mainText() {
    return constants.AvailableProductResultMessages[
      booleanToStringKeyGenerator(!!availabilityChecksStore.availableProducts.length, !!availabilityChecksStore.addresses.length)
    ]?.mainText;
  }

  @computed get callBackText() {
    return constants.AvailableProductResultMessages[
      booleanToStringKeyGenerator(!!availabilityChecksStore.availableProducts.length, !!availabilityChecksStore.addresses.length)
    ]?.callBackText;
  }

  onRequestCallBack = async () => {
    if (this.state.disabled) {
      return;
    }

    this.runValidationOnChange = true;

    if (!this.validateForm()) {
      return;
    }

    this.setState({ disabled: true });

    await availabilityChecksStore.requestCallback();

    return this.props.history.push('/services/availability-checker/confirm');
  };

  render() {
    return (
      <div className={classNames(appStyles.container_center, availabilityCheckerStyles.availabilityChecker__container)}>
        <div className={appStyles.pageHeading_lg}>
          <h1 className={classNames(appStyles.heading__text, appStyles.text_midBlue)}>
            {formatString(
              this.heading,
              availabilityChecksStore.selectedProduct ? getProductSpeedString(availabilityChecksStore.selectedProduct.downstreamSpeed) : ''
            )}
          </h1>
        </div>
        {availabilityChecksStore.pending ? (
          <Preloader />
        ) : (
          <div className={appStyles.container_center}>
            <p>{formatString(this.mainText, availabilityChecksStore.postcode)}</p>
            <p>{this.callBackText}</p>
            <form className={classNames(appStyles.container_center, availabilityCheckerStyles.form__inputContainer)}>
              <div className={classNames(availabilityCheckerStyles.form__inputContainer, confirmCallBackStyles.text_input)}>
                <Input
                  labelText=""
                  required={true}
                  inputType="tel"
                  name="inputContactNumber"
                  elementId="inputContactNumber"
                  minLength={constants.telephoneNumberMinLength}
                  maxLength={constants.telephoneNumberMaxLength}
                  value={availabilityChecksStore.contactNumber}
                  handleChange={this.handleInput}
                  placeholder={'Preferred contact number'}
                  validationError={this.errors.fieldErrors['contactNumber']}
                />
              </div>
              <Button id="btnRequestCallBack" size="lg" buttonStyle="primary" disabled={this.state.disabled} handleClick={this.onRequestCallBack}>
                <span>{this.state.disabled ? 'Requesting' : 'Request'} Call Back</span>
              </Button>
            </form>
          </div>
        )}
        <BottomButtonContainer backgroundColor="white" layout="left">
          <Button size="lg" buttonStyle="primary" id="btnSearchAddresses" handleClick={this.handleCheckAnotherAddress}>
            <span>Check Another Address</span>
          </Button>
        </BottomButtonContainer>
      </div>
    );
  }
}
