import api from 'api/api';
import { Location } from 'models/api/location.model';
import { ValidationRule } from 'models/api/validationRule.model';
import { FalconError } from 'models/generic/falconError.model';
import { ListItem } from 'models/generic/listItem.model';

class LocationsStore {
  async getLocationValidationRules(): Promise<ValidationRule[]> {
    return api.getValidationRules('Address/GetValidationRules');
  }

  async getLocations(): Promise<Location[]> {
    let locations: Location[] = [];

    const result = await api.get<Location[]>('/Address/Index', {
      friendlyErrorMessage: `There was an error loading locations.`
    });
    if (result instanceof FalconError) {
    } else {
      result.forEach((location) => {
        if (location.addressId) {
          location.fullAddressAsString = Location.getFormattedAsString(location, true);
          location.fullAddressAsStringWithoutPostCode = Location.getFormattedAsString(location, false);
        }
      });

      locations = result;
    }

    return locations;
  }

  async getLocation(id: number): Promise<Location> {
    let location: Location = new Location();

    const result = await api.get<Location>('/Address/Edit/' + id, {
      friendlyErrorMessage: `There was an error loading address details.`
    });
    if (result instanceof FalconError) {
    } else {
      location = result;

      if (location && location.addressId) {
        location.fullAddressAsString = Location.getFormattedAsString(location, true);
        location.fullAddressAsStringWithoutPostCode = Location.getFormattedAsString(location, false);
      }
    }

    return location;
  }

  async getNewLocation(): Promise<Location> {
    let address: Location = new Location();

    const result = await api.get<Location>('/Address/Create', {
      friendlyErrorMessage: `There was an error loading the form.`
    });

    if (result instanceof FalconError) {
    } else {
      address = result;
    }

    return address;
  }

  async updateLocation(address: Location): Promise<Location | FalconError> {
    const result = await api.put<Location>('/Address/Edit/', address, `There was an error updating the address.`);

    return result;
  }

  async createLocation(address: Location): Promise<Location | FalconError> {
    const result = await api.post<Location>('/Address/Create/', address, `There was an error saving the address.`);

    return result;
  }

  async getLocationsListItems(): Promise<ListItem[]> {
    const locations: Location[] = await this.getLocations();
    const items: ListItem[] = [];

    locations.forEach((location) => {
      if (location.addressId) {
        location.fullAddressAsString = Location.getFormattedAsString(location, true);
        location.fullAddressAsStringWithoutPostCode = Location.getFormattedAsString(location, false);

        items.push(new ListItem(location.addressId, location.fullAddressAsString));
      }
    });

    return items;
  }
}

const locationsStore = new LocationsStore();
export default locationsStore;
