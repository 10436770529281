import { FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import NewsArticle from 'components/Dashboard/Newsfeed/NewsArticle';
import LinkWithLabel from 'components/Dashboard/Newsfeed/LinkWithLabel';
import { Article } from 'models/generic/article.model';
import { ReactComponent as Link } from 'assets/images/svg/externalLink.svg';
import styles from './Newsfeed.module.scss';

const subscriptionKey = process.env.REACT_APP_API_KEY ?? '';
const newsRssApiUrl = process.env.REACT_APP_NEWS_RSS_API ?? '';
const chessSiteUrl = process.env.REACT_APP_CHESS_WEBSITE_BASE_URL ?? '';

const getValue = (doc: Document, tagName: string, i: number) => {
  const elements = doc.getElementsByTagName(tagName);

  return elements?.[i]?.childNodes?.[0]?.nodeValue ?? '';
};

const Newsfeed: FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);

  const fetchArticles = useCallback(async () => {
    const latestArticles: Article[] = [];
    const domParser = new DOMParser();
    const rssItemsToShow = 3;
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    };

    const headers = new Headers();
    headers.append('Ocp-Apim-Subscription-Key', subscriptionKey);

    const articlesResponse = await fetch(newsRssApiUrl, { headers });

    const articles = await articlesResponse.text();
    const doc = domParser.parseFromString(articles, 'text/xml');

    for (let i = 1; i <= rssItemsToShow; i++) {
      const itemTitle = getValue(doc, 'title', i) || 'Untitled';
      const itemUrl = getValue(doc, 'link', i);
      const itemDate = new Date(getValue(doc, 'pubDate', i - 1));
      const itemReadtime = Number(getValue(doc, 'category', i - 1)) || 0;

      const article = new Article(itemTitle, itemUrl, itemDate.toLocaleDateString('en-GB', options), itemReadtime);

      latestArticles.push(article);
    }
    setArticles(latestArticles);
  }, []);

  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);

  return (
    <>
      <div className={classNames(styles.newsfeed)}>
        {articles.map((item, index) => (
          <NewsArticle key={index} {...item} />
        ))}
      </div>
      <LinkWithLabel label="View more" link={{ pathname: chessSiteUrl }} target="_blank" Icon={Link} />
    </>
  );
};

export default observer(Newsfeed);
