import { ChangeEvent, ReactNode } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Prompt, withRouter } from 'react-router-dom';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import Alert from 'components/Generic/Alert/Alert';
import { BaseUnsavedChangesComponent } from 'components/Generic/BaseUnsavedChangesComponent';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import FileDownloads from 'components/Generic/FormElements/FileDownloads/FileDownloads';
import azureUpload from 'components/Generic/FormElements/FileUpload/AzureUpload';
import FileMultiUpload from 'components/Generic/FormElements/FileUpload/FileMultiUpload';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import Select from 'components/Generic/FormElements/Select/Select';
import TextArea from 'components/Generic/FormElements/TextArea/TextArea';
import Icon from 'components/Generic/Icon/icon';
import { Ticket } from 'models/api/ticket.model';
import { ValidationRule } from 'models/api/validationRule.model';
import { FalconError } from 'models/generic/falconError.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import { ListItem } from 'models/generic/listItem.model';
import { ValidationErrors } from 'models/generic/validationError.model';
import ticketsStore from 'stores/tickets.store';
import constants from 'utils/constants';
import validation from 'utils/validation';
import appStyles from 'App.module.scss';
import preloader from 'assets/images/preloader.gif';
import styles from '../ManageTickets.module.scss';

interface ITicketsBreakdownProps extends IBaseProps {
  ticket: Ticket;
  contacts: ListItem[];
  addresses: ListItem[];
  validationRules: ValidationRule[];
  setRequest: (shouldUpdate: boolean) => void;
}

@observer
class TicketsBreakdown extends BaseUnsavedChangesComponent<ITicketsBreakdownProps> {
  @observable ticket: Ticket = this.props.ticket;
  @observable isCloudOrDevice = false;
  @observable isAccountAndBilling = false;
  @observable isDevice = false;
  @observable statusList = [new ListItem(0, 'NEW'), new ListItem(1, 'IN PROGRESS'), new ListItem(2, 'DEFERRED'), new ListItem(3, 'CLOSED')];
  submitAttempted = false;
  @observable formValid = true;
  @observable ticketErrors: ValidationErrors = new ValidationErrors();
  @observable ticketConcurrencyError = '';
  @observable ticketBreakdownLoading = true;
  @observable saveInProgress = false;
  @observable activeIndex: number[] = [];
  @observable notesExpanded = false;
  @observable filesToUpload: File[] = [];

  constructor(props: ITicketsBreakdownProps) {
    super(props);

    this.saveTicket = this.saveTicket.bind(this);
    this.setFilesToUpload = this.setFilesToUpload.bind(this);
  }

  async componentDidMount() {
    // TODO: update to real values
    this.isCloudOrDevice = true;
    this.isAccountAndBilling = false;
    this.isDevice = true;
    this.ticketBreakdownLoading = false;
    this.expandFirstNote();
  }

  handleInput = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    if (this.ticket) {
      this.ticket = {
        ...this.ticket,
        [name]: value
      };
    }

    this.unsavedChange = true;

    if (this.submitAttempted) {
      this.validate();
    }
  };

  handleInputAsNumber = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    let value = null;
    if (e.target.value) {
      value = +e.target.value;
    }
    const name = e.target.name;

    this.ticket = {
      ...this.ticket,
      [name]: value
    };

    this.unsavedChange = true;

    if (this.submitAttempted) {
      this.validate();
    }
  };

  validate() {
    // reset state
    this.formValid = true;
    this.ticketErrors = new ValidationErrors();
    this.formValid = validation.validate(this.ticket, this.props.validationRules, this.ticketErrors);

    return this.formValid;
  }

  setFilesToUpload(files: File[]) {
    this.filesToUpload = files;
  }

  async fileUpload() {
    if (this.filesToUpload.length === 0 || !this.ticket.supportTicketId) {
      return;
    }

    await azureUpload.fileUpload(this.filesToUpload, this.ticket.supportTicketId, 'Support');
  }

  async saveTicket() {
    this.saveInProgress = true;
    this.submitAttempted = true;

    const fileNames: string[] = [];
    this.filesToUpload.forEach((f) => {
      fileNames.push(f.name);
    });
    this.ticket.attachedFileNames = fileNames;

    this.validate();

    if (this.formValid) {
      const result = await ticketsStore.updateTicket(this.ticket);
      // if result is error than check for validation messages
      if (result instanceof FalconError) {
        this.ticketErrors = validation.getValidationErrorsFromFalconError(result);
        if (result.concurrencyMessage) {
          this.ticketConcurrencyError = result.concurrencyMessage;
          window.scrollTo(0, 0);
        }
      } else {
        // upload attachments if there are any
        await this.fileUpload();

        // show information in parent component
        // then return to ticket grid
        this.props.setRequest(true);

        this.unsavedChange = false;

        this.back();
        this.submitAttempted = false;
      }
    }

    this.saveInProgress = false;
  }

  getConcurrencyMessage(): ReactNode | null {
    if (this.ticketConcurrencyError) {
      return (
        <Alert alertType="error" customClass="concurrencyAlert">
          {this.ticketConcurrencyError}
          <br />
          {constants.validation.concurrencyMessage}
        </Alert>
      );
    }
  }

  expandFirstNote = () => {
    if (this.ticket.formattedNotes) {
      const firstNote = this.ticket.formattedNotes[0];
      if (firstNote) {
        const firstNoteContent = firstNote[1];
        if (firstNoteContent) {
          const firstNoteExpanded =
            (this.ticket.formattedNotes.length < 10 && firstNoteContent.length < 200) ||
            (this.ticket.formattedNotes.length < 5 && firstNoteContent.length < 600);
          if (firstNoteExpanded) {
            this.toggleNote(0);
          }
        }
      }
    }
  };

  formattedNotes = () => {
    if (this.ticket.formattedNotes) {
      this.ticket.formattedNotes.map((note) => {
        const noteHeader = note[0];
        const noteContent = note[1];

        return (
          <div className={styles.notes}>
            <div className={styles.note__header}>{ReactHtmlParser(noteHeader as string)}</div>
            <div className={styles.note__content}>{ReactHtmlParser(noteContent as string)}</div>
          </div>
        );
      });
    }
  };

  toggleAllNotes = () => {
    if (this.ticket.formattedNotes) {
      if (!this.notesExpanded) {
        for (let i = 0; i < this.ticket.formattedNotes.length; i++) {
          if (!this.activeIndex.includes(i)) {
            this.activeIndex.push(i);
          }
        }
        this.notesExpanded = true;
      } else {
        this.activeIndex = [];
        this.notesExpanded = false;
      }
    }
  };

  toggleNote = (index: number) => {
    if (this.activeIndex.includes(index)) {
      this.activeIndex = this.activeIndex.filter((i) => i !== index);

      return;
    }

    this.activeIndex.push(index);
  };

  back = () => {
    this.props.history.push('/tickets');
  };

  goToNewTicket = () => {
    this.props.history.push('/tickets/new');
  };

  render() {
    if (this.ticket.supportTicketId) {
      return (
        <>
          <Prompt
            when={this.unsavedChange && BaseUnsavedChangesComponent.enableCheck}
            message="You have unsaved changes. Are you sure you want to navigate away from this page?"
          />

          <div className={`${styles.ticketsSection} ${appStyles.container}`} id="ticketBreakdownSection">
            <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
              <h3 id="ticketBreakdownTitle" className={appStyles.heading__text_sm}>
                Ticket Breakdown - {this.ticket.supportTicketId}
              </h3>
            </div>

            {this.getConcurrencyMessage()}

            {!this.ticketBreakdownLoading ? (
              <form className={appStyles.row}>
                <div id="ticketDetailsCard" className={appStyles.col_md_6}>
                  <Legend type="large" text="Ticket Details" />
                  <div className={appStyles.form__row}>
                    <div className={appStyles.form__label}>Ticket Summary</div>
                    <div className={appStyles.form__readOnlyElement}>{ReactHtmlParser(this.ticket.subject || '')}</div>
                  </div>
                  <div className={appStyles.form__row}>
                    <div className={appStyles.form__label}>Date Ticket Logged</div>
                    <div className={appStyles.form__readOnlyElement}>
                      {new Intl.DateTimeFormat('en-gb', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: false
                      }).format(new Date(this.ticket.time))}
                    </div>
                  </div>
                  <div className={appStyles.form__row}>
                    <Select
                      elementId="statusId"
                      labelText="Ticket Status"
                      name="statusId"
                      options={this.statusList}
                      placeholder="Please choose..."
                      disablePlaceholder={true}
                      handleChange={this.handleInputAsNumber}
                      value={this.ticket.statusId}
                    />
                  </div>

                  {this.isAccountAndBilling ? (
                    <></>
                  ) : (
                    <>
                      <div className={appStyles.form__row}>
                        <div className={appStyles.form__label}>Contract</div>
                        <div className={appStyles.form__readOnlyElement}>{this.ticket.contract}</div>
                      </div>
                      <div className={appStyles.form__row}>
                        <div className={appStyles.form__label}>Number of Users Affected</div>
                        <div className={appStyles.form__readOnlyElement}>{this.ticket.affectedUsers}</div>
                      </div>
                    </>
                  )}

                  {this.isCloudOrDevice ? (
                    <div className={appStyles.form__row}>
                      <div className={appStyles.form__label}>Error Code</div>
                      <div className={appStyles.form__readOnlyElement}>{this.ticket.errorcodes}</div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {this.isDevice ? (
                    <div className={appStyles.form__row}>
                      <div className={appStyles.form__label}>Related Device</div>
                      <div className={appStyles.form__readOnlyElement}>{this.ticket.relatedDevice}</div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <Legend type="large" text="Contact Details" />
                  <div className={appStyles.form__row}>
                    <Select
                      elementId="contactId"
                      labelText="Contact"
                      name="contactId"
                      options={this.props.contacts}
                      placeholder="Please choose..."
                      disablePlaceholder={true}
                      handleChange={this.handleInputAsNumber}
                      value={this.ticket.contactId}
                      validationError={this.ticketErrors.fieldErrors['contactId']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <Select
                      elementId="addressId"
                      labelText="Address"
                      name="addressId"
                      options={this.props.addresses}
                      placeholder="Please choose..."
                      disablePlaceholder={true}
                      handleChange={this.handleInputAsNumber}
                      value={this.ticket.addressId}
                    />
                  </div>
                </div>

                <div id="messageCard" className={appStyles.col_md_6}>
                  <div className={appStyles.form__row}>
                    <TextArea
                      elementId="message"
                      labelText="Details"
                      name="message"
                      placeholder="Please type your update here, providing as much information as possible"
                      handleChange={this.handleInput}
                      value={this.ticket.message}
                      rows={10}
                      validationError={this.ticketErrors.fieldErrors['message']}
                    />
                    <FileMultiUpload files={this.filesToUpload} passResults={this.setFilesToUpload} itemId={this.ticket.supportTicketId} />
                  </div>
                  <div id="messageHistory" className={`${appStyles.form__row} ${appStyles.form__markup} ${appStyles.text_midBlue}`}>
                    <Legend type="large" text="Update History" />
                    <div className={styles.note__toggleContainer}>
                      <button type="button" className={styles.note__toggleAll} onClick={this.toggleAllNotes}>
                        {this.notesExpanded ? 'Collapse all' : 'Expand all'}
                      </button>
                    </div>
                    {this.ticket.formattedNotes &&
                      this.ticket.formattedNotes.map((note, index) => {
                        const noteHeader = note[0];
                        const noteContent = note[1];
                        const noteHasContent = noteContent !== '' && noteContent !== '<p />';

                        return (
                          <div
                            key={index}
                            id={`note-${index}`}
                            className={classNames(
                              styles.note,
                              { [styles.active]: this.activeIndex.includes(index) } // add active class if note selected
                            )}>
                            <div className={styles.note__header}>
                              {ReactHtmlParser(noteHeader as string)}
                              {noteHasContent ? (
                                <button
                                  type="button"
                                  className={styles.note__toggle}
                                  onClick={() => {
                                    this.toggleNote(index);
                                  }}>
                                  {this.activeIndex.includes(index) ? (
                                    <Icon icon={Icon.minus} iconName="Hide notes" color="lightBlue" size="xs" />
                                  ) : (
                                    <Icon icon={Icon.plus} iconName="Show notes" color="lightBlue" size="xs" />
                                  )}
                                </button>
                              ) : (
                                <></>
                              )}
                            </div>
                            {noteHasContent ? <div className={styles.note__content}>{ReactHtmlParser(noteContent as string)}</div> : <></>}
                          </div>
                        );
                      })}
                  </div>
                  <div className={appStyles.form__row}>
                    <Legend type="large" text="Attached Files" />
                    <FileDownloads dataType="ticket" itemId={this.ticket.supportTicketId}></FileDownloads>
                  </div>
                </div>
              </form>
            ) : (
              <>
                <div>
                  <img alt="Loading..." className={appStyles.preloader} src={preloader} />
                </div>
              </>
            )}
          </div>
          <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
            <Button id="buttonViewTickets" buttonStyle="outline_secondary" size="lg" handleClick={this.back}>
              Back to Tickets
            </Button>
            <Button id="btnAddTicket" customClass={appStyles.button_hasIcon} buttonStyle="outline_primary" size="lg" handleClick={this.goToNewTicket}>
              <Icon icon={Icon.plus} customClass={appStyles.buttonWithIcon__icon} color="currentColor" size="sm" iconName="addIcon" />
              <span className={appStyles.buttonWithIcon__text}>Log New Ticket</span>
            </Button>
            <Button id="saveTicket" buttonStyle="primary" size="lg" inProgress={this.saveInProgress} handleClick={this.saveTicket}>
              <Icon icon={Icon.check} customClass={appStyles.buttonWithIcon__icon} color="currentColor" size="sm" iconName="addIcon" />
              <span className={appStyles.buttonWithIcon__text}>Save Changes</span>
            </Button>
          </BottomButtonContainer>
        </>
      );
    }

    return null;
  }
}

export default withRouter(TicketsBreakdown);
