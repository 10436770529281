import { FC } from 'react';
import classNames from 'classnames';
import chessLogo from 'assets/images/chess_logo.png';
import Button from 'components/Generic/FormElements/Button/Button';
import FormWrapper from 'components/Generic/FormWrapper/FormWrapper';
import appStyles from 'App.module.scss';
import styles from 'components/Authentication/Authentication.module.scss';
import LoginError from './LoginError';
import UserGuideWidget from './UserGuideWidget';

interface ILoginFormProps {
  onSubmit: React.FormEventHandler<Element>;
  errorMessage?: string | null;
  inProgress: boolean;
}

const LoginForm: FC<ILoginFormProps> = ({ onSubmit, inProgress, errorMessage }) => (
  <div className={styles.loginContainer}>
    <div className={styles.logo_white_wrapper}>
      <img className={styles.login__logo} src={chessLogo} alt="chess logo" />
    </div>
    <div className={styles.login_plate}>
      <div className={styles.login_form__wrapper}>
        <FormWrapper formTitle="Welcome to the Customer Portal" onSubmit={onSubmit} className={styles.login}>
          <div className={appStyles.form__row}>
            <Button id="loginButton" buttonType="submit" buttonStyle="primary" size="lg" inProgress={inProgress}>
              Click here to proceed
            </Button>
          </div>
        </FormWrapper>
        {!!errorMessage && <LoginError message={errorMessage} />}
        <div className={styles.fullWidth}>
          <div className={classNames(styles.column_centred, styles.text_right)}>
            <a className={styles.employeeLogin__link} href="/login?employee">
              Employee login
            </a>
          </div>
        </div>
      </div>
      <UserGuideWidget />
    </div>
  </div>
);

export default LoginForm;
