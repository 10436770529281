import React, { ChangeEvent, RefObject } from 'react';
import { Grid, GridFilterChangeEvent } from '@progress/kendo-react-grid';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import Alert from 'components/Generic/Alert/Alert';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Confirmation } from 'components/Generic/Confirmation/Confirmation';
import Button from 'components/Generic/FormElements/Button/Button';
import Input from 'components/Generic/FormElements/Input/Input';
import { BaseServerGrid } from 'components/Generic/Grid/BaseServerGrid';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { QuoteStatusColumnMenu } from 'components/Generic/Grid/QuoteStatusColumnMenu';
import Icon from 'components/Generic/Icon/icon';
import AcceptQuote from 'components/Products/Quotes/AcceptQuote/AcceptQuote';
import QuoteQuestion from 'components/Products/Quotes/QuoteQuestion/QuoteQuestion';
import QuotesBreakdown from 'components/Products/Quotes/QuotesBreakdown/QuotesBreakdown';
import { Quote } from 'models/api/quote.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import { ModelCollection } from 'models/generic/modelCollection.model';
import { QuotesGridRequest } from 'models/requests/quotesGridRequest.model';
import productsStore from 'stores/products.store';
import constants from 'utils/constants';
import { IMatchResult } from 'utils/routing';
import utils from 'utils/utils';
import { ReactComponent as DocumentsSVGLogo } from 'assets/images/svg/documents.svg';
import appStyles from 'App.module.scss';
import styles from './MyQuotes.module.scss';

@observer
export class ManageQuotes extends BaseServerGrid<Quote, IBaseProps, QuotesGridRequest> {
  @observable selectedQuote: Quote | null = null;
  @observable searchKeyword = '';
  @observable displayMode: 'grid' | 'breakdown' | 'accept' | 'question' | 'acceptSubmitted' | 'questionSubmitted' = 'grid';
  @observable breakdownRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  @observable acceptRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  @observable questionRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

  subscriptionId = '';

  constructor(props: IBaseProps) {
    super(props);

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchFinishedTyping = this.handleSearchFinishedTyping.bind(this);
    this.showAcceptConfirmation = this.showAcceptConfirmation.bind(this);
    this.showMessageConfirmation = this.showMessageConfirmation.bind(this);

    // set default sort and filter
    this.sort = [{ field: 'quoteId', dir: 'desc' }];
    this.filter = {
      logic: 'and',
      filters: [{ field: 'status', operator: 'eq', value: 'Pending' }]
    };

    // set default request - sort order and statuses
    this.request = new QuotesGridRequest('quoteId DESC', this.take, [constants.quoteStatuses.pending]);

    // monitor route changes
    this.subscriptionId = utils.routing.subscribe((location) => {
      const match = utils.routing.matchRoutes(location, ['/products/quotes/:id/:mode', '/products/quotes/:id', '/products/quotes']);
      this.setUpView(match);
    });
  }

  async componentDidMount() {
    await this.loadData();

    this.setUpView(this.props.match);
  }

  componentWillUnmount() {
    utils.routing.unsubscribe(this.subscriptionId);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async componentDidUpdate(prevProps: IBaseProps) {
    // reload data if request was updated
    if (this.requestUpdated) {
      await this.loadData();
    }
  }

  // eslint-disable-next-line complexity
  async setUpView(match: IMatchResult | null) {
    // get quote ID from match params (query string)
    let quoteId: number | null = null;
    if (match) {
      quoteId = +match.params.id || null;
      if (quoteId) {
        // if ID present in query string then load quote if not loaded already
        if (this.selectedQuote === null || this.selectedQuote.quoteId !== quoteId) {
          this.selectedQuote = await productsStore.getQuote(quoteId);
        }
        // show quote breakdown or accept or question form and scroll to relevant component
        if (this.selectedQuote) {
          if (match.params.mode === 'accept') {
            // go to accept form only if quote is in valid state for acceptance
            if (this.selectedQuote.status === 'Pending' && this.selectedQuote.validThrough > new Date()) {
              this.showSection('accept', this.acceptRef);
            } else {
              // go to breakdown if invalid
              this.goToQuote(this.selectedQuote);
            }
          } else if (match.params.mode === 'question') {
            this.showSection('question', this.questionRef);
          } else {
            this.showSection('breakdown', this.breakdownRef);
          }
        }
      } else {
        // otherwise show all quotes
        this.selectedQuote = null;
        this.showSection('grid', null);
      }
    }
  }

  showSection(mode: 'grid' | 'breakdown' | 'accept' | 'question' | 'acceptSubmitted' | 'questionSubmitted', ref: RefObject<HTMLDivElement> | null) {
    this.displayMode = mode;
    if (ref && ref.current) {
      window.scrollTo(0, ref.current.offsetTop);
    } else {
      window.scrollTo(0, 0);
    }
  }

  getData(): Promise<ModelCollection<Quote>> {
    return productsStore.getQuotes(this.request);
  }

  handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    // update value displayed in search box
    this.searchKeyword = e.target.value;
  };

  handleSearchFinishedTyping = async (value: string) => {
    // pass value to grid for search once user stopped typing
    this.setSearchKeyword(value);

    if (this.requestUpdated) {
      await this.loadData();
    }
  };

  showAcceptConfirmation() {
    this.displayMode = 'acceptSubmitted';
  }

  showMessageConfirmation() {
    this.displayMode = 'questionSubmitted';
  }

  filterChange = (event: GridFilterChangeEvent) => {
    super.filterChange(event);

    // update request object
    this.request.includedStatusCodes = [];

    if (event.filter && event.filter.filters) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.parseFilterEvent(event).filters?.forEach((filter: any) => {
        switch (filter.value) {
          case 'Pending':
            this.request.includedStatusCodes.push(constants.quoteStatuses.pending);
            break;
          case 'Ordered':
            this.request.includedStatusCodes.push(constants.quoteStatuses.ordered);
            break;
          case 'Declined':
            this.request.includedStatusCodes.push(constants.quoteStatuses.declined);
            break;
        }
      });
    }

    // reset to first page
    this.request.pageNumber = 1;
    this.skip = 0;

    // force refresh
    this.requestUpdated = true;
  };

  back = () => {
    this.props.history.push('/products/quotes/');
  };

  goToQuote(quote: Quote) {
    this.props.history.push('/products/quotes/' + quote.quoteId);
  }

  getGrid(): JSX.Element {
    // return grid, if selectedQuote is not null then disable sorting/paging and hide View column
    return (
      <div id="mainGrid">
        <Grid
          data={this.selectedQuote ? [this.selectedQuote] : this.data.items}
          filter={this.pureFilter}
          filterable={false}
          pageable={this.selectedQuote ? false : { pageSizes: this.pageSizes }}
          skip={this.skip}
          take={this.take}
          total={this.selectedQuote ? 1 : this.data.totalCount}
          onPageChange={this.pageChange}
          sortable={this.selectedQuote ? false : true}
          sort={this.selectedQuote ? undefined : toJS(this.sort)}
          resizable
          onFilterChange={this.filterChange}
          onSortChange={this.sortChange}>
          {ColumnHelper.getGridColumns([
            { field: 'projectId', title: 'Project ID', dataType: 'text', size: 'sm', currentFilter: this.filter },
            { field: 'quoteId', title: 'Quote ID', dataType: 'text', size: 'sm', currentFilter: this.filter },
            { field: 'quoteDateTime', title: 'Date', dataType: 'date', size: 'sm', currentFilter: this.filter },
            { field: 'contactName', title: 'Contact', dataType: 'text', size: 'sm', currentFilter: this.filter, sortable: false },
            { field: 'linkedQuotesLabel', title: 'Linked Quotes', dataType: 'text', size: 'sm', currentFilter: this.filter, sortable: false, isHtml: true },
            { field: 'details', title: 'Details', dataType: 'text', size: 'xl', isHtml: true, currentFilter: this.filter, sortable: false },
            { field: 'lineItemsCount', title: 'Lines', dataType: 'text', size: 'xs', currentFilter: this.filter, sortable: false },
            { field: 'versionsCount', title: 'Versions', dataType: 'text', size: 'xs', currentFilter: this.filter, sortable: false },
            { field: 'valueLabel', title: 'Total Value', dataType: 'text', size: 'sm', currentFilter: this.filter, sortable: false },
            {
              field: 'status',
              title: 'Status',
              dataType: 'text',
              size: 'sm',
              currentFilter: this.filter,
              columnMenu: this.selectedQuote ? undefined : QuoteStatusColumnMenu
            },
            this.selectedQuote
              ? undefined
              : {
                  field: '',
                  title: 'View',
                  dataType: 'icon',
                  size: 'xs',
                  cell: (props) => (
                    <td className="k-table-td gridColumn_xs gridColumn_button">
                      <div role="button" onClick={() => this.goToQuote(props.dataItem)} className={appStyles.button___grid_view}>
                        <DocumentsSVGLogo title="View quote breakdown" height={32} width={32} />
                      </div>
                    </td>
                  )
                }
          ])}
        </Grid>
      </div>
    );
  }

  // eslint-disable-next-line complexity
  render() {
    const grid = this.getGrid();

    const quotesArea = (
      <>
        <div className={appStyles.pageHeading_lg}>
          <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
            <h1 className={appStyles.heading__text}>Quotes</h1>
          </div>

          {!this.selectedQuote && (
            <div className={styles.search__container}>
              <Input
                elementId="searchBar"
                inputType="search"
                name="searchBar"
                labelText="Search Quotes"
                labelHidden={true}
                placeholder="Search Quotes"
                value={this.searchKeyword}
                handleChange={this.handleSearchChange}
                handleFinishedTyping={this.handleSearchFinishedTyping}
                customLabelClass={appStyles.form__label}
                customInputClass={appStyles.form__input}
                autocomplete="off"
              />
            </div>
          )}
        </div>

        {this.displayMode === 'grid' && (
          <Alert alertType="blank">
            <p className={`${appStyles.text_darkGrey} ${appStyles.info__main}`}>You can view all your quotes below, select a quote to see a full breakdown.</p>
          </Alert>
        )}

        {this.displayMode === 'breakdown' && (
          <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
            <h2 className={appStyles.heading__text_sm}>Required Items</h2>
          </div>
        )}
        {grid}
        <GridPDFExport ref={(pdfExport) => (this.gridPDFExport = pdfExport)}>{grid}</GridPDFExport>
      </>
    );

    return (
      <>
        <div className={appStyles.container}>
          {this.displayMode === 'grid' ? (
            <>{quotesArea}</>
          ) : this.displayMode === 'breakdown' ? (
            <>
              {quotesArea}
              <div ref={this.breakdownRef}>{this.selectedQuote ? <QuotesBreakdown quote={this.selectedQuote}></QuotesBreakdown> : ''}</div>
            </>
          ) : this.displayMode === 'accept' ? (
            <div ref={this.acceptRef}>
              {this.selectedQuote ? <AcceptQuote quote={this.selectedQuote} showAcceptConfirmation={this.showAcceptConfirmation}></AcceptQuote> : ''}
            </div>
          ) : this.displayMode === 'question' ? (
            <div ref={this.questionRef}>
              {this.selectedQuote ? <QuoteQuestion grid={grid} quote={this.selectedQuote} showMessageConfirmation={this.showMessageConfirmation} /> : ''}
            </div>
          ) : this.displayMode === 'acceptSubmitted' ? (
            <Confirmation
              mainMessage="Thanks for accepting your quote, we will send a confirmation shortly of your acceptance."
              subMessages={["If you have any other questions, don't hesitate to get in contact with Customer Service."]}
              icon={Icon.success}
              iconText="Complete"
              containerId="acceptConfirmation"
            />
          ) : this.displayMode === 'questionSubmitted' ? (
            <Confirmation
              mainMessage="We will get back to you regarding your question as soon as possible."
              subMessages={["If you have any other questions, don't hesitate to get in contact with Customer Service."]}
              icon={Icon.success}
              iconText="Sent"
              containerId="questionConfirmation"
            />
          ) : (
            ''
          )}

          {this.displayMode === 'questionSubmitted' ||
            (this.displayMode === 'acceptSubmitted' && (
              <BottomButtonContainer backgroundColor="white" layout="right">
                <Button id="buttonSubmitQuestion" buttonStyle="outline_primary" size="lg" handleClick={this.back}>
                  Back to Quotes
                </Button>
              </BottomButtonContainer>
            ))}
        </div>
      </>
    );
  }
}
