import { toJS } from 'mobx';
import { ValidationRule } from 'models/api/validationRule.model';
import { FalconError } from 'models/generic/falconError.model';
import { FalconFile } from 'models/generic/falconFile.model';
import { ValidationErrors } from 'models/generic/validationError.model';
import constants from 'utils/constants';

export type ValidationHandler<T> = (value: T) => boolean | string;

class Validation {
  regexPatterns = {
    name: /[^0-9a-zA-Z+-=_/\\\s]/g,
    bankAccountNumber: '^(\\d){8}$',
    bankSortCode: '^[0-9]{6}$',
    email:
      // eslint-disable-next-line max-len
      "^((([a-z]|\\d|[!#\\$%&'\\*\\+\\-\\/=\\?\\^_`{\\|}~]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])+(\\.([a-z]|\\d|[!#\\$%&'\\*\\+\\-\\/=\\?\\^_`{\\|}~]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])+)*)|((\\x22)((((\\x20|\\x09)*(\\x0d\\x0a))?(\\x20|\\x09)+)?(([\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x7f]|\\x21|[\\x23-\\x5b]|[\\x5d-\\x7e]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(\\\\([\\x01-\\x09\\x0b\\x0c\\x0d-\\x7f]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF]))))*(((\\x20|\\x09)*(\\x0d\\x0a))?(\\x20|\\x09)+)?(\\x22)))@((([a-z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(([a-z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])([a-z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])*([a-z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])))\\.)+(([a-z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(([a-z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])([a-z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])*([a-z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])))\\.?$",
    fileName: '^[a-zA-Z0-9](?:[a-zA-Z0-9_]*[a-zA-Z0-9])?\\.[a-zA-Z0-9]+$',
    // TODO: review regex phone pattern - first one errors in firefox, second one may be too simple, we should also use warnings as opposed to errors
    // phone: '^(\\+\\s?)?((?<!\\+.*)\\(\\+?\\d+([\\s\\-\\.]?\\d+)?\\)|\\d+)([\\s\\-\\.]?(\\(\\d+([\\s\\-\\.]?\\d+)?\\)|\\d+))*(\\s?(x|ext\\.?)\\s?\\d+)?$',
    phone: '(^(\\+\\d+)?(\\ \\d+)?(\\ \\d+)?$)|(^(\\d+)(\\ \\d+)?$)',
    // eslint-disable-next-line max-len
    url: "^(https?|ftp):\\/\\/(((([a-z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(%[\\da-f]{2})|[!\\$&'\\(\\)\\*\\+,;=]|:)*@)?(((\\d|[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-5])\\.(\\d|[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-5])\\.(\\d|[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-5])\\.(\\d|[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-5]))|((([a-z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(([a-z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])([a-z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])*([a-z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])))\\.)+(([a-z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(([a-z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])([a-z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])*([a-z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])))\\.?)(:\\d*)?)(\\/((([a-z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(%[\\da-f]{2})|[!\\$&'\\(\\)\\*\\+,;=]|:|@)+(\\/(([a-z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(%[\\da-f]{2})|[!\\$&'\\(\\)\\*\\+,;=]|:|@)*)*)?)?(\\?((([a-z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(%[\\da-f]{2})|[!\\$&'\\(\\)\\*\\+,;=]|:|@)|[\\uE000-\\uF8FF]|\\/|\\?)*)?(\\#((([a-z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(%[\\da-f]{2})|[!\\$&'\\(\\)\\*\\+,;=]|:|@)|\\/|\\?)*)?$",
    postCode: '^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$',
    digit: '^[0-9]+$',
    nonDigit: /[^0-9]/g
  };

  // #region individual rule implementation
  validateSelectedClientContact(value: number, fieldName: string, errors: ValidationErrors, description: string, severity: number): boolean {
    let isValid = true;
    if (value === 0) {
      errors.addError(fieldName, description, severity);
      isValid = false;
    }

    return isValid;
  }

  validateBankAccountNumber(value: string, fieldName: string, errors: ValidationErrors, description: string, severity: number): boolean {
    return this.validateRegex(value, this.regexPatterns.bankAccountNumber, fieldName, errors, description, severity);
  }

  validateBankSortCode(value: string, fieldName: string, errors: ValidationErrors, description: string, severity: number): boolean {
    return this.validateRegex(value, this.regexPatterns.bankSortCode, fieldName, errors, description, severity);
  }

  validateEmail(value: string, fieldName: string, errors: ValidationErrors, description: string, severity: number): boolean {
    return this.validateRegex(value, this.regexPatterns.email, fieldName, errors, description, severity);
  }

  validateDigit(value: number, fieldName: string, errors: ValidationErrors, description: string, severity: number): boolean {
    let isValid = true;

    if (value) {
      isValid = this.validateRegex(value.toString(), this.regexPatterns.digit, fieldName, errors, description, severity);
    }

    return isValid;
  }

  validateFileExtension(file: FalconFile, extensions: string[], fieldName: string, errors: ValidationErrors, description: string, severity: number): boolean {
    let isValid = true;

    if (file && extensions) {
      isValid = false;
      // check if file name ends with any of the defined extensions
      extensions.forEach((ext) => {
        if (file.name.toLowerCase().endsWith('.' + ext)) {
          isValid = true;
        }
      });

      if (!isValid) {
        errors.addError(fieldName, description, severity);
      }
    }

    return isValid;
  }

  validateFileName(file: FalconFile, fieldName: string, errors: ValidationErrors, description: string, severity: number): boolean {
    let isValid = true;

    if (file) {
      isValid = this.validateRegex(file.name, this.regexPatterns.fileName, fieldName, errors, description, severity);
    }

    return isValid;
  }

  validateFileSize(file: FalconFile, sizeInMegaBytes: number, fieldName: string, errors: ValidationErrors, description: string, severity: number): boolean {
    let isValid = true;

    if (file) {
      // remove data prefix
      const index = file.contents.indexOf(',') + 1;
      const rawData = file.contents.substring(index);

      let paddingLength = 0;
      if (rawData.endsWith('==')) {
        paddingLength = 2;
      } else if (rawData.endsWith('=')) {
        paddingLength = 1;
      }

      // check size of base64 string
      const sizeInBytes = rawData.length * (3 / 4) - paddingLength;
      if (sizeInBytes > sizeInMegaBytes * 1024 * 1024) {
        isValid = false;
        errors.addError(fieldName, description, severity);
      }
    }

    return isValid;
  }

  validateIdentialFields(value: string, compareValue: string, fieldName: string, errors: ValidationErrors, description: string, severity: number): boolean {
    let isValid = true;

    // compare values are identical if at least one of them is not empty
    if (value || compareValue) {
      if (value !== compareValue) {
        errors.addError(fieldName, description, severity);
        isValid = false;
      }
    }

    return isValid;
  }

  validateNumericRange(value: number, min: number, max: number, fieldName: string, errors: ValidationErrors, description: string, severity: number): boolean {
    let isValid = true;

    if (value < min || value > max) {
      errors.addError(fieldName, description, severity);
      isValid = false;
    }

    return isValid;
  }

  validatePhone(value: string, fieldName: string, errors: ValidationErrors, description: string, severity: number): boolean {
    let isValid = true;
    if (value) {
      isValid = this.validateRegex(value, this.regexPatterns.phone, fieldName, errors, description, severity);
    }

    return isValid;
  }

  validateRequired(value: unknown, fieldName: string, errors: ValidationErrors, description: string, severity: number): boolean {
    let isValid = true;

    if (value === '' || value === null || value === undefined || (Array.isArray(toJS(value)) && (toJS(value) as []).length === 0)) {
      errors.addError(fieldName, description, severity);
      isValid = false;
    }

    return isValid;
  }

  validateSignature(value: string, fieldName: string, errors: ValidationErrors, description: string, severity: number): boolean {
    let isValid = true;

    // validate if string is not empty
    if (value === '' || value === null || value === undefined) {
      errors.addError(fieldName, description, severity);
      isValid = false;
    }

    return isValid;
  }

  validateStringLength(value: string, min: number, max: number, fieldName: string, errors: ValidationErrors, description: string, severity: number): boolean {
    let isValid = true;

    if ((value && value.length < min) || (value && value.length > max)) {
      errors.addError(fieldName, description, severity);
      isValid = false;
    }

    return isValid;
  }

  validateTrue(value: unknown, fieldName: string, errors: ValidationErrors, description: string, severity: number): boolean {
    let isValid = true;

    if (value !== true) {
      errors.addError(fieldName, description, severity);
      isValid = false;
    }

    return isValid;
  }

  validateUrl(value: string, fieldName: string, errors: ValidationErrors, description: string, severity: number): boolean {
    // validate with regex, but override error message
    return this.validateRegex(value, this.regexPatterns.url, fieldName, errors, description, severity);
  }

  validatePostCode(value: string, fieldName: string, errors: ValidationErrors, description: string, severity: number): boolean {
    // validate with regex, but override error message
    return this.validateRegex(value, this.regexPatterns.postCode, fieldName, errors, description, severity);
  }

  private validateRegex(value: string, pattern: string, fieldName: string, errors: ValidationErrors, description: string, severity: number): boolean {
    let isValid = true;
    const regex = RegExp(pattern, 'i'); // ignore case

    if (regex.test(value) === false) {
      errors.addError(fieldName, description || 'This field is in incorrect format', severity);
      isValid = false;
    }

    return isValid;
  }

  validateEmailChangeWarning(
    value: string,
    originalValue: string,
    fieldName: string,
    errors: ValidationErrors,
    description: string,
    severity: number
  ): boolean {
    let isValid = true;

    if (originalValue && value !== originalValue) {
      errors.addError(fieldName, description, severity);

      isValid = false;
    }

    return isValid;
  }

  validateContactNoRolesWarning(value: unknown, fieldName: string, errors: ValidationErrors, description: string, severity: number): boolean {
    let isValid = true;

    if (value === 0) {
      errors.addError(fieldName, description, severity);

      isValid = false;
    }

    return isValid;
  }

  validateEmailChangeInfo(value: string, fieldName: string, errors: ValidationErrors, description: string, severity: number): boolean {
    errors.addError(fieldName, description, severity);

    return false;
  }
  // #endregion

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public validate(item: any, rules: ValidationRule[], errors: ValidationErrors, severity: 'error' | 'warning' | 'info' = 'error'): boolean {
    let isValid = true;

    // filter rules by severity
    const severityId = this.getSeverityId(severity);

    rules = rules.filter((r) => r.severity === severityId);

    // group rules by properties
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const groupedRules: any = [];
    const properties: string[] = [];
    rules.forEach((rule) => {
      if (rule.property) {
        if (!groupedRules[rule.property]) {
          groupedRules[rule.property] = [];
          properties.push(rule.property);
        }
        groupedRules[rule.property].push(rule);
      }
    });

    // for each field check if required and if has value first
    // continue with other validation rules only if required check passes
    properties.forEach((property) => {
      const propertyRules = groupedRules[property];

      let isRequired = false;
      let isPopulated = false;

      propertyRules.forEach((propertyRule: ValidationRule) => {
        if (propertyRule.ruleName && propertyRule.ruleName.includes(constants.validation.rules.required) && propertyRule.property && propertyRule.description) {
          isRequired = true;

          const fieldName = propertyRule.property[0].toLowerCase() + propertyRule.property.substring(1);
          const value = item[fieldName];

          isPopulated = this.validateRequired(value, fieldName, errors, propertyRule.description, severityId);
          if (!isPopulated) {
            isValid = false;
          }
        }
      });

      // check other rules only if not required or if required and populated
      if ((isRequired && isPopulated) || !isRequired) {
        // eslint-disable-next-line complexity
        rules.forEach((rule) => {
          if (rule.property === property) {
            if (rule.ruleName && rule.property && rule.description) {
              const fieldName = rule.property[0].toLowerCase() + rule.property.substring(1);
              const value = item[fieldName];

              // map rules by name and execute them on an object
              if (rule.ruleName.includes(constants.validation.rules.selectedClientContact)) {
                isValid = this.validateSelectedClientContact(value, fieldName, errors, rule.description, severityId) && isValid;
              }

              if (rule.ruleName.includes(constants.validation.rules.bankAccountNumber)) {
                isValid = this.validateBankAccountNumber(value, fieldName, errors, rule.description, severityId) && isValid;
              }

              if (rule.ruleName.includes(constants.validation.rules.bankSortCode)) {
                isValid = this.validateBankSortCode(value, fieldName, errors, rule.description, severityId) && isValid;
              }

              if (rule.ruleName.includes(constants.validation.rules.email)) {
                isValid = this.validateEmail(value, fieldName, errors, rule.description, severityId) && isValid;
              }

              if (rule.ruleName.includes(constants.validation.rules.fileExtension)) {
                if (rule.parameters && rule.parameters.ext) {
                  isValid = this.validateFileExtension(value, rule.parameters.ext, fieldName, errors, rule.description, severityId) && isValid;
                }
              }

              if (rule.ruleName.includes(constants.validation.rules.fileName)) {
                isValid = this.validateFileName(value, fieldName, errors, rule.description, severityId) && isValid;
              }

              if (rule.ruleName.includes(constants.validation.rules.fileSize)) {
                if (rule.parameters && rule.parameters.size) {
                  isValid = this.validateFileSize(value, rule.parameters.size, fieldName, errors, rule.description, severityId) && isValid;
                }
              }

              if (rule.ruleName.includes(constants.validation.rules.indenticalFields)) {
                if (rule.parameters && rule.parameters.compareField) {
                  const fieldName = rule.parameters.compareField[0].toLowerCase() + rule.parameters.compareField.substring(1);
                  const compareValue = item[fieldName];

                  isValid = this.validateIdentialFields(value, compareValue, fieldName, errors, rule.description, severityId) && isValid;
                }
              }

              if (rule.ruleName.includes(constants.validation.rules.numericRange)) {
                if (rule.parameters && rule.parameters.min && rule.parameters.max) {
                  isValid =
                    this.validateNumericRange(value, rule.parameters.min, rule.parameters.max, fieldName, errors, rule.description, severityId) && isValid;
                }
              }

              if (rule.ruleName.includes(constants.validation.rules.phone)) {
                isValid = this.validatePhone(value, fieldName, errors, rule.description, severityId) && isValid;
              }

              if (rule.ruleName.includes(constants.validation.rules.signature)) {
                isValid = this.validateSignature(value, fieldName, errors, rule.description, severityId) && isValid;
              }

              if (rule.ruleName.includes(constants.validation.rules.stringLength)) {
                if (rule.parameters && rule.parameters.min !== null && rule.parameters.max !== null) {
                  isValid =
                    this.validateStringLength(value, rule.parameters.min, rule.parameters.max, fieldName, errors, rule.description, severityId) && isValid;
                }
              }

              if (rule.ruleName.includes(constants.validation.rules.true)) {
                isValid = this.validateTrue(value, fieldName, errors, rule.description, severityId) && isValid;
              }

              if (rule.ruleName.includes(constants.validation.rules.url)) {
                isValid = this.validateUrl(value, fieldName, errors, rule.description, severityId) && isValid;
              }

              if (rule.ruleName.includes(constants.validation.rules.postCode)) {
                isValid = this.validatePostCode(value, fieldName, errors, rule.description, severityId) && isValid;
              }

              if (rule.ruleName.includes(constants.validation.rules.digit)) {
                isValid = this.validateDigit(value, fieldName, errors, rule.description, severityId) && isValid;
              }

              if (rule.ruleName.includes(constants.validation.rules.emailChangeWarning)) {
                const originalValue = item['originalEmail'];

                isValid = this.validateEmailChangeWarning(value, originalValue, fieldName, errors, rule.description, severityId) && isValid;
              }

              if (rule.ruleName.includes(constants.validation.rules.emailChangeInfo)) {
                isValid = this.validateEmailChangeInfo(value, fieldName, errors, rule.description, severityId) && isValid;
              }

              if (rule.ruleName.includes(constants.validation.rules.contactNoRolesWarning)) {
                isValid = this.validateContactNoRolesWarning(value, fieldName, errors, rule.description, severityId) && isValid;
              }
            }
          }
        });
      }
    });

    return isValid;
  }

  public getValidationErrorsFromFalconError(errorResult: FalconError): ValidationErrors {
    const errors = new ValidationErrors();

    errorResult.validationMessages.forEach((validationMessage) => {
      validationMessage.messages.forEach((message) => {
        errors.addError(validationMessage.fieldName, message.message, message.severity);
      });
    });

    return errors;
  }

  public getValidationErrorsFromBrokenRules(validationResult: ValidationRule[]): ValidationErrors {
    const errors = new ValidationErrors();

    validationResult.forEach((validationMessage: ValidationRule) => {
      let fieldName = '';
      if (validationMessage.property) {
        fieldName = validationMessage.property.charAt(0).toLowerCase() + validationMessage.property.slice(1);
      }
      errors.addError(fieldName, validationMessage.description || '', validationMessage.severity);
    });

    return errors;
  }

  public getSeverityId(severity: 'error' | 'warning' | 'info'): number {
    return constants.validation.severity[severity] || constants.validation.severity.error;
  }

  public getSeverityFromId(id: number | null): 'error' | 'warning' | 'info' {
    let severity: 'error' | 'warning' | 'info' = 'error';

    switch (id) {
      case constants.validation.severity.error:
        severity = 'error';
        break;
      case constants.validation.severity.warning:
        severity = 'warning';
        break;
      case constants.validation.severity.info:
        severity = 'info';
        break;
    }

    return severity;
  }
}

const validation = new Validation();
export default validation;
