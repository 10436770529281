import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Confirmation } from 'components/Generic/Confirmation/Confirmation';
import Button from 'components/Generic/FormElements/Button/Button';
import Icon from 'components/Generic/Icon/icon';
import { IBaseProps } from 'models/generic/iBaseProps';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

interface IEditContactConfirmationProps extends IBaseProps {
  returnToEditContact: () => void;
}

class EditContactConfirmation extends Component<IEditContactConfirmationProps> {
  back = () => {
    this.props.history.push('/account/contacts');
  };

  render() {
    return (
      <>
        <Confirmation mainMessage="Contact updated successfully." containerId="account_success" icon={Icon.success} iconText="Saved" />

        <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
          <Button buttonStyle="outline_secondary" size="lg" handleClick={this.back}>
            Back to Manage Contacts
          </Button>

          <Button buttonStyle="primary" size="lg" handleClick={this.props.returnToEditContact}>
            Edit Contact
          </Button>
        </BottomButtonContainer>
      </>
    );
  }
}

export default withRouter(EditContactConfirmation);
