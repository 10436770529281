import { ChangeEvent } from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { BaseUnsavedChangesComponent } from 'components/Generic/BaseUnsavedChangesComponent';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import TextArea from 'components/Generic/FormElements/TextArea/TextArea';
import Icon from 'components/Generic/Icon/icon';
import { Quote } from 'models/api/quote.model';
import { QuoteMessage } from 'models/api/quoteMessage.model';
import { ValidationRule } from 'models/api/validationRule.model';
import { FalconError } from 'models/generic/falconError.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import { ValidationErrors } from 'models/generic/validationError.model';
import productsStore from 'stores/products.store';
import validation from 'utils/validation';
import appStyles from 'App.module.scss';

interface IQuoteQuestionProps extends IBaseProps {
  quote: Quote;
  grid: JSX.Element;
  showMessageConfirmation: () => void;
}

@observer
class QuoteQuestion extends BaseUnsavedChangesComponent<IQuoteQuestionProps> {
  @observable message: QuoteMessage = new QuoteMessage();
  @observable errors = new ValidationErrors();
  validationRules: ValidationRule[] = [];
  @observable formValid = true;
  submitAttempted = false;

  constructor(props: IQuoteQuestionProps) {
    super(props);

    this.submitQuestion = this.submitQuestion.bind(this);

    this.message.quoteId = props.quote.quoteId;
  }

  async componentDidMount() {
    this.validationRules = await productsStore.getQuoteQuestionValidationRules();
  }

  handleQuestionInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    this.message = {
      ...this.message,
      [name]: value
    };

    this.unsavedChange = true;

    if (this.submitAttempted) {
      this.validate();
    }
  };

  validate() {
    // reset state
    this.formValid = true;
    const errors = new ValidationErrors();
    this.formValid = validation.validate(this.message, this.validationRules, errors);

    this.errors = errors;
  }

  async submitQuestion() {
    this.submitAttempted = true;
    this.validate();
    if (this.formValid) {
      const result = await productsStore.postQuoteQuestion(this.message);
      if (result instanceof FalconError) {
        this.errors = validation.getValidationErrorsFromFalconError(result);
      } else {
        this.unsavedChange = false;
        this.props.showMessageConfirmation();
      }
    }
  }

  backToBreakdown = () => {
    this.props.history.push(`/products/quotes/${this.message.quoteId}`);
  };

  render() {
    return (
      <>
        <Prompt
          when={this.unsavedChange && BaseUnsavedChangesComponent.enableCheck}
          message="You have unsaved changes. Are you sure you want to navigate away from this page?"
        />

        <div className={appStyles.pageHeading_lg} id="quotesQuestionSection">
          <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
            <Icon icon={Icon.helpCircle} size="heading" color="currentColor" iconName="question icon" customClass={appStyles.heading__icon} />
            <h1 className={appStyles.heading__text}>Have a Question?</h1>
          </div>
        </div>
        <div className={appStyles.row}>
          <div className={appStyles.col_md_6}>
            <fieldset>
              <div className={appStyles.form__row}>
                <TextArea
                  name="message"
                  elementId="message"
                  placeholder="enter your message"
                  labelText="Not Ready to Accept? Ask any questions below."
                  resize="vertical"
                  value={this.message.message}
                  handleChange={this.handleQuestionInputChange}
                  rows={4}
                  validationError={this.errors.fieldErrors['message']}
                />
              </div>
            </fieldset>
          </div>
          <div className={appStyles.col_md_12} id="quotesQuestionSection">
            <Legend type="medium" text="Related Quote" />
            {this.props.grid}
          </div>
        </div>

        <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
          <Button buttonStyle="outline_secondary" size="lg" handleClick={this.backToBreakdown}>
            Back to Quote Breakdown
          </Button>
          <Button id="buttonSubmitQuestion" buttonStyle="primary" size="lg" handleClick={this.submitQuestion}>
            Submit Question
          </Button>
        </BottomButtonContainer>
      </>
    );
  }
}

export default withRouter(QuoteQuestion);
