import api from 'api/api';
import { action, observable } from 'mobx';
import { Contact } from 'models/api/contact.model';
import { Role } from 'models/api/role.model';
import { ValidationRule } from 'models/api/validationRule.model';
import { FalconError } from 'models/generic/falconError.model';
import { ICountableArray } from 'models/generic/iCountableArray';
import { ListItem } from 'models/generic/listItem.model';
import { ModelCollection } from 'models/generic/modelCollection.model';
import { ContactsGridRequest } from 'models/requests/contactsGridRequest.model';
import constants from 'utils/constants';

class AccountStore {
  @observable selectedClientId: number | null = null;

  @action selectClientId(id: number | null) {
    this.selectedClientId = id;
  }

  async getContactValidationRules(): Promise<ValidationRule[]> {
    return api.getValidationRules('Contact/GetValidationRules');
  }

  async getClientValidationRules(): Promise<ValidationRule[]> {
    return api.getValidationRules('Client/GetValidationRules');
  }

  async getContacts(request: ContactsGridRequest): Promise<ModelCollection<Contact>> {
    const contacts = new ModelCollection<Contact>();

    const qs = request.getRequestAsQueryString();

    const result = await api.get<Contact[]>('Contact/ContactsByCurrentClientId?' + qs, { friendlyErrorMessage: 'There was an error loading contacts.' });

    if (result instanceof FalconError) {
    } else {
      result.forEach((contact) => {
        if (contact.lastLogin) {
          contact.lastLogin = new Date(contact.lastLogin);
        } else {
          contact.lastLogin = 'Never';
        }
      });

      contacts.items = result;
      contacts.totalCount = (result as unknown as ICountableArray).totalCount;
    }

    return contacts;
  }

  async getContactDetails(id: number): Promise<Contact | null> {
    let contact = null;

    const result = await api.get<Contact>(`/contact/details/${id}`, { friendlyErrorMessage: "We couldn't find the associated contact." });
    if (result instanceof FalconError) {
    } else {
      contact = result;

      if (contact && contact.lastLogin) {
        contact.lastLogin = new Date(contact.lastLogin);
      }
    }

    return contact;
  }

  async getContactsForDropdown(): Promise<Contact[]> {
    let contacts: Contact[] = [];

    const result = await api.get<Contact[]>('/Contact/ContactsByCurrentClientIdForDropdown', { friendlyErrorMessage: 'There was an error loading contacts.' });
    if (result instanceof FalconError) {
    } else {
      contacts = result;
    }

    return contacts;
  }

  async getContactsListItems(): Promise<ListItem[]> {
    const contacts: Contact[] = await this.getContactsForDropdown();
    const items: ListItem[] = [];

    contacts.forEach((contact) => {
      if (contact.contactId) {
        items.push(new ListItem(contact.contactId, contact.formattedContactDetails as string));
      }
    });

    return items;
  }

  async getCurrentContact(): Promise<Contact> {
    let contact: Contact = new Contact();

    const result = await api.get<Contact>('/Contact/EditCurrentContact', { friendlyErrorMessage: 'There was an error loading your details.' });
    if (result instanceof FalconError) {
    } else {
      contact = result;

      if (contact && contact.lastLogin) {
        contact.lastLogin = new Date(contact.lastLogin);
      }
    }

    return contact;
  }

  async getNewContact(): Promise<Contact | FalconError> {
    const result = await api.get<Contact>('/Contact/Create', { friendlyErrorMessage: 'There was an error loading the form.' });

    return result;
  }

  async createNewContact(contact: Contact): Promise<Contact | FalconError> {
    const result = await api.post<Contact>('/Contact/Create', contact, 'There was an error creating a contact.');

    return result;
  }

  async getContact(id: number): Promise<Contact | FalconError> {
    let contact = null;

    const result = await api.get<Contact>('/Contact/Edit/' + id, { friendlyErrorMessage: 'There was an error loading contact to edit.' });

    if (result instanceof FalconError) {
    } else {
      contact = result;

      if (contact && contact.lastLogin) {
        contact.lastLogin = new Date(contact.lastLogin);
      }
    }

    return result;
  }

  async updateContact(id: number, contact: Contact): Promise<Contact | FalconError> {
    contact.contactRoles = [];
    const result = await api.put<Contact>('/Contact/Edit/', contact, 'There was an error updating the contact.');

    return result;
  }

  async updateCurrentContact(contact: Contact): Promise<Contact | FalconError> {
    const result = await api.put<Contact>('/Contact/EditCurrentContact/', contact, 'There was an error updating your details.');

    return result;
  }

  async getRolesAsListItem(roles: Role[]): Promise<ListItem[]> {
    const items: ListItem[] = [];

    // hide unused roles
    const unusedRoles = [constants.roleNames.Tasks, constants.roleNames.DailySummary, constants.roleNames.Repairs, constants.roleNames.OrderOnline];

    roles.forEach((role) => {
      if (role.roleId && role.roleName) {
        const item = new ListItem(role.roleId, role.roleName);

        if (unusedRoles.indexOf(role.roleName) > -1) {
          item.visible = false;
        }

        items.push(item);
      }
    });

    return items;
  }

  getSelectedRoles(roles: Role[]): number[] {
    const selectedRoles: number[] = [];
    roles.forEach((role) => {
      if (role.isSelected) {
        selectedRoles.push(role.roleId as number);
      }
    });

    return selectedRoles;
  }
}

export default new AccountStore();
