import { Component } from 'react';
import classNames from 'classnames';
import { action, computed } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { IAvailableProduct } from 'models/api/availableProduct.model';
import availabilityChecksStore from 'stores/availabilityChecks.store';
import { errorMessages } from 'utils/messages';
import Preloader from 'components/Generic/Preloader/Preloader';
import appStyles from 'App.module.scss';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import availabilityCheckerStyles from '../AvailabilityChecker.module.scss';
import AvailableProduct from './AvailableProduct';

@observer
class ProductAvailability extends Component<RouteComponentProps> {
  @action handleCheckAnotherAddress: React.MouseEventHandler<HTMLButtonElement> = async () => {
    const { history } = this.props;

    availabilityChecksStore.cleanUpStore();
    history.push('/services/availability-checker');
  };

  @computed get showReadyToOrder() {
    return (
      availabilityChecksStore.pending ||
      !availabilityChecksStore?.selectedAddress?.aLK ||
      availabilityChecksStore.availableProductsRequestError ||
      !availabilityChecksStore.availableProducts
    );
  }

  onOrderClick = (availableProduct: IAvailableProduct) => {
    availabilityChecksStore.setSelectedProduct(availableProduct);
    const { history } = this.props;

    history.push('/services/availability-checker/request-callback');
  };

  render() {
    return (
      <div className={classNames(appStyles.container_center, availabilityCheckerStyles.availabilityChecker__container)}>
        <div className={appStyles.heading}>
          <h1 className={classNames(appStyles.heading__text, appStyles.text_midBlue)}>Great News! Ultrafast Fibre is Available at This Address!</h1>
        </div>
        {availabilityChecksStore.pending ? (
          <div className={availabilityCheckerStyles.preloader__container}>
            <Preloader titleClass={availabilityCheckerStyles.center_text} title="Please wait a moment while we check product availability for your address" />
          </div>
        ) : (
          <>
            <AvailableProduct
              errorMessage={errorMessages.availableProductsRequestError}
              availableProducts={availabilityChecksStore.availableProducts}
              onOrderClick={this.onOrderClick}
            />
            <BottomButtonContainer backgroundColor="white" layout="left">
              <Button size="lg" buttonStyle="primary" id="btnSearchAddresses" handleClick={this.handleCheckAnotherAddress}>
                <span>Check Another Address</span>
              </Button>
            </BottomButtonContainer>
          </>
        )}
      </div>
    );
  }
}

export default ProductAvailability;
