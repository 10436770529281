import appStyles from 'App.module.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Contact } from 'models/api/contact.model';
import { Returns } from 'models/api/returns.model';
import { Component } from 'react';
import accountStore from 'stores/account.store';

interface IContactDetailsProps {
  return: Returns;
}

@observer
export default class ContactDetails extends Component<IContactDetailsProps> {
  @observable contact: Contact | null = null;

  public async componentDidMount() {
    if (this.props.return.contactId) {
      this.contact = await accountStore.getContactDetails(this.props.return.contactId);
    }
  }

  public render() {
    if (this.contact) {
      return (
        <>
          <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
            <h3 className={appStyles.heading__text_sm}>Contact Details</h3>
          </div>
          <div className={`${appStyles.card} ${appStyles.background_white} ${appStyles.card_boxShadow}`}>
            <div className={appStyles.card__row}>
              <div className={`${appStyles.card__label} ${appStyles.text_midBlue}`}>Contact</div>
              <div className={`${appStyles.card__value} ${appStyles.text_marginBlue}`}>{this.contact.fullName}</div>
            </div>
            <div className={appStyles.card__row}>
              <div className={`${appStyles.card__label} ${appStyles.text_midBlue}`}>Sort Code</div>
              <div className={`${appStyles.card__value} ${appStyles.text_marginBlue}`}>{this.contact.email}</div>
            </div>
            <div className={appStyles.card__row}>
              <div className={`${appStyles.card__label} ${appStyles.text_midBlue}`}>Contact Number</div>
              <div className={`${appStyles.card__value} ${appStyles.text_marginBlue}`}>{this.contact.telephone}</div>
            </div>
            <div className={appStyles.card__row}>
              <div className={`${appStyles.card__label} ${appStyles.text_midBlue}`}>Address</div>
              <div className={`${appStyles.card__value} ${appStyles.text_marginBlue}`}>{this.contact.addressId}</div>
            </div>
          </div>
          <br />
          <br />
        </>
      );
    } else {
      return '';
    }
  }
}
