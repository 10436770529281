import { filterBy, orderBy } from '@progress/kendo-data-query';
import { GridFilterChangeEvent, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { BaseGrid } from 'components/Generic/Grid/BaseGrid';
import { observable, toJS } from 'mobx';

export class BaseClientGrid<DataItemType, PropsType> extends BaseGrid<PropsType> {
  // represents all data loaded into the component
  @observable allData: DataItemType[] = [];
  // represents the filtered data displayed in the grid
  @observable gridData: DataItemType[] = [];

  pageChange = (event: GridPageChangeEvent) => {
    this.skip = event.page.skip;
    this.take = event.page.take;
  };

  sortChange = (event: GridSortChangeEvent) => {
    if (event.sort) {
      this.sort = event.sort;
    }
  };

  filterChange = (event: GridFilterChangeEvent) => {
    this.gridData = filterBy(this.allData, event.filter);
    this.filter = event.filter;
    this.skip = 0;
  };

  getData = (selectedItem?: DataItemType | null) => {
    // if row is selected and breakdown area is visible then hide other rows
    if (selectedItem) {
      return [selectedItem];
    } else {
      return orderBy(filterBy(this.gridData, this.filter), this.sort).slice(this.skip, this.skip + this.take);
    }
  };

  getPageable = (selectedItem?: DataItemType | null) => {
    // if row is selected then hide paging panel
    if (selectedItem) {
      return false;
    } else {
      return { pageSizes: this.pageSizes };
    }
  };

  getSortable = (selectedItem?: DataItemType | null) => {
    // if row is selected then disable sorting
    if (selectedItem) {
      return false;
    } else {
      return true;
    }
  };

  getSort = (selectedItem?: DataItemType | null) => {
    // if row is selected then remove current sort
    if (selectedItem) {
      return undefined;
    } else {
      return toJS(this.sort);
    }
  };

  getTotal = (selectedItem?: DataItemType | null) => {
    // if row is selected then show 1 as total
    if (selectedItem) {
      return 1;
    } else {
      return filterBy(this.gridData, this.filter).length;
    }
  };

  exportPdf = () => {
    super.exportPdf(this.gridData);
  };
}
