export function getProductSpeedString(speed: number): string {
  return speed < 999 ? `${speed} Mbps` : '1 Gbps';
}

export function booleanToStringKeyGenerator(...conditions: boolean[]): string {
  return `Key_${conditions.join('_')}`;
}

export function formatString(str: string, ...val: string[]) {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }

  return str;
}
