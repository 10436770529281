import React, { Component, RefObject } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import EditLocation from 'components/Account/Locations/EditLocation/EditLocation';
import LocationsGrid from 'components/Account/Locations/LocationsGrid';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import Icon from 'components/Generic/Icon/icon';
import { Location } from 'models/api/location.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import locationsStore from 'stores/locations.store';
import { IMatchResult } from 'utils/routing';
import utils from 'utils/utils';
import appStyles from 'App.module.scss';

@observer
export class ManageLocations extends Component<IBaseProps> {
  @observable selectedLocation: Location | null = null;
  @observable editRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  subscriptionId = '';

  constructor(props: IBaseProps) {
    super(props);

    // monitor route changes
    this.subscriptionId = utils.routing.subscribe((location) => {
      const match = utils.routing.matchRoutes(location, ['/account/locations/:id', '/account/locations/new', '/account/locations']);
      this.setUpView(location, match);
    });
  }

  componentDidMount() {
    const currentRoute = window.location.pathname;
    this.setUpView(currentRoute, this.props.match);
  }

  componentWillUnmount() {
    utils.routing.unsubscribe(this.subscriptionId);
  }

  // eslint-disable-next-line complexity
  async setUpView(path: string, match: IMatchResult | null) {
    // get location ID from match params (query string)
    let locationId: number | null = null;
    if (match && match.params) {
      locationId = +match.params.id || null;
    }

    const isNew = path.indexOf('/new') > 0;

    if (isNew) {
      // will show new form
      this.selectedLocation = await locationsStore.getNewLocation();
    } else if (locationId) {
      // will show edit form
      this.selectedLocation = await locationsStore.getLocation(locationId);

      if (!this.selectedLocation) {
        this.props.history.push('/not-found');

        return;
      }
    } else {
      // will show list
      this.selectedLocation = null;
    }

    // if in edit mode then scroll to edit form
    if (((match && match.params.id) || isNew) && this.editRef.current) {
      // ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      window.scrollTo(0, this.editRef.current.offsetTop);
    } else {
      // otherwise scroll to top
      window.scrollTo(0, 0);
    }
  }

  goToNewLocationForm = () => {
    this.props.history.push('/account/locations/new');
  };

  render() {
    return (
      <>
        <div className={appStyles.container}>
          <LocationsGrid />
          <br />
          <br />
          <div ref={this.editRef}>{this.selectedLocation ? <EditLocation seletedLocation={this.selectedLocation} /> : ''}</div>
          {!this.selectedLocation && (
            <BottomButtonContainer backgroundColor="white" layout="right">
              <Button id="newLocation" buttonStyle="primary" size="lg" handleClick={this.goToNewLocationForm}>
                <Icon icon={Icon.plus} customClass={appStyles.buttonWithIcon__icon} color="currentColor" size="sm" iconName="addIcon" />
                <span className={appStyles.buttonWithIcon__text}>Add New Location</span>
              </Button>
            </BottomButtonContainer>
          )}
        </div>
      </>
    );
  }
}
