import { Grid, GridToolbar } from '@progress/kendo-react-grid';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import appStyles from 'App.module.scss';
import Button from 'components/Generic/FormElements/Button/Button';
import Input from 'components/Generic/FormElements/Input/Input';
import { BaseClientGrid } from 'components/Generic/Grid/BaseClientGrid';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { IBaseProps } from 'models/generic/iBaseProps';
import { ChangeEvent } from 'react';

@observer
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class ExampleKendoGrid extends BaseClientGrid<any, any> {
  constructor(props: IBaseProps) {
    super(props);

    // hardcoded sample data
    this.allData = [
      {
        ID: 1,
        Title: 'Mr',
        FullName: 'Alan McWard',
        Email: 'amcward0@canalblog.com',
        Position: 'Account Coordinator',
        Mobile: '8251440453',
        Telephone: '8151092216'
      },
      {
        ID: 2,
        Title: 'Ms',
        FullName: 'Dido Puddifer',
        Email: 'dpuddifer1@theglobeandmail.com',
        Position: 'Software Test Engineer I',
        Mobile: '9174068165',
        Telephone: '6742739839'
      },
      {
        ID: 3,
        Title: 'Ms',
        FullName: 'Bartel Erb',
        Email: 'berb2@foxnews.com',
        Position: 'Software Engineer II',
        Mobile: '4359928914',
        Telephone: '3502304285'
      },
      {
        ID: 4,
        Title: 'Mrs',
        FullName: 'Anabella Maletratt',
        Email: 'amaletratt3@bigcartel.com',
        Position: 'VP Quality Control',
        Mobile: '4326781877',
        Telephone: '9027010548'
      },
      {
        ID: 5,
        Title: 'Rev',
        FullName: 'Calla Mordecai',
        Email: 'cmordecai4@amazon.de',
        Position: 'Health Coach III',
        Mobile: '9467238577',
        Telephone: '8957251944'
      },
      {
        ID: 6,
        Title: 'Ms',
        FullName: 'Jany Winslet',
        Email: 'jwinslet5@google.es',
        Position: 'Junior Executive',
        Mobile: '5499655705',
        Telephone: '3431512850'
      },
      {
        ID: 7,
        Title: 'Rev',
        FullName: 'Cindie Delagnes',
        Email: 'cdelagnes6@senate.gov',
        Position: 'Senior Editor',
        Mobile: '6815026698',
        Telephone: '2495973950'
      },
      {
        ID: 8,
        Title: 'Honorable',
        FullName: 'Chane Recke',
        Email: 'crecke7@admin.ch',
        Position: 'Data Coordiator',
        Mobile: '2667593383',
        Telephone: '4991666014'
      },
      {
        ID: 9,
        Title: 'Honorable',
        FullName: 'Lauritz Jailler',
        Email: 'ljailler8@surveymonkey.com',
        Position: 'Software Consultant',
        Mobile: '5037541234',
        Telephone: '1007891363'
      },
      {
        ID: 10,
        Title: 'Mr',
        FullName: 'Launce Markl',
        Email: 'lmarkl9@loc.gov',
        Position: 'Junior Executive',
        Mobile: '6029603051',
        Telephone: '5335192325'
      },
      {
        ID: 11,
        Title: 'Rev',
        FullName: 'Raeann Seed',
        Email: 'rseeda@imageshack.us',
        Position: 'Cost Accountant',
        Mobile: '7052543426',
        Telephone: '5784868946'
      },
      {
        ID: 12,
        Title: 'Rev',
        FullName: 'Elmore Shurrocks',
        Email: 'eshurrocksb@about.me',
        Position: 'Human Resources Assistant II',
        Mobile: '2507637045',
        Telephone: '9466731225'
      },
      {
        ID: 13,
        Title: 'Honorable',
        FullName: 'Darcy Marland',
        Email: 'dmarlandc@nih.gov',
        Position: 'Design Engineer',
        Mobile: '1052768087',
        Telephone: '3112580710'
      },
      {
        ID: 14,
        Title: 'Dr',
        FullName: 'Somerset Olensby',
        Email: 'solensbyd@wix.com',
        Position: 'Human Resources Assistant I',
        Mobile: '8563449173',
        Telephone: '3425039179'
      },
      {
        ID: 15,
        Title: 'Honorable',
        FullName: 'Eloisa Brennen',
        Email: 'ebrennene@cdc.gov',
        Position: 'Account Coordinator',
        Mobile: '4764089372',
        Telephone: '5799725309'
      },
      {
        ID: 16,
        Title: 'Rev',
        FullName: 'Isadora Pilpovic',
        Email: 'ipilpovicf@yellowpages.com',
        Position: 'Staff Scientist',
        Mobile: '8501477266',
        Telephone: '4357411468'
      },
      {
        ID: 17,
        Title: 'Honorable',
        FullName: 'Linn Lindenstrauss',
        Email: 'llindenstraussg@google.ru',
        Position: 'Senior Sales Associate',
        Mobile: '3694994478',
        Telephone: '5778615680'
      },
      {
        ID: 18,
        Title: 'Mrs',
        FullName: 'Aime Wisham',
        Email: 'awishamh@prlog.org',
        Position: 'Research Nurse',
        Mobile: '7787610854',
        Telephone: '8539464690'
      },
      {
        ID: 19,
        Title: 'Rev',
        FullName: 'Ardra Dehmel',
        Email: 'adehmeli@spotify.com',
        Position: 'Food Chemist',
        Mobile: '8935960606',
        Telephone: '4105298141'
      },
      {
        ID: 20,
        Title: 'Mrs',
        FullName: 'Yasmine Yates',
        Email: 'yyates@netscape.com',
        Position: 'Librarian',
        Mobile: '6214200907',
        Telephone: '8369746263'
      }
    ];
    this.gridData = this.allData;

    this.filterData = this.filterData.bind(this);
    this.sort = [{ field: 'FullName', dir: 'asc' }];
  }

  @observable searchInput = '';

  filterData(event: ChangeEvent<HTMLInputElement>) {
    this.searchInput = event.target.value;
    const keyword = this.searchInput.toLowerCase();

    const result = this.allData.filter(
      (c) =>
        c.Email.toLowerCase().indexOf(keyword) > -1 ||
        c.FullName.toLowerCase().indexOf(keyword) > -1 ||
        c.ID.toString().toLowerCase().indexOf(keyword) > -1 ||
        c.Mobile.toLowerCase().indexOf(keyword) > -1 ||
        c.Position.toLowerCase().indexOf(keyword) > -1 ||
        c.Telephone.toLowerCase().indexOf(keyword) > -1 ||
        c.Title.toLowerCase().indexOf(keyword) > -1
    );

    this.gridData = result;
  }

  render() {
    // First store the grid itself as an object
    const mainGrid = (
      <Grid
        // Bind the data, slice with paging take/skip and sort
        data={this.getData()}
        style={{ height: '420px' }}
        filter={this.filter}
        filterable={false}
        pageable={{ pageSizes: this.pageSizes }}
        skip={this.skip}
        take={this.take}
        total={this.gridData.length}
        onPageChange={this.pageChange}
        sortable
        sort={toJS(this.sort)}
        resizable
        onRowClick={() => {
          // redirect to edit account page
          // console.log(e.dataItem.ID);
        }}
        onFilterChange={this.filterChange}
        onSortChange={this.sortChange}>
        <GridToolbar>
          <Button buttonStyle="outline_primary" handleClick={this.exportPdf} inProgress={this.exporting} customClass="toolbarButton">
            Export PDF
          </Button>
        </GridToolbar>

        {ColumnHelper.getGridColumns([
          { field: 'Title', title: 'Title', dataType: 'text', size: 'xs', currentFilter: this.filter },
          { field: 'FullName', title: 'FullName', dataType: 'text', size: 'md', currentFilter: this.filter },
          { field: 'Email', title: 'Email', dataType: 'text', size: 'md', currentFilter: this.filter },
          { field: 'Position', title: 'Position', dataType: 'text', size: 'md', currentFilter: this.filter },
          { field: 'Mobile', title: 'Mobile', dataType: 'text', size: 'md', currentFilter: this.filter },
          { field: 'Telephone', title: 'Telephone', dataType: 'text', size: 'md', currentFilter: this.filter }
        ])}
      </Grid>
    );

    return (
      <>
        <hr></hr>
        <h1 className="heading">Kendo Grid</h1>
        <a href="https://www.telerik.com/kendo-react-ui/components/grid/" target="_blank noopener noreferrer">
          https://www.telerik.com/kendo-react-ui/components/grid/
        </a>
        <p>
          <i>This is using hard coded sample data</i>
        </p>

        <Input
          elementId="searchBar"
          inputType="text"
          name="searchBar"
          labelText=""
          placeholder="Search Example"
          value={this.searchInput}
          handleChange={this.filterData}
          customLabelClass={appStyles.form__label}
          customInputClass={appStyles.form__input}
          autocomplete="off"></Input>

        {/* Call the grid object to render it */}
        {mainGrid}

        {/* Call the export PDF component with the grid object inside it */}
        <GridPDFExport ref={(pdfExport) => (this.gridPDFExport = pdfExport)}>{mainGrid}</GridPDFExport>
        <hr></hr>
      </>
    );
  }
}

export default ExampleKendoGrid;
