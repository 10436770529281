import { BaseGridRequest } from 'models/requests/baseGridRequest.model';

export class InvoicesGridRequest extends BaseGridRequest {
  includedStatusCodes: number[] = [];
  unpaidOnly: boolean | undefined = false;

  constructor(sortBy: string, pageSize: number, statuses: number[], unpaidOnly?: boolean) {
    super(pageSize);

    this.sortBy = sortBy;
    this.includedStatusCodes = statuses;
    this.unpaidOnly = unpaidOnly;
  }
}
