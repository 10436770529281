import { Component } from 'react';
import { observer } from 'mobx-react';
import Alert from 'components/Generic/Alert/Alert';
import Icon from 'components/Generic/Icon/icon';
import messagesStore from 'stores/messages.store';
import appStyles from 'App.module.scss';
import styles from './Header.module.scss';

@observer
class GlobalMessages extends Component {
  render() {
    const { messages } = messagesStore;

    return (
      !!messages.length && (
        <div className={styles.messageBar}>
          {messages.map((message, index) => (
            <div className={`${styles.messageBar__item} ${appStyles.container}`} key={index}>
              <Alert alertType={message.type}>{message.text}</Alert>
              <button className={styles.close} onClick={() => messagesStore.removeMessage(index)}>
                <Icon icon={Icon.cross} iconName="close" color="white" size="xs" />
              </button>
            </div>
          ))}
        </div>
      )
    );
  }
}

export default GlobalMessages;
