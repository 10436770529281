import { Grid } from '@progress/kendo-react-grid';
import { BaseClientGrid } from 'components/Generic/Grid/BaseClientGrid';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Invoice } from 'models/api/invoice.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import { withRouter } from 'react-router-dom';
import invoicesStore from 'stores/invoices.store';

interface ISelectedInvoicesGridProps extends IBaseProps {
  setTotalAmount: (total: number) => void;
}

@observer
class SelectedInvoicesGrid extends BaseClientGrid<Invoice, ISelectedInvoicesGridProps> {
  @observable selectedInvoiceIds: number[] = [];
  @observable selectedInvoices: Invoice[] = [];
  @observable total = 0;

  constructor(props: ISelectedInvoicesGridProps) {
    super(props);
    // set default sort
    this.sort = [{ field: 'dateIssued', dir: 'desc' }];
  }

  componentDidMount() {
    const unpaid = invoicesStore.unpaidInvoices;
    const ids = invoicesStore.selectedInvoices;

    let result: Invoice[] = [];
    if (unpaid && ids) {
      result = unpaid.items.filter((invoice: Invoice) => {
        if (invoice.invoiceNumber) {
          const invoiceSelected = ids.includes(invoice.invoiceNumber);
          if (invoiceSelected && invoice.outstandingAmount) {
            this.total += invoice.outstandingAmount;
          }
          if (invoice.dateIssued) {
            invoice.dateIssued = new Date(invoice.dateIssued);
          }

          return invoiceSelected;
        } else {
          return null;
        }
      });
    }

    this.props.setTotalAmount(this.total);

    this.allData = result;
    this.gridData = this.allData;
    this.take = this.getTotal();
  }

  render() {
    const mainGrid = (
      <Grid
        data={this.getData()}
        filter={this.filter}
        filterable={false}
        pageable={false}
        pageSize={this.getTotal()}
        skip={this.skip}
        take={this.take}
        total={this.getTotal()}
        onPageChange={this.pageChange}
        sortable={false}
        sort={toJS(this.sort)}
        resizable
        onFilterChange={this.filterChange}
        onSortChange={this.sortChange}
        className="k-grid-non-interactive-links">
        {ColumnHelper.getGridColumns([
          { field: 'invoiceNumber', title: 'Invoice Number', dataType: 'text', size: 'md', currentFilter: this.filter },
          { field: 'dateIssued', title: 'Date Issued', dataType: 'date', size: 'md', currentFilter: this.filter },
          { field: 'orderNumber', title: 'Order Number', dataType: 'text', size: 'md', currentFilter: this.filter },
          { field: 'accountNumber', title: 'Account Number', dataType: 'text', size: 'md', currentFilter: this.filter },
          { field: 'total', title: 'Total', dataType: 'currency', size: 'md', currentFilter: this.filter },
          { field: 'outstandingAmount', title: 'Outstanding Amount', dataType: 'currency', size: 'md', currentFilter: this.filter }
        ])}
      </Grid>
    );

    return mainGrid;
  }
}

export default withRouter(SelectedInvoicesGrid);
