import { action, computed, observable } from 'mobx';
import addressesApi from 'api/addressesApi';
import api from 'api/api';
import productsApi from 'api/productsApi';
import { AvailableAddresses, ITTBAddress } from 'models/api/availableAddress.model';
import { IAvailableProduct } from 'models/api/availableProduct.model';
import ILogEventMessage from 'models/api/logEventMessage.model';
import { IProducts } from 'models/api/products.model';
import { FalconError } from 'models/generic/falconError.model';
import accountStore from 'stores/account.store';
import messagesStore from 'stores/messages.store';
import constants from 'utils/constants';
import { errorMessages } from 'utils/messages';
import { createNoUndefFilter } from 'utils/typeGuards';
import { LogEventCategories, LogEventResultStatuses } from 'utils/enums';

class AvailabilityChecksStore {
  @observable postcode = '';
  @observable pending = false;
  @observable addressesError: FalconError | string = '';
  @observable addresses: ITTBAddress[] = [];
  @observable correlationId: string | null = null;
  @observable availableProductsRequestError: FalconError | null = null;
  @observable selectedAddress: ITTBAddress | null = null;
  @observable availableProducts: IAvailableProduct[] = [];
  @observable canSelectProduct = true;
  @observable callbackRequested = false;
  @observable contactNumber = '';
  @observable selectedProduct: IAvailableProduct | null = null;

  @computed get isContactNumberValid() {
    return this.contactNumber.length >= 10;
  }

  @action setPostcode = (postcode: string) => {
    this.postcode = postcode;
  };

  @action setSelectedProduct = (selectedProduct: IAvailableProduct | null) => {
    this.selectedProduct = selectedProduct;
  };

  @action setSelectedAddress = (index?: number) => {
    if (index || index === 0) {
      if (this.selectedAddress !== this.addresses[index]) {
        this.selectedAddress = this.addresses[index];
      }
    } else {
      this.selectedAddress = null;
    }
  };

  @action getContactPhoneNumber = async () => {
    this.pending = true;
    const contact = await accountStore.getCurrentContact();

    this.contactNumber = contact.telephone ?? '';
    this.contactNumber = this.contactNumber.replace('+', '').replace(' ', '');

    this.pending = false;
  };

  @action setContactNumber = (phoneNumber: string) => {
    this.contactNumber = phoneNumber;
  };

  @action logEvent = async (category: LogEventCategories, message: ILogEventMessage, status: LogEventResultStatuses) => {
    const result = await api.post<{ correlationId: string }>(
      `/FttpAvailabilityCheck/LogEvent`,
      { correlationId: this.correlationId, category, message: JSON.stringify(message), status },
      `There was an error logging the Availability Checker activity.`
    );

    if (!(result instanceof FalconError)) {
      this.correlationId = result.correlationId;
    }

    return result;
  };

  @action getAvailableAddresses = async (postCode: string) => {
    this.addresses = [];
    this.addressesError = '';
    this.pending = true;
    this.postcode = postCode.toLocaleUpperCase();

    await this.logEvent(LogEventCategories.FttpAddressSearchStarted, { inputs: { postcode: this.postcode } }, LogEventResultStatuses.Ok);

    const result = await addressesApi.post<AvailableAddresses>(
      `GetAvailableAddresses?subscription-key=${process.env.REACT_APP_API_KEY}`,
      { getAvailableAddresses: { request: { address: { postCode } } } },
      'Something went wrong while getting available addresses'
    );

    if (result instanceof FalconError) {
      this.addresses = [];
      this.addressesError = result;

      await this.logEvent(
        LogEventCategories.FttpAddressSearchCompleted,
        { inputs: { postcode: this.postcode }, result: result.errorMessages?.join('\n') },
        LogEventResultStatuses.Error
      );

      throw result;
    }

    this.addresses = result.getAvailableAddressesResponse?.getAvailableAddressesResult?.Sites.map((site) => site.address) ?? [];

    this.addressesError =
      result.getAvailableAddressesResponse?.getAvailableAddressesResult?.status.Errors.filter(
        createNoUndefFilter((x) => !!x.errorCode && x.errorCode !== constants.addressesApiErrorCodes.noMatchingAddressesFound)
      )
        .map((e) => constants.addressesApiErrorCodesDescription[e.errorCode])
        .join('\n') ?? '';
    this.canSelectProduct = !!this.addresses?.length && !this.addressesError;

    if (!this.canSelectProduct) {
      await this.logEvent(
        LogEventCategories.FttpAddressSearchCompleted,
        { inputs: { postcode: this.postcode }, result: errorMessages.noMatchingAddressesFound },
        LogEventResultStatuses.Ok
      );
    } else if (this.addresses.length) {
      await this.logEvent(
        LogEventCategories.FttpAddressSearchCompleted,
        { inputs: { postcode: this.postcode }, result: `${this.addresses.length} address(es) found at this postcode.` },
        LogEventResultStatuses.Ok
      );
    } else {
      await this.logEvent(
        LogEventCategories.FttpAddressSearchCompleted,
        { inputs: { postcode: this.postcode }, result: 'Unexpected error in address lookup.' },
        LogEventResultStatuses.Error
      );
    }

    this.pending = false;

    if (this.addressesError) {
      messagesStore.addErrorAsString(this.addressesError);
    }
  };

  @action getAvailableProducts = async () => {
    this.pending = true;
    this.availableProductsRequestError = null;

    await this.logEvent(
      LogEventCategories.FttpProductSearchStarted,
      { inputs: { postcode: this.postcode, aLK: this.selectedAddress?.aLK } },
      LogEventResultStatuses.Ok
    );

    const result = await productsApi.get<IProducts>(
      `address-availability/available-products?subscription-key=${process.env.REACT_APP_API_KEY}&galk=${this.selectedAddress?.aLK}`,
      { friendlyErrorMessage: 'Something went wrong during getting available products' }
    );

    if (result instanceof FalconError) {
      this.availableProductsRequestError = result;

      await this.logEvent(
        LogEventCategories.FttpProductSearchCompleted,
        { inputs: { postcode: this.postcode, aLK: this.selectedAddress?.aLK }, result: result.errorMessages?.join('\n') },
        LogEventResultStatuses.Error
      );
    } else {
      this.availableProducts = result.availableProduct;

      const resultStatus = result.availableProduct ? LogEventResultStatuses.Ok : LogEventResultStatuses.Warning;
      const resultMessage = {
        inputs: { postcode: this.postcode, aLK: this.selectedAddress?.aLK },
        result: `${this.availableProducts.length} FTTP product(s) found.` || 'No FTTP products are available for provided address'
      };

      await this.logEvent(LogEventCategories.FttpProductSearchCompleted, resultMessage, resultStatus);
    }

    this.pending = false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @action requestCallback = async <T extends []>(args: T = {} as any) => {
    const data = {
      addressFound: !!this.addresses?.length,
      productSelected: !!this.selectedProduct,
      speed: this.selectedProduct?.downstreamSpeed,
      maxSpeed: this.availableProducts[0]?.downstreamSpeed,
      phoneNumber: this.contactNumber,
      correlationId: this.correlationId,
      ...args
    };

    const result = await api.post<{
      addressFound: boolean;
      productSelected: boolean;
      maxSpeed: number;
      speed: number;
      phoneNumber: string;
      correlationId: string;
    }>('FttpAvailabilityCheck/CreateLead', data, 'Cannot create lead');

    if (result instanceof FalconError) {
      throw result;
    }

    this.callbackRequested = true;
  };

  @action cleanUpAddresses = () => {
    this.addresses = [];
    this.addressesError = '';
    this.selectedProduct = null;
    this.selectedAddress = null;
  };

  @action cleanUpProduct = () => {
    this.availableProducts = [];
    this.availableProductsRequestError = null;
    this.selectedProduct = null;
    this.canSelectProduct = true;
  };

  @action cleanUpStore = () => {
    this.pending = false;
    this.availableProducts = [];
    this.availableProductsRequestError = null;
    this.addresses = [];
    this.addressesError = '';
    this.selectedAddress = null;
    this.canSelectProduct = true;
    this.correlationId = null;
    this.callbackRequested = false;
    this.contactNumber = '';
    this.postcode = '';
    this.selectedProduct = null;
  };
}

export default new AvailabilityChecksStore();
