/* returns an XMLDocument, or null if `src` is malformed */
export function tryParseXml(src: string): XMLDocument | null {
  const key = `a` + Math.random().toString(32);

  const parser = new DOMParser();

  let doc = null;
  try {
    doc = parser.parseFromString(src + `<?${key}?>`, `application/xml`);
  } catch (_) {}

  if (!(doc instanceof XMLDocument)) {
    return null;
  }

  const lastNode = doc.lastChild;
  if (!(lastNode instanceof ProcessingInstruction) || lastNode.target !== key || lastNode.data !== ``) {
    return null;
  }

  doc.removeChild(lastNode);

  const errElemCount = doc.documentElement.getElementsByTagName(`parsererror`).length;
  if (errElemCount !== 0) {
    let errDoc = null;
    try {
      errDoc = parser.parseFromString(src + `<?`, `application/xml`);
    } catch (_) {}

    if (!(errDoc instanceof XMLDocument) || errDoc.documentElement.getElementsByTagName(`parsererror`).length === errElemCount) {
      return null;
    }
  }

  return doc;
}
