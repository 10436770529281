import api from 'api/api';
import { action, observable } from 'mobx';
import { FalconError } from 'models/generic/falconError.model';
import { ListItem } from 'models/generic/listItem.model';

class LookupStore {
  // flag to indicate lookup data is already loaded
  private loaded = false;

  // static lookups
  @observable yesNoOptions: ListItem[] = [new ListItem(true, 'Yes'), new ListItem(false, 'No')];
  @observable billingOptions: ListItem[] = [new ListItem(true, 'Post'), new ListItem(false, 'Email')];
  @observable marketingOptions: ListItem[] = [new ListItem(false, 'Post'), new ListItem(true, 'Email')];
  @observable marketingPreferenceOptions: ListItem[] = [new ListItem(true, 'Opt In'), new ListItem(false, 'Opt Out')];

  // dynamic lookups loaded from API
  @observable ticketPriorities: ListItem[] = [];

  @action async loadLookups() {
    // no need to load if already loaded
    if (this.loaded === false) {
      const priorities = await api.get<ListItem[]>('SupportTicket/TicketPriorities', {
        friendlyErrorMessage: 'There was an error loading ticket priorities'
      });

      if (priorities instanceof FalconError) {
      } else {
        this.ticketPriorities = priorities;
      }

      // load any other lookups here

      // set flag
      this.loaded = true;
    }
  }
}

const lookupStore = new LookupStore();
export default lookupStore;
