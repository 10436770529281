import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import auth from 'utils/auth';
import constants from 'utils/constants';
import { HubConnectionEventHandler } from 'websockets/types';

export class BaseHub {
  protected baseApiUrl = process.env.REACT_APP_API_BASE_URL || '';
  protected connection: HubConnection | null = null;
  protected hubUrl = constants.notificationsHubUrl;
  protected reconnectHandlers: HubConnectionEventHandler<string>[] = [];
  protected closeHandlers: HubConnectionEventHandler<Error>[] = [];

  private get disconnected() {
    return this.connection?.state === HubConnectionState.Disconnected || this.connection?.state === HubConnectionState.Disconnecting;
  }

  connect = () => {
    if (!this.connection) {
      this.connection = new HubConnectionBuilder()
        .withUrl(this.baseApiUrl + this.hubUrl, {
          headers: {
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_KEY ?? ''
          },
          accessTokenFactory: async () => {
            const result = await auth.acquireTokenSilent();

            return result?.accessToken ?? '';
          }
        })
        .withAutomaticReconnect()
        .build();
    }

    if (this.disconnected) {
      this.connection.start();
    }

    if (this.reconnectHandlers.length) {
      this.reconnectHandlers.forEach(this.connection.onreconnected);
    }

    if (this.closeHandlers.length) {
      this.closeHandlers.forEach(this.connection.onclose);
    }
  };

  disconnect = () => {
    this.connection?.stop();
  };

  onReconnect = (callback: HubConnectionEventHandler<string>): void => {
    if (this.connection) {
      this.connection.onreconnected(callback);
    } else {
      this.reconnectHandlers.push(callback);
    }
  };

  onClose = (callback: HubConnectionEventHandler<Error>) => {
    if (this.connection) {
      this.connection.onclose(callback);
    } else {
      this.closeHandlers.push(callback);
    }
  };
}
