import { FC, useCallback } from 'react';
import { FcDown, FcUp } from 'react-icons/fc';
import Button from 'components/Generic/FormElements/Button/Button';
import { IAvailableProduct } from 'models/api/availableProduct.model';
import { getProductSpeedString } from 'utils/string';
import SpeedScale from './SpeedScale';
import FttpProductsStyles from './FttpProducts.module.scss';

interface IFttpProductProps {
  availableProduct: IAvailableProduct;
  onOrderClick: (product: IAvailableProduct) => void;
}

interface ISpeedInfoProps {
  speed: number;
  arrowDirection: 'up' | 'down';
}

const SpeedInfo: FC<ISpeedInfoProps> = ({ speed, arrowDirection }) => {
  const isDownstream = arrowDirection === 'down';
  const ArrowComponent = isDownstream ? FcDown : FcUp;
  const speedValueClassName = isDownstream ? FttpProductsStyles.speed_value_download : FttpProductsStyles.speed_value_upload;
  const arrowLabel = `Average ${isDownstream ? 'download' : 'upload'} speed`;

  return (
    <div className={FttpProductsStyles.arrow_container}>
      <ArrowComponent size="32" color="#6bb3ff" className={FttpProductsStyles.arrow} />
      <div className={FttpProductsStyles.info_container}>
        <div className={speedValueClassName}>{getProductSpeedString(speed)}</div>
        <div className={FttpProductsStyles.speed_label}>{arrowLabel}</div>
      </div>
    </div>
  );
};

const FttpProduct: FC<IFttpProductProps> = ({ availableProduct, onOrderClick }) => {
  const orderHandler = useCallback(() => {
    onOrderClick(availableProduct);
  }, [availableProduct, onOrderClick]);

  return (
    <div className={FttpProductsStyles.product_tile}>
      <div className={FttpProductsStyles.product_header}>
        <p className={FttpProductsStyles.product_header_text}>{availableProduct.chessProductName}</p>
      </div>
      <div className={FttpProductsStyles.product_body}>
        <SpeedScale
          startColor="#1d70c9"
          endColor="#2cd0bc"
          startOffset="40%"
          endOffset="100%"
          speedMbps={availableProduct.downstreamSpeed}
          customStyle={FttpProductsStyles.product_speed}
        />
        <div className={FttpProductsStyles.half_border} />
        <div className={FttpProductsStyles.product_speed_info}>
          <SpeedInfo speed={availableProduct.downstreamSpeed} arrowDirection="down" />
          <SpeedInfo speed={availableProduct.upstreamSpeed} arrowDirection="up" />
          <div className={FttpProductsStyles.button_container}>
            <Button
              size="md"
              buttonStyle="primary"
              id={`${availableProduct.productName}_btnReadyToOrder`}
              customClass={FttpProductsStyles.order_button}
              handleClick={orderHandler}>
              <span className={FttpProductsStyles.button_text}>Ready to Order?</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FttpProduct;
