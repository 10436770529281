import { action, observable } from 'mobx';
import notificationApi from 'api/notificationApi';
import { INotificationsQueryParams } from 'api/types';
import NotificationHub from 'websockets/notificationHub';
import { Notification, NotificationsWithUnreadCount } from 'models/api/notification.model';
import { UnreadCount } from 'models/api/unreadCount.model';
import { FalconError } from 'models/generic/falconError.model';
import { ModelCollection } from 'models/generic/modelCollection.model';
import { NotificationsGridRequest } from 'models/requests/notificationsGridRequest.model';
import lineFaultsStore from 'stores/lineFaults.store';
import constants from 'utils/constants';
import { NotificationStatuses } from 'utils/enums';
import { checkNotificationsAvailability, raiseNotification } from 'utils/notifications';
import utils from 'utils/utils';
import { ICountableArray } from 'models/generic/iCountableArray';

class NotificationsStore {
  private notificationHub: NotificationHub | null = null;

  @observable pending = false;
  @observable notificationNavOpen = false;
  @observable latestNotifications: Notification[] = [];
  @observable notificationsToDisplay: Notification[] = [];
  @observable unreadNotificationCount = 0;
  @observable unreadNotificationCountForClients: Record<string, UnreadCount> = {};
  @observable lastFetchedNotification: Notification | null = null;

  @action addNotificationToDisplay = (notification: Notification) => {
    this.notificationsToDisplay.push(notification);
  };

  @action removeNotificationToDisplay = (notificationId: number | null) => {
    this.notificationsToDisplay = this.notificationsToDisplay.filter((n) => n.notificationId !== notificationId);
  };

  lineFaultCheckHandler = async (payload: string) => {
    const results = await this.getMyLatestNotifications();
    const notification = results.find((n) => n.payload === payload);
    const parsedPayload = payload && JSON.parse(payload);

    if (notification) {
      const browserNotificationsAvailable = await checkNotificationsAvailability();
      if (browserNotificationsAvailable && !document.hasFocus()) {
        raiseNotification({
          title: 'Chess Customer Portal',
          body: notification.message ?? 'You have unread notification',
          icon: '/logo512.png',
          redirectUrl: utils.routing.notificationsLinkCreator(notification.type)({ payload: parsedPayload, notificationId: notification.notificationId })
        });
      } else if (!lineFaultsStore.isLineFaultFlowStarted) {
        this.addNotificationToDisplay(notification);
        setTimeout(() => this.removeNotificationToDisplay(notification.notificationId), 60000);
      }
    }

    await lineFaultsStore.lineFaultCheckFinished(parsedPayload.lineFaultCheckId);
  };

  closeNotificationsPopup() {
    this.notificationNavOpen = false;
  }

  toggleNotificationsPopup() {
    this.notificationNavOpen = !this.notificationNavOpen;
  }

  connect = () => {
    if (!this.notificationHub) {
      this.notificationHub = new NotificationHub();
    }

    this.notificationHub.connect();
  };

  disconnect = () => {
    this.notificationHub?.disconnect();
  };

  startHandleLineFaultCheck = () => {
    this.notificationHub?.addLineFaultCheckHandler(this.lineFaultCheckHandler);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateHandleLineFaultCheck = (handler: (payload?: any) => void) => {
    this.notificationHub?.replaceLineFaultCheckHandler(handler);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stopHandleLineFaultCheck = (handler?: (payload?: any) => void) => {
    this.notificationHub?.removeLineFaultCheckHandlers(handler);
  };

  getNotificationById = (id: number) => this.latestNotifications.find((x) => x.notificationId === id);

  async getMyLatestNotifications(
    params: INotificationsQueryParams = {
      SortBy: 'issuedon DESC',
      PageSize: 3,
      PageNumber: 1,
      includedStatusCodes: [0, 1]
    }
  ) {
    this.pending = true;
    let result: NotificationsWithUnreadCount | FalconError | null = null;

    try {
      result = await notificationApi.get<NotificationsWithUnreadCount>('Notification/MyNotifications', {
        params,
        friendlyErrorMessage: `There was an error loading notifications.`
      });

      if (!(result instanceof FalconError)) {
        this.latestNotifications = result.map((notification) => ({ ...notification, issuedOn: new Date(notification.issuedOn) }));
        this.unreadNotificationCount = result.unreadCount;

        return result;
      }

      throw result;
    } finally {
      this.pending = false;
    }
  }

  async getMyNotifications(params?: INotificationsQueryParams, request?: NotificationsGridRequest): Promise<ModelCollection<Notification>> {
    const notifications = new ModelCollection<Notification>();
    const qs = request?.getRequestAsQueryString() ?? '';

    const result = await notificationApi.get<Notification[]>('Notification/MyNotifications?' + qs, {
      params,
      friendlyErrorMessage: `There was an error loading notifications.`
    });
    if (result instanceof FalconError) {
    } else {
      result.map((item) => {
        item.issuedOn = new Date(item.issuedOn);

        return item;
      });

      notifications.items = result;
      notifications.totalCount = (result as unknown as ICountableArray).totalCount;
    }

    return notifications;
  }

  async getNotification(notificationId: number) {
    let notification: Notification | null = null;

    const existingNotification = this.latestNotifications.find((n) => n.notificationId === notificationId);

    if (existingNotification) {
      this.lastFetchedNotification = existingNotification;
    } else {
      this.pending = true;
      const result = await notificationApi.get<Notification>('Notification/Details', {
        params: { id: notificationId },
        friendlyErrorMessage: 'There was an error loading notification.'
      });

      if (result instanceof FalconError) {
      } else {
        notification = result;

        this.lastFetchedNotification = notification;
      }
      this.pending = false;
    }
  }

  getNotificationByLineFaultId = (faultId: string) =>
    this.latestNotifications.reduce<number | null | undefined>((acc, item) => {
      if (!acc && item.type === constants.notificationTypes.LineFaultCheck && item.payload && JSON.parse(item.payload)?.lineFaultCheckId === faultId) {
        return item.notificationId;
      } else {
        return acc;
      }
    }, null);

  async markAsRead(id: number) {
    let success = true;
    const status = this.latestNotifications.find((notification) => notification.notificationId === id)?.status;
    if (status !== NotificationStatuses[NotificationStatuses.Read]) {
      const result = await notificationApi.put<Notification>('/Notification/MarkAsRead?id=' + id, null, 'There was an error updating the notification.');
      if (!(result instanceof FalconError)) {
        success = true;
        this.latestNotifications = this.latestNotifications.map((notification) =>
          notification.notificationId === id ? { ...notification, readOn: new Date(), status: NotificationStatuses[NotificationStatuses.Read] } : notification
        );
        this.unreadNotificationCount -= 1;
        this.getNotification(id);
      } else {
        success = false;
      }
    }

    return success;
  }

  @action async getUnreadNotificationsCountForContactsClients() {
    this.pending = true;
    const notificationsCounts = await notificationApi.get<UnreadCount[]>('Notification/UnreadCount');

    if (!(notificationsCounts instanceof FalconError)) {
      this.unreadNotificationCountForClients = notificationsCounts.reduce((acc, notificationsCount) => {
        if (notificationsCount.clientId) {
          acc[notificationsCount.clientId.toString()] = notificationsCount;
        }

        return acc;
      }, {} as Record<string, UnreadCount>);
    }

    this.pending = false;
  }

  @action archive = async (notificationId: number) => {
    const result = await notificationApi.put<Notification>(
      '/Notification/MarkAsArchived?id=' + notificationId,
      null,
      'There was an error updating the notification.'
    );

    if (!(result instanceof FalconError)) {
      this.latestNotifications = this.latestNotifications.map((notification) =>
        notification.notificationId === notificationId ? { ...notification, status: NotificationStatuses[NotificationStatuses.Archived] } : notification
      );
      this.getNotification(notificationId);
    }
  };

  @action unarchive = async (notificationId: number) => {
    const result = await notificationApi.put<Notification>(
      '/Notification/MarkAsUnArchived?id=' + notificationId,
      null,
      'There was an error updating the notification.'
    );
    if (!(result instanceof FalconError)) {
      this.latestNotifications = this.latestNotifications.map((notification) =>
        notification.notificationId === notificationId ? { ...notification, status: NotificationStatuses[NotificationStatuses.Read] } : notification
      );
      this.getNotification(notificationId);
    }
  };
}

export default new NotificationsStore();
