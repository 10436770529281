const logging = {
  // logging only enabled for non-production
  enabled: process.env.REACT_APP_ENVIRONMENT_NAME !== 'Production',

  log(message?: unknown, ...optionalParams: unknown[]) {
    if (this.enabled) {
      // eslint-disable-next-line no-console
      console.log(message, ...optionalParams);
    }
  },

  error(message?: unknown, ...optionalParams: unknown[]) {
    if (this.enabled) {
      // eslint-disable-next-line no-console
      console.error(message, ...optionalParams);
    }
  }
};

export default logging;
