import appStyles from 'App.module.scss';
import classNames from 'classnames';
import ValidationArea from 'components/Generic/FormElements/ValidationArea/ValidationArea';
import { ListItem } from 'models/generic/listItem.model';
import { ValidationError } from 'models/generic/validationError.model';
import { ChangeEvent, Component } from 'react';

interface ISelectProps {
  /**
   * @property {string} name Set the name attribute
   */
  name: string;
  /**
   *@property {string} labelText Set the text to appear in the label
   */
  labelText: string;
  /**
   *@property {string} elementId Set the ID attribute
   */
  elementId: string;
  /**
   *@property {string | number | boolean | null} value Set the value attribute
   */
  value: string | number | boolean | null;
  /**
   *@property {string} placeholder Set the placeholder option
   */
  placeholder: string;
  /**
   *@property {boolean} disablePlaceholder Set the placeholder option as disabled
   */
  disablePlaceholder?: boolean;
  /**
   *@property {ListItem[]} options Set the list of available options
   */
  options: ListItem[];
  /**
   *@property {(event: ChangeEvent<HTMLSelectElement>) => void} handleChange Set the action onChange
   */
  handleChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  /**
   * @property {string} customSelectClass Optional. Use a custom class on the select element - use styles from the current component or appStyles
   */
  customSelectClass?: string;
  /**
   * @property {string} customLabelClass Optional. Use a custom class on the label - use styles from the current component or appStyles
   */
  customLabelClass?: string;
  /**
   * @property {ValidationError} validationError Optional. Any validation messages related to this field
   */
  validationError?: ValidationError;
  /**
   * @property {boolean} Optional. required Set the field to required
   */
  required?: boolean;
}

/**
 * @description Add a select element with a label. Optional properties: customSelectClass, customLabelClass,
 * disablePlaceholder, required, validationError
 */
class Select extends Component<ISelectProps> {
  isValueValid() {
    return this.props.options.find((i) => i.id === this.props.value);
  }

  // eslint-disable-next-line complexity
  render() {
    return (
      <>
        <label
          htmlFor={this.props.name}
          className={classNames(
            appStyles.form__label,
            { [appStyles.form__label_invalid]: this.props.validationError && this.props.validationError.messages.length > 0 }, // add invalid class if errors
            { [this.props.customLabelClass as string]: this.props.customLabelClass } // add custom class if defined
          )}>
          {this.props.labelText}
        </label>
        <select
          id={this.props.elementId}
          name={this.props.name}
          className={classNames(
            appStyles.form__input,
            appStyles.form__input_select,
            { [appStyles.select_placeholder]: this.props.value === null || this.props.value === undefined }, // add placeholder class if no value
            { [appStyles.form__input_invalid]: this.props.validationError && this.props.validationError.messages.length > 0 }, // add invalid class if errors
            { [this.props.customSelectClass as string]: this.props.customSelectClass } // add custom class if defined
          )}
          onBlur={this.props.handleChange}
          onChange={this.props.handleChange}
          value={this.props.value !== null && this.isValueValid() ? this.props.value.toString() : ''}
          required={this.props.required || undefined}
          aria-required={this.props.required || undefined}>
          <option
            value=""
            disabled={this.props.disablePlaceholder}
            aria-selected={this.props.value === null || this.props.value === '' || !this.isValueValid() ? 'true' : 'false'}>
            {this.props.placeholder}
          </option>
          {this.props.options.map((option) => (
            <option
              key={option.id as string}
              value={option.id as string}
              label={option.text as string}
              aria-selected={option.id === this.props.value ? 'true' : 'false'}>
              {option.text}
            </option>
          ))}
        </select>

        <ValidationArea sectionId={this.props.elementId} validationError={this.props.validationError}></ValidationArea>
      </>
    );
  }
}

export default Select;
