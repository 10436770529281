import { Component } from 'react';
import classNames from 'classnames';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import constants from 'utils/constants';
import { NotificationStatuses } from 'utils/enums';
import { Notification } from 'models/api/notification.model';
import { FalconError } from 'models/generic/falconError.model';
import notificationsStore from 'stores/notifications.store';
import LinkWithLabel from 'components/Dashboard/Newsfeed/LinkWithLabel';
import Table from 'components/Dashboard/Table/Table';
import utils from 'utils/utils';
import { ReactComponent as ChevronRight } from 'assets/images/svg/chevronRight.svg';
import dashboardStyles from '../Dashboard.module.scss';
import styles from './LatestNotifications.module.scss';

interface ILatestNotificationsProps {
  onViewItem?: (item: Notification) => void;
  onViewAll?: () => void;
}

@observer
export default class LatestNotifications extends Component<ILatestNotificationsProps> {
  @computed get formattedNotifications() {
    return notificationsStore.latestNotifications.map((item) => {
      const error = new FalconError();

      if (item.type === constants.notificationTypes.FailedLineFaultCheck) {
        error.data = item.payload && JSON.parse(item.payload).error;
      }
      const { subject, notificationId, issuedOn, type, readOn } = item;

      const onOpenClick = () => this.linkClick(item);
      const payload = item.payload && JSON.parse(item.payload);
      const redirectTo = {
        pathname: utils.routing.notificationsLinkCreator(type)({ notificationId, payload }),
        state: { ...error }
      };

      return {
        time: new Date(issuedOn),
        subject: subject,
        to: redirectTo,
        clickHandler: onOpenClick,
        unread: !readOn,
        id: item.notificationId
      };
    });
  }

  async linkClick(item: Notification) {
    if (item.status !== NotificationStatuses[NotificationStatuses.Read] && item.notificationId) {
      notificationsStore.markAsRead(item.notificationId);

      item.status = NotificationStatuses[NotificationStatuses.Read];
    }

    this.props.onViewItem?.(item);
  }

  render() {
    return this.formattedNotifications.length === 0 ? (
      <>
        <div className={classNames(dashboardStyles.dashboardSection__content, styles.latestNotifications__container)}>You have no recent notifications</div>
        <LinkWithLabel label="View all" link="/notifications" Icon={ChevronRight} />
      </>
    ) : (
      <>
        <Table columns={this.formattedNotifications} showNumber={false} rowTitle="View Notification" />
        <LinkWithLabel label="View all" link="/notifications" Icon={ChevronRight} />
      </>
    );
  }
}
