import { Component } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { RouteComponentProps, Switch } from 'react-router-dom';
import lineFaultsStore from 'stores/lineFaults.store';
import { AuthRoute } from 'routing/AuthRoute';
import { GuardedRoute } from 'routing/GuardedRoute';
import LineCheckResultsRoute from 'routing/LineFaults/LineCheckResultsRoute';
import appStyles from 'App.module.scss';
import LineCheckError from 'components/Services/LineFaults/LineCheckError/LineCheckError';
import lineFaultStyles from './LineFaults.module.scss';
import ContactDataForm from './ContactDataForm/ContactDataForm';
import EngineerAppointmentSelection from './EngineerAppointmentSelection/EngineerAppointmentSelection';
import LineCheckResults from './LineCheckResults/LineCheckResults';
import LineFaultForm from './LineDataForm/LineDataForm';
import LineFaultSubmitSuccess from './LineFaultSubmitSuccess/LineFaultSubmitSuccess';

@observer
export default class LineFaults extends Component<RouteComponentProps> {
  get resultsPath() {
    return `${this.props.match.path}/:id`;
  }
  get errorPath() {
    return `${this.props.match.path}/error`;
  }
  get appointmentPath() {
    return `${this.props.match.path}/:id/engineer-appointment`;
  }
  get collectDataPath() {
    return `${this.props.match.path}/:id/collect-data`;
  }
  get successPath() {
    return `${this.props.match.path}/:id/success`;
  }

  componentWillUnmount() {
    lineFaultsStore.cleanResults();
  }

  render() {
    const { match } = this.props;
    const nextStepsAvailable = !!lineFaultsStore.lineFaultCheck;

    return (
      <div className={classNames(appStyles.container, lineFaultStyles.lineTest__container)}>
        <Switch>
          <AuthRoute exact path={match.path} component={LineFaultForm} pageTitle="Line Fault Form" />
          <AuthRoute exact path={this.errorPath} component={LineCheckError} pageTitle="Line Checker Error" />
          <LineCheckResultsRoute exact path={this.resultsPath} component={LineCheckResults} redirectPath={match.path} pageTitle="Line Checker Results" />
          <GuardedRoute
            exact
            path={this.appointmentPath}
            component={EngineerAppointmentSelection}
            condition={nextStepsAvailable}
            redirectPath={match.path}
            pageTitle="Engineer Appointment"
          />
          <GuardedRoute
            exact
            path={this.collectDataPath}
            component={ContactDataForm}
            condition={nextStepsAvailable}
            redirectPath={match.path}
            pageTitle="Contact Form"
          />
          <GuardedRoute
            exact
            path={this.successPath}
            component={LineFaultSubmitSuccess}
            condition={nextStepsAvailable}
            redirectPath={match.path}
            pageTitle="Line Fault Submitted"
          />
        </Switch>
      </div>
    );
  }
}
