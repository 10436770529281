import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { IBaseProps } from 'models/generic/iBaseProps';
import { AuthRoute, IAuthGuardProps } from 'routing/AuthRoute';

export interface IGuardProps extends IAuthGuardProps {
  condition: boolean;
  redirectPath: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GuardedRoute: FC<IGuardProps> = ({ condition, redirectPath, component, ...rest }: any) => {
  const routeComponent = (props: IBaseProps) => (condition ? React.createElement(component, props) : <Redirect to={{ pathname: redirectPath }} />);

  return <AuthRoute {...rest} component={routeComponent} />;
};
