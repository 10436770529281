export class MarketingItem {
  title: string;
  imageUrl: string;
  link: string;
  altText: string;

  constructor(title: string, imageUrl: string, link: string, altText: string) {
    this.title = title;
    this.imageUrl = imageUrl;
    this.link = link;
    this.altText = altText;
  }
}
