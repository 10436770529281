/* eslint-disable max-len */

export const errorMessages = {
  availableProductsRequestError:
    'We are unable to carry out an availability check using the details you provided. Please try again later or contact us for more information.',
  cliSameAsTelephoneNumber:
    'The contact number cannot be the same as the number you are reporting the fault on, as this will be used by Openreach engineers should they need to contact you. Please provide an alternative contact number.',
  highCareLevelFault: 'Your fault has a high care level. Please, fill related info to raise a trouble ticket.',
  noMatchingAddressesFound: 'We have not been able to locate any addresses for that postcode. Please check and try again, or contact us for more information.',
  theSpecifiedValueIsNotValid: 'The specified value is not valid.',
  defaultLoginErrorMessage: 'Something went wrong during the login process. Please, try to log in later.',
  portalUnavailable: 'The portal is temporarily unavailable. Please, try to log in later.',
  accountsCannotBeLoaded: 'There was an error loading associated accounts.',
  accountsNotFound: 'No accounts were found for your user.',
  tokenCannotBeRenewed: 'There was an error renewing your authentication token.'
};

export const messages = {
  raiseTroubleReportLoaderTitle: 'Please wait while your trouble report is logged. Please do not refresh this page.',
  productAvailableHeading: 'Ultrafast Fibre {0} is a great choice!',
  productAvailableMainText: 'Ready to proceed?',
  productAvailableCallBackText: "Please enter your preferred contact number and one of our '2025' experts will call you back within 48 hours.",
  noProductAvailableHeading: "Our Ultrafast Fibre hasn't reached you yet.",
  noProductAvailableMainText:
    "Don't worry, we enable new addresses every month. Leave your details and we will notify you when Ultrafast Fibre becomes available at your postcode {0}.",
  noProductAvailableCallBackText: 'Please enter your preferred contact number below.',
  noAddressAvailableHeading: "We can't find your address.",
  noAddressAvailableMainText: "It looks like we can't find your address. Our team can help you find the best business broadband deal. Get in touch today.",
  noAddressAvailableCallBackText: 'Please enter your preferred contact number below.',
  additionalChargesAgreement:
    'Please be aware that Openreach engineers will charge if a fault is found outside of the BT network, if damage caused by negligence towards equipment is identified, or if access to the site is denied.'
};

export const defaultErrorKey = 'default';

export const b2cErrorMessagesMap = new Map([
  ['AADB2C90052', 'Invalid username or password.'],
  ['AADB2C90048', 'An unhandled exception has occurred on the server.'],
  ['AADB2C90053', 'A user with the specified credential could not be found.'],
  ['AADB2C90225', 'The username or password provided in the request are invalid.'],
  ['AADB2C90035', 'The service is temporarily unavailable. Please retry after a few minutes.'],
  ['AADB2C90157', 'You have exceeded the maximum number for retries for a self-asserted step.'],
  ['AADB2C90114', 'Your account is temporarily locked to prevent unauthorized use. Try again later.'],
  ['AADB2C90151', 'You have exceeded the maximum number for retries for multi-factor authentication.'],
  ['AADB2C90085', 'The service has encountered an internal error. Please reauthenticate and try again.'],
  ['AADB2C90289', 'We encountered an error connecting to the identity provider. Please try again later.'],
  ['AADB2C90111', 'Your account has been locked. Contact your support person to unlock it, then try again.'],
  ['AADB2C90037', 'An error occurred while processing the request. Please contact administrator of the site you are trying to access.'],
  ['AADB2C90046', 'We are having trouble loading your current state. You might want to try starting your session over from the beginning.'],
  ['AADB2C90091', null],
  [defaultErrorKey, 'Something went wrong during the login process. Please, try to log in later.']
]);
