export class NavItem {
  title: string;
  route: string;
  tooltip: string;
  children: NavItem[];

  constructor(title: string, route: string, tooltip: string, children: NavItem[] | null = null) {
    this.title = title;
    this.route = route;
    this.tooltip = tooltip;

    if (children) {
      this.children = children;
    } else {
      this.children = [];
    }
  }
}
