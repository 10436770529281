import { ChangeEvent, Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import CheckBox from 'components/Generic/FormElements/Checkbox/Checkbox';
import FileUpload from 'components/Generic/FormElements/FileUpload/FileUpload';
import Input from 'components/Generic/FormElements/Input/Input';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import Radio from 'components/Generic/FormElements/Radio/Radio';
import Select from 'components/Generic/FormElements/Select/Select';
import TextArea from 'components/Generic/FormElements/TextArea/TextArea';
import { ValidationRule } from 'models/api/validationRule.model';
import { FalconFile } from 'models/generic/falconFile.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import { ListItem } from 'models/generic/listItem.model';
import { ValidationErrors } from 'models/generic/validationError.model';
import lookupStore from 'stores/lookups.store';
import validation from 'utils/validation';
import appStyles from 'App.module.scss';

@observer
class ExampleFormContainer extends Component {
  @observable account: {
    givenName: '';
    surname: '';
    title: number | null;
    position: '';
    memorableWord: '';
    email: string;
    phone: '';
    message: '';
    signature: string;
    billingPreferences: number[];
    marketingPreference: boolean | null;
    accountHolderConfirm: boolean | null;
    accountAuthConfirm: boolean | null;
    attachment: FalconFile | null;
  } = {
    givenName: '',
    surname: '',
    title: 1, // Mr
    position: '',
    memorableWord: '',
    email: '',
    phone: '',
    message: '',
    signature: '',
    billingPreferences: [], // Email
    marketingPreference: null, // Opt In
    accountHolderConfirm: null,
    accountAuthConfirm: null,
    attachment: null
  };

  submitted = false;
  @observable errors = new ValidationErrors();

  titleOptions = [new ListItem(1, 'Mr'), new ListItem(2, 'Mrs'), new ListItem(3, 'Miss'), new ListItem(4, 'Ms')];

  constructor(props: IBaseProps) {
    super(props);

    this.handleInput = this.handleInput.bind(this);
    this.handleInputAsNumber = this.handleInputAsNumber.bind(this);
    this.handleInputAsBool = this.handleInputAsBool.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.validate = this.validate.bind(this);

    // initial load of lookup values
    lookupStore.loadLookups();
  }

  handleInput(e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
    const value = e.target.value;
    const name = e.target.name;

    this.account = {
      ...this.account,
      [name]: value
    };

    if (this.submitted) {
      this.validate();
    }
  }

  handleFile(file: FalconFile | null) {
    this.account = {
      ...this.account,
      attachment: file
    };

    if (this.submitted) {
      this.validate();
    }
  }

  handleInputAsNumber(e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) {
    let value = null;
    if (e.target.value) {
      value = +e.target.value;
    }
    const name = e.target.name;

    this.account = {
      ...this.account,
      [name]: value
    };

    if (this.submitted) {
      this.validate();
    }
  }

  handleInputAsBool(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const value = e.target.value === 'true' ? true : e.target.value === 'false' ? false : null;
    const name = e.target.name;

    this.account = {
      ...this.account,
      [name]: value
    };

    if (this.submitted) {
      this.validate();
    }
  }

  handleCheckBox(e: ChangeEvent<HTMLInputElement>) {
    const newSelection = +e.target.value;
    let newSelectionArray: number[];

    if (this.account.billingPreferences.indexOf(newSelection) > -1) {
      newSelectionArray = this.account.billingPreferences.filter((s) => s !== newSelection);
    } else {
      newSelectionArray = [...this.account.billingPreferences, newSelection];
    }

    this.account.billingPreferences = newSelectionArray;

    if (this.submitted) {
      this.validate();
    }
  }

  validate() {
    // sample dynamic validation method
    this.submitted = true;

    // this mimics a call to API to get validation rules for a given object (e.g. call /edit endpoint for an item that has all fields empty)
    const rules: ValidationRule[] = [
      {
        ruleName: 'rule://businesslibrary.validators.requiredvalidator/GivenName',
        description: 'The GivenName field is required.',
        property: 'GivenName',
        severity: 0,
        originProperty: 'GivenName',
        parameters: null
      },
      {
        ruleName: 'rule://businesslibrary.validators.requiredvalidator/Surname',
        description: 'The Surname field is required.',
        property: 'Surname',
        severity: 0,
        originProperty: 'Surname',
        parameters: null
      },
      {
        ruleName: 'rule://businesslibrary.validators.requiredvalidator/Title',
        description: 'The Title field is required.',
        property: 'Title',
        severity: 0,
        originProperty: 'Title',
        parameters: null
      },
      {
        ruleName: 'rule://businesslibrary.validators.requiredvalidator/Phone',
        description: 'The Phone field is required.',
        property: 'Phone',
        severity: 0,
        originProperty: 'Phone',
        parameters: null
      },
      {
        ruleName: 'rule://businesslibrary.validators.phonevalidator/Phone',
        description: 'The Phone field is not a valid phone number.',
        property: 'Phone',
        severity: 0,
        originProperty: 'Phone',
        parameters: null
      },
      {
        ruleName: 'rule://businesslibrary.validators.requiredvalidator/Email',
        description: 'The Email field is required.',
        property: 'Email',
        severity: 0,
        originProperty: 'Email',
        parameters: null
      },
      {
        ruleName: 'rule://businesslibrary.validators.emailvalidator/Email',
        description: 'The Email field is not a valid email address.',
        property: 'Email',
        severity: 0,
        originProperty: 'Email',
        parameters: null
      },
      {
        ruleName: 'rule://businesslibrary.validators.requiredvalidator/Position',
        description: 'The Position field is required.',
        property: 'Position',
        severity: 0,
        originProperty: 'Position',
        parameters: null
      },
      {
        ruleName: 'rule://businesslibrary.validators.requiredvalidator/Signature',
        description: 'The Signature field is required.',
        property: 'Signature',
        severity: 0,
        originProperty: 'Signature',
        parameters: null
      },
      {
        ruleName: 'rule://businesslibrary.validators.filenamevalidator/Attachment',
        description: 'Please ensure your file name only includes letters, numbers and/or underscores.',
        property: 'Attachment',
        severity: 0,
        originProperty: 'Attachment',
        parameters: null
      },
      {
        ruleName: 'rule://businesslibrary.validators.fileextensionvalidator/Attachment',
        description: 'Please upload a file with one of the following extensions: doc, docx.',
        property: 'Attachment',
        severity: 0,
        originProperty: 'Attachment',
        parameters: {
          min: null,
          max: null,
          size: null,
          ext: ['doc', 'docx'],
          compareField: null
        }
      },
      {
        ruleName: 'rule://businesslibrary.validators.filesizevalidator/Attachment',
        description: 'Please ensure your file is less than 3MB.',
        property: 'Attachment',
        severity: 0,
        originProperty: 'Attachment',
        parameters: {
          min: null,
          max: null,
          size: 3,
          ext: null,
          compareField: null
        }
      }
    ];

    // validate object
    const errors = new ValidationErrors();
    validation.validate(this.account, rules, errors);
    this.errors = errors;
  }

  render() {
    return (
      <form className={`${appStyles.form} ${appStyles.row}`}>
        <div className={appStyles.col_md_6}>
          <fieldset>
            <Legend text="Details" type="large" />
            <div className={appStyles.form__row}>
              <Select
                elementId="title"
                labelText="Title"
                name="title"
                options={this.titleOptions}
                placeholder="Please choose..."
                disablePlaceholder={false}
                handleChange={this.handleInput}
                value={this.account.title}
                validationError={this.errors.fieldErrors['title']}
              />
            </div>
            <div className={appStyles.form__row}>
              <Input
                elementId="givenName"
                inputType="text"
                labelText="Given Name"
                name="givenName"
                placeholder="enter your given name"
                handleChange={this.handleInput}
                value={this.account.givenName}
                validationError={this.errors.fieldErrors['givenName']}
                autocomplete="given-name"
              />
            </div>
            <div className={appStyles.form__row}>
              <Input
                elementId="surname"
                inputType="text"
                labelText="Surname"
                name="surname"
                placeholder="enter your surname"
                handleChange={this.handleInput}
                value={this.account.surname}
                validationError={this.errors.fieldErrors['surname']}
                autocomplete="family-name"
              />
            </div>
            <div className={appStyles.form__row}>
              <Input
                elementId="position"
                inputType="text"
                labelText="Position"
                name="position"
                placeholder="enter your position"
                handleChange={this.handleInput}
                value={this.account.position}
                validationError={this.errors.fieldErrors['position']}
              />
            </div>
            <div className={appStyles.form__row}>
              <Input
                elementId="memorableWord"
                inputType="password"
                labelText="Memorable Word"
                name="memorableWord"
                placeholder="enter your memorable word"
                handleChange={this.handleInput}
                value={this.account.memorableWord}
                validationError={this.errors.fieldErrors['memorableWord']}
              />
            </div>
            <div className={appStyles.form__row}>
              <Select
                elementId="accountHolderConfirm"
                labelText="Can you confirm that you are the account holder?"
                name="accountHolderConfirm"
                options={lookupStore.yesNoOptions}
                placeholder="Please select..."
                disablePlaceholder={false}
                handleChange={this.handleInputAsBool}
                value={this.account.accountHolderConfirm}
                validationError={this.errors.fieldErrors['accountHolderConfirm']}
              />
            </div>
            <div className={appStyles.form__row}>
              <Legend type="medium" text="Can you confirm that you are the only person required to authorise Direct Debits from this account?" />
              <div className={appStyles.form__row}>
                <Radio
                  name="accountAuthConfirm"
                  selectedOption={this.account.accountAuthConfirm}
                  handleChange={this.handleInputAsBool}
                  options={lookupStore.yesNoOptions}
                  validationError={this.errors.fieldErrors['accountAuthConfirm']}
                />
              </div>
            </div>
          </fieldset>
        </div>
        <div className={appStyles.col_md_6}>
          <fieldset>
            <Legend text="Contact Details" type="large" />
            <div className={appStyles.form__row}>
              <Input
                elementId="email"
                inputType="email"
                labelText="Email"
                name="email"
                placeholder="enter your email address"
                handleChange={this.handleInput}
                value={this.account.email}
                validationError={this.errors.fieldErrors['email']}
              />
            </div>
            <div className={appStyles.form__row}>
              <Input
                elementId="phone"
                inputType="tel"
                labelText="Phone"
                name="phone"
                placeholder="enter your phone number"
                handleChange={this.handleInput}
                value={this.account.phone}
                validationError={this.errors.fieldErrors['phone']}
              />
            </div>
            <div className={appStyles.form__row}>
              <TextArea
                name="message"
                elementId="message"
                placeholder="enter your message"
                labelText="Message"
                resize="vertical"
                value={this.account.message}
                handleChange={this.handleInput}
                rows={4}
                validationError={this.errors.fieldErrors['message']}
              />
            </div>
            <div className={appStyles.form__row}>
              <FileUpload
                name="attachment"
                elementId="attachment"
                placeholder="Upload a file"
                labelText="Upload a File"
                value={this.account.attachment}
                handleChange={this.handleFile}
                validationError={this.errors.fieldErrors['attachment']}
              />
            </div>
          </fieldset>
          <div className={appStyles.card}>
            <fieldset>
              <Legend type="medium" text="Billing Preferences" />
              <div className={appStyles.form__row}>
                <Legend type="small" text="How would you like to receive your bills?" />
                <CheckBox
                  name="billingPreferences"
                  options={lookupStore.billingOptions}
                  selectedOptions={this.account.billingPreferences}
                  handleChange={this.handleCheckBox}
                  validationError={this.errors.fieldErrors['billingPreferences']}
                />
              </div>
            </fieldset>
            <fieldset>
              <Legend type="medium" text="Marketing Preferences" />
              <div className={appStyles.form__row}>
                <Legend type="small" text="Select your marketing preferences" />
                <Radio
                  name="marketingPreference"
                  selectedOption={this.account.marketingPreference}
                  handleChange={this.handleInputAsBool}
                  options={lookupStore.marketingOptions}
                  validationError={this.errors.fieldErrors['marketingPreference']}
                />
              </div>
            </fieldset>
          </div>
        </div>

        <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
          <Button buttonStyle="primary" size="lg" handleClick={this.validate}>
            Validate
          </Button>
          <Button buttonStyle="secondary" size="lg">
            Test button
          </Button>
        </BottomButtonContainer>
      </form>
    );
  }
}

export default ExampleFormContainer;
