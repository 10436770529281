export class ModulusCheck {
  // CODEREVIEW: Properties are named to match API response.

  Valid: boolean | null = null;
  UKBankBranch: IModulusCheckBankBranch | null = null;
  InvalidReason: string | null = null;
  InvalidParameter: string | null = null;
  AccountDetailsOutput: IModulusCheckAccountDetailsOutput | null = null;

  static parsedInvalidReason(m: ModulusCheck): string {
    if (!m.InvalidReason || m.InvalidReason === '') {
      return '';
    }
    switch (m.InvalidReason) {
      case 'The sort code and account number do not pass the modulus check': // User doesn't know what a modulus check is.
        return 'Your sort code and account number did not pass our external checks.';
      case 'The sort code is not found in the database': // User doesn't need to know about the database.
        return "Your sort code couldn't be matched to a bank.";
      default:
        return m.InvalidReason;
    }
  }
}

export interface IModulusCheckBankBranch {
  BankName: string;
}

export interface IModulusCheckAccountDetailsOutput {
  AccountNumber: string;
  SortCode: string;
  BankAccountVerificationMAC: string;
}
