import { FC, HTMLAttributes, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { Notification } from 'models/api/notification.model';
import { FalconError } from 'models/generic/falconError.model';
import constants from 'utils/constants';
import utils from 'utils/utils';
import styles from './NotificationCards.module.scss';

interface INotificationCardsItemProps {
  item: Notification;
  onCloseClick: (id: number | null) => unknown;
  onViewClick: (id: number | null) => unknown;
}

const NotificationCardsItem: FC<INotificationCardsItemProps & HTMLAttributes<HTMLElement>> = ({ item, onCloseClick, onViewClick, ...rest }) => {
  const error = new FalconError();

  const payloadObject = useMemo(() => item.payload && JSON.parse(item.payload), [item.payload]);

  const onClose = useCallback(() => onCloseClick(item.notificationId), [item.notificationId, onCloseClick]);
  const onView = useCallback(() => onViewClick(item.notificationId), [item.notificationId, onViewClick]);

  if (item.type === constants.notificationTypes.FailedLineFaultCheck) {
    error.data = payloadObject.error;
  }

  const redirectTo = {
    pathname: utils.routing.notificationsLinkCreator(item.type)({ notificationId: item.notificationId, payload: payloadObject }),
    state: error
  };

  return (
    <CSSTransition timeout={500} unmountOnExit {...rest}>
      <div className={styles.alert} id={`notification-card-${item.notificationId}`}>
        <h3>New Notification</h3>
        <p>{item.message}</p>
        <Link to={redirectTo} onClick={onView} className={styles.button__view_more}>
          <p>View details</p>
        </Link>
        <CloseIcon height="16" width="16" className={styles.close__button} role="button" onClick={onClose} />
      </div>
    </CSSTransition>
  );
};

export default NotificationCardsItem;
