import { FC } from 'react';
import classNames from 'classnames';
import appStyles from 'App.module.scss';

interface ICardRowProps {
  label?: string;
}

const CardRow: FC<ICardRowProps> = ({ label, children }) => (
  <div className={appStyles.card__row}>
    {label && <div className={classNames(appStyles.card__label, appStyles.text_midBlue)}>{label}</div>}
    <div className={classNames(appStyles.card__value, appStyles.text_marginBlue)}>{children}</div>
  </div>
);

export default CardRow;
