import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridFilterChangeEvent, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { BaseGrid } from 'components/Generic/Grid/BaseGrid';
import { instanceOfFilter } from 'components/Generic/Grid/utils';
import { observable } from 'mobx';
import { ModelCollection } from 'models/generic/modelCollection.model';
import { BaseGridRequest } from 'models/requests/baseGridRequest.model';
import constants from 'utils/constants';

export abstract class BaseServerGrid<DataItemType, PropsType, RequestType extends BaseGridRequest> extends BaseGrid<PropsType> {
  @observable data: ModelCollection<DataItemType> = new ModelCollection<DataItemType>();
  @observable request: RequestType = {} as RequestType;
  @observable requestUpdated = false;
  @observable requestInProgress = false;
  dataPromise: Promise<ModelCollection<DataItemType>> | null = null;

  constructor(props: PropsType) {
    super(props);

    this.pageChange = this.pageChange.bind(this);
    this.sortChange = this.sortChange.bind(this);
    this.exportPdf = this.exportPdf.bind(this);
  }

  abstract getData(): Promise<ModelCollection<DataItemType>>;

  loadData = async () => {
    // reset request updated flag
    this.requestUpdated = false;

    // wait for previous request (if any) to complete before sending a new one
    if (this.requestInProgress && this.dataPromise) {
      await this.dataPromise;
    }

    if (this.requestInProgress === false) {
      this.requestInProgress = true;
      this.dataPromise = this.getData();
      this.data = await this.dataPromise;
      this.requestInProgress = false;
    }
  };

  pageChange(event: GridPageChangeEvent) {
    this.skip = event.page.skip;
    this.take = event.page.take;

    this.request.pageSize = this.take;
    const pageNumber = this.skip / this.request.pageSize + 1;
    this.request.pageNumber = pageNumber;
    this.requestUpdated = true;
  }

  sortChange(event: GridSortChangeEvent) {
    this.sort = event.sort;

    // server side sorting if request is specified
    const sort = event.sort[0];
    if (sort) {
      this.request.sortBy = sort.field + ' ' + sort.dir;
    } else {
      // use default value
      this.request.sortBy = '';
    }
    this.requestUpdated = true;
  }

  parseFilterEvent(event: GridFilterChangeEvent) {
    const filter = event.filter.filters?.find((item) => instanceOfFilter(item)) || event.filter.filters[event.filter.filters.length - 1];

    return filter as CompositeFilterDescriptor;
  }

  filterChange(event: GridFilterChangeEvent) {
    this.filter = event.filter;
  }

  exportPdf() {
    super.exportPdf(this.data.items);
  }

  setSearchKeyword(keyword: string) {
    keyword = keyword.trim();

    // update keyword if modified and has minimum length or if changed back to empty string
    if (keyword !== this.request.searchKeyword && (keyword.length >= constants.searchKeywordMinimumLength || keyword === '')) {
      this.request.searchKeyword = keyword;
      this.requestUpdated = true;

      // reset to first page
      this.request.pageNumber = 1;
      this.skip = 0;
    }
  }
}
