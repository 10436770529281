import React from 'react';
import { useMediaQuery } from 'react-responsive';

const withMediaQueryInfo =
  <P,>(Component: React.ComponentType<P>) =>
  (props: P) => {
    const isTablet = useMediaQuery({ minWidth: 768 });
    const isDesktop = useMediaQuery({ minWidth: 992 });

    return <Component {...props} isTablet={isTablet} isDesktop={isDesktop} />;
  };

export default withMediaQueryInfo;
