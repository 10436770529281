import { ChangeEvent, Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import Icon from 'components/Generic/Icon/icon';
import Alert from 'components/Generic/Alert/Alert';
import Select from 'components/Generic/FormElements/Select/Select';
import { DirectDebit } from 'models/api/directDebit.model';
import { ListItem } from 'models/generic/listItem.model';
import { ValidationErrors } from 'models/generic/validationError.model';
import lookupStore from 'stores/lookups.store';
import constants from 'utils/constants';
import appStyles from 'App.module.scss';
import styles from '../NewDirectDebit.module.scss';

interface IStep1Props {
  newDirectDebit: boolean;
  currentStep: number;
  directDebit: DirectDebit;
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  errors: ValidationErrors;
  hasSageAccountsAvailable: boolean;
  sageAccountsList: ListItem[];
}

@observer
export class Step1 extends Component<IStep1Props> {
  @observable hasSageAccountsAvailable = false;

  containerClass = '';
  showManualDDMessage = false;
  sageAccountsList: ListItem[] = [];
  sageAccountsLoaded = false;

  constructor(props: IStep1Props) {
    super(props);
    this.containerClass = this.props.newDirectDebit ? appStyles.container : '';
    lookupStore.loadLookups();
  }

  componentDidMount() {
    this.updateSageAccounts();
  }

  updateSageAccounts() {
    this.sageAccountsList = this.props.sageAccountsList;
    this.hasSageAccountsAvailable = this.sageAccountsList.length > 0;
    this.sageAccountsLoaded = true;
    this.forceUpdate();
  }

  // eslint-disable-next-line complexity
  render() {
    let showManualDDMessage = false;
    let noSageAccountsAvailableForNewDD = false;

    if (this.props.currentStep !== 1) {
      return null;
    }

    if (this.props.directDebit.accountHolderConfirm === false || this.props.directDebit.accountAuthConfirm === false) {
      showManualDDMessage = true;
    }

    if (this.sageAccountsLoaded && !this.hasSageAccountsAvailable && this.props.newDirectDebit) {
      noSageAccountsAvailableForNewDD = true;
    }

    return (
      <div className={this.containerClass}>
        {!!this.props.newDirectDebit && (
          <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
            <Icon icon={Icon.invoices} size="heading" color="currentColor" iconName="invoice icon" customClass={appStyles.heading__icon} />
            <h1 className={appStyles.heading__text}>New Direct Debit</h1>
          </div>
        )}
        {noSageAccountsAvailableForNewDD ? (
          <Alert alertType="warning">
            <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>You can only have a single Direct Debit for each account.</p>
            <div className={`${appStyles.text_midBlue} ${appStyles.info__sub}`}>
              You will need to either:
              <ul>
                <li>edit your existing Direct Debits with any changes (you can do this on the Manage Direct Debit page), or</li>
                <li>
                  contact {constants.keyVariables.customerServicesName} for further advice on {constants.keyVariables.helpdeskTelephone}.
                </li>
                <li>Our correspondence address is Bridgford House, Heyes Lane, Alderley Edge, Cheshire SK9 7JP.</li>
              </ul>
            </div>
          </Alert>
        ) : (
          <>
            <Alert alertType="blank">
              <p className={`${appStyles.text_tangaroaBlue} ${appStyles.info__main} ${appStyles.info_bold}`}>
                To {this.props.newDirectDebit ? 'set up ' : 'edit '} a Direct Debit please fill out the form below and click <i>Proceed</i>
              </p>
              <p className={`${appStyles.text_darkGrey} ${appStyles.info__sub}`}>
                By completing this form you agree that you have the permission of all parties needed to authorise the Direct Debit.
              </p>
              <p className={`${appStyles.text_darkGrey} ${appStyles.info__sub}`}>
                You can contact {constants.keyVariables.customerServicesName} on {constants.keyVariables.helpdeskTelephone} if you require support with setting
                up or changing a Direct Debit.
              </p>
              <p className={`${appStyles.text_darkGrey} ${appStyles.info__sub}`}>
                Our correspondence address is Bridgford House, Heyes Lane, Alderley Edge, Cheshire SK9 7JP.
              </p>
            </Alert>
            <div className={`${appStyles.row} ${styles.ddForm} ${appStyles.col_md_6} ${appStyles.col_lg_5}`} id="ddStep1">
              <fieldset>
                <div className={appStyles.form__row}>
                  <Select
                    elementId="accountHolderConfirm"
                    labelText="Do you hold a UK bank or building society account and are you the account holder?"
                    name="accountHolderConfirm"
                    options={lookupStore.yesNoOptions}
                    placeholder="Please select..."
                    disablePlaceholder={true}
                    handleChange={this.props.handleChange}
                    value={this.props.directDebit.accountHolderConfirm}
                    validationError={this.props.errors.fieldErrors['accountHolderConfirm']}
                  />
                </div>
                <div className={appStyles.form__row}>
                  <Select
                    elementId="accountAuthConfirm"
                    labelText="Are you the only person required to authorise Direct Debits from this account?"
                    name="accountAuthConfirm"
                    options={lookupStore.yesNoOptions}
                    placeholder="Please select..."
                    disablePlaceholder={true}
                    handleChange={this.props.handleChange}
                    value={this.props.directDebit.accountAuthConfirm}
                    validationError={this.props.errors.fieldErrors['accountAuthConfirm']}
                  />
                </div>
              </fieldset>
            </div>
          </>
        )}
        {!!showManualDDMessage && (
          <Alert alertType="blank">
            <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>
              If you have answered ‘no’ to either of these questions, you will need to complete a paper Direct Debit instruction which you can download using
              the button at the bottom left of this page.
            </p>
            <p className={`${appStyles.text_midBlue} ${appStyles.info__sub}`}>If you do not wish to proceed any further, please click ‘Cancel’.</p>
          </Alert>
        )}
      </div>
    );
  }
}
