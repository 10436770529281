import { FC } from 'react';
import { getProductSpeedString } from 'utils/string';
import { createArc } from 'utils/svg';
import FttpProductsStyles from './FttpProducts.module.scss';

interface ISpeedScaleProps {
  startColor: string;
  endColor: string;
  startOffset: string;
  endOffset: string;
  speedMbps: number;
  customStyle: string;
}

const SpeedScale: FC<ISpeedScaleProps> = ({ startColor, endColor, startOffset, endOffset, speedMbps, customStyle }) => {
  const speedScaleEndAngle = (speedMbps / 1000) * 180 - 90;
  const speedScaleStartAngle = -90;
  const centerBottomPositionX = 50;
  const centerBottomPositionY = 50;
  const innerRadius = 37;
  const outerRadius = 42;
  const endAngle = 90;

  return (
    <div className={customStyle}>
      <svg viewBox="0 0 100 55" id="field">
        <defs>
          <linearGradient id="productSpeedGradient">
            <stop stopColor={startColor} offset={startOffset} />
            <stop stopColor={endColor} offset={endOffset} />
          </linearGradient>
        </defs>
        <g>
          <path
            fill="url('#productSpeedGradient')"
            d={createArc(centerBottomPositionX, centerBottomPositionY, innerRadius, outerRadius, speedScaleStartAngle, endAngle)}
          />
          <path
            className={FttpProductsStyles.svg_gray_path}
            d={createArc(centerBottomPositionX, centerBottomPositionY, innerRadius, outerRadius, speedScaleEndAngle, endAngle)}
          />
          <path
            className={FttpProductsStyles.svg_white_path}
            d={createArc(centerBottomPositionX - 2, centerBottomPositionY + 1, 0, innerRadius + 1, speedScaleStartAngle, endAngle)}
          />
          <text x={centerBottomPositionX} y={centerBottomPositionY} fill={startColor} className={FttpProductsStyles.svg_text}>
            {getProductSpeedString(speedMbps)}
          </text>
        </g>
      </svg>
    </div>
  );
};

export default SpeedScale;
