import { AxiosError } from 'axios';
import { BaseApi } from 'api/baseApi';
import { FalconError } from 'models/generic/falconError.model';
import messagesStore from 'stores/messages.store';

export class ProductsApi extends BaseApi {
  constructor() {
    super();

    this.baseApiUrl = process.env.REACT_APP_TTB_FTTP_AVAILABILITY_BASE_URL || '';
  }

  protected getResultFromError(error: AxiosError<unknown>, friendlyErrorMessage: string | null): FalconError {
    const apiError = new FalconError();

    apiError.errorMessages.push(friendlyErrorMessage || 'Something went wrong.');

    messagesStore.addError(apiError);

    return apiError;
  }
}

export default new ProductsApi();
