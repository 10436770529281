import appStyles from 'App.module.scss';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Confirmation } from 'components/Generic/Confirmation/Confirmation';
import Button from 'components/Generic/FormElements/Button/Button';
import Icon from 'components/Generic/Icon/icon';
import { observable } from 'mobx';
import { PaymentResult } from 'models/api/paymentResult.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import invoicesStore from 'stores/invoices.store';
import utils from 'utils/utils';

export class PaymentFailed extends Component<IBaseProps> {
  @observable paymentResult = new PaymentResult();
  @observable storedPaymentIds: string[] | null = null;

  constructor(props: IBaseProps) {
    super(props);

    this.sendPaymentResult = this.sendPaymentResult.bind(this);
  }

  componentDidMount() {
    this.storedPaymentIds = utils.session.getFromSession('paymentIds');
    this.sendPaymentResult();
  }

  async sendPaymentResult() {
    const params = new URLSearchParams(window.location.search);

    const paymentId = params.get('paymentId');
    const crypt = params.get('crypt');

    if (!paymentId) {
      // don't do anything if no paymentid param

      return;
    }

    this.paymentResult.paymentId = paymentId;
    this.paymentResult.ipAddress = await utils.ip.getCurrentIpAddress();
    this.paymentResult.crypt = crypt;

    params.delete('crypt');
    params.delete('paymentId');

    this.props.history.replace(window.location.pathname);

    if (this.storedPaymentIds && this.storedPaymentIds.includes(paymentId)) {
      return;
    }

    await invoicesStore.completePaymentTransaction(this.paymentResult);

    // save payment id to session to ensure completion isn't logged multiple times
    let updatedPaymentIds: string[];
    if (this.storedPaymentIds) {
      this.storedPaymentIds.push(this.paymentResult.paymentId);
      updatedPaymentIds = this.storedPaymentIds;
    } else {
      updatedPaymentIds = [this.paymentResult.paymentId];
    }

    utils.session.saveToSession('paymentIds', updatedPaymentIds);
  }

  render() {
    return (
      <>
        <Confirmation
          mainMessage="Your payment has failed."
          subMessages={["You can view your invoices or click 'Try again' to review your details and retry your payment."]}
          containerId="payment_failed"
          icon={Icon.warning}
          iconText="Payment failed"
          iconName="warning icon"
        />
        <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
          <Button buttonStyle="outline_secondary" size="lg" handleClick={() => this.props.history.push('/invoices')}>
            Manage Invoices
          </Button>
          <Link
            id="paymentTryAgain"
            className={`${appStyles.button} ${appStyles.button_primary} ${appStyles.button_lg}`}
            to={'/invoices/payment-summary'}
            title="review your details">
            Try again
          </Link>
        </BottomButtonContainer>
      </>
    );
  }
}

export default withRouter(PaymentFailed);
