import api from 'api/api';
import bankingApi from 'api/bankingApi';
import directDebitFileApi from 'api/directDebitFileApi';
import invoicesFileApi from 'api/invoicesFileApi';
import { DirectDebit } from 'models/api/directDebit.model';
import { DirectDebitDownload } from 'models/api/directDebitDownloadLog.model';
import { Invoice } from 'models/api/invoice.model';
import { ModulusCheck } from 'models/api/modulusCheck.model';
import { PaymentDetails } from 'models/api/paymentDetails.model';
import { PaymentResponse } from 'models/api/paymentResponse.model';
import { PaymentResult } from 'models/api/paymentResult.model';
import { SageAccount } from 'models/api/SageAccount.model';
import { SagePayFormData } from 'models/api/sagePayFormData.model';
import { ValidationRule } from 'models/api/validationRule.model';
import { FalconError } from 'models/generic/falconError.model';
import { FalconFile } from 'models/generic/falconFile.model';
import { ICountableArray } from 'models/generic/iCountableArray';
import { ListItem } from 'models/generic/listItem.model';
import { ModelCollection } from 'models/generic/modelCollection.model';
import { InvoicesGridRequest } from 'models/requests/InvoicesGridRequest.model';
import userStore from 'stores/user.store';
import utils from 'utils/utils';

class InvoicesStore {
  set selectedInvoices(invoiceIds: string[] | null) {
    utils.session.saveToSession('invoiceNumbers', invoiceIds);
  }

  get selectedInvoices(): string[] | null {
    return utils.session.getFromSession('invoiceNumbers');
  }

  set unpaidInvoices(invoices: ModelCollection<Invoice> | null) {
    utils.session.saveToSession('unpaidInvoices', invoices);
  }

  get unpaidInvoices(): ModelCollection<Invoice> | null {
    return utils.session.getFromSession('unpaidInvoices');
  }

  set paymentDetails(paymentDetails: PaymentDetails | null) {
    utils.session.saveToSession('paymentDetails', paymentDetails);
  }

  get paymentDetails(): PaymentDetails | null {
    return utils.session.getFromSession('paymentDetails');
  }

  async getPaymentDetailsValidationRules(): Promise<ValidationRule[]> {
    return api.getValidationRules('Payment/GetValidationRules');
  }

  async getDirectDebitValidationRules(): Promise<ValidationRule[]> {
    return api.getValidationRules('DirectDebit/GetValidationRules');
  }

  async getSagePayUrl(form: PaymentDetails): Promise<SagePayFormData | FalconError> {
    const result = await api.post<SagePayFormData>('Payment/BeginPaymentTransaction', form, 'There was an error getting the sagepay url');

    return result;
  }

  async completePaymentTransaction(form: PaymentResult): Promise<PaymentResponse | FalconError> {
    const result = await api.post<PaymentResponse>('Payment/CompletePaymentTransaction', form, 'There was an error logging the transaction');

    return result;
  }

  async getNewDirectDebit(): Promise<DirectDebit | FalconError> {
    const result = await api.get<DirectDebit>('DirectDebit/Create', {
      friendlyErrorMessage: 'There was an error loading the form.'
    });

    return result;
  }

  async setUpDirectDebit(directDebit: DirectDebit): Promise<DirectDebit | FalconError> {
    const result = await api.post<DirectDebit>('/DirectDebit/Create', directDebit, `There was an error setting up the Direct Debit.`);

    return result;
  }

  async updateDirectDebit(directDebit: DirectDebit): Promise<DirectDebit | FalconError> {
    const result = await api.put<DirectDebit>('/DirectDebit/Edit', directDebit, 'There was an error updating the Direct Debit');

    return result;
  }

  async logDirectDebitDownload(directDebitLetterDownload: DirectDebitDownload): Promise<DirectDebitDownload | FalconError> {
    const result = await api.post<DirectDebitDownload>(
      '/DirectDebit/LogDirectDebitLetterDownloadEvent',
      directDebitLetterDownload,
      `There was an error logging the Direct Debit Download.`
    );

    return result;
  }

  async getDirectDebit(id: number): Promise<DirectDebit | FalconError> {
    const result = await api.get<DirectDebit>('/DirectDebit/Edit/' + id, {
      friendlyErrorMessage: `There was an error loading Direct Debit details.`
    });

    return result;
  }

  async getDirectDebits() {
    let directDebits: DirectDebit[] = [];

    const result = await api.get<DirectDebit[]>('/DirectDebit/GetDirectDebits', {
      friendlyErrorMessage: `There was an error loading Direct Debits.`
    });

    if (result instanceof FalconError) {
    } else {
      directDebits = result;
    }

    return directDebits;
  }

  async getUnusedSageAccounts(): Promise<ListItem[]> {
    const listItems: ListItem[] = [];
    const sageList = await api.get<SageAccount[]>('/DirectDebit/GetSageAccountsOfClient?includeUsedAccounts=false', {
      friendlyErrorMessage: `There was an error loading your Sage accounts.`
    });

    if (sageList instanceof FalconError) {
    } else {
      sageList.forEach((sageAccount: SageAccount) => {
        // Better way of handling nulls
        if (sageAccount.id) {
          if (sageAccount.name) {
            listItems.push(new ListItem(sageAccount.id, sageAccount.name));
          }
        }
      });
    }

    return listItems;
  }

  async getInvoices(request: InvoicesGridRequest): Promise<ModelCollection<Invoice>> {
    const quotes = new ModelCollection<Invoice>();

    const qs = request.getRequestAsQueryString();

    const result = await api.get<Invoice[]>('Invoice/MyInvoices?' + qs, {
      friendlyErrorMessage: 'There was an error loading invoices.'
    });

    if (result instanceof FalconError) {
    } else {
      result.forEach((invoice) => {
        if (invoice.dateIssued) {
          invoice.dateIssued = new Date(invoice.dateIssued);
        }
        if (invoice.items) {
          invoice.items.forEach((invoiceItem) => {
            if (invoiceItem.description) {
              invoiceItem.description = invoiceItem.description.replace(/\n/g, '<br />');
            }
          });
        }
      });

      quotes.items = result;
      quotes.totalCount = (result as unknown as ICountableArray).totalCount;
    }

    return quotes;
  }

  async getInvoice(invoiceNumber: string): Promise<Invoice | null> {
    let invoice: Invoice | null = null;

    const result = await api.get<Invoice>('Invoice/Details?invoiceNumber=' + invoiceNumber, {
      friendlyErrorMessage: 'There was an error loading invoice.'
    });

    if (result instanceof FalconError) {
    } else {
      if (result) {
        if (result.dateIssued) {
          result.dateIssued = new Date(result.dateIssued);
        }

        if (result.items) {
          result.items.forEach((invoiceItem) => {
            if (invoiceItem.description) {
              invoiceItem.description = invoiceItem.description.replace(/\n/g, '<br />');
            }
          });
        }
      }

      invoice = result;
    }

    return invoice;
  }

  async getInvoiceDownloads(invoice: Invoice): Promise<FalconFile[]> {
    const files: FalconFile[] = [];

    // check if invoice files exist before displaying download links
    if (invoice) {
      if (invoice.invoiceDownloadUrl) {
        if (await invoicesFileApi.fileExists(invoice.invoiceDownloadUrl, { injectClientId: false })) {
          files.push(new FalconFile(`Invoice_${invoice.invoiceNumber}.pdf`, '', invoice.invoiceDownloadUrl));
        }
      }

      if (invoice.callsDownloadUrl) {
        if (await invoicesFileApi.fileExists(invoice.callsDownloadUrl, { injectClientId: false })) {
          files.push(new FalconFile(`Calls_${invoice.invoiceNumber}.csv`, '', invoice.callsDownloadUrl));
        }
      }

      if (invoice.reportsDownloadUrl) {
        if (await invoicesFileApi.fileExists(invoice.reportsDownloadUrl, { injectClientId: false })) {
          files.push(new FalconFile(`Reports_${invoice.invoiceNumber}.pdf`, '', invoice.reportsDownloadUrl));
        }
      }
    }

    return files;
  }

  async getInvoiceDownload(downloadUrl: string) {
    return await invoicesFileApi.getFile<ArrayBuffer>(downloadUrl, 'There was an error downloading your invoice, please check back again soon');
  }

  async getDirectDebitMandateLink() {
    const customerTierId = userStore.currentUser?.currentClient?.customerTierId ?? '';

    // Provide the partner letter for wholesale (partner) customers:
    if (customerTierId === 8) {
      // TODO: user store constant for partner Id.
      return '/DD_mandate_Chess_Wholesale_Limited_SUN_445744.pdf';
    } else {
      return '/DD_mandate_Chess_ICT_Limited_SUN_442693.pdf';
    }
  }

  async DirectDebitLetterExists(sageId: string) {
    const clientId = userStore.currentUser?.currentClient?.clientId ?? '';
    const result = await directDebitFileApi.fileExists(`/DirectDebitLetter/${clientId}/${sageId}`);

    return result;
  }

  async getDirectDebitLetter(sageId: string) {
    const clientId = userStore.currentUser?.currentClient?.clientId ?? '';
    const result = await directDebitFileApi.getByteArrayPdf<ArrayBuffer>(
      `/DirectDebitLetter/${clientId}/${sageId}`,
      `There was an error getting this direct debit letter.`,
      true
    );

    if (result instanceof FalconError) {
    } else {
      const blob = new Blob([result], { type: 'application/pdf' });
      const blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    }

    return result;
  }

  async getModulusCheck(sortcode: string, accountNumber: string): Promise<ModulusCheck | FalconError> {
    const clientId = userStore.currentUser?.currentClient?.clientId ?? '';

    const result = await bankingApi.get<ModulusCheck>(`/ValidateUKBankAccount/${clientId}?SortCode=${sortcode}&AccountNumber=${accountNumber}`, {
      friendlyErrorMessage: `There was an error validating your sort code and account number.`
    });

    if (result instanceof FalconError) {
      return result as FalconError;
    }

    return result;
  }

  async getUKBranch(sortcode: string): Promise<string | null> {
    let response: string | null = null;
    const clientId = userStore.currentUser?.currentClient?.clientId ?? '';

    const result = await bankingApi.get<string>(`/GetUKBankBranch/${clientId}?SortCode=${sortcode}`, {
      friendlyErrorMessage: `There was an error checking your bank branch.`
    });

    if (result instanceof FalconError) {
    } else {
      response = result as string;
      const obj = JSON.parse(response);
      response = obj.UKBankBranch.BankName;
    }

    return response;
  }

  async GetUKBankBranches(bankName: string, postcode: string): Promise<Record<string, unknown> | null> {
    let branches: Record<string, unknown> | null = null;
    const clientId = userStore.currentUser?.currentClient?.clientId ?? '';

    const result = await bankingApi.get<Record<string, unknown>>(`/GetUKBankBranches/${clientId}?${bankName}&Postcode=${postcode}`, {
      friendlyErrorMessage: `There was an error checking your bank branches.`
    });

    if (result instanceof FalconError) {
    } else {
      branches = result;
    }

    return branches;
  }
}

export default new InvoicesStore();
