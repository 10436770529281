import { ChangeEvent } from 'react';
import { Grid } from '@progress/kendo-react-grid';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import Alert from 'components/Generic/Alert/Alert';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import Input from 'components/Generic/FormElements/Input/Input';
import { BaseServerGrid } from 'components/Generic/Grid/BaseServerGrid';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import Icon from 'components/Generic/Icon/icon';
import { Contact } from 'models/api/contact.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import { ModelCollection } from 'models/generic/modelCollection.model';
import { ContactsGridRequest } from 'models/requests/contactsGridRequest.model';
import accountStore from 'stores/account.store';
import { ReactComponent as EditSVGLogo } from 'assets/images/svg/edit.svg';
import appStyles from 'App.module.scss';
import styles from './Contacts.module.scss';

@observer
export class ContactsGrid extends BaseServerGrid<Contact, IBaseProps, ContactsGridRequest> {
  @observable searchInputValue = ''; // current search box value

  constructor(props: IBaseProps) {
    super(props);

    this.sort = [{ field: 'fullName', dir: 'asc' }];

    // set default request - sort order
    this.request = new ContactsGridRequest('fullName ASC', this.take);
  }

  async componentDidMount() {
    await this.loadData();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async componentDidUpdate(prevProps: IBaseProps) {
    // reload data if request was updated
    if (this.requestUpdated) {
      await this.loadData();
    }
  }

  getData(): Promise<ModelCollection<Contact>> {
    return accountStore.getContacts(this.request);
  }

  handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    // update value displayed in search box
    this.searchInputValue = e.target.value;
  };

  handleSearchFinishedTyping = async (value: string) => {
    // refresh data based on keyword and go to first page of results
    this.request.searchKeyword = value;
    this.request.pageNumber = 1;
    this.skip = 0;
    this.requestUpdated = true;
    await this.loadData();
  };

  goToNewContact = () => {
    this.props.history.push('/account/contacts/new');
  };

  goToEditContact = (contactId: number) => {
    this.props.history.push('/account/contacts/' + contactId);
  };

  render() {
    const contactsGrid = (
      <Grid
        data={this.data.items}
        filter={this.filter}
        filterable={false}
        pageable={{ pageSizes: this.pageSizes }}
        skip={this.skip}
        take={this.take}
        total={this.data.totalCount}
        onPageChange={this.pageChange}
        sortable
        sort={toJS(this.sort)}
        resizable
        onFilterChange={this.filterChange}
        onSortChange={this.sortChange}>
        {ColumnHelper.getGridColumns([
          { field: 'salutation', title: 'Title', dataType: 'text', size: 'xs', currentFilter: this.filter },
          { field: 'fullName', title: 'Full Name', dataType: 'text', size: 'md', currentFilter: this.filter },
          { field: 'email', title: 'Email', dataType: 'text', size: 'md', currentFilter: this.filter },
          { field: 'position', title: 'Position', dataType: 'text', size: 'md', currentFilter: this.filter },
          { field: 'loginAccountStatus', title: 'Account Status', dataType: 'text', size: 'sm', sortable: false, currentFilter: this.filter },
          { field: 'lastLogin', title: 'Last Login', dataType: 'date', size: 'sm', currentFilter: this.filter },
          {
            field: '',
            title: 'Edit',
            dataType: 'icon',
            size: 'xs',
            cell: (props) => (
              <td className="k-table-td gridColumn_xs gridColumn_button">
                <div role="button" onClick={() => this.goToEditContact(props.dataItem.contactId)} className={appStyles.button___grid_view}>
                  <EditSVGLogo title="Edit location breakdown" height={24} width={24} />
                </div>
              </td>
            )
          }
        ])}
      </Grid>
    );

    return (
      <>
        <div className={appStyles.container}>
          <div className={appStyles.pageHeading_lg}>
            <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
              <h1 className={appStyles.heading__text}>Manage Contacts</h1>
            </div>
            <div className={styles.search__container}>
              <Input
                elementId="searchBar"
                inputType="search"
                name="searchBar"
                labelText="Search Contacts"
                labelHidden={true}
                placeholder="Search Contacts"
                value={this.searchInputValue}
                handleChange={this.handleSearchChange}
                handleFinishedTyping={this.handleSearchFinishedTyping}
                customLabelClass={appStyles.form__label}
                customInputClass={appStyles.form__input}
                autocomplete="off"
              />
            </div>
          </div>
          <Alert alertType="blank">
            <p className={`${appStyles.info__main} ${appStyles.text_darkGrey}`}>Select a contact to edit information and see Customer Portal permissions.</p>
          </Alert>
          {contactsGrid}
          <GridPDFExport ref={(pdfExport) => (this.gridPDFExport = pdfExport)}>{contactsGrid}</GridPDFExport>
        </div>

        <BottomButtonContainer backgroundColor="white" layout="right">
          <Button id="btnAddContact" customClass={appStyles.button_hasIcon} buttonStyle="primary" size="lg" handleClick={this.goToNewContact}>
            <Icon icon={Icon.plus} customClass={appStyles.buttonWithIcon__icon} color="currentColor" size="sm" iconName="addIcon" />
            <span className={appStyles.buttonWithIcon__text}>Add New Contact</span>
          </Button>
        </BottomButtonContainer>
      </>
    );
  }
}

export default ContactsGrid;
