import { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import productsStore from 'stores/products.store';
import { FalconError } from 'models/generic/falconError.model';
import { ValidationError } from 'models/generic/validationError.model';
import Icon from 'components/Generic/Icon/icon';

@observer
export default class DevicesRedirect extends Component {
  @observable errors: ValidationError[] = [];
  promise = productsStore.getMercatoRedirectUrl();

  async componentDidMount() {
    // redirect 3s after loading component
    setTimeout(async () => {
      await this.redirect();
    }, 3000);
  }

  async redirect() {
    const result = await this.promise;
    if (result instanceof FalconError) {
      this.errors = result.validationMessages;
    } else {
      window.location.replace(result);
    }
  }

  render() {
    return (
      <div className={appStyles.container}>
        <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
          <Icon icon={Icon.laptop} size="heading" color="currentColor" iconName="user icon" customClass={appStyles.heading__icon} />
          <h1 className={appStyles.heading__text}>Please wait</h1>
        </div>

        <Alert alertType="blank" id="pleaseWaitAlert">
          <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>Please wait, you are being redirected to Device Solutions...</p>
        </Alert>

        <Alert alertType="error" hidden={!this.errors.length}>
          {this.errors.map((error) => error.messages.map((errorMessage) => <span>{errorMessage}</span>))}
        </Alert>
      </div>
    );
  }
}
