import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import Input from 'components/Generic/FormElements/Input/Input';
import Icon from 'components/Generic/Icon/icon';
import TicketsBreakdown from 'components/Tickets/TicketsBreakdown/TicketsBreakdown';
import TicketsGrid from 'components/Tickets/TicketsGrid/TicketsGrid';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Ticket } from 'models/api/ticket.model';
import { ValidationRule } from 'models/api/validationRule.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import { ListItem } from 'models/generic/listItem.model';
import React, { ChangeEvent, Component, RefObject } from 'react';
import accountStore from 'stores/account.store';
import locationsStore from 'stores/locations.store';
import ticketsStore from 'stores/tickets.store';
import { IMatchResult } from 'utils/routing';
import utils from 'utils/utils';
import styles from './ManageTickets.module.scss';

@observer
export class ManageTickets extends Component<IBaseProps> {
  @observable selectedTicket: Ticket | null = null;
  @observable searchInputValue = ''; // current search box value
  @observable searchKeyword = ''; // value passed into grid for search once user finished typing
  @observable updatedRequest = false;
  @observable breakdownRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

  contacts: ListItem[] = [];
  addresses: ListItem[] = [];
  validationRules: ValidationRule[] = [];
  subscriptionId = '';

  constructor(props: IBaseProps) {
    super(props);

    // monitor route changes
    this.subscriptionId = utils.routing.subscribe((location) => {
      const match = utils.routing.matchRoutes(location, ['/tickets/:id', '/tickets']);
      this.setUpView(match);
    });
  }

  async componentDidMount() {
    this.contacts = await accountStore.getContactsListItems();
    this.addresses = await locationsStore.getLocationsListItems();
    this.validationRules = await ticketsStore.getTicketValidationRules();

    this.setUpView(this.props.match);
  }

  componentWillUnmount() {
    utils.routing.unsubscribe(this.subscriptionId);
  }

  async setUpView(match: IMatchResult | null) {
    // get ticket ID from match params (query string)
    let ticketId: number | null = null;
    if (match && match.params) {
      ticketId = +match.params.id || null;
    }

    // if ID present then load ticket and scroll down to breakdown
    if (ticketId) {
      this.selectedTicket = await ticketsStore.getTicket(ticketId);

      if (this.breakdownRef.current) {
        window.scrollTo(0, this.breakdownRef.current.offsetTop);
      }
    } else {
      // otherwise show grid and scroll to top
      this.selectedTicket = null;
      window.scrollTo(0, 0);
    }
  }

  setRequest = (shouldUpdate: boolean = false) => {
    if (shouldUpdate) {
      this.updatedRequest = true;
    } else {
      this.updatedRequest = false;
    }
  };

  handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    // update value displayed in search box
    this.searchInputValue = e.target.value;
  };

  handleSearchFinishedTyping = (value: string) => {
    // pass value to grid for search once user stopped typing
    this.searchKeyword = value;
  };

  goToNewTicket = () => this.props.history.push('/tickets/new');

  render() {
    return (
      <>
        <div className={appStyles.container}>
          <div className={appStyles.pageHeading_lg}>
            <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
              <h1 className={appStyles.heading__text}>Tickets</h1>
            </div>

            {!this.selectedTicket && (
              <div className={styles.search__container}>
                <Input
                  elementId="searchBar"
                  inputType="search"
                  name="searchBar"
                  labelText="Search Tickets"
                  labelHidden={true}
                  placeholder="Search Tickets"
                  value={this.searchInputValue}
                  handleChange={this.handleSearchChange}
                  handleFinishedTyping={this.handleSearchFinishedTyping}
                  customLabelClass={appStyles.form__label}
                  customInputClass={appStyles.form__input}
                  autocomplete="off"
                />
              </div>
            )}
          </div>

          {!this.selectedTicket && (
            <Alert alertType="blank">
              <p className={`${appStyles.text_darkGrey} ${appStyles.info__main}`}>
                View your current logged tickets here. Click on an individual ticket to see the full breakdown.
              </p>
              <p className={`${appStyles.text_darkGrey} ${appStyles.info__main}`}>
                To view closed tickets, click on the three dots next to the Status column and adjust the filter.
              </p>
            </Alert>
          )}

          <div className={styles.ticketsGrid}>
            <TicketsGrid
              selectedTicket={this.selectedTicket}
              setRequest={this.setRequest}
              ticketUpdated={this.updatedRequest}
              searchKeyword={this.searchKeyword}
            />
          </div>
        </div>

        <div ref={this.breakdownRef}>
          {!!this.selectedTicket && (
            <TicketsBreakdown
              ticket={this.selectedTicket}
              contacts={this.contacts}
              addresses={this.addresses}
              setRequest={this.setRequest}
              validationRules={this.validationRules}
            />
          )}
        </div>

        {!this.selectedTicket && (
          <BottomButtonContainer backgroundColor="white" layout="right">
            <Button id="btnAddTicket" customClass={appStyles.button_hasIcon} buttonStyle="primary" size="lg" handleClick={this.goToNewTicket}>
              <Icon icon={Icon.plus} customClass={appStyles.buttonWithIcon__icon} color="currentColor" size="sm" iconName="addIcon" />
              <span className={appStyles.buttonWithIcon__text}>Log New Ticket</span>
            </Button>
          </BottomButtonContainer>
        )}
      </>
    );
  }
}
