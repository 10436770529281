import api from 'api/api';
import fileApi from 'api/fileApi';
import { License } from 'models/api/license.model';
import { Quote } from 'models/api/quote.model';
import { QuoteMessage } from 'models/api/quoteMessage.model';
import { Returns } from 'models/api/returns.model';
import { ValidationRule } from 'models/api/validationRule.model';
import { FalconError } from 'models/generic/falconError.model';
import { ICountableArray } from 'models/generic/iCountableArray';
import { ModelCollection } from 'models/generic/modelCollection.model';
import { QuotesGridRequest } from 'models/requests/quotesGridRequest.model';

class ProductsStore {
  // #region Licenses
  async getLicenses(): Promise<License[]> {
    let licenses: License[] = [];
    const result = await api.get<License[]>('/License/Index', {
      friendlyErrorMessage: `There was an error loading subscriptions.`
    });
    if (result instanceof FalconError) {
    } else {
      result.map((item) => {
        item.issueDate = new Date(item.issueDate);
        item.expiryDate = new Date(item.expiryDate);

        return item;
      });

      licenses = result;
    }

    return licenses;
  }

  async getLicenseDownloads(licenseId: number) {
    return await fileApi.getFilesList(`/License_${licenseId}`, 'There was an error getting subscription downloads');
  }

  async getLicenseDownload(licenseId: number, fileName: string) {
    return await fileApi.getFile<ArrayBuffer>(`/License_${licenseId}/${fileName}`, 'There was an error downloading subscription file');
  }
  // #endregion

  // #region Quotes
  async getQuoteQuestionValidationRules(): Promise<ValidationRule[]> {
    return api.getValidationRules('Quote/GetMessageValidationRules');
  }

  async getQuotes(request: QuotesGridRequest): Promise<ModelCollection<Quote>> {
    const quotes = new ModelCollection<Quote>();

    const qs = request.getRequestAsQueryString();

    const result = await api.get<Quote[]>('Quote/QuotesByCurrentClientId?' + qs, {
      friendlyErrorMessage: 'There was an error loading quotes.'
    });

    if (result instanceof FalconError) {
    } else {
      result.forEach((quote) => {
        if (quote.quoteDateTime) {
          quote.quoteDateTime = new Date(quote.quoteDateTime);
        }
        if (quote.validThrough) {
          quote.validThrough = new Date(quote.validThrough);
        }
        if (quote.linkedQuoteIds && quote.linkedQuoteIds.length > 0) {
          // sort by ID
          quote.linkedQuoteIds = quote.linkedQuoteIds.sort();

          // build HTML string with hyperlinks
          quote.linkedQuoteIds.forEach((quoteId, index) => {
            if (index === 0) {
              quote.linkedQuotesLabel = '';
            } else {
              quote.linkedQuotesLabel += ', ';
            }

            quote.linkedQuotesLabel += `<a href="/products/quotes/${quoteId}" target="_blank" title="Click to open this quote in a new tab">${quoteId}</a>`;
          });
        }
      });

      quotes.items = result;
      quotes.totalCount = (result as unknown as ICountableArray).totalCount;
    }

    return quotes;
  }

  async getQuote(quoteId: number): Promise<Quote | null> {
    let quote: Quote | null = null;

    const result = await api.get<Quote>('Quote/QuoteById?quoteId=' + quoteId, {
      friendlyErrorMessage: 'There was an error loading quote.'
    });

    if (result instanceof FalconError) {
    } else {
      quote = result;

      if (quote) {
        if (quote.quoteDateTime) {
          quote.quoteDateTime = new Date(quote.quoteDateTime);
        }
        if (quote.validThrough) {
          quote.validThrough = new Date(quote.validThrough);
        }

        if (quote.items && quote.items.length > 0) {
          quote.items.forEach((item) => {
            // converting boolean to Yes or empty string for better UX
            item.v1 = (item.v1 as unknown as boolean) === true ? 'Yes' : '';
            item.v2 = (item.v2 as unknown as boolean) === true ? 'Yes' : '';
            item.v3 = (item.v3 as unknown as boolean) === true ? 'Yes' : '';
            item.v4 = (item.v4 as unknown as boolean) === true ? 'Yes' : '';
            item.v5 = (item.v5 as unknown as boolean) === true ? 'Yes' : '';
          });
        }
      }
    }

    return quote;
  }

  async postQuoteQuestion(message: QuoteMessage): Promise<boolean | FalconError> {
    const result = await api.post<boolean>('Quote/PostQuoteQuestion', message, 'There was an error sending your message.');

    return result;
  }

  async getQuoteDownloads(quoteId: number) {
    return await fileApi.getFilesList(`/Quote_${quoteId}`, 'There was an error getting quote downloads');
  }

  async getQuoteDownload(quoteId: number, fileName: string) {
    return await fileApi.getFile<ArrayBuffer>(`/Quote_${quoteId}/${fileName}`, 'There was an error downloading quote file');
  }
  // #endregion

  // #region Returns
  async getReturns(): Promise<Returns[]> {
    let returns: Returns[] = [];

    const result = await api.get<Returns[]>('/Returns', {
      friendlyErrorMessage: 'There was an error loading returns.'
    });
    if (result instanceof FalconError) {
    } else {
      result.forEach((r) => {
        if (r.origDateRecvd) {
          r.origDateRecvd = new Date(r.origDateRecvd);
        }
      });

      returns = result;
    }

    return returns;
  }

  async getReturnsDownloads(returnsId: number) {
    return await fileApi.getFilesList(`/Return_${returnsId}`, 'There was an error getting return downloads');
  }

  async getReturnsDownload(returnsId: number, fileName: string) {
    return await fileApi.getFile<ArrayBuffer>(`/Return_${returnsId}/${fileName}`, 'There was an error downloading return file');
  }
  // #endregion

  async getMercatoRedirectUrl(): Promise<string | FalconError> {
    return await api.get<string>('Order/GetMercatoRedirectUrl', {
      friendlyErrorMessage: 'There was an error getting the Mercato redirect URL',
      silent: true,
      decodeResponse: false
    });
  }
}

const productsStore = new ProductsStore();
export default productsStore;
