import { ChangeEvent, ReactNode } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Prompt, withRouter } from 'react-router-dom';
import Alert from 'components/Generic/Alert/Alert';
import { BaseUnsavedChangesComponent } from 'components/Generic/BaseUnsavedChangesComponent';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import azureUpload from 'components/Generic/FormElements/FileUpload/AzureUpload';
import FileMultiUpload from 'components/Generic/FormElements/FileUpload/FileMultiUpload';
import Input from 'components/Generic/FormElements/Input/Input';
import Legend from 'components/Generic/FormElements/Legend/Legend';
import Select from 'components/Generic/FormElements/Select/Select';
import TextArea from 'components/Generic/FormElements/TextArea/TextArea';
import Icon from 'components/Generic/Icon/icon';
import { newTicketCategories, newTicketServices } from 'components/Tickets/NewTicket/NewTicketService/services';
import { Ticket } from 'models/api/ticket.model';
import { ValidationRule } from 'models/api/validationRule.model';
import { FalconError } from 'models/generic/falconError.model';
import { FalconFile } from 'models/generic/falconFile.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import { ListItem } from 'models/generic/listItem.model';
import { ValidationErrors } from 'models/generic/validationError.model';
import ticketsStore from 'stores/tickets.store';
import validation from 'utils/validation';
import preloader from 'assets/images/preloader.gif';
import appStyles from 'App.module.scss';
import styles from '../NewTicket.module.scss';

interface INewTicketFormProps extends IBaseProps {
  category: string;
  serviceId: string;
  secondaryService: string;
  tertiaryService: string;
  backButton: ReactNode;
  contacts: ListItem[];
  addresses: ListItem[];
  parentNotifyUnsavedData: (childHasUnsavedChanges: boolean) => void;
}

@observer
class NewTicketForm extends BaseUnsavedChangesComponent<INewTicketFormProps> {
  @observable ticket = new Ticket();
  @observable formValid = true;
  @observable ticketErrors: ValidationErrors = new ValidationErrors();
  @observable files: FalconFile[] = [];
  @observable ticketLoading = true;
  @observable isCloudOrDevice = false;
  @observable isAccountAndBilling = false;
  @observable isDevice = false;
  @observable saveInProgress = false;
  @observable filesToUpload: File[] = [];

  category = newTicketCategories.filter((s) => s && s.id === this.props.category)[0] || null;
  service = newTicketServices.filter((s) => s && s.id === this.props.serviceId)[0] || null;
  secondaryService = newTicketServices.filter((s) => s && s.id === this.props.secondaryService)[0] || null;
  tertiaryService = newTicketServices.filter((s) => s && s.id === this.props.tertiaryService)[0] || null;

  contracts: ListItem[] = [];
  devices: ListItem[] = [];

  validationRules: ValidationRule[] = [];
  submitAttempted = false;

  async componentDidMount() {
    window.scrollTo(0, 0);

    const result = await ticketsStore.getCreateNewTicket();

    if (result instanceof FalconError) {
      return;
    } else {
      this.ticket = result;
      if (this.ticket.contractList) {
        this.ticket.contractList.forEach((contract) => {
          if (contract.contractId) {
            this.contracts.push(new ListItem(contract.contractId, contract.formattedContractDetails as string));
          }
        });
        if (this.ticket.contractList.length === 1) {
          this.ticket.contractCover = this.ticket.contractList[0].contractId;
        }
      }

      if (this.ticket.deviceList) {
        this.ticket.deviceList.forEach((device) => {
          if (device.itemId) {
            this.devices.push(new ListItem(device.itemId, device.formattedDeviceDetails as string));
          }
        });
      }
    }

    this.isCloudOrDevice = (this.service !== null && this.service.id === 'Cloud') || (this.service !== null && this.service.id === 'Devices');
    this.isAccountAndBilling = this.category !== null && this.category.id === 'Account-Billing';
    this.isDevice = this.service !== null && this.service.id === 'Devices';
    this.validationRules = await ticketsStore.getTicketValidationRules();

    this.ticketLoading = false;
  }

  handleInput = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    this.ticket = {
      ...this.ticket,
      [name]: value
    };

    this.unsavedChange = true;
    this.props.parentNotifyUnsavedData(true); // Make parent component aware of unsaved state.

    if (this.submitAttempted) {
      this.validate();
    }
  };

  handleInputAsNumber = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    let value = null;
    if (e.target.value) {
      value = +e.target.value;
    }
    const name = e.target.name;

    this.ticket = {
      ...this.ticket,
      [name]: value
    };

    this.unsavedChange = true;
    this.props.parentNotifyUnsavedData(true); // Make parent component aware of unsaved state.

    if (this.submitAttempted) {
      this.validate();
    }
  };

  handleFile = (file: FalconFile, clear: boolean = false) => {
    if (clear) {
      this.files = this.files.filter((f) => f.name !== file.name);

      return;
    }

    if (this.files.length > 2) {
      return;
    }

    if (this.files.filter((f) => f.name === file.name).length === 0) {
      this.files = [...this.files, file];
    }

    this.unsavedChange = true;
    this.props.parentNotifyUnsavedData(true); // Make parent component aware of unsaved state.

    if (this.submitAttempted) {
      this.validate();
    }
  };

  validate() {
    // reset state
    this.formValid = true;
    this.ticketErrors = new ValidationErrors();
    this.formValid = validation.validate(this.ticket, this.validationRules, this.ticketErrors);

    return this.formValid;
  }

  setFilesToUpload = (files: File[]) => {
    this.filesToUpload = files;
  };

  async fileUpload(supportTicketId: number) {
    if (this.filesToUpload.length === 0 || supportTicketId === -1) {
      return;
    }

    await azureUpload.fileUpload(this.filesToUpload, supportTicketId, 'Support');
  }

  async sendTicket() {
    this.saveInProgress = true;
    this.submitAttempted = true;

    if (this.isAccountAndBilling) {
      this.ticket.contractCover = 1;
    }

    const fileNames: string[] = [];
    this.filesToUpload.forEach((f) => {
      fileNames.push(f.name);
    });
    this.ticket.attachedFileNames = fileNames;

    this.validate();

    let result;
    if (this.formValid) {
      result = await ticketsStore.createNewTicket(this.ticket);
      // if result is error than check for validation messages
      if (result instanceof FalconError) {
        this.ticketErrors = validation.getValidationErrorsFromFalconError(result);
      } else {
        await this.fileUpload(result);

        this.unsavedChange = false;

        this.props.history.push('/tickets/confirmation');
      }
    }

    this.saveInProgress = false;
  }

  public render() {
    const breadCrumbItem = (text: string) => (
      <>
        <p className={styles.breadcrumb__text}>{text}</p>
        <Icon customClass={styles.breadcrumb__icon} icon={Icon.chevronRight} iconName="breadcrumb arrow" size="xxs" color="currentColor" />
      </>
    );

    return (
      <>
        <Prompt
          when={this.unsavedChange && BaseUnsavedChangesComponent.enableCheck}
          message="You have unsaved changes. Are you sure you want to navigate away from this page?"
        />

        <div className={`${appStyles.container}`}>
          <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
            <Icon icon={Icon.tickets} size="heading" color="currentColor" iconName="invoice icon" customClass={appStyles.heading__icon} />
            <h1 className={appStyles.heading__text}>New Customer Service Ticket</h1>
          </div>
          <Alert alertType="blank">
            <p className={`${appStyles.text_midBlue} ${appStyles.info__main}`}>
              Please fill in the details below and click <i>Send Ticket</i>
            </p>
          </Alert>
          <div className={`${appStyles.text_midBlue} ${styles.breadcrumb}`}>
            {this.category !== null && breadCrumbItem(this.category.headingText)}
            {this.service !== null && breadCrumbItem(this.service.headingText)}
            {this.secondaryService !== null && breadCrumbItem(this.secondaryService.headingText)}
            {this.tertiaryService !== null && breadCrumbItem(this.tertiaryService.headingText)}
            <p className={`${styles.breadcrumb__text} ${styles.breadcrumb__text_bold}`}>Add Details</p>
          </div>
          {!this.ticketLoading ? (
            <form className={appStyles.row}>
              <div id="ticketDetailsCard" className={appStyles.col_md_6}>
                {!this.isAccountAndBilling && (
                  <fieldset>
                    <Legend type="large" text="Ticket Details" />
                    <div className={appStyles.form__row}>
                      <Select
                        elementId="contractCover"
                        labelText="Contract"
                        name="contractCover"
                        options={this.contracts}
                        placeholder="Please choose..."
                        disablePlaceholder={true}
                        handleChange={this.handleInputAsNumber}
                        value={this.ticket.contractCover}
                        validationError={this.ticketErrors.fieldErrors['contractCover']}
                      />
                    </div>
                    <div className={appStyles.form__row}>
                      <Input
                        elementId="affectedUsers"
                        inputType="number"
                        labelText="Number of Users Affected"
                        name="affectedUsers"
                        placeholder="Enter how many users are affected"
                        handleChange={this.handleInputAsNumber}
                        value={this.ticket.affectedUsers}
                        minValue="0"
                        validationError={this.ticketErrors.fieldErrors['affectedUsers']}
                      />
                    </div>
                    {this.isCloudOrDevice && (
                      <div className={appStyles.form__row}>
                        <Input
                          elementId="errorcodes"
                          inputType="text"
                          labelText="Error Code (if applicable)"
                          name="errorcodes"
                          placeholder="Enter an error code"
                          handleChange={this.handleInput}
                          value={this.ticket.errorcodes}
                          validationError={this.ticketErrors.fieldErrors['errorcodes']}
                        />
                      </div>
                    )}

                    {this.isDevice && (
                      <div className={appStyles.form__row}>
                        <Select
                          elementId="contractItemId"
                          labelText="Related Device"
                          name="contractItemId"
                          options={this.devices}
                          placeholder="Please choose..."
                          disablePlaceholder={true}
                          handleChange={this.handleInputAsNumber}
                          value={this.ticket.contractItemId}
                        />
                      </div>
                    )}
                  </fieldset>
                )}
                <fieldset>
                  <Legend type="large" text="Contact Details" />
                  <div className={appStyles.form__row}>
                    <Select
                      elementId="contactId"
                      labelText="Contact"
                      name="contactId"
                      options={this.props.contacts}
                      placeholder="Please choose..."
                      disablePlaceholder={true}
                      handleChange={this.handleInputAsNumber}
                      value={this.ticket.contactId}
                      validationError={this.ticketErrors.fieldErrors['contactId']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <Select
                      elementId="addressId"
                      labelText="Address"
                      name="addressId"
                      options={this.props.addresses}
                      placeholder="Please choose..."
                      disablePlaceholder={true}
                      handleChange={this.handleInputAsNumber}
                      value={this.ticket.addressId}
                    />
                  </div>
                </fieldset>
              </div>
              <div id="messageCard" className={appStyles.col_md_6}>
                <fieldset>
                  <Legend type="large" text="Ticket Summary" />
                  <div className={appStyles.form__row}>
                    <Input
                      elementId="subject"
                      inputType="text"
                      labelText="Summary"
                      name="subject"
                      placeholder="Please tell us what the problem is"
                      handleChange={this.handleInput}
                      value={this.ticket.subject}
                      validationError={this.ticketErrors.fieldErrors['subject']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <TextArea
                      elementId="message"
                      labelText="Details"
                      name="message"
                      placeholder="Please tell us about the problem in as much detail as you can"
                      handleChange={this.handleInput}
                      value={this.ticket.message}
                      rows={10}
                      validationError={this.ticketErrors.fieldErrors['message']}
                    />
                  </div>
                  <div className={appStyles.form__row}>
                    <FileMultiUpload files={this.filesToUpload} passResults={this.setFilesToUpload} itemId={this.ticket.supportTicketId} />
                  </div>
                </fieldset>
              </div>
            </form>
          ) : (
            <div>
              <img alt="Loading..." className={appStyles.preloader} src={preloader} />
            </div>
          )}
        </div>
        <BottomButtonContainer backgroundColor="white" layout={'spaceBetween'}>
          {this.props.backButton}

          <Button
            id="sendTicketButton"
            customClass={appStyles.button_hasIcon}
            buttonStyle="primary"
            size="lg"
            handleClick={() => this.sendTicket()}
            inProgress={this.saveInProgress}>
            <Icon icon={Icon.check} customClass={appStyles.buttonWithIcon__icon} color="currentColor" size="sm" iconName="addIcon" />
            <span className={appStyles.buttonWithIcon__text}>Send Ticket</span>
          </Button>
        </BottomButtonContainer>
      </>
    );
  }
}

export default withRouter(NewTicketForm);
