import cookie from 'react-cookies';
import { browserHistory } from 'App';
import { AccountInfo, AuthenticationResult, SilentRequest } from '@azure/msal-browser';
import { action, observable } from 'mobx';
import api, { Api } from 'api/api';
import { AccountDetails } from 'models/api/accountDetails.model';
import { Client } from 'models/api/client.model';
import { Contact } from 'models/api/contact.model';
import { FalconError } from 'models/generic/falconError.model';
import Icon from 'components/Generic/Icon/icon';
import { User } from 'models/generic/user.model';
import messagesStore from 'stores/messages.store';
import navigationStore from 'stores/navigation.store';
import auth from 'utils/auth';
import logging from 'utils/logging';
import { errorMessages } from 'utils/messages';
import utils from 'utils/utils';
import notificationsStore from './notifications.store';

class UserStore {
  @observable _currentUser: User | null = null;

  private clientsKey = 'chess.falcon.clients';
  private clientKey = 'chess.falcon.client';
  private accountDetailsKey = 'chess.falcon.details.';

  constructor() {
    auth.registerRedirectHandlers(this.onAuthenticationSuccess, this.onAuthenticationFailed);
  }

  get userSelectedClientId() {
    return this.currentUser?.currentClient?.clientId ?? '';
  }

  get currentUser(): User | null {
    if (this._currentUser === null) {
      // load user from cache if present
      try {
        // TODO: add more workaround for infinity calling loop

        this.loadCachedUser();
      } catch (error) {
        logging.error('unexpected error during getting currentUser', error);
      }
    }

    return this._currentUser;
  }

  set currentUser(user: User | null) {
    this._currentUser = user;
  }

  @action logIn(account: AccountInfo) {
    if (!auth.areCustomClaimsValid(account)) {
      throw new Error(errorMessages.portalUnavailable);
    }

    const user = User.fromAccount(account);

    // in case of renewing the token preserve clients list, selected client, primary address and sage IDs
    if (user.id && this._currentUser?.id === user.id) {
      user.currentClient = this._currentUser.currentClient;
      user.clients = this._currentUser.clients;
      user.primaryAddress = this._currentUser.primaryAddress;
      user.sageIds = this._currentUser.sageIds;
    }

    this.currentUser = user;

    auth.setActiveAccount(account);

    notificationsStore.connect();
    notificationsStore.updateHandleLineFaultCheck(notificationsStore.lineFaultCheckHandler);

    navigationStore.loadNavigation();
  }

  invalidateSessions = async () => {
    // need to replace base url for API instance on local development because /logout exists only in the API Management service
    let instance = api;

    if (utils.environment.isDevelopment) {
      instance = new Api(process.env.REACT_APP_FALLBACK_API_BASE_URL);
    }

    const result = await instance.post('/logout', null, 'Something went wrong during logout session');

    if (result instanceof FalconError) {
      logging.error('Logout endpoint returned an error:', { error: result });
    }
  };

  logOut = async ({ logOutFromNoAccess = false, noAccessMode = 'hasNoRoles', invalidateSessions = true } = {}) => {
    notificationsStore.disconnect();

    // manually remove any msal and chess cookies
    const cookies = cookie.loadAll();
    const names = Object.keys(cookies);

    if (invalidateSessions) {
      // call API endpoint to invalidate all refresh tokens for the current user
      await this.invalidateSessions();
    }
    // when user is logged out due to lack of access then store a value in session to allow a redirect from logout page after log out is complete
    if (logOutFromNoAccess) {
      utils.session.saveToSession('noAccess', true);
      utils.session.saveToSession('noAccessMode', noAccessMode);
    }

    auth.logoutRedirect({ logOutFromNoAccess, noAccessMode });

    // possible we should clean cookies after all MSAL requests
    names.forEach((cookieName) => {
      if (cookieName.startsWith('msal') || cookieName.startsWith('chess') || cookieName.startsWith('debug') || cookieName.startsWith('ai')) {
        cookie.remove(cookieName);
      }
    });
  };

  @action private loadCachedUser = () => {
    const account = auth.getActiveAccount();

    if (account) {
      // load user from cache only if there is valid token
      this.logIn(account);

      // also load clients and selected client
      if (this._currentUser) {
        this._currentUser.clients = utils.localStorage.getFromLocalStorage<Client[]>(this.clientsKey) || [];
        this._currentUser.currentClient = utils.localStorage.getFromLocalStorage<Client>(this.clientKey);

        if (this._currentUser.currentClient) {
          this.setSelectedClient(this._currentUser.currentClient);
        }

        // reload navigation
        navigationStore.loadNavigation();
      }

      if (this.currentUser?.currentClient?.clientId) {
        notificationsStore.getMyLatestNotifications();
      }
    }
  };

  handleAuthenticationResult = (result: AuthenticationResult | null) => {
    if (result?.idToken && result.expiresOn && result.account) {
      auth.setActiveAccount(result.account);
    } else {
      logging.error('no token returned', { result });
    }
  };

  getNewToken = async () => {
    const refreshRequest: Partial<SilentRequest> = {
      forceRefresh: true
    };

    // get new token silently
    try {
      const result = await auth.acquireTokenSilent(refreshRequest);

      this.handleAuthenticationResult(result);
    } catch (error) {
      try {
        // if failed then retry
        logging.error('error in acquireTokenSilent', error);
        const result = await auth.acquireTokenSilent(refreshRequest);

        this.handleAuthenticationResult(result);
      } catch (error) {
        try {
          // if failed again then try popup
          logging.error('error in acquireTokenSilent retry', error);
          const result = await auth.acquireTokenPopup(refreshRequest);

          this.handleAuthenticationResult(result);
        } catch (error) {
          // if popup failed then log out
          logging.error('error in acquireTokenPopup', error);

          messagesStore.addErrorAsString(errorMessages.tokenCannotBeRenewed);
        }
      }
    }
  };

  @action private onAuthenticationFailed = (error: unknown) => {
    logging.error('onAuthenticationFailed error', error);
  };

  @action private onAuthenticationSuccess = (response: AuthenticationResult | null) => {
    if (response) {
      this.handleAuthenticationResult(response);

      if (response.account) {
        this.logIn(response.account);
      }
    }
  };

  @action async setSelectedClient(client: Client) {
    // set selected client for current user
    if (this.currentUser) {
      utils.localStorage.saveToLocalStorage(this.clientKey, client);
      this.currentUser.currentClient = client;

      // also load additional account details and set Sage IDs for current client
      if (client.clientId) {
        const details = await this.getAccountDetails(client.clientId);
        this.currentUser.primaryAddress = details.address;
        this.currentUser.sageIds = this.getSageIds(client.clientId, details.myAccounts);
      }
    }

    navigationStore.loadNavigation();
  }

  async getAccountDetails(clientId: number): Promise<AccountDetails> {
    // check if account details in session
    let accountDetails = utils.localStorage.getFromLocalStorage<AccountDetails>(this.accountDetailsKey + clientId);

    if (!accountDetails) {
      // load from API if not in session
      accountDetails = new AccountDetails();

      const result = await api.get<AccountDetails>('/Client/GetSummaryDetails', {
        friendlyErrorMessage: `There was an error loading client details.`
      });

      if (result instanceof FalconError) {
      } else {
        // save to session
        accountDetails = result;
        utils.localStorage.saveToLocalStorage(this.accountDetailsKey + clientId, result);
      }
    }

    return accountDetails;
  }

  getSageIds(selectedClientId: number | null, myAccounts: string[] = []) {
    const sageIds = [];

    for (const entry of myAccounts) {
      const eachAccount = entry.split(':');
      if (Number(eachAccount[0]) === selectedClientId) {
        const accountNumbers = eachAccount[1].split('/');
        sageIds.push(accountNumbers[1]);
      }
    }

    return sageIds;
  }

  @action async loadClients() {
    if (this.currentUser) {
      const result = await api.get<Client[]>('/Client/MyAccounts');
      if (result instanceof FalconError) {
        const message = result.errorMessages.includes('Network Error') ? errorMessages.portalUnavailable : errorMessages.accountsCannotBeLoaded;
        browserHistory.push('/error', {
          mainMessage: message,
          subMessages: ["It looks like we couldn't load your client details, the site may be temporarily down, please try again later."],
          containerId: 'error_loading_clients',
          icon: Icon.error,
          iconText: 'Error',
          showLogout: true
        });
      } else if (!result || !result.length) {
        browserHistory.push('/error', {
          mainMessage: errorMessages.accountsNotFound,
          subMessages: ["It looks like we couldn't load your client details, the site may be temporarily down, please try again later."],
          containerId: 'error_loading_clients',
          icon: Icon.error,
          iconText: 'Error',
          showLogout: true
        });
      } else {
        this.currentUser.clients = result;

        utils.localStorage.saveToLocalStorage(this.clientsKey, result);

        // if only one returned then set as selected
        if (result.length === 1) {
          this.setSelectedClient(result[0]);
        }
      }

      // reload navigation
      navigationStore.loadNavigation();
    }
  }

  async testLoadData() {
    const result = await api.post<Contact>('/Contact/Create', {}, `There was an error.`);
    if (result instanceof FalconError) {
      // handle or return error
    } else {
    }
  }
}

export default new UserStore();
