export enum AddressCategory {
  Gold = 'GOLD',
  Silver = 'SILVER',
  Bronze = 'BRONZE'
}

export enum CareLevel {
  Low = 'LOW',
  Medium = 'MED',
  High = 'HIGH',
  ExtraHigh = 'EXTRA_HIGH'
}

export enum LogEventCategories {
  FttpAddressSearchStarted = 'FTTP Address Search Started',
  FttpAddressSearchCompleted = 'FTTP Address Search Completed',
  FttpProductSearchStarted = 'FTTP Product Search Started',
  FttpProductSearchCompleted = 'FTTP Product Search Completed',
  FttpLeadCreated = 'FTTP Lead Created'
}

export enum LogEventResultStatuses {
  Ok = 'OK',
  Warning = 'Warning',
  Error = 'Error'
}

export enum NotificationStatuses {
  Unread,
  Read,
  Archived
}

export enum RequestCallbackScenario {
  FTTPServiceCanBeProvided = 'FTTPServiceCanBeProvided',
  FTTPServiceCantBeProvided = 'FTTPServiceCantBeProvided',
  AddressCantBeLocatedFromGivenPostcode = 'AddressCantBeLocatedFromGivenPostcode'
}

export enum UserTypes {
  Customer = 'customer',
  Employee = 'employee'
}
