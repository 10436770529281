import appStyles from 'App.module.scss';
import classNames from 'classnames';
import ValidationArea from 'components/Generic/FormElements/ValidationArea/ValidationArea';
import { ValidationError } from 'models/generic/validationError.model';
import { ChangeEvent, Component } from 'react';

interface ITextAreaProps {
  /**
   * @property {string} elementId Set the id attribute
   */
  elementId: string;
  /**
   * @property {string} labelText Set the name attribute
   */
  labelText: string;
  /**
   * @property {string} name Set the name attribute
   */
  name: string;
  /**
   *@property {string} resize Set the type of the textarea resize - none, vertical, horizontal, both
   */
  resize?: 'none' | 'vertical' | 'horizontal' | 'both';
  /**
   * @property {number} rows Set the rows attribute
   */
  rows?: number;
  /**
   * @property {number} cols Set the cols attribute
   */
  cols?: number;
  /**
   * @property {string} value Set the value attribute
   */
  value: string | null;
  /**
   * @property {string} placeholder Set the placeholder attribute
   */
  placeholder: string;
  /**
   *@property {(event: ChangeEvent<HTMLInputElement>) => void} handleChange Set the action onChange
   */
  handleChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  /**
   * @property {string} customTextAreaClass Optional. Use a custom class on the textarea element - use styles from the current component or appStyles
   */
  customTextAreaClass?: string;
  /**
   * @property {string} customLabelClass Optional. Use a custom class on the label - use styles from the current component or appStyles
   */
  customLabelClass?: string;
  /**
   * @property {ValidationError} validationError Optional. Any validation messages related to this field
   */
  validationError?: ValidationError;
}
/**
 * @description Add a textarea element with a label. Optional properties: resize, rows, cols, customTextAreaClass, customLabelClass
 */
class TextArea extends Component<ITextAreaProps> {
  render() {
    return (
      <>
        <label
          htmlFor={this.props.name}
          className={classNames(
            appStyles.form__label,
            { [appStyles.form__label_invalid]: this.props.validationError && this.props.validationError.messages.length > 0 }, // add invalid class if errors
            { [this.props.customLabelClass as string]: this.props.customLabelClass } // add custom class if defined
          )}>
          {this.props.labelText}
        </label>
        <textarea
          className={classNames(
            appStyles.form__input,
            appStyles.form__input_textarea,
            { [appStyles[`resize_${this.props.resize}`]]: this.props.resize }, // add resize if specified
            { [appStyles.resizeVertical]: !this.props.resize }, // add resizeVertical if resize not specified
            { [appStyles.form__input_invalid]: this.props.validationError && this.props.validationError.messages.length > 0 }, // add invalid class if errors
            { [this.props.customTextAreaClass as string]: this.props.customTextAreaClass } // add custom class if defined
          )}
          id={this.props.elementId}
          name={this.props.name}
          rows={this.props.rows}
          cols={this.props.cols}
          value={this.props.value || ''}
          placeholder={this.props.placeholder}
          onChange={this.props.handleChange}
        />

        <ValidationArea sectionId={this.props.elementId} validationError={this.props.validationError}></ValidationArea>
      </>
    );
  }
}

export default TextArea;
