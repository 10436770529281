export class Article {
  title: string;
  url: string;
  date: string;
  timeToRead: number;

  constructor(title: string, url: string, date: string, timeToRead: number) {
    this.title = title;
    this.url = url;
    this.date = date;
    this.timeToRead = timeToRead;
  }
}
