import { FC, useEffect, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { IBaseProps } from 'models/generic/iBaseProps';
import { AuthRoute, IAuthGuardProps } from 'routing/AuthRoute';
import lineFaultsStore from 'stores/lineFaults.store';

interface ILineCheckResultsRouteProps extends IAuthGuardProps {
  redirectPath: string;
  component: React.ComponentType<IBaseProps>;
}

interface IAnotherComponentProps extends IBaseProps {
  lineFaultId: string;
  component: React.ComponentType<IBaseProps>;
}

const LineCheckResult: FC<IAnotherComponentProps> = ({ lineFaultId, component: Component, ...rest }) => {
  const id = lineFaultsStore.lineFaultCheck?.id;
  const pending = lineFaultsStore.pending;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    if (!pending && id !== lineFaultId) {
      lineFaultsStore.fetchLineCheck(lineFaultId);
    }
  }, [id, pending, lineFaultId, setMounted]);

  return !mounted || pending || !lineFaultsStore.lineFaultCheck?.troubleReportReference ? (
    <Component {...rest} />
  ) : (
    <Redirect to={{ pathname: `/tickets/${lineFaultsStore.lineFaultCheck?.supportTicketId}` }} />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LineCheckResultsRoute: FC<ILineCheckResultsRouteProps> = ({ redirectPath, component, ...rest }) => {
  const routeComponent: FC<IBaseProps & RouteComponentProps<{ id?: string }>> = (props: IBaseProps & RouteComponentProps<{ id?: string }>) => {
    const lineFaultId = props.match?.params?.id;

    return <LineCheckResult lineFaultId={lineFaultId} component={component} {...props} />;
  };

  return <AuthRoute {...rest} component={routeComponent} />;
};

export default LineCheckResultsRoute;
