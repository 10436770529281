import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import Input from 'components/Generic/FormElements/Input/Input';
import { LicensesBreakdown } from 'components/Products/Licenses/LicensesBreakdown/LicensesBreakdown';
import LicensesGrid from 'components/Products/Licenses/LicensesGrid/LicensesGrid';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { License } from 'models/api/license.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import React, { ChangeEvent, Component, RefObject } from 'react';
import { IMatchResult } from 'utils/routing';
import utils from 'utils/utils';
import styles from './ManageLicenses.module.scss';

@observer
export class Licenses extends Component<IBaseProps> {
  @observable breakdownRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  @observable selectedLicense: License | null = null;
  @observable searchInput = '';
  subscriptionId = '';

  constructor(props: IBaseProps) {
    super(props);

    this.setSelectedLicense = this.setSelectedLicense.bind(this);

    // monitor route changes
    this.subscriptionId = utils.routing.subscribe((location) => {
      const match = utils.routing.matchRoutes(location, ['/products/subscriptions/:id', '/products/subscriptions']);
      this.setUpView(match);
    });
  }

  componentDidMount() {
    this.setUpView(this.props.match);
  }

  componentWillUnmount() {
    utils.routing.unsubscribe(this.subscriptionId);
  }

  setUpView(match: IMatchResult | null) {
    // if ID present then scroll down to breakdown
    if (match && match.params.id && this.breakdownRef.current) {
      window.scrollTo(0, this.breakdownRef.current.offsetTop);
    } else {
      // otherwise scroll to top
      window.scrollTo(0, 0);
    }
  }

  handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    this.searchInput = e.target.value;
  };

  setSelectedLicense(value: License | null) {
    this.selectedLicense = value;
  }

  render() {
    return (
      <>
        <div className={appStyles.container}>
          <div className={appStyles.pageHeading_lg}>
            <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
              <h1 className={appStyles.heading__text}>Subscriptions</h1>
            </div>

            {!this.selectedLicense && (
              <div className={styles.search__container}>
                <Input
                  elementId="searchBar"
                  inputType="search"
                  name="searchBar"
                  labelText="Search Subscriptions"
                  labelHidden={true}
                  placeholder="Search Subscriptions"
                  value={this.searchInput}
                  handleChange={this.handleSearch}
                  customLabelClass={appStyles.form__label}
                  customInputClass={appStyles.form__input}
                  autocomplete="off"
                />
              </div>
            )}
          </div>

          {!this.selectedLicense && (
            <Alert alertType="blank">
              <p className={`${appStyles.text_darkGrey} ${appStyles.info__main}`}>
                You can view all your subscriptions below, select a subscription to see a full breakdown.
              </p>
            </Alert>
          )}

          <div className={styles.licensesGrid}>
            <LicensesGrid search={this.searchInput} setSelectedLicense={this.setSelectedLicense} />
          </div>
        </div>

        <div ref={this.breakdownRef}>{this.selectedLicense ? <LicensesBreakdown license={this.selectedLicense} /> : ''}</div>
      </>
    );
  }
}
