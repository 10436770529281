import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { IBaseProps } from 'models/generic/iBaseProps';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import invoicesStore from 'stores/invoices.store';
import styles from '../Invoices/ManageInvoices.module.scss';
import OutstandingInvoicesGrid from '../OutstandingInvoicesGrid/OutstandingInvoicesGrid';

@observer
export class MakeAPayment extends Component<IBaseProps> {
  @observable selectedInvoices: string[] | null = null;
  @observable disableButton = true;

  constructor(props: IBaseProps) {
    super(props);

    this.setSelectedInvoices = this.setSelectedInvoices.bind(this);
    this.proceed = this.proceed.bind(this);
  }

  setSelectedInvoices(value: string[] | null) {
    this.selectedInvoices = value;

    invoicesStore.selectedInvoices = this.selectedInvoices;

    if (this.selectedInvoices && this.selectedInvoices.length > 0) {
      this.disableButton = false;
    } else {
      this.disableButton = true;
    }
  }

  proceed() {
    if (this.selectedInvoices && this.selectedInvoices.length > 0) {
      this.props.history.push('/invoices/payment-summary');
    } else {
      this.disableButton = true;
    }
  }

  render() {
    return (
      <>
        <div className={appStyles.container}>
          <div className={appStyles.pageHeading_lg}>
            <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
              <h1 className={appStyles.heading__text}>Make a Payment</h1>
            </div>
          </div>

          <Alert alertType="blank">
            <p className={`${appStyles.text_darkGrey} ${appStyles.info__main}`}>
              Below are all outstanding invoices for your account. Select which invoices you would like to pay and then click Proceed to Payment.
            </p>
            <p className={`${appStyles.text_darkGrey} ${appStyles.info__sub}`}>
              We cannot take a further payment against an invoice if the status shows as payment processing. Please try again later.
            </p>
          </Alert>
          <div className={styles.invoicesGrid}>
            <OutstandingInvoicesGrid setSelectedInvoices={this.setSelectedInvoices} />
          </div>
        </div>

        <BottomButtonContainer backgroundColor="white" layout="spaceBetween">
          <Link className={`${appStyles.button} ${appStyles.button_outline_secondary} ${appStyles.button_lg}`} to={'/invoices'} title="back to invoices">
            Manage Invoices
          </Link>
          <Link
            className={`${appStyles.button} ${appStyles.button_outline_primary} ${appStyles.button_lg}`}
            to={'/invoices/manage-direct-debit'}
            title="Setup Direct Debits">
            Setup Direct Debit
          </Link>
          <Button
            id="proceedToPayment"
            title={this.disableButton ? 'Select an invoice to continue' : 'Continue to payment summary'}
            buttonStyle="primary"
            size="lg"
            handleClick={this.proceed}
            disabled={this.disableButton}>
            Proceed to Payment
          </Button>
        </BottomButtonContainer>
      </>
    );
  }
}
