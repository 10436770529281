import { Component, ReactNode } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import banners from 'assets/files/banner.json';
import Icon from 'components/Generic/Icon/icon';
import appStyles from 'App.module.scss';
import styles from './BannerNotification.module.scss';

export default class BannerNotification extends Component {
  bannerFile = banners;
  currentBanner = this.bannerFile.banners.find((banner) => new Date(banner.expires).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0));

  bannerLinkIcon: ReactNode = (
    <Icon customClass={styles.banner__icon_right} icon={Icon.arrowRight} size="sm" color="currentColor" iconName="notification icon" />
  );

  bannerText(content: string): ReactNode {
    return (
      <div className={styles.banner__text}>
        <Icon customClass={styles.banner__icon_left} icon={Icon.star} size="sm" color="currentColor" iconName="notification icon" />
        <div>{ReactHtmlParser(content)}</div>
      </div>
    );
  }
  render() {
    if (!this.currentBanner) {
      return null;
    }

    return (
      <div className={classNames(appStyles.col_lg_12, styles.banner__container)}>
        {this.currentBanner.linkUrl ? (
          this.currentBanner.isExternalLink ? (
            <a
              href={this.currentBanner.linkUrl}
              title={this.currentBanner.linkTitle ? this.currentBanner.linkTitle : 'visit page'}
              target="_blank"
              rel="noopener noreferrer"
              id="bannerNotification"
              className={`${styles.banner} ${styles.banner_link} ${styles[`banner_${this.currentBanner.colour}`]}`}>
              {this.bannerText(this.currentBanner.content)}
              {this.bannerLinkIcon}
            </a>
          ) : (
            <Link
              className={`${styles.banner} ${styles.banner_link} ${styles[`banner_${this.currentBanner.colour}`]}`}
              to={this.currentBanner.linkUrl}
              id="bannerNotification"
              title={this.currentBanner.linkTitle ? this.currentBanner.linkTitle : 'visit page'}>
              {this.bannerText(this.currentBanner.content)}
              {this.bannerLinkIcon}
            </Link>
          )
        ) : (
          <div id="bannerNotification" className={`${styles.banner} ${styles[`banner_${this.currentBanner.colour}`]}`}>
            {this.bannerText(this.currentBanner.content)}
          </div>
        )}
      </div>
    );
  }
}
