import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Confirmation } from 'components/Generic/Confirmation/Confirmation';
import Button from 'components/Generic/FormElements/Button/Button';
import Icon from 'components/Generic/Icon/icon';
import { observable } from 'mobx';
import { IBaseProps } from 'models/generic/iBaseProps';
import { Component } from 'react';
import utils from 'utils/utils';

export class EditAccountConfirmation extends Component<IBaseProps> {
  @observable subMessages: string[] = [];

  componentDidMount() {
    const marketingPreferences = utils.session.getFromSession('marketingPreferences');

    if (marketingPreferences === 'unsubscribed') {
      this.subMessages.push("You've unsubscribed from marketing emails. This means you will not get special offers or your newsletter.");
      this.subMessages.push(
        'Operational updates will continue to be sent by email to the Primary Account address. ' +
          'Chess ICT Limited will send operational updates to the primary account email address designated by you, as a customer. ' +
          'These include product price changes, migrations, retirements, terms and conditions and other essential updates.'
      );
      this.subMessages.push('If you have any questions, please visit our <a href="https://chessict.co.uk/customers/" target="_blank">Contact Us</a> hub.');
    } else if (marketingPreferences === 'updated') {
      this.subMessages.push('Thank you for updating your contact information and preferences.');
      this.subMessages.push(
        'Please note operational updates will continue to be sent by email to the Primary Account address. ' +
          'Chess ICT Limited will send operational updates to the primary account email address designated by you, as a customer. ' +
          'These include product price changes, migrations, retirements, terms and conditions and other essential updates.'
      );
      this.subMessages.push('If you have any questions, please visit our <a href="https://chessict.co.uk/customers/" target="_blank">Contact Us</a> hub.');
    }

    utils.session.removeFromSession('marketingPreferences');
  }

  goToEditForm = () => {
    this.props.history.push('/account');
  };

  render() {
    return (
      <>
        <Confirmation
          mainMessage="Your account has been updated successfully."
          subMessages={this.subMessages}
          containerId="account_success"
          icon={Icon.success}
          iconText="Saved"
        />

        <BottomButtonContainer backgroundColor="white" layout="right">
          <Button buttonStyle="outline_secondary" size="lg" handleClick={this.goToEditForm}>
            Edit Account
          </Button>
        </BottomButtonContainer>
      </>
    );
  }
}

export default EditAccountConfirmation;
