import { ChangeEvent, Component, FocusEvent } from 'react';
import classNames from 'classnames';
import { ValidationError } from 'models/generic/validationError.model';
import constants from 'utils/constants';
import ValidationArea from 'components/Generic/FormElements/ValidationArea/ValidationArea';
import Icon from 'components/Generic/Icon/icon';
import appStyles from 'App.module.scss';

interface IInputProps {
  /**
   * @property {string} name Set the name attribute
   */
  name: string;
  /**
   * @property {string} title Set the title attribute
   */
  title?: string;
  /**
   * @property {boolean} required Optional. Set the field to required
   */
  required?: boolean;
  /**
   *@property {string} labelText Set the text to appear in the label
   */
  labelText: string;
  /**
   *@property {string} elementId Set the ID attribute
   */
  elementId: string;
  /**
   *@property {string} value Set the value attribute
   */
  value: string | number | null;
  /**
   * @property {string} inputType Choose the type of input
   */
  inputType: 'text' | 'password' | 'email' | 'number' | 'tel' | 'url' | 'search' | 'hidden';
  /**
   * @property {string} inputMode Optional. Choose the input mode, default is 'text'.
   * Check https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode for details
   */
  inputMode?: 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url';
  /**
   * @property {string} pattern Choose the type of input
   */
  pattern?: string;
  /**
   *@property {string} placeholder Set the placeholder
   */
  placeholder: string;
  /**
   *@property {boolean} labelHidden Optional. Hide the label. labelText will be used for screenreaders. Default is false.
   */
  labelHidden?: boolean;
  /**
   *@property {number} maxLength Optional. Set the maxLength of the input
   */
  maxLength?: number;
  /**
   *@property {number} minLength Optional. Set the minLength of the input
   */
  minLength?: number;
  /**
   *@property {boolean} readonly Optional. Set the readonly of the input
   */
  readonly?: boolean;
  /**
   *@property {(value: string) => void} onEnterClick Optional.  Set the action when Enter button pushed
   */
  onEnterClick?: () => void;
  /**
   *@property {(event: ChangeEvent<HTMLInputElement>) => void} handleChange Set the action onChange
   */
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  /**
   *@property {(event: FocusEvent<HTMLInputElement>) => void} handleBlur Set the action onBlur
   */
  handleBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  /**
   *@property {(value: string) => void} handleFinishedTyping Set the action handleFinishedTyping
   */
  handleFinishedTyping?: (value: string) => void;
  /**
   * @property {string} minValue Optional. Set min value for a number input
   */
  minValue?: string;
  /**
   * @property {string} customInputClass Optional. Use a custom class on the input - use styles from the current component or appStyles
   */
  customInputClass?: string;
  /**
   * @property {string} customLabelClass Optional. Use a custom class on the label - use styles from the current component or appStyles
   */
  customLabelClass?: string;
  /**
   * @property {ValidationError} validationError Optional. Any validation messages related to this field
   */
  validationError?: ValidationError;
  /**
   * @property {string} autocomplete Optional. Set the autocomplete attribute, default is off. Check https://www.w3.org/TR/WCAG21/#input-purposes for details
   */
  autocomplete?:
    | 'off'
    | 'name'
    | 'honorific-prefix'
    | 'given-name'
    | 'additional-name'
    | 'family-name'
    | 'honorific-suffix'
    | 'nickname'
    | 'organization-title'
    | 'username'
    | 'new-password'
    | 'current-password'
    | 'organization'
    | 'street-address'
    | 'address-line1'
    | 'address-line2'
    | 'address-line3'
    | 'address-level4'
    | 'address-level3'
    | 'address-level2'
    | 'address-level1'
    | 'country'
    | 'country-name'
    | 'postal-code'
    | 'cc-name'
    | 'cc-given-name'
    | 'cc-additional-name'
    | 'cc-family-name'
    | 'cc-number'
    | 'cc-exp'
    | 'cc-exp-month'
    | 'cc-exp-year'
    | 'cc-csc'
    | 'cc-type'
    | 'transaction-currency'
    | 'transaction-amount'
    | 'language'
    | 'bday'
    | 'bday-day'
    | 'bday-month'
    | 'bday-year'
    | 'sex'
    | 'url'
    | 'photo'
    | 'tel'
    | 'tel-country-code'
    | 'tel-national'
    | 'tel-area-code'
    | 'tel-local'
    | 'tel-local-prefix'
    | 'tel-local-suffix'
    | 'tel-extension'
    | 'email'
    | 'impp';
}

/**
 * @description Add an input element with a label. Optional properties: readonly, customInputClass, customLabelClass,
 * autocomplete, inputMode, pattern, title, required, maxLength, validationError
 */
export default class Input extends Component<IInputProps> {
  inputInstance: HTMLInputElement | null = null;
  private value: string | null = null;
  private finishTypingWaitTimeInSeconds = 1;

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    // raise change event
    this.props.handleChange(event);

    // check if user is still typing (if value after a short delay is the same as current)
    const newValue = event.target.value;
    this.value = newValue;

    if (this.props.handleFinishedTyping) {
      setTimeout(() => {
        this.checkIfFinishedTyping(newValue);
      }, this.finishTypingWaitTimeInSeconds * 1000);
    }
  };

  checkIfFinishedTyping = (newValue: string) => {
    if (this.props.handleFinishedTyping && newValue === this.value) {
      this.props.handleFinishedTyping(newValue);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void = (e) => {
    if (e.key === 'Enter') {
      this.props.onEnterClick?.();
    }
  };

  get isSearchableInput() {
    return this.props.inputType === 'search';
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      name,
      value,
      title,
      pattern,
      minValue,
      required,
      readonly,
      labelText,
      inputType,
      elementId,
      inputMode,
      maxLength,
      minLength,
      labelHidden,
      placeholder,
      autocomplete,
      validationError,
      customLabelClass,
      customInputClass,
      handleBlur
    } = this.props;

    const isError = validationError?.messages.find((m) => m.severity === constants.validation.severity.error);

    return (
      <>
        <label
          htmlFor={name}
          className={classNames(
            appStyles.form__label,
            { [appStyles.form__label_invalid]: isError }, // add invalid class if errors
            { [customLabelClass as string]: customLabelClass }, // add custom class if defined
            { [appStyles.srOnly]: labelHidden } // add srOnly class if label hidden
          )}>
          {labelText}
        </label>

        <input
          ref={(ref) => {
            this.inputInstance = ref;
          }}
          className={classNames(
            appStyles.form__input,
            { [appStyles.form__input_search]: this.isSearchableInput }, // add search class if type is search
            { [appStyles.form__input_text]: !this.isSearchableInput }, // add text class if type is not search
            { [appStyles.form__input_invalid]: isError }, // add invalid class if errors
            { [customInputClass as string]: customInputClass } // add custom class if defined
          )}
          id={name}
          name={name}
          type={inputType}
          value={value ?? ''}
          onChange={this.onChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          autoComplete={autocomplete || 'off'}
          inputMode={inputMode || 'text'}
          pattern={pattern}
          title={title || ''}
          min={minValue || undefined}
          maxLength={maxLength || undefined}
          minLength={minLength || undefined}
          required={required || undefined}
          readOnly={readonly || undefined}
          aria-required={required || undefined}
        />
        {this.isSearchableInput && <Icon icon={Icon.search} iconName="search icon" size="md" color="midBlue" customClass={appStyles.search__icon} />}

        <ValidationArea sectionId={elementId} validationError={validationError} />
      </>
    );
  }
}
