import { action, computed, observable } from 'mobx';
import { MarketingItem } from 'models/generic/marketingItem.model';
import auth from 'utils/auth';
import { tryParseXml } from 'utils/xml';

class MarketingStore {
  @observable items: MarketingItem[] | null = null;

  url = process.env.REACT_APP_MARKETING_BASE_URL ?? '';
  subscriptionKey = process.env.REACT_APP_API_KEY ?? '';

  @computed get dots(): boolean {
    return !!this.items && this.items.length <= 5;
  }

  @action getPictures = async () => {
    const headers = new Headers();
    headers.append('Authorization', await this.getToken());
    headers.append('Ocp-Apim-Subscription-Key', this.subscriptionKey);

    const response = await fetch(this.url, { headers });
    const responseText = await response.text();
    const doc = tryParseXml(responseText);
    if (doc === null) {
      // console.log('Unable to retrieve marketing items. The rss feed is invalid.');
      return;
    }

    const marketingItems = new Array<MarketingItem>();
    for (let i = 0; i < doc.querySelectorAll('item').length; i++) {
      const title = this.getValue(doc, 'title', i);
      const mediaUrl = await this.fetchImage(this.getValue(doc, 'mediaUrl', i));
      const link = this.getValue(doc, 'link', i).replace(/\s/g, '');
      const imageAltText = this.getValue(doc, 'imageAltText', i);
      marketingItems.push(new MarketingItem(title, mediaUrl, link, imageAltText));
    }

    this.items = marketingItems;
  };

  private fetchImage = async (url: string): Promise<string> => {
    const headers = new Headers();

    headers.append('Ocp-Apim-Subscription-Key', this.subscriptionKey);

    return fetch(url, { headers })
      .then((response) => response.arrayBuffer())
      .then((buffer) => URL.createObjectURL(new Blob([buffer])));
  };

  private getToken = async () => {
    const tokenResponse = await auth.acquireTokenSilent();
    if (tokenResponse?.idToken) {
      return `Bearer ${tokenResponse.idToken}`;
    }

    return '';
  };

  private getValue = (doc: Document, tagName: string, i: number) => {
    const elements = doc.getElementsByTagName(tagName);

    return elements?.[i]?.childNodes?.[0]?.nodeValue ?? '';
  };
}

export default new MarketingStore();
