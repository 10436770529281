import { BaseModel } from 'models/api/base.model';

export class PaymentResponse extends BaseModel {
  paymentId: string | null = null;
  paymentDate: string | null = null;
  txAuthNo: string | null = null;
  amount: string | null = null;
  cardType: string | null = null;
  vendorTxCode: string | null = null;
}
