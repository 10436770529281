import { Grid } from '@progress/kendo-react-grid';
import { BaseClientGrid } from 'components/Generic/Grid/BaseClientGrid';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Invoice } from 'models/api/invoice.model';
import { InvoiceItem } from 'models/api/invoiceItem.model';

interface IInvoicesBreakdownGridProps {
  invoice: Invoice;
}

@observer
export class InvoicesBreakdownGrid extends BaseClientGrid<InvoiceItem, IInvoicesBreakdownGridProps> {
  constructor(props: IInvoicesBreakdownGridProps) {
    super(props);

    this.sort = [{ field: 'description', dir: 'asc' }];

    this.allData = this.props.invoice.items;
    this.gridData = this.allData;
  }

  render() {
    const mainGrid = (
      <Grid
        data={this.getData()}
        filter={this.filter}
        filterable={false}
        pageable={{ pageSizes: this.pageSizes }}
        skip={this.skip}
        take={this.take}
        total={this.gridData.length}
        onPageChange={this.pageChange}
        sortable
        sort={toJS(this.sort)}
        resizable
        onFilterChange={this.filterChange}
        onSortChange={this.sortChange}>
        {ColumnHelper.getGridColumns([
          { field: 'itemCode', title: 'Item Code', dataType: 'text', size: 'md', currentFilter: this.filter },
          { field: 'description', title: 'Description', dataType: 'text', size: 'lg', isHtml: true, currentFilter: this.filter },
          { field: 'quantity', title: 'Quantity', dataType: 'numeric', size: 'md', currentFilter: this.filter },
          { field: 'unit', title: 'Unit', dataType: 'text', size: 'md', currentFilter: this.filter },
          { field: 'discount', title: 'Discount %', dataType: 'numeric', size: 'md', currentFilter: this.filter },
          { field: 'vatCode', title: 'VAT Code', dataType: 'numeric', size: 'md', currentFilter: this.filter },
          { field: 'net', title: 'Net', dataType: 'currency', size: 'md', currentFilter: this.filter }
        ])}
      </Grid>
    );

    return <>{mainGrid}</>;
  }
}
export default InvoicesBreakdownGrid;
