import { GridColumnMenuCheckboxFilter, GridColumnMenuProps } from '@progress/kendo-react-grid';
import React from 'react';

export class CheckBoxColumnMenu extends React.Component<GridColumnMenuProps> {
  data: Array<string | Record<string, unknown>> = [];

  render() {
    return (
      <div>
        {/* <GridColumnMenuSort {...this.props} /> */}
        <GridColumnMenuCheckboxFilter {...this.props} data={this.data} expanded={true} />
      </div>
    );
  }
}
