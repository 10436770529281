import { Grid } from '@progress/kendo-react-grid';
import appStyles from 'App.module.scss';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import { Confirmation } from 'components/Generic/Confirmation/Confirmation';
import Button from 'components/Generic/FormElements/Button/Button';
import { BaseClientGrid } from 'components/Generic/Grid/BaseClientGrid';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import Icon from 'components/Generic/Icon/icon';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { IBaseProps } from 'models/generic/iBaseProps';
import styles from '../NewTicket.module.scss';

export class Priority {
  level: string | null = null;
  queryType: string | null = null;
  responseTime: string | null = null;
}

@observer
export class NewTicketConfirmation extends BaseClientGrid<Priority, IBaseProps> {
  @observable ticketPriorities: Priority[] = [
    { level: 'Priority 1', queryType: 'Critical', responseTime: '1 hour' },
    { level: 'Priority 2', queryType: 'Major', responseTime: '4 hours' },
    { level: 'Priority 3', queryType: 'Minor', responseTime: '8 hours' },
    { level: 'Priority 5', queryType: 'Billing and Account', responseTime: '24 hours' },
    { level: 'Priority 6', queryType: 'Administration change', responseTime: '5 working days' }
  ];

  componentDidMount() {
    this.allData = this.ticketPriorities;
    this.gridData = this.allData;
  }

  back = () => {
    this.props.history.push('/tickets');
  };

  render() {
    const mainGrid = (
      <Grid
        data={this.getData()}
        filter={this.filter}
        filterable={false}
        pageable={false}
        skip={this.skip}
        take={this.take}
        total={this.getTotal()}
        onPageChange={this.pageChange}
        sortable={false}
        sort={this.getSort()}
        resizable
        onFilterChange={this.filterChange}
        onSortChange={this.sortChange}>
        {ColumnHelper.getGridColumns([
          { field: 'level', title: 'Level', dataType: 'text', size: 'md', currentFilter: this.filter },
          { field: 'queryType', title: 'Query Type', dataType: 'text', size: 'md', currentFilter: this.filter },
          { field: 'responseTime', title: 'Response Time', dataType: 'text', size: 'md', currentFilter: this.filter }
        ])}
      </Grid>
    );

    return (
      <>
        <Confirmation
          // eslint-disable-next-line max-len
          mainMessage="Your ticket will be reviewed during business hours as soon as we receive it. Customers with an enhanced service package will be managed in line with their agreement. Our team will assign a priority level depending on your query type and we have included a summary of our priority levels below."
          containerId="ticket_success"
          icon={Icon.success}
          iconText="Saved"
        />

        <div className={`${styles.gridMargin} ${appStyles.container}`}>
          <div className={appStyles.row}>
            <div className={`${appStyles.col_md_9} ${appStyles.col_lg_7} ${appStyles.col_xl_6}`}>{mainGrid}</div>
          </div>
        </div>
        <BottomButtonContainer backgroundColor="white" layout="right">
          <Button buttonStyle="outline_secondary" size="lg" handleClick={this.back}>
            Manage Tickets
          </Button>
        </BottomButtonContainer>
      </>
    );
  }
}

export default NewTicketConfirmation;
