import { Component } from 'react';
import cookie from 'react-cookies';
import ReactGA from 'react-ga';
import { observer } from 'mobx-react';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ITelemetryPlugin } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import navigationStore from 'stores/navigation.store';
import classNames from 'classnames';
import styles from './App.module.scss';

export const browserHistory = createBrowserHistory({ basename: '' });

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
    instrumentationKey: process.env.REACT_APP_INSIGHTS_KEY || '',
    extensions: [reactPlugin as unknown as ITelemetryPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
});

// load app insights if key is set
if (process.env.REACT_APP_INSIGHTS_KEY) {
  appInsights.loadAppInsights();
}

// export app insights
export default appInsights;

// Initialise GA
const googleTrackingId = process.env.REACT_APP_GA_KEY;
const allowAnalyticsCookies = cookie.load('allowAnalyticsCookies');

if (allowAnalyticsCookies !== undefined && allowAnalyticsCookies === false) {
  // disable ga if user has opted out
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any)[`ga-disable-${process.env.REACT_APP_GA_KEY}`] = true;
}

// initialise ga if user has not set cookies or has accepted cookies
if (googleTrackingId && (allowAnalyticsCookies === 'true' || allowAnalyticsCookies === undefined)) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const trackPageView = (location: any) => {
    ReactGA.set({ page: location.pathname, anonymizeIp: true });
    ReactGA.pageview(window.location.pathname + window.location.search);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const initGa = (history: any) => {
    ReactGA.initialize(googleTrackingId, {
      gaOptions: { cookieDomain: process.env.REACT_APP_GA_COOKIE_DOMAIN }
    });
    trackPageView(history.location);
    history.listen(trackPageView);
  };

  initGa(browserHistory);
}

@observer
export class App extends Component {
  render() {
    return (
      <div
        className={classNames(styles.app, { [styles.containerBottom__spacer]: navigationStore.bottomButtonContainerIsPresent })}
        data-version={process.env.REACT_APP_VERSION}>
        {this.props.children}
      </div>
    );
  }
}
