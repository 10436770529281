import { BaseApi } from 'api/baseApi';
import { AxiosError } from 'axios';
import { LogDownloadClicked } from 'models/api/logDownloadClicked.model';
import { FalconError } from 'models/generic/falconError.model';
import eventLogsStore from 'stores/eventLogs.store';
import messagesStore from 'stores/messages.store';

export class DirectDebitFileApi extends BaseApi {
  constructor() {
    super();

    this.baseApiUrl = process.env.REACT_APP_DIRECTDEBIT_FILE_API_BASE_URL || '';
  }

  protected getResultFromError(error: AxiosError, friendlyErrorMessage: string | null): FalconError {
    const apiError = new FalconError();
    apiError.errorMessages.push(friendlyErrorMessage || `Something went wrong.`);
    messagesStore.addError(apiError);

    return apiError;
  }
  async getByteArrayPdf<T>(url: string, friendlyErrorMessage: string | null, decodeResponse: boolean = true) {
    const config = await this.getConfig();
    config.responseType = 'arraybuffer';

    const form = new LogDownloadClicked(this.baseApiUrl, url);
    eventLogsStore.logDownloadClicked(form); // this is async, but no need to await

    const response = await this.axiosInstance.get<T>(url, config);

    return this.getResult(response, friendlyErrorMessage, decodeResponse) as T | FalconError;
  }
}

const directDebitFileApi = new DirectDebitFileApi();
export default directDebitFileApi;
