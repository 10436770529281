import appStyles from 'App.module.scss';
import { BaseUnsavedChangesComponent } from 'components/Generic/BaseUnsavedChangesComponent';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import { NewTicketCategory } from 'components/Tickets/NewTicket/NewTicketCategory/NewTicketCategory';
import NewTicketForm from 'components/Tickets/NewTicket/NewTicketForm/NewTicketForm';
import { NewTicketService } from 'components/Tickets/NewTicket/NewTicketService/NewTicketService';
import { newTicketServices } from 'components/Tickets/NewTicket/NewTicketService/services';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { ListItem } from 'models/generic/listItem.model';
import { Component } from 'react';
import accountStore from 'stores/account.store';
import locationsStore from 'stores/locations.store';
import styles from './NewTicket.module.scss';

@observer
export class NewTicket extends Component {
  @observable contacts: ListItem[] = [];
  @observable addresses: ListItem[] = [];

  @observable selectedCategory = '';
  @observable selectedServiceId = '';
  @observable selectedSecondaryServiceId = '';
  @observable selectedTertiaryServiceId = '';
  @observable showForm = false;
  @observable formHasUnsavedData = false;

  async componentDidMount() {
    this.contacts = await accountStore.getContactsListItems();
    this.addresses = await locationsStore.getLocationsListItems();
  }

  setTicketFormUnsavedDataState = (childHasUnsavedChanges: boolean) => {
    if (!BaseUnsavedChangesComponent.enableCheck) {
      this.formHasUnsavedData = false;
    } else {
      this.formHasUnsavedData = childHasUnsavedChanges;
    }
  };

  private back = () => {
    let goBack = true;

    if (this.formHasUnsavedData) {
      goBack = window.confirm('You have unsaved changes. Are you sure you want to navigate away from this page?');
      if (goBack) {
        this.formHasUnsavedData = false;
      }
    }

    if (goBack) {
      window.scrollTo(0, 0);

      if (this.showForm && this.selectedSecondaryServiceId !== '') {
        this.showForm = false;

        return;
      }

      this.setCategory('');
    }
  };

  private setCategory = (category: string, showForm?: boolean) => {
    this.showForm = showForm || false;
    this.selectedCategory = category;
    this.selectedServiceId = '';
    this.selectedSecondaryServiceId = '';
    this.selectedTertiaryServiceId = '';
  };

  private setService = (serviceId: string) => {
    const unset = this.selectedServiceId === serviceId;

    this.selectedSecondaryServiceId = '';
    this.selectedTertiaryServiceId = '';
    this.selectedServiceId = unset ? '' : serviceId;
  };

  private setSecondaryService = (serviceId: string) => {
    const unset = this.selectedSecondaryServiceId === serviceId;
    const hasChildren = newTicketServices.filter((s) => s.parentId === serviceId).length !== 0;

    this.selectedTertiaryServiceId = '';
    this.selectedSecondaryServiceId = unset ? '' : serviceId;
    this.showForm = !hasChildren;
  };

  private setTertiaryService = (serviceId: string) => {
    const unset = this.selectedTertiaryServiceId === serviceId;

    this.selectedTertiaryServiceId = unset ? '' : serviceId;
    this.showForm = true;
  };

  render() {
    const backButton = (
      <Button id="ticketBackButton" title="Back a step" size="lg" buttonStyle="outline_secondary" handleClick={this.back}>
        Previous Step
      </Button>
    );

    return (
      <>
        {this.selectedCategory ? <></> : <NewTicketCategory setCategory={this.setCategory}></NewTicketCategory>}
        {this.selectedCategory && !this.showForm ? (
          <>
            <div className={`${styles.newTicket} ${appStyles.container}`}>
              <div className={`${appStyles.heading} ${appStyles.text_midBlue}`}>
                <h1 className={appStyles.heading__text}>Select the service to log a support ticket</h1>
              </div>

              <NewTicketService
                sectionId="service"
                services={newTicketServices.filter((s) => !s.parentId)}
                selectedServiceId={this.selectedServiceId}
                setService={this.setService}></NewTicketService>

              {this.selectedServiceId ? (
                <NewTicketService
                  sectionId="secondaryService"
                  services={newTicketServices.filter((s) => s && s.parentId === this.selectedServiceId)}
                  selectedServiceId={this.selectedSecondaryServiceId}
                  setService={this.setSecondaryService}></NewTicketService>
              ) : (
                <></>
              )}

              {this.selectedSecondaryServiceId ? (
                <NewTicketService
                  sectionId="tertiaryService"
                  services={newTicketServices.filter((s) => s && s.parentId === this.selectedSecondaryServiceId)}
                  selectedServiceId={this.selectedTertiaryServiceId}
                  setService={this.setTertiaryService}></NewTicketService>
              ) : (
                <></>
              )}
            </div>

            <BottomButtonContainer backgroundColor="white" layout={this.selectedCategory ? 'spaceBetween' : 'right'}>
              {this.selectedCategory ? backButton : <></>}
            </BottomButtonContainer>
          </>
        ) : (
          <></>
        )}

        {this.showForm && (
          <NewTicketForm
            category={this.selectedCategory}
            serviceId={this.selectedServiceId}
            secondaryService={this.selectedSecondaryServiceId}
            tertiaryService={this.selectedTertiaryServiceId}
            backButton={backButton}
            contacts={this.contacts}
            addresses={this.addresses}
            parentNotifyUnsavedData={this.setTicketFormUnsavedDataState}
          />
        )}
      </>
    );
  }
}

export default NewTicket;
