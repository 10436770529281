import appStyles from 'App.module.scss';
import { FC, HTMLAttributes, ReactNode } from 'react';
import styles from './Alert.module.scss';

interface IAlertProps {
  /**
   *@property {string} alertType Applies a class for the type of alert (these styles are in _validation.scss)
   */
  alertType: 'error' | 'warning' | 'info' | 'success' | 'blank';
  /**
   *@property {ReactNode} children Add elements inside this container
   */
  children: ReactNode;
  /**
   * @property {string} customClass Optional. Use a custom class on the alert - use styles from the current component or appStyles
   */
  customClass?: string;
}

/**
 * @description Add an alert. Optional properties: customClass
 */
const Alert: FC<IAlertProps & HTMLAttributes<HTMLDivElement>> = ({ alertType, customClass, children, ...props }) => (
  <div className={`${styles.alert} ${styles[`alert_${alertType}`]} ${customClass || ''}`} role="alert" data-type={`alert-${alertType}`} {...props}>
    {alertType !== 'blank' && <span className={appStyles.srOnly}>{alertType}:</span>}
    <div>{children}</div>
  </div>
);

export default Alert;
