import { Component } from 'react';
import classNames from 'classnames';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { ListItem } from 'models/generic/listItem.model';
import { ValidationErrors } from 'models/generic/validationError.model';
import availabilityChecksStore from 'stores/availabilityChecks.store';
import constants from 'utils/constants';
import validation from 'utils/validation';
import withVisible from 'hoc/withVisible';
import Button from 'components/Generic/FormElements/Button/Button';
import Input from 'components/Generic/FormElements/Input/Input';
import appStyles from 'App.module.scss';
import Alert from 'components/Generic/Alert/Alert';
import FTTPImage from 'assets/images/fast_fttp2020.png';
import availabilityCheckerStyles from '../AvailabilityChecker.module.scss';
import AddressList from './AddressList';

const AddressListWithVisible = withVisible(AddressList);

@observer
export default class AvailabilityCheckerForm extends Component<RouteComponentProps> {
  showValidationOnInput = false;

  @observable errors = new ValidationErrors();
  @observable selectedAddressId: string | null = null;
  @observable continueInProgress = false;

  constructor(props: RouteComponentProps) {
    super(props);

    const preservedPostcode = availabilityChecksStore.postcode;
    availabilityChecksStore.cleanUpStore();
    availabilityChecksStore.postcode = preservedPostcode;
  }

  @action validateForm = () => {
    this.errors = new ValidationErrors();

    return validation.validatePostCode(
      availabilityChecksStore.postcode,
      'postcode',
      this.errors,
      'Enter a valid postcode',
      constants.validation.severity.error
    );
  };

  @action handleInput: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const { value } = target;
    availabilityChecksStore.setPostcode(value);

    if (this.showValidationOnInput) {
      this.validateForm();
    }
  };

  @action handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    await this.onSearchClick();
  };

  @action handleSelect: React.ChangeEventHandler<HTMLSelectElement> = ({ target }) => {
    this.selectedAddressId = target.value;

    availabilityChecksStore.setSelectedAddress(Number(target.value));
  };

  @action onSearchClick = async () => {
    this.showValidationOnInput = true;

    if (!this.validateForm()) {
      return;
    }

    this.selectedAddressId = null;

    await availabilityChecksStore.getAvailableAddresses(availabilityChecksStore.postcode);

    await availabilityChecksStore.getContactPhoneNumber();

    if (!availabilityChecksStore.addresses.length && !availabilityChecksStore.addressesError) {
      const { history, match } = this.props;

      history.push(`${match.path}/request-callback`);
    }
  };

  @action onContinueClick: React.MouseEventHandler<HTMLButtonElement> = async () => {
    const { history, match } = this.props;

    this.continueInProgress = true;

    await availabilityChecksStore.getAvailableProducts();

    const path = availabilityChecksStore.availableProducts.length ? `${match.path}/product-availability` : `${match.path}/request-callback`;

    this.continueInProgress = false;

    history.push(path);
  };

  get searchButtonDisabled() {
    return availabilityChecksStore.pending || availabilityChecksStore.postcode.length < 5;
  }

  get continueButtonDisabled() {
    return !this.selectedAddressId;
  }

  @computed get availableAddresses() {
    return availabilityChecksStore.addresses.map(({ postTown, street, buildingName, buildingNumber, locality, organisationName, subBuilding }, index) => {
      const fullAddress = [organisationName, subBuilding, buildingName, buildingNumber, street, locality, postTown].filter((s) => s).join(', ');

      return new ListItem(`${index}`, fullAddress);
    });
  }

  render() {
    return (
      <div className={classNames(availabilityCheckerStyles.availabilityChecker__container, appStyles.container_center)}>
        <div className={appStyles.heading}>
          <h1 className={classNames(appStyles.heading__text, appStyles.text_midBlue)}>FTTP Availability Checker</h1>
        </div>
        <Alert alertType="blank" customClass={availabilityCheckerStyles.fttpInfo}>
          <div className={availabilityCheckerStyles.fttpInfo__content}>
            <div>
              <p className={`${appStyles.text_tangaroaBlue} ${appStyles.info__main} ${appStyles.info_bold}`}>
                Chess Ultrafast broadband may now be available in your area. Thanks to the fibre optic cable direct from your local exchange to your business,
                you can rely on a fast, secure and affordable connection at all times.
              </p>
              <p className={`${appStyles.text_darkGrey} ${appStyles.info__sub}`}>
                The UK Public Switched Telephone Network (PSTN) will be switched off in 2025, with some services affected as early as 2021. The replacement
                products, referred to as "Single Order", include Fibre to the Premises (FTTP) and Single Order Generic Ethernet Access (SOGEA).
              </p>
              <p className={`${appStyles.text_darkGrey} ${appStyles.info__sub}`}>
                Chess Ultrafast broadband offers a fibre to the premises connection. FTTP is the fastest fibre broadband on the market, it's more reliable and
                secure.
              </p>
            </div>
            <img className={availabilityCheckerStyles.fttpImage} alt="Lightning bolt image" src={FTTPImage} />
          </div>
        </Alert>
        <form className={availabilityCheckerStyles.form} onSubmit={this.handleSubmit}>
          <Alert alertType="blank" customClass={availabilityCheckerStyles.full_width}>
            <p className={classNames(availabilityCheckerStyles.subheading, appStyles.text_tangaroaBlue, appStyles.info__main, appStyles.info_bold)}>
              Can you get ultrafast FTTP?
            </p>
            <p className={classNames(appStyles.text_darkGrey, appStyles.info__sub)}>Let's find out. Enter your postcode and click Search.</p>
          </Alert>
          <div className={classNames(availabilityCheckerStyles.form__input_container, availabilityCheckerStyles.postcodeContainer)}>
            <div className={availabilityCheckerStyles.postcodeInputWrapper}>
              <Input
                labelText="Postcode"
                labelHidden={true}
                name="postcode"
                required={true}
                inputType="text"
                elementId="postcode"
                value={availabilityChecksStore.postcode}
                readonly={availabilityChecksStore.pending || availabilityChecksStore.addresses?.length > 0}
                placeholder="Enter a postcode"
                handleChange={this.handleInput}
                minLength={constants.postcodeMinLength}
                maxLength={constants.postcodeMaxLength}
                validationError={this.errors.fieldErrors['postcode']}
                customInputClass={classNames(availabilityCheckerStyles.form__input, {
                  [availabilityCheckerStyles.form__input_uppercase]: availabilityChecksStore.postcode
                })}
              />
            </div>
            <Button
              type="submit"
              customClass={availabilityCheckerStyles.searchButton}
              size="lg"
              buttonStyle="primary"
              id="btnSearchAddresses"
              handleClick={this.onSearchClick}
              inProgress={availabilityChecksStore.pending && !this.availableAddresses.length}
              disabled={this.searchButtonDisabled}>
              <span>Search</span>
            </Button>
          </div>
          <AddressListWithVisible
            selectedAddressId={this.selectedAddressId}
            handleSelect={this.handleSelect}
            availableAddresses={this.availableAddresses}
            errors={this.errors}
            onContinueClick={this.onContinueClick}
            continueButtonDisabled={this.continueButtonDisabled}
            addressesError={availabilityChecksStore.addressesError}
            isAddressesAvailable={!!availabilityChecksStore.addresses.length}
            visible={!!this.availableAddresses.length}
            inProgress={this.continueInProgress}
          />
        </form>
      </div>
    );
  }
}
