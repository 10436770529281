import appStyles from 'App.module.scss';
import classNames from 'classnames';
import { Component, ReactNode } from 'react';

interface IButtonProps {
  /**
   *@property {ReactNode} children Add elements inside this container
   */
  children: ReactNode;

  /**
   *@property {string} style Style of a button
   */
  buttonStyle: 'none' | 'primary' | 'secondary' | 'outline_primary' | 'outline_secondary' | 'outline_white';

  /**
   *@property {string} buttonType Type of a button. Defaults to 'button'
   */
  buttonType?: 'button' | 'submit';

  /**
   *@property {string} size Optional. Size of a button. Defaults to md
   */
  size?: 'lg' | 'md';

  /**
   *@property {boolean} inProgress Optional. Once set to true button becomes disabled and has a preloader embedded
   */
  inProgress?: boolean;

  /**
   *@property {boolean} disabled Optional. Once set to true button becomes disabled and has the disabled class applied. Default is false.
   */
  disabled?: boolean;

  /**
   *@property {(event: ChangeEvent<HTMLInputElement>) => void} handleChange Optional. Set the action onClick
   */
  handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;

  /**
   * @property {string} customClass Optional. Use a custom class on the button - use styles from the current component or appStyles
   */
  customClass?: string;

  /**
   * @property {string} id Optional. Add an id to the button
   */
  id?: string;

  /**
   * @property {string} title Optional. Add a title to the button
   */
  title?: string;
}

/**
 * @description Add a button. Optional properties: buttonType, size, inProgress, handleClick, customClass, disabled, title
 */
class Button extends Component<React.ButtonHTMLAttributes<HTMLButtonElement> & IButtonProps> {
  render() {
    const { size, disabled, children, buttonType, inProgress, buttonStyle, customClass, handleClick, ...restButtonProps } = this.props;

    return (
      <button
        {...restButtonProps}
        type={buttonType || 'button'}
        className={classNames(
          { [appStyles.button]: buttonStyle !== 'none' }, // add default class unless 'none' is selected
          { [appStyles[`button_${buttonStyle}`]]: buttonStyle !== 'none' }, // add primary or secondary class
          { [appStyles.button_spinner]: inProgress }, // add spinner class if work in progress
          { [appStyles.button_lg]: size === 'lg' }, // add lg class if size is lg (no additional class for size md)
          { [customClass as string]: customClass }, // add custom class if defined
          { [appStyles.button_disabled]: disabled }
        )}
        disabled={inProgress || disabled}
        onClick={handleClick}>
        {children}
      </button>
    );
  }
}

export default Button;
