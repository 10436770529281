import { withRouter } from 'react-router-dom';
import { Grid } from '@progress/kendo-react-grid';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import BottomButtonContainer from 'components/Generic/BottomButtonContainer/BottomButtonContainer';
import Button from 'components/Generic/FormElements/Button/Button';
import { BaseClientGrid } from 'components/Generic/Grid/BaseClientGrid';
import { ColumnHelper } from 'components/Generic/Grid/ColumnHelper';
import { LicenseStatusColumnMenu } from 'components/Generic/Grid/LicenseStatusColumnMenu';
import { License } from 'models/api/license.model';
import { IBaseProps } from 'models/generic/iBaseProps';
import productsStore from 'stores/products.store';
import { IMatchResult } from 'utils/routing';
import utils from 'utils/utils';
import { ReactComponent as DocumentsSVGLogo } from 'assets/images/svg/documents.svg';
import appStyles from 'App.module.scss';

interface ILicensesGridProps extends IBaseProps {
  search: string;
  setSelectedLicense: (license: License | null) => void;
}

@observer
class LicensesGrid extends BaseClientGrid<License, ILicensesGridProps> {
  @observable selectedLicense: License | null = null;
  subscriptionId = '';

  constructor(props: ILicensesGridProps) {
    super(props);

    // set default sort and filter
    this.sort = [{ field: 'issueDate', dir: 'desc' }];
    this.filter = {
      logic: 'or',
      filters: [
        { field: 'status', operator: 'eq', value: 'Active' },
        { field: 'status', operator: 'eq', value: 'Expires Soon' },
        { field: 'status', operator: 'eq', value: 'No expiry date set' }
      ]
    };

    // monitor route changes
    this.subscriptionId = utils.routing.subscribe((location) => {
      const match = utils.routing.matchRoutes(location, ['/products/subscriptions/:id', '/products/subscriptions']);
      this.setUpView(match);
    });
  }

  async componentDidMount() {
    this.allData = await productsStore.getLicenses();
    this.gridData = this.allData;
    this.setUpView(this.props.match.params);
  }

  componentDidUpdate(prevProps: ILicensesGridProps) {
    if (this.props.search !== prevProps.search) {
      this.filterData(this.props.search);
    }
  }

  componentWillUnmount() {
    utils.routing.unsubscribe(this.subscriptionId);
  }

  filterData = (searchInput: string) => {
    const keyword = searchInput.toLowerCase();

    const result = this.allData.filter((l: License) => License.getAllSearchableFieldsAsString(l).toLowerCase().indexOf(keyword) > -1);

    this.gridData = result;
  };

  setUpView(match: IMatchResult | null) {
    // get subscriptions ID from match params (query string)
    let licenseId: number | null = null;
    if (match && match.params) {
      licenseId = +match.params.id || null;
    }

    let license: License | null = null;
    if (licenseId) {
      // if id present in query string then show subscription breakdown
      // otherwise show all subscriptions
      license = this.allData.find((l) => l.licenseId === licenseId) || null;
    }

    this.selectedLicense = license;
    this.props.setSelectedLicense(license);
  }

  goToSubscription = (license: License) => {
    this.props.history.push('/products/subscriptions/' + license.licenseId);
  };

  back = () => {
    this.props.history.push('/products/subscriptions');
  };

  render() {
    const mainGrid = (
      <div id="mainGrid">
        <Grid
          data={this.getData(this.selectedLicense)}
          filter={this.pureFilter}
          filterable={false}
          pageable={this.getPageable(this.selectedLicense)}
          skip={this.skip}
          take={this.take}
          total={this.getTotal(this.selectedLicense)}
          onPageChange={this.pageChange}
          sortable={this.getSortable(this.selectedLicense)}
          sort={this.getSort(this.selectedLicense)}
          resizable
          onFilterChange={this.filterChange}
          onSortChange={this.sortChange}>
          {ColumnHelper.getGridColumns([
            { field: 'vendor', title: 'Vendor', dataType: 'text', size: 'md', currentFilter: this.filter },
            { field: 'issueDate', title: 'Issue Date', dataType: 'date', size: 'md', currentFilter: this.filter },
            { field: 'licenseNotes', title: 'Notes', dataType: 'text', size: 'lg', currentFilter: this.filter },
            {
              field: 'status',
              title: 'Status',
              dataType: 'text',
              size: 'md',
              currentFilter: this.filter,
              columnMenu: this.selectedLicense ? undefined : LicenseStatusColumnMenu
            },
            { field: 'expiryDate', title: 'Expires', dataType: 'date', size: 'md', currentFilter: this.filter },
            this.selectedLicense
              ? undefined
              : {
                  field: '',
                  title: 'View',
                  dataType: 'icon',
                  size: 'xs',
                  cell: (props) => (
                    <td className="k-table-td gridColumn_xs gridColumn_button">
                      <div role="button" onClick={() => this.goToSubscription(props.dataItem)} className={appStyles.button___grid_view}>
                        <DocumentsSVGLogo title="View subscription breakdown" height={32} width={32} />
                      </div>
                    </td>
                  )
                }
          ])}
        </Grid>
      </div>
    );

    return (
      <>
        {mainGrid}

        {!!this.selectedLicense && (
          <BottomButtonContainer backgroundColor="white" layout="left">
            <Button id="buttonViewLicenses" buttonStyle="outline_secondary" size="lg" handleClick={this.back}>
              Back to Subscriptions
            </Button>
          </BottomButtonContainer>
        )}
      </>
    );
  }
}

export default withRouter(LicensesGrid);
